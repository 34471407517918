import {
  assign,
  setup,
  raise,
  sendParent,
  enqueueActions,
  DoneActorEvent,
  fromCallback,
  fromPromise,
} from 'xstate';
import { ON } from '@repo/shared-types';

import {
  ACTIONS,
  ACTORS,
  AnyElementTapEvent,
  ELEMENT_TAP_EVENTS,
  GUARDS,
  STATES,
  TapMachineContext,
  ValidTouchEvent,
} from './index.types';
import { EventObject } from 'xstate';

const forwardEventData = <
  E extends EventObject & { data: unknown },
  A extends ACTIONS | GUARDS,
>(
  type: A,
) => ({ type, params: ({ event }: { event: E }): E['data'] => event.data });

const machine = setup({
  types: {} as {
    context: TapMachineContext;
    events: AnyElementTapEvent;
    input: {
      element: Element;
      repetitions?: number;
      fingers?: number;
    };
  },
  actors: {
    [ACTORS.PASSWORD_PROMPT]: fromPromise<
      boolean,
      {
        password: string;
      }
    >(async ({ input }): Promise<boolean> => {
      await new Promise(resolve => setTimeout(resolve, 10));
      const password = prompt('Tool');
      return password === input.password;
    }),
    [ACTORS.TOUCH_LISTENER]: fromCallback<
      AnyElementTapEvent,
      {
        fingers: number;
        element: Element;
      }
    >(({ input, sendBack }) => {
      const listener = (event: Event) => {
        const touchEvent = event as TouchEvent;
        if (touchEvent.touches && touchEvent.touches.length === input.fingers) {
          sendBack({ type: ELEMENT_TAP_EVENTS.TOUCH, data: Date.now() } as ValidTouchEvent);
        }
      };
      input.element.addEventListener('touchstart', listener);
      return () => {
        input.element.removeEventListener('touchstart', listener);
      };
    }),
  },
  actions: {
    [ACTIONS.RESET_TAPS]: assign({
      taps: 1,
    }),
    [ACTIONS.INCREMENT_TAPS]: assign({
      taps: ({ context }) => context.taps + 1,
    }),
    [ACTIONS.RAISE_REPEATED_EVENT]: raise({
      type: ELEMENT_TAP_EVENTS.REPEATED,
    }),
    [ACTIONS.RECORD_TOUCH]: assign({
      lastTouch: (_, time: number) => time,
    }),
  },
  guards: {
    [GUARDS.EXCEEDED_REPETITIONS]: ({ context }): boolean => context.taps >= context.repetitions,
    [GUARDS.EVENT_WITHIN_TIMEOUT]: ({ context }, time: number): boolean =>
      time - context.lastTouch < context.timeout,
  },
}).createMachine({
  initial: STATES.TOUCHING,
  context: ({ input }) => ({
    taps: 0,
    element: input.element,
    repetitions: input.repetitions || 1,
    fingers: input.fingers || 1,
    password: 'bordeaux',
    lastTouch: 0,
    timeout: 500,
  }),
  states: {
    [STATES.TOUCHING]: {
      invoke: {
        src: ACTORS.TOUCH_LISTENER,
        input: ({ context }) => ({
          fingers: context.fingers,
          element: context.element,
        }),
      },
      on: {
        [ELEMENT_TAP_EVENTS.TOUCH]: [
          {
            guard: forwardEventData(GUARDS.EVENT_WITHIN_TIMEOUT),
            actions: [
              ACTIONS.INCREMENT_TAPS,
              forwardEventData(ACTIONS.RECORD_TOUCH),
              enqueueActions(({ enqueue, check }) => {
                if (check(GUARDS.EXCEEDED_REPETITIONS))
                  enqueue(ACTIONS.RAISE_REPEATED_EVENT);
              }),
            ],
          },
          {
            actions: [
              ACTIONS.RESET_TAPS,
              forwardEventData(ACTIONS.RECORD_TOUCH)
            ]
          }
        ],
        [ELEMENT_TAP_EVENTS.REPEATED]: STATES.CONFIRMING,
      },
    },
    [STATES.CONFIRMING]: {
      invoke: {
        src: ACTORS.PASSWORD_PROMPT,
        input: ({ context }) => ({
          password: context.password,
        }),
        onDone: [
          {
            guard: ({ event }: { event: DoneActorEvent<boolean> }) => event.output,
            target: STATES.FINAL,
          },
          { target: STATES.TOUCHING },
        ],
      },
    },
    [STATES.FINAL]: {
      type: 'final',
      entry: sendParent({ type: ON.openAdTool }),
    },
  },
});
export default machine;
