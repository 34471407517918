import { log } from '@repo/utils';
import { BordeauxMachineContext, UserSyncConfig } from '@repo/shared-types';

const compileUrl = (context: BordeauxMachineContext, userSyncPixel: UserSyncConfig): string => {
  let { url } = userSyncPixel;
  if (userSyncPixel.dynamicUrl) {
    url = userSyncPixel.dynamicUrl(context);
  }
  if (!url) {
    log.error(
      `A URL or URL generator was not defined for UserSyncPixel '${userSyncPixel.bidderName}'`,
    );
    return '';
  }
  const urlObj = new URL(url);

  const params = {
    ...(userSyncPixel.dynamicParams ? userSyncPixel.dynamicParams(context) : {}),
    ...(userSyncPixel.params || {}), // Params must be last to ensure `r=...` comes last
  };
  Object.entries(params).forEach(([param, value]) => {
    urlObj.searchParams.set(param, value);
  });
  return urlObj.toString();
};
export default compileUrl;
