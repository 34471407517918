const performAdBlockTest = () =>
  fetch('https://www3.doubleclick.net', {
    method: 'HEAD',
    mode: 'no-cors',
    cache: 'no-store',
  })
    .then(({ redirected }) => (redirected ? true : false))
    .catch(() => false);

export default performAdBlockTest;
