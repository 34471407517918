import { AnySlotDefinition } from '@repo/shared-types';

const replaceConstants = <O extends object = Record<string, string>>(
  data: O,
  constantEntries: Array<[string, string]>,
): O => {
  return Object.fromEntries<string>(
    Object.entries(data).map(([key, value]) => {
      return [
        key,
        constantEntries.reduce(
          (currentValue, [constName, constValue]) =>
            currentValue?.replace(new RegExp(constName, 'gmi'), constValue),
          value,
        ),
      ];
    }),
  ) as O;
};

const replaceConstantsInSlot = <S extends AnySlotDefinition = AnySlotDefinition>(
  constants: Record<string, string>,
): ((slot: S) => S) => {
  const constantEntries = Object.entries(constants);
  return (slot: S): S => {
    return {
      ...slot,
      style: replaceConstants(slot.style, constantEntries),
      label: replaceConstants(slot.label, constantEntries),
      ...(slot.generatedConfig
        ? {
            generatedConfig: {
              ...slot.generatedConfig,
              containerStyle: replaceConstants(
                slot.generatedConfig.containerStyle,
                constantEntries,
              ),
              spacerStyle: replaceConstants(slot.generatedConfig.spacerStyle, constantEntries),
            },
          }
        : {}),
    };
  };
};
export default replaceConstantsInSlot;
