import { log } from '@repo/utils';
import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export const getExperimentId = (service: ApiMachineEventService): Promise<string> =>
  service.getData<string>('experimentId');

export const setExperimentId = (service: ApiMachineEventService, id: string): void => {
  if (typeof id !== 'string') {
    log.info(`Argument passed to setExperimentId must be a string, ${typeof id} given`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  service.sendEvent({
    type: API_EMIT.SET_EXPERIMENT_ID,
    data: id,
  });
};
