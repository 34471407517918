import { Ad, AdUnitStatus, GPTOutput, DataObjectStoreType } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService): (() => Promise<boolean>) => {
  const firstAdsDeliveredOrViewed = new Promise(res => {
    service.getData<DataObjectStoreType<Ad>>('ads').then(ads =>
      ads.subscribe(ads => {
        ads.forEach(ad =>
          ad.subscribe(() => {
            if (
              ad.getProperty('status') === AdUnitStatus.DELIVERED ||
              ad.getProperty('status') === AdUnitStatus.VIEWED
            ) {
              if (res !== null) {
                res(true);
              }
            }
          }),
        );
      }),
    );
  });
  return async () => {
    await firstAdsDeliveredOrViewed;
    const [disabledLineItems, disabledAdvertisers, ads] = await Promise.all([
      service.getData<Array<number> | undefined>(
        'config',
        'features',
        'customActivations',
        'VIDEO_STICKY_AUTOPLAY_DISABLED',
        'LINE_ITEM',
      ),
      service.getData<Array<number> | undefined>(
        'config',
        'features',
        'customActivations',
        'VIDEO_STICKY_AUTOPLAY_DISABLED',
        'ADVERTISER',
      ),
      service.getData<DataObjectStoreType<Ad>>('ads'),
    ]);
    const gptOutputs = ads
      .getValues()
      .map(ad => ad.getProperty('gptOutput'))
      .filter((gptOutput): gptOutput is GPTOutput => !!gptOutput);
    const isLineItemVideoStickyEnabled = !gptOutputs
      .map(gptOutput => gptOutput.lineItem)
      .some(lineItem => disabledLineItems?.includes(lineItem));
    const isOrderVideoStickyEnabled = !gptOutputs
      .map(gptOutput => gptOutput.advertiser)
      .some(advertiser => disabledAdvertisers?.includes(advertiser));

    return isLineItemVideoStickyEnabled && isOrderVideoStickyEnabled;
  };
};
