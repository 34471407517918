import { Targeting } from '@repo/shared-types';

export default (targeting: Targeting) => {
  const replaceAmpersand = (text: string) => text.replace(/&amp;|&/g, 'and');

  return Object.fromEntries(
    Object.entries(targeting).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          replaceAmpersand(key),
          value.map(item => (typeof item === 'string' ? replaceAmpersand(item) : item)),
        ];
      }
      if (typeof value === 'string') {
        return [replaceAmpersand(key), replaceAmpersand(value)];
      }
      return [replaceAmpersand(key), value];
    }),
  );
};  
