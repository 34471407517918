import { getEnv, sentry } from '@repo/utils';

const env = getEnv();

export default (message = 'Reported from the console'): void => {
  env.setTimeout(() => {
    const error: Error = new Error(message);
    sentry.reportError(error);
  });
};
