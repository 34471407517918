import { AdUnitSizes, AdUnitSize, AdUnitSizeTuple, SlotifyMachineContext } from '@repo/shared-types';

const LOW_FLOOR_PRICES_STEP_INVERSE = 20 // step = 0.05;
const LOW_FLOOR_PRICES_LIMIT = 1.0;
const HIGH_FLOOR_PRICES_STEP_INVERSE = 10 // step = 0.10;
const HIGH_FLOOR_PRICES_LIMIT = 3.0;

const isWidthHeightSize = (size: AdUnitSize): size is AdUnitSizeTuple =>
  Array.isArray(size) &&
  size.length === 2 &&
  typeof size[0] === 'number' &&
  typeof size[1] === 'number';

export const selectFloorPrice = (
  config: Pick<SlotifyMachineContext['config'], 'bidFloors'>,
  sizes: AdUnitSizes,
): number | null => {
  const { bidFloors } = config;
  if (!bidFloors?.floorPrices) return null;

  const flattenSizes = sizes.filter(isWidthHeightSize).map(size => size.join('x'));
  const floorPrices = flattenSizes
  .filter(size => bidFloors.floorPrices && size in bidFloors.floorPrices)
  .map(size => bidFloors?.floorPrices?.[size])
  .filter((price): price is number => price !== undefined);
  return floorPrices.length != 0 ? Math.min(...floorPrices) : null;
}

export const floorPriceTargeting = (floorPrice: number): string => {
  let roundedFloorPrice: number;
  if (floorPrice <= LOW_FLOOR_PRICES_LIMIT) {
    roundedFloorPrice = Math.floor(floorPrice*LOW_FLOOR_PRICES_STEP_INVERSE)/LOW_FLOOR_PRICES_STEP_INVERSE;
  } else if (floorPrice <= HIGH_FLOOR_PRICES_LIMIT) {
    roundedFloorPrice = LOW_FLOOR_PRICES_LIMIT + (Math.floor((floorPrice-LOW_FLOOR_PRICES_LIMIT)*HIGH_FLOOR_PRICES_STEP_INVERSE)/HIGH_FLOOR_PRICES_STEP_INVERSE);
  } else if (floorPrice > HIGH_FLOOR_PRICES_LIMIT) {
    roundedFloorPrice = HIGH_FLOOR_PRICES_LIMIT;
  } else {
    roundedFloorPrice = 0;
  } 
  return roundedFloorPrice.toFixed(2).toString().replace('.', '_');
}