import { JWPlayer } from '@repo/shared-types';
import { getEnv } from './env';

const waitForJwPLayer = (): Promise<() => JWPlayer> => {
  const env = getEnv();
  return new Promise((resolve, reject) => {
    const onJWPlayerInstanceLoaded = (): void => {
      env.document.removeEventListener('jwplayerInstanceLoaded', onJWPlayerInstanceLoaded);
      if (typeof env.jwplayer === 'function') {
        resolve(env.jwplayer);
      } else {
        reject(new Error('jwplayer is not available'));
      }
    };

    env.document.addEventListener('jwplayerInstanceLoaded', onJWPlayerInstanceLoaded);
  });
};

export default waitForJwPLayer;
