import { Labels } from '@future/promjs/lib';

import { PrometheusAPI } from '@repo/shared-types';

import { labels } from '../labels';
import { PrometheusController } from '../PrometheusController';
import { Histogram } from '@future/promjs/lib/histogram';

export const recordAdDensity =
  (histogram: Histogram): PrometheusAPI['recordAdDensity'] =>
  (value: number | void) => {
    if (labels.site && typeof value != 'undefined') {
      const adsLabels: Labels = { target: 'bordeaux', ...labels };
      PrometheusController.update({ metricsUpdated: true });
      histogram.observe(value, adsLabels);
    }
  };
