import { Screens, ON, ScreenPositionedEvent, ScreensPositionedEvent } from '@repo/shared-types';
import { fastdom } from '@repo/utils';
import { EventObject, fromCallback } from 'xstate';
import positionElement from 'ad-framework/slot/position-element';

const screensPositioner = fromCallback<
  EventObject,
  {
    screens: Screens;
  }
>(({ input: { screens }, sendBack }) => {
  const promises = Object.entries(screens).map(([screenId, screen]) =>
    fastdom
      .mutate(() => {
        screen.matches.forEach(({ slot }) => {
          positionElement(
            slot.getProperty('element'),
            slot.getProperty('position'),
            slot.getProperty('hookElement'),
          );
        });
      })
      .then(() => {
        sendBack({
          type: ON.screenPositioned,
          data: { screenId },
        } as ScreenPositionedEvent);
      }),
  );
  Promise.all(promises).then(() => {
    sendBack({
      type: ON.screensPositioned,
    } as ScreensPositionedEvent);
  });
});

export default screensPositioner;
