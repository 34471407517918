import {
  Slotify_DO as DO,
  Slotify_ON as ON,
  Slotify_IF as IF,
  Slotify_GO as GO,
  Slotify_TO as TO,
  Slotify_SPAWN as SPAWN,
  AnySlotifyEvent,
  Slotify_RECIEVE,
  SlotifyMachineContext,
  BordeauxMachineContext,
  Slotify_EMIT,
  SlotifyMachineDefinition,
  SlotInViewEvent,
  AdUnitMode,
  SlotWatcher_EMIT,
} from '@repo/shared-types';
import { and, AnyActorRef, assign, enqueueActions, not, sendParent, setup, stateIn } from 'xstate';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import slotifyGuards from './guards';
import slotifyActions from './actions';
import slotifyActors from './actors';

const slotifyMachine: SlotifyMachineDefinition = setup({
  types: {} as {
    context: SlotifyMachineContext;
    events: AnySlotifyEvent;
    input: BordeauxMachineContext;
  },
  actors: {
    ...slotifyActors,
  },
  guards: {
    ...slotifyGuards,
  },
  actions: {
    ...slotifyActions,
  },
}).createMachine({
  context: ({ input }) => ({
    ...input,
    firstAuctionDone: false,
    incrementalsStarted: false,
    takeoverIncrementalCount: 0,
    batchCounter: 0,
    adCounter: 0,
    adTypeCounters: {},
    adMatches: [],
    newAds: [],

    slotStack: [],
    slotWatchers: [],
    slotPositioners: [],
    slotPositions: {},
    [TO.dynamicSlotGenerator]: {} as AnyActorRef,
  }),
  initial: GO.creatingStaticSlots,
  states: {
    [GO.creatingStaticSlots]: {
      invoke: {
        id: TO.staticSlotGenerator,
        src: SPAWN.staticSlotGenerator,
        input: ({ context }) => ({
          slotDefinitions: context.config.placement.slots.static.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
        }),
      },
      on: {
        [ON.staticSlotsDone]: {
          target: GO.waitForStaticSlotsReady,
        },
      },
    },
    [GO.waitForStaticSlotsReady]: {
      always: {
        guard: IF.allSlotsPositioned,
        target: GO.waitForStandardAdsEnabled,
      },
    },
    [GO.waitForStandardAdsEnabled]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(IF.standardAdsEnabled)) {
          enqueue(DO.raise_enableStandardAds);
        }
      }),
      on: {
        [Slotify_RECIEVE.standardAdsEnabled]: {
          target: GO.yieldingStaticAffinityAds,
        },
      },
    },
    [GO.yieldingStaticAffinityAds]: {
      invoke: {
        id: TO.affinityAdGenerator,
        src: SPAWN.affinityAdGenerator,
        input: ({ context }) => ({
          adDefinitions: context.adUnits.standard,
          slots: context.slots.getValues(),
        }),
      },
      on: {
        [ON.adsMatch]: {
          actions: {
            type: DO.setAdMatches,
            params: ({ event: { data } }) =>
              data.map(({ adDefinition, slot }) => ({
                slot,
                adDefinition,
              })),
          },
          target: GO.createAds,
        },
      },
    },
    [GO.waitForFirstAuction]: {
      on: {
        [ON.firstAuctionDone]: [
          {
            // ADP-12918 anchored ads should be avoided if roadblock (takeover) is active
            guard: and([not(IF.takeoverActive), IF.anchoredNotInTakeover]),
            actions: [
              DO.firstAuctionDone,
              {
                type: DO.setAdMatches,
                params: ({
                  context: {
                    adUnits: { standard: adDefinitions },
                  },
                }) =>
                  adDefinitions
                    .filter(
                      adDefinition =>
                        adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inTakeover,
                    )
                    .map(adDefinition => ({
                      adDefinition,
                    })),
              },
            ],
            target: GO.createAds,
          },
          {
            actions: DO.firstAuctionDone,
            target: GO.waitForIncrementalAdsEnabled,
          },
        ],
      },
    },
    [GO.waitForIncrementalAdsEnabled]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(IF.incrementalAdsEnabled)) {
          enqueue(DO.raise_enableIncrementalAds);
        }
      }),
      on: {
        [Slotify_RECIEVE.incrementalAdsEnabled]: {
          actions: [
            DO.enableIncrementalAds,
            enqueueActions(({ enqueue, check }) => {
              if (check(IF.incrementalAdsEnabled)) enqueue(DO.raise_enableIncrementalAds);
            }),
          ],
        },
        [ON.incrementalAdsEnabled]: {
          target: GO.watchingIncrementalSlots,
        },
      },
    },
    [GO.watchingIncrementalSlots]: {
      entry: [
        assign({ incrementalsStarted: true }),
        DO.createSynamicSlotGenerator,
        DO.watchExclusionZones,
      ],
      always: {
        target: GO.waitingForSlots,
      },
    },
    [GO.waitingForSlots]: {
      always: [
        {
          // Do nothing, keep waiting
          guard: not(IF.slotsToProcess),
        },
        {
          // ADP-13241 Always process native slots
          guard: {
            type: IF.slotIsNative,
            params: ({ context: { slotStack } }) => ({ slot: slotStack[0] }),
          },
          target: GO.processingSlotStack,
        },
        {
          // ADP-13056 Incrementals should continue when roadblocked (takeover) to allow a limited number to be generated
          guard: not(IF.takeoverIncrementalsFilled),
          target: GO.processingSlotStack,
        },
        {
          // ADP-13241 Only stop after all incrementals and native content are filled
          guard: IF.nativeContentFilled,
          target: GO.done,
        },
      ],
    },
    [GO.processingSlotStack]: {
      invoke: {
        id: TO.matchSlot,
        src: SPAWN.matchSlot,
        input: ({
          context: {
            slotStack,
            slots,
            overrideCompanionBounds,
            config,
            takeoverActive,
            avoidanceDistance,
            takeoverIncrementals,
            adUnits,
            ads,
          },
        }) => ({
          slots,
          overrideCompanionBounds,
          config,
          takeoverActive,
          avoidanceDistance,
          takeoverIncrementals,
          adUnits,
          ads,
          slot: slotStack[0],
        }),
        onDone: [
          {
            guard: ({ event: { output } }) => output !== null,
            actions: [
              DO.popSlotStack,
              {
                type: DO.setAdMatches,
                params: ({ event: { output } }) => output!,
              },
            ],
            target: GO.createAds,
          },
          {
            actions: DO.popSlotStack,
            target: GO.waitingForSlots,
          },
        ],
      },
    },
    [GO.createAds]: {
      invoke: {
        id: TO.createAds,
        src: SPAWN.createAds,
        input: ({ context: { adMatches, adCounter, pageAdUnitPath, adTypeCounters, config } }) => ({
          adMatches,
          adCounter,
          pageAdUnitPath,
          adTypeCounters,
          config,
        }),
        onDone: {
          actions: [
            {
              type: DO.incrementAdCounter,
              params: ({ event: { output } }) => output,
            },
            {
              type: DO.incrementTakeoverIncrementalCounter,
              params: ({ event: { output } }) => output,
            },
            {
              type: DO.incrementAdTypeCounters,
              params: ({ event: { output } }) => output,
            },
            {
              type: DO.setNewAds,
              params: ({ event: { output } }) => output,
            },
          ],
          target: GO.insertAds,
        },
      },
    },
    [GO.insertAds]: {
      entry: DO.putNewAdsInStore,
      invoke: {
        id: TO.insertAds,
        src: SPAWN.insertAds,
        input: ({ context: { newAds, slots } }) => ({
          matches: newAds.map(ad => {
            const slotID = ad.getProperty('slotID');
            const slot = slots.getValues().find(slot => slot.getProperty('id') === slotID)!;
            return { slot, ad };
          }),
        }),
        onDone: { target: GO.requestAds },
      },
    },
    [GO.requestAds]: {
      entry: [DO.reorderAds, DO.reportFirstAdLoad, DO.triggerAuction, DO.incrementBatchCounter],
      always: [
        {
          guard: not(IF.firstAuctionDone),
          target: GO.waitForFirstAuction,
        },
        {
          guard: not(IF.incrementalAdsEnabled),
          target: GO.waitForIncrementalAdsEnabled,
        },
        {
          guard: not(IF.incrementalsStarted),
          target: GO.watchingIncrementalSlots,
        },
        {
          target: GO.waitingForSlots,
        },
      ],
    },
    [GO.done]: {},
  },
  on: {
    [ON.slotHookFailed]: {
      actions: { type: DO.reportSlotHookFailed, params: ({ event }) => event.data },
    },
    [ON.slotCreated]: {
      actions: [
        { type: DO.addSlot, params: ({ event }) => event.data },
        { type: DO.positionSlotElement, params: ({ event }) => event.data },
        {
          type: DO.createAdditionalSlotWatchers,
          params: ({ event }) => event.data,
        },
        { type: DO.createSlotWatcher, params: ({ event }) => event.data },
      ],
    },
    [Slotify_RECIEVE.standardAdsEnabled]: {
      actions: DO.enableStandardAds,
    },
    [Slotify_RECIEVE.incrementalAdsEnabled]: {
      actions: DO.enableIncrementalAds,
    },
    [ON.adAffinityFailed]: {
      actions: {
        type: DO.reportAdAffinityFailed,
        params: ({ event }) => event.data,
      },
    },

    [SlotWatcher_EMIT.viewabilityChanged]: {
      actions: enqueueActions(({ check, enqueue }) => {
        enqueue.sendParent(({ event: { data } }) => ({
          type: Slotify_EMIT.slotViewabilityChanged,
          data,
        }));
        if (
          check({
            type: IF.intersectionInView,
            params: ({
              event: {
                data: { intersection },
              },
            }) => intersection,
          })
        )
          enqueue.raise(
            ({
              event: {
                data: { slot },
              },
            }) =>
              ({
                type: ON.slotInView,
                data: slot,
              }) as SlotInViewEvent,
          );
      }),
    },

    [ON.slotInView]: [
      {
        guard: not(stateIn(GO.waitingForSlots)),
        actions: {
          type: DO.addSlotToStack,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
      },
      {
        guard: {
          type: IF.slotIsNative,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        actions: {
          type: DO.addSlotToStack,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${GO.processingSlotStack}`,
      },
      {
        guard: not(IF.takeoverIncrementalsFilled),
        actions: {
          type: DO.addSlotToStack,
          params: ({ event: { data: slot } }) => ({ slot }),
        },
        target: `.${GO.processingSlotStack}`,
      },
    ],
    [ON.findNewDynamicSlots]: {
      guard: IF.incrementalsStarted,
      actions: DO.triggerDynamicSlotRefresh,
    },
    [ON.slotPositioned]: {
      actions: [
        assign({
          slotPositions: ({
            context: { slotPositions },
            event: {
              data: { slot, position },
            },
          }) => ({
            ...slotPositions,
            [slot.getProperty('id')]: position,
          }),
        }),
        sendParent(({ event: { data } }) => ({
          type: Slotify_EMIT.slotPositioned,
          data,
        })),
      ],
    },
    [ON.setTakeoverStatus]: {
      actions: assign({
        takeoverActive: ({ event: { data } }) => data,
      }),
    },
  },
});

export default slotifyMachine;
