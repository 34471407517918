import { once } from 'ramda';
import { log, EuidCallback, getEnv, getParam, hasUserConsentedVendorGDPR } from '@repo/utils';
import { GDPRConsent } from '@repo/shared-types';

const env = getEnv();
const lrh = getParam('lrh');

export default once(async function processEUID(gdprConsent: GDPRConsent): Promise<void> {
  if (lrh && hasUserConsentedVendorGDPR(gdprConsent, 21)) {
    const { __euid } = env;

    const onEuidIdentityUpdated: EuidCallback = (state): void => {
      if (__euid?.isLoginRequired()) {
        log.info('EUID login required');
      } else {
        log.info('EUID login not required');
      }
      log.info(`EUID: ${state.statusText}`);
    };

    const euidIdentity = await fetch(`https://euid.futurehybrid.tech/euid?lrh=${lrh}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }).then(response => response.json());

    __euid?.init({
      callback: onEuidIdentityUpdated,
      identity: await euidIdentity.body,
    });
  }
});
