import { Ad, ActionArgs, DataObjectType } from '@repo/shared-types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';

const extractLineItem = (ad: DataObjectType<Ad>): number => {
  const adGPTOutput = ad.getProperty('gptOutput');
  return adGPTOutput?.lineItem || -1;
};
const lineItemsMatch = (ads: Array<DataObjectType<Ad>>, config: BordeauxConfig): boolean => {
  const lineItems = ads.map(extractLineItem);
  const firstLineItem = lineItems[0];
  if (firstLineItem === -1) {
    return false;
  }
  return (
    ((config.features.customActivations.FULL_PAGE_TAKEOVER.LINE_ITEM ?? []).length > 0 &&
      lineItems.every(
        (item: number): boolean =>
          item === firstLineItem &&
          (config.features.customActivations.FULL_PAGE_TAKEOVER.LINE_ITEM ?? []).includes(item),
      )) ??
    false
  );
};

const getTakeoverStatus = ({ context }: ActionArgs): boolean => {
  if (context.queryParameters.forceTakeover !== null) {
    return context.queryParameters.forceTakeover === 'true';
  }

  const { ads, config } = context;
  const takeoverMatchingAds = ads.getValues().filter(ad => ad.getProperty('inTakeover'));

  if (takeoverMatchingAds.length === 0) {
    return false;
  }

  return lineItemsMatch(takeoverMatchingAds, config);
};

export default getTakeoverStatus;
