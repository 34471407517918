import { z } from 'zod';
import adaptPlacementConfig from '../../ad-framework/placement/adapt-config';
import { placementSchema } from '../../ad-framework/placement/zod-schema';
import { targetingSchema } from '../../ad-framework/targeting/zod-schema';
import adaptTargetingConfig from '../../ad-framework/targeting/adapt-config';
import { hybridFeaturesSchema } from '@repo/features';
import { thirdPartyAPIConfigSchema } from '../../third-party-apis/zod-schema';

export const debugSchema = z
  .record(z.union([z.number(), z.array(z.record(z.unknown()))]))
  .default({});

export const errorSchema = z
  .object({
    message: z.string().optional(),
  })
  .default({});

export const bidFloorsSchema = z
  .object({
    useForPrebid: z.boolean().default(false),
    exp: z.number().default(-1),
    floorPrices: z.record(z.string(), z.number()).nullish().default(null),
  })
  .default({});

export const sommelierResponseSchema = z
  .object({
    placement: placementSchema.transform(adaptPlacementConfig),
    targeting: targetingSchema.transform(adaptTargetingConfig),
    config: thirdPartyAPIConfigSchema,
    bidFloors: bidFloorsSchema,
    features: hybridFeaturesSchema,

    /* TODO: These are unused
      id: z.number(),
      server: z.string(),
      ts: z.number(),
      debug: debugSchema,
      error: errorSchema,
    */
  })
  .strip();
