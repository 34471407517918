import { Registry } from '@future/promjs/lib/registry';

import { log, sendBeacon } from '@repo/utils';
import { PrometheusController } from './PrometheusController';

const shouldPush = (): boolean => {
  return (
    PrometheusController.getProperty('metricsUpdated') &&
    PrometheusController.getProperty('metricsEnabled')
  );
};

export const pushMetrics = (registry: Registry) => (): void => {
  if (!shouldPush()) {
    return;
  }

  const url = 'https://bordeaux-gateway.futureplc.com/push_metrics/';
  const params: string = registry.metrics();
  const sent = sendBeacon(url, params);
  if (sent) {
    PrometheusController.update({ metricsUpdated: false });
    registry.reset();
  } else {
    log.error('Error pushing Bordeaux metrics data.');
  }
};
