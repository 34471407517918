import getCftParameters from 'ad-framework/targeting/cft-parameters';
import { BordeauxMachineContext, ON } from '@repo//shared-types';
import { getUserid } from '@repo/utils';
import { AnyEventObject, fromCallback } from 'xstate';

const arbitraryEventEmitter = fromCallback<
  AnyEventObject,
  Pick<BordeauxMachineContext, 'pageParameters'>
>(({ sendBack, input: context }) => {
  getUserid(context).then(hybridId => {
    if (!hybridId) return;
    sendBack({
      type: ON.setHybridId,
      data: hybridId,
    });
  });
  getCftParameters().then(cftParameters => {
    sendBack({
      type: ON.setCFTParameters,
      data: cftParameters,
    });
  });
});
export default arbitraryEventEmitter;
