type Callback = (entry: IntersectionObserverEntry) => void;

export const getObserver = (element: Element, callback: Callback, slotBuffer: number) => {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => callback(entry));
    },
    {
      rootMargin: `${slotBuffer}px 0px ${slotBuffer}px 0px`,
      threshold: [0, 1],
    },
  );

  observer.observe(element);

  return () => {
    observer.unobserve(element);
  };
};

export function calculatePosition(entry: IntersectionObserverEntry, buffer: number): number {
  if (entry.boundingClientRect.bottom < (entry.rootBounds?.top || 0) + buffer) {
    return -1;
  }

  if (entry.boundingClientRect.y > (entry.rootBounds?.bottom || 0) - buffer) {
    return 1;
  }

  return 0;
}

export const noop = (): void => {
  /** noop */
};
