import { Ad, AdUnitStatus, DataObjectStore } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

const getStandardAdsLoaded = (service: ApiMachineEventService) => {
  const loadedPromise = service.getData<DataObjectStore<Ad>>('ads').then(
    ads =>
      new Promise<boolean>(resolve => {
        ads.subscribe(ads => {
          ads.forEach(ad =>
            ad.subscribe(() => {
              if (
                ad.getProperty('status') === AdUnitStatus.DELIVERED ||
                ad.getProperty('status') === AdUnitStatus.UNDELIVERED ||
                ad.getProperty('status') === AdUnitStatus.INVALID
              ) {
                resolve(true);
              }
            }),
          );
        });
      }),
  );

  return (): Promise<boolean> => loadedPromise;
};
export default getStandardAdsLoaded;
