
export enum AD_AFFINITY_FAILED_REASONS {
  ABSENT = 'ABSENT',
  NO_SLOT = 'NO_SLOT',
  SLOT_FILLED = 'SLOT_FILLED',
}

export enum Slotify_GO {
  creatingStaticSlots = 'SLOTIFY:STATE:CREATING_STATIC_SLOTS',
  waitForStaticSlotsReady = 'SLOTIFY:STATE:WAIT_FOR_STATIC_SLOTS_READY',
  waitForStandardAdsEnabled = 'SLOTIFY:STATE:WAIT_FOR_STANDARD_ADS_ENABLED',
  waitForFirstAuction = 'SLOTIFY:STATE:WAIT_FOR_FIRST_AUCTION',
  waitForIncrementalAdsEnabled = 'SLOTIFY:STATE:WAIT_FOR_INCREMENTAL_ADS_ENABLED',
  yieldingStaticAffinityAds = 'SLOTIFY:STATE:YIELDING_STATIC_AFFINITY_ADS',
  watchingIncrementalSlots = 'SLOTIFY:STATE:WATCHING_INCREMENTAL_SLOTS',
  waitingForSlots = 'SLOTIFY:STATE:WAITING_FOR_SLOTS',
  processingSlotStack = 'SLOTIFY:STATE:PROCESSING_SLOT_STACK',
  createAds = 'SLOTIFY:STATE:CREATE_ADS',
  insertAds = 'SLOTIFY:STATE:INSERT_ADS',
  requestAds = 'SLOTIFY:STATE:REQUEST_ADS',
  done = 'SLOTIFY:STATE:DONE',
}

export enum Slotify_DO {
  reportAdAffinityFailed = 'SLOTIFY:ACTION:REPORT_AD_AFFINITY_FAILED',
  enableIncrementalAds = 'SLOTIFY:ACTION:ENABLE_INCREMENTAL_ADS',
  enableStandardAds = 'SLOTIFY:ACTION:ENABLE_STANDARD_ADS',
  positionSlotElement = 'SLOTIFY:ACTION:POSITION_SLOT_ELEMENT',
  addSlot = 'SLOTIFY:ACTION:ADD_SLOT',
  createAdditionalSlotWatchers = 'SLOTIFY:ACTION:CREATE_ADDITIONAL_SLOT_WATCHERS',
  createSlotWatcher = 'SLOTIFY:ACTION:CREATE_SLOT_WATCHER',
  reportSlotHookFailed = 'SLOTIFY:ACTION:REPORT_SLOT_HOOK_FAILED',

  createSynamicSlotGenerator = 'SLOTIFY:ACTION:CREATE_DYNAMIC_SLOT_GENERATOR',
  watchExclusionZones = 'SLOTIFY:ACTION:WATCH_EXCLUSION_ZONES',
  raise_enableIncrementalAds = 'SLOTIFY:ACTION:RAISE_ENABLE_INCREMENTAL_ADS',
  raise_enableStandardAds = 'SLOTIFY:ACTION:RAISE_ENABLE_STANDARD_ADS',

  reorderAds = 'SLOTIFY:ACTION:REORDER_ADS',

  addSlotToStack = 'SLOTIFY:ACTION:ADD_SLOT_TO_STACK',

  incrementAdCounter = 'SLOTIFY:ACTION:INCREMENT_AD_COUNTER',
  incrementTakeoverIncrementalCounter = 'SLOTIFY:ACTION:INCREMENT_TAKEOVER_INCREMENTAL_COUNTER',
  incrementAdTypeCounters = 'SLOTIFY:ACTION:INCREMENT_AD_TYPE_COUNTERS',

  triggerDynamicSlotRefresh = 'SLOTIFY:ACTION:TRIGGER_DYNAMIC_SLOT_REFRESH',
  incrementBatchCounter = 'SLOTIFY:ACTION:INCREMENT_BATCH_COUNTER',
  triggerAuction = 'SLOTIFY:ACTION:TRIGGER_AUCTION',
  reportFirstAdLoad = 'SLOTIFY:ACTION:REPORT_FIRST_AD_LOAD',
  putNewAdsInStore = 'SLOTIFY:ACTION:PUT_NEW_ADS_IN_STORE',
  setNewAds = 'SLOTIFY:ACTION:SET_NEW_ADS',
  popSlotStack = 'SLOTIFY:ACTION:POP_SLOT_STACK',
  setAdMatches = 'SLOTIFY:ACTION:SET_AD_MATCHES',
  firstAuctionDone = 'SLOTIFY:ACTION:FIRST_AUCTION_DONE',
}

export enum Slotify_ON {
  slotHookFailed = 'SLOTIFY:EVENT:SLOT_HOOK_FAILED',
  adAffinityFailed = 'SLOTIFY:EVENT:AD_AFFINITY_FAILED',

  slotCreated = 'SLOTIFY:EVENT:SLOT_CREATED',
  adsMatch = 'SLOTIFY:EVENT:ADS_MATCH',

  staticSlotsDone = 'SLOTIFY:EVENT:STATIC_SLOTS_DONE',
  generatedSlotsDone = 'SLOTIFY:EVENT:GENERATED_SLOTS_DONE',
  
  slotViewabilityChanged = 'SLOTIFY:EVENT:SLOT_VIEWABILITY_CHANGED',
  slotPositioned = 'SLOTIFY:EVENT:SLOT_POSITIONED',

  slotInView = 'SLOTIFY:EVENT:SLOT_IN_VIEW',

  incrementalAdsEnabled = 'SLOTIFY:EVENT:INCREMENTAL_ADS_ENABLED_INTERNAL',
  setTakeoverStatus = 'SLOTIFY:EVENT:SET_TAKEOVER_STATUS',
  firstAuctionDone = 'SLOTIFY:EVENT:FIRST_AUCTION_DONE',

  findNewDynamicSlots = 'SLOTIFY:EVENT:FIND_NEW_DYNAMIC_SLOTS',
}

export enum Slotify_IF {
  standardAdsEnabled = 'SLOTIFY:GUARD:STANDARD_ADS_ENABLED',
  incrementalAdsEnabled = 'SLOTIFY:GUARD:INCREMENTAL_ADS_ENABLED',
  slotsToProcess = 'SLOTIFY:GUARD:SLOTS_TO_PROCESS',
  takeoverIncrementalsFilled = 'SLOTIFY:GUARD:TAKEOVER_INCREMENTALS_FILLED',
  firstAuctionDone = 'SLOTIFY:GUARD:FIRST_AUCTION_DONE',
  slotIsNative = 'SLOTIFY:GUARD:SLOT_IS_NATIVE',
  nativeContentFilled = 'SLOTIFY:GUARD:NATIVE_CONTENT_FILLED',
  intersectionInView = 'SLOTIFY:GUARD:INTERSECTION_IN_VIEW',
  incrementalsStarted = 'SLOTIFY:GUARD:INCREMENTALS_STARTED',
  allSlotsPositioned = 'SLOTIFY:GUARD:ALL_SLOTS_POSITIONED',
  takeoverActive = 'SLOTIFY:GUARD:TAKEOVER_ACTIVE',
  anchoredNotInTakeover = 'SLOTIFY:GUARD:ANCHORED_NOT_IN_TAKEOVER',
}

export enum Slotify_SPAWN {
  staticSlotGenerator = 'SLOTIFY:ACTOR:STATIC_SLOT_GENERATOR',
  dynamicSlotGenerator = 'SLOTIFY:ACTOR:DYNAMIC_SLOT_GENERATOR',
  affinityAdGenerator = 'SLOTIFY:ACTOR:AFFINITY_AD_GENERATOR',
  slotWatcher = 'SLOTIFY:ACTOR:SLOT_WATCHER',
  slotPositioner = 'SLOTIFY:ACTOR:SLOT_POSITIONER',
  matchSlot = 'SLOTIFY:ACTOR:MATCH_SLOT',
  createAds = 'SLOTIFY:ACTOR:CREATE_ADS',
  insertAds = 'SLOTIFY:ACTOR:INSERT_ADS',
}
export enum Slotify_TO {
  staticSlotGenerator = 'STATIC_SLOT_GENERATOR',
  dynamicSlotGenerator = 'DYNAMIC_SLOT_GENERATOR',
  affinityAdGenerator = 'AFFINITY_AD_GENERATOR',
  slotWatcher = 'SLOT_WATCHER',
  slotPositioner = 'SLOT_POSITIONER',
  matchSlot = 'MATCH_SLOT',
  createAds = 'CREATE_ADS',
  insertAds = 'INSERT_ADS',
}

export enum Slotify_EMIT {
  slotViewabilityChanged = 'SLOTIFY:EVENT_OUT:SLOT_VIEWABILITY_CHANGED',
  slotPositioned = 'SLOTIFY:EVENT_OUT:SLOT_POSITIONED',
}
export enum Slotify_RECIEVE {
  incrementalAdsEnabled = 'SLOTIFY:EVENT_IN:INCREMENTAL_ADS_ENABLED',
  standardAdsEnabled = 'SLOTIFY:EVENT_IN:STANDARD_ADS_ENABLED',
}