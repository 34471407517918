import { getGDPRData } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const sharethrough: UserSyncConfig = {
  id: '64',
  bidderName: 'sharethrough',
  iabIdList: [80],
  element: UserSyncElement.IFRAME,
  url: 'https://match.sharethrough.com/universal/v1',
  params: {
    supply_id: 'dSvJjhJF',
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return gdprData
      ? {
          gdpr: 'true',
          gdpr_consent: gdprData,
        }
      : ({} as Record<string, string>);
  },
};
export default sharethrough;
