const createElement = (
  elementType: string,
  attributes: Partial<{
    style: Partial<CSSStyleDeclaration>;
    onClick: EventListenerOrEventListenerObject;
    [attr: string]: any;
  }> = {},
  ...children: Array<HTMLElement | string | null>
): HTMLElement => {
  const element = document.createElement(elementType);
  Object.entries(attributes).forEach(([attribute, value]) => {
    switch (attribute) {
      case 'style':
        Object.assign(element.style, value);
        break;
      case 'onClick':
        element.addEventListener('click', value);
        break;
      default:
        element[attribute] = value;
        element.setAttribute(attribute, value);
    }
  });
  children.forEach(child => {
    if (child === null) return;

    if (typeof child === 'string') {
      element.innerHTML += child;
    } else {
      element.appendChild(child);
    }
  });
  return element;
};

export default createElement;
