import { ActionArgs, MultiFrameAdFeatureEvent } from '@repo/shared-types';

type Callback = (frame: HTMLIFrameElement) => void;
const frames: Record<string, Record<string, HTMLIFrameElement>> = {};
const callbacks: Record<string, Record<string, Array<Callback>>> = {};

export const getFrames = (creativeID: string) => frames[creativeID] || {};
export const onFrame = (creativeID: string, name: string, callback: Callback) => {
  callbacks[creativeID] = callbacks[creativeID] || {};
  callbacks[creativeID][name] = callbacks[creativeID][name] || [];
  callbacks[creativeID][name].push(callback);
};

const multiFrame = ({}: ActionArgs, data: MultiFrameAdFeatureEvent['data']): void => {
  frames[data.creativeID] = frames[data.creativeID] || {};

  frames[data.creativeID][data.name] = data.frame;
  callbacks[data.creativeID]?.[data.name]?.forEach(callback => {
    callback(data.frame);
  });
};
export default multiFrame;
