import { getEnv } from '../env';
import { LogEvent, LogLevel, LogItem, LogCounts, LogAPI, LogHistory } from '@repo/shared-types';

const LOG_PREFIX = '%c BORDEAUX ';
const LOG_COLOUR = 'background: #800020; color: #ffffff';
const env = getEnv();

let currentLogItemID = 0;
const history: LogHistory = [];
const counts: LogCounts = {
  all: 0,
  info: 0,
  warn: 0,
  error: 0,
};

const logs: Array<LogEvent> = [];
const logCallbacks: { [type: string]: Array<(logEvent: LogEvent) => void> } = {
  all: [],
  info: [],
  warn: [],
  error: [],
};

export const api: LogAPI = {
  history,
  counts,
  logs,
  onLogAll: callback => logCallbacks.all.push(callback),
  onLogInfo: callback => logCallbacks.info.push(callback),
  onLogWarn: callback => logCallbacks.warn.push(callback),
  onLogError: callback => logCallbacks.error.push(callback),
};

const wrapLog = (level: LogLevel) => {
  return function logOut(...logData: Array<unknown>): void {
    const args = Array.prototype.slice.call(logData);
    const logItem: LogItem = {
      id: currentLogItemID,
      timestamp: Date.now(),
      level,
      args,
    };
    currentLogItemID += 1;
    counts.all += 1;
    counts[level] += 1;
    history.push(logItem);

    const logEvent: LogEvent = { type: `log-${level}`, logItem };
    logs.push(logEvent);

    logCallbacks.all.forEach(callback => callback(logEvent));
    switch (level) {
      case 'info':
        logCallbacks.info.forEach(callback => callback(logEvent));
        break;
      case 'warn':
        logCallbacks.warn.forEach(callback => callback(logEvent));
        break;
      case 'error':
        logCallbacks.error.forEach(callback => callback(logEvent));
        break;
      default:
        break;
    }

    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const outputMap: Record<LogLevel, 'log' | 'warn' | 'error'> = {
      info: 'log',
      warn: 'warn',
      error: 'error',
    };
    const outputType = outputMap[level];

    const consoleArgs = Array.prototype.slice.call(logData);
    consoleArgs.unshift(LOG_COLOUR);
    consoleArgs.unshift(LOG_PREFIX);

    env.console[outputType](...consoleArgs);
  };
};

const log = {
  info: wrapLog('info'),
  warn: wrapLog('warn'),
  error: wrapLog('error'),
};

export default log;
