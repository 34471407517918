import { AdUnitCategory, SommelierResponseAdUnit } from '@repo/shared-types';

export const leaderboardAdUnit: SommelierResponseAdUnit = {
  type: 'standard',
  name: 'leaderboard',
  slotAffinity: 'leaderboard',
  sizes: [
    { w: 728, h: 90 },
    { w: 728, h: 91 },
  ],
  targeting: {
    pos: ['1'],
    placement: [`dfp_rs_tablet_leaderboard_1`],
  },
  properties: {},
};

export const incrementalLeaderboardAdUnit: SommelierResponseAdUnit = {
  type: 'incremental',
  name: `incr-leaderboard`,
  sizes: [{ w: 728, h: 90 }],
  targeting: {
    pos: ['2'],
    placement: [`dfp_rs_tablet_leaderboard`],
    format: ['takeover'],
  },
  properties: {
    category: AdUnitCategory.LEADERBOARD,
  },
};
