import { ThirdPartyAPIMachineConfigs, ThirdParty, PartialPick } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.AM_CLIO], 'thirdParty'> = {
  thirdParty: ThirdParty.AM_CLIO,
  scriptLocation: 'https://cadmus.script.ac/d4el4parm0zb3/script.js',
  methods: {
    loadScript: (scriptLocation): Promise<void> =>
      loadScript(scriptLocation, 'bordeaux-am-clio', { async: true }),
  },
};
export default config;
