export default (key: string, path: string, delimiter = '&'): null | string => {
  if (key === '') {
    return null;
  }
  const start: number = path.indexOf(`${key}=`);
  if (start >= 0) {
    const eq: number = path.indexOf('=', start);
    const end: number = path.indexOf(delimiter, eq + 1);
    return end >= 0 ? path.substring(eq + 1, end) : path.substring(eq + 1);
  }
  return null;
};
