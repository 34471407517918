import { log } from '@repo/utils';
import { ApiMachineEventService } from './events.service';

export default async (service: ApiMachineEventService): Promise<null | string> => {
  await service.waitFor.hybridId;
  return service.getData<null | string>('hybridId').catch(err => {
    log.warn(err);
    return null;
  });
};
