import getEnv from './env';

const env = getEnv();

export default (): Promise<string[] | null> => {
  return new Promise(resolve => {
    try {
      const segments = JSON.parse(env.localStorage._pdfps || '[]').slice(0, 100);
      resolve(segments);
    } catch (_e) {
      resolve(null);
    }
  });
};
