import { Ad, AdUnitMode, BordeauxMachineContext, DataObjectType } from '@repo/shared-types';
import { log, getRefreshTargetingValues } from '@repo/utils';
import { adCanRefresh, tandemAdsPresent } from '../logic';
import { updateViewabilityTargeting } from 'slotify/viewability-targeting';

const isSelected = (name: string, adUnits: Array<string>): boolean =>
  adUnits.length === 0 || adUnits.indexOf(name) !== -1;

const isInView = (ad: DataObjectType<Ad>): boolean => {
  const adMode = ad.getProperty('mode');
  const isAnchored = adMode === AdUnitMode.ANCHORED;
  const isSkyscraper = adMode === AdUnitMode.SKYSCRAPER;
  const isOutOfPage = adMode === AdUnitMode.OOP;
  const inView = ad.getProperty('viewed') && ad.getProperty('inView');

  return inView || isAnchored || isSkyscraper || isOutOfPage;
};

const getListOfAdsToRefresh = (
  context: Pick<
    BordeauxMachineContext,
    | 'ads'
    | 'slots'
    | 'unrefreshableNames'
    | 'unrefreshableStatuses'
    | 'unrefreshableModes'
    | 'unrefreshableCategories'
    | 'unrefreshableOrders'
    | 'unrefreshableLineItems'
    | 'unrefreshableAdvertisers'
    | 'anchoredRefreshDisabled'
  >,
  adUnitNames: Array<string>,
  inView: boolean,
): Array<DataObjectType<Ad>> => {
  const refreshableAds = context.ads.getValues().filter(adCanRefresh(context));
  const selectedAds = refreshableAds.filter(ad => isSelected(ad.getProperty('name'), adUnitNames));
  const validAds = inView ? selectedAds.filter(isInView) : selectedAds;
  const tandemValidAds = tandemAdsPresent(validAds, context.slots.getValues());

  return tandemValidAds;
};

const requestRefresh = (
  context: Pick<
    BordeauxMachineContext,
    | 'ads'
    | 'slots'
    | 'unrefreshableNames'
    | 'unrefreshableStatuses'
    | 'unrefreshableModes'
    | 'unrefreshableCategories'
    | 'unrefreshableOrders'
    | 'unrefreshableLineItems'
    | 'unrefreshableAdvertisers'
    | 'anchoredRefreshDisabled'
  >,
  adUnitNames: Array<string>,
  inView: boolean,
): Array<DataObjectType<Ad>> => {
  const adsToRefresh = getListOfAdsToRefresh(context, adUnitNames, inView);
  if (adsToRefresh.length === 0) {
    log.warn(
      `API refresh - No ad units could be refreshed with parameters: ad units ${JSON.stringify(
        adUnitNames,
      )}, inView: ${inView}`,
    );
    return [];
  }
  adsToRefresh.forEach(ad => {
    const targeting = ad.getProperty('targeting');
    ad.update({
      targeting: {
        ...targeting,
        ...getRefreshTargetingValues(ad, true),
        ...updateViewabilityTargeting(
          String(targeting['plcmt-slot-refreshed']),
          getRefreshTargetingValues(ad, true)['refresh'],
          String(getRefreshTargetingValues(ad, true)['api-refresh']),
        ),
      },
    });
  });
  return adsToRefresh;
};
export default requestRefresh;
