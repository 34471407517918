import { AdUnitMode, SommelierResponseAdUnit } from '@repo/shared-types';

export default {
  type: AdUnitMode.OOP,
  name: 'skin',
  sizes: [{ w: 1, h: 1 }],
  targeting: {
    oop: ['skin'],
  },
  properties: {},
} as SommelierResponseAdUnit;
