import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export const setPrebidAnalyticsEnabled = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EMIT.SET_PREBID_ANALYTICS_ENABLED,
    data: true,
  });
};

export const setPrebidAnalyticsDisabled = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EMIT.SET_PREBID_ANALYTICS_ENABLED,
    data: false,
  });
};
