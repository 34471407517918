export enum Panels {
  MAIN =  'main',
  ADS =  'ads',
  SLOTS =  'slots',
  TARGETING =  'targeting',
  THIRD_PARTIES =  'api',
  COOKIES = 'cookies',
  CONSENT = 'consent',
  LOGS = 'logs',
  METRICS = 'metrics',
  SOMMELIER = 'sommelier',
  PREBID = 'prebid',
  VISUALISER = 'visualiser',
}

export enum Tool_EMIT {
  getTargeting = 'TOOl:EMIT:GET_TARGETING',
  setPosition = 'TOOL:EMIT:SET_POSITION',
  setMinimised = 'TOOL:EMIT:SET_MINIMISED',
  scrollToSlot = 'TOOL:EMIT:SCROLL_TO_SLOT',
  scrollToElement = 'TOOL:EMIT:SCROLL_TO_ELEMENT',
}
export enum Tool_RECIEVE {
  getTargeting = 'TOOl:RECIEVE:GET_TARGETING'
}