import { AdManagerMachineContext, AnyAdManagerEvent } from '@repo/shared-types';
import { assign as genericAssign, NonReducibleUnknown } from 'xstate';

const assign = <R extends AnyAdManagerEvent>(
  ...args: Parameters<
    typeof genericAssign<AdManagerMachineContext, R, NonReducibleUnknown, AnyAdManagerEvent, any>
  >
) =>
  genericAssign<AdManagerMachineContext, R, NonReducibleUnknown, AnyAdManagerEvent, any>(...args);

export default assign;

export const assignParams = <P = NonReducibleUnknown>(
  ...args: Parameters<
    typeof genericAssign<AdManagerMachineContext, AnyAdManagerEvent, P, AnyAdManagerEvent, any>
  >
) => genericAssign<AdManagerMachineContext, AnyAdManagerEvent, P, AnyAdManagerEvent, any>(...args);
