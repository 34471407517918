import { getEnv } from '@repo/utils';
import {
  ActionArgs,
  PreBidEventAuction,
  PreBidEventData,
  PreBidEventTypes,
} from '@repo/shared-types';

export const sampleList = <T = unknown>(array: Array<T>, sampleSize: number): Array<T> => {
  // Sample size must be an integer
  sampleSize = Math.floor(sampleSize);
  // Return nothing if sample size is zero
  if (sampleSize <= 0) return [];
  // Return everything if sample size is bigger than list
  if (sampleSize >= array.length) return [...array];
  // Return the middle entry if the sample size is 1 (n >> 1 effectively divides by 2 and floors the result)
  if (sampleSize === 1) return [array[array.length >> 1]];
  // Pick the right number of evenly spaced entries
  return Array.from(
    { length: sampleSize },
    (_, i) => array[Math.round((i / (sampleSize - 1)) * (array.length - 1))],
  );
};

const getLoadTimeDistribution = (time: number): string => {
  let distribution: string;

  if (time >= 0 && time < 200) {
    distribution = '0-200ms';
  } else if (time >= 200 && time < 300) {
    distribution = '200-300ms';
  } else if (time >= 300 && time < 400) {
    distribution = '300-400ms';
  } else if (time >= 400 && time < 500) {
    distribution = '400-500ms';
  } else if (time >= 500 && time < 600) {
    distribution = '500-600ms';
  } else if (time >= 600 && time < 800) {
    distribution = '600-800ms';
  } else if (time >= 800 && time < 1000) {
    distribution = '800-1000ms';
  } else if (time >= 1000 && time < 1200) {
    distribution = '1000-1200ms';
  } else if (time >= 1200 && time < 1500) {
    distribution = '1200-1500ms';
  } else if (time >= 1500 && time < 2000) {
    distribution = '1500-2000ms';
  } else {
    distribution = '> 2000ms';
  }

  return distribution;
};

const getCpmDistribution = (cpm: number): string => {
  let distribution: string;

  if (cpm >= 0 && cpm < 0.5) {
    distribution = '$0-0.5';
  } else if (cpm >= 0.5 && cpm < 1) {
    distribution = '$0.5-1';
  } else if (cpm >= 1 && cpm < 1.5) {
    distribution = '$1-1.5';
  } else if (cpm >= 1.5 && cpm < 2) {
    distribution = '$1.5-2';
  } else if (cpm >= 2 && cpm < 2.5) {
    distribution = '$2-2.5';
  } else if (cpm >= 2.5 && cpm < 3) {
    distribution = '$2.5-3';
  } else if (cpm >= 3 && cpm < 4) {
    distribution = '$3-4';
  } else if (cpm >= 4 && cpm < 6) {
    distribution = '$4-6';
  } else if (cpm >= 6 && cpm < 8) {
    distribution = '$6-8';
  } else {
    distribution = '> $8';
  }

  return distribution;
};

export const cloneBidEvent = (bidEvent: PreBidEventAuction): PreBidEventAuction => {
  return {
    ...bidEvent,
    ...('arguments' in bidEvent ? { arguments: [...(bidEvent.arguments as Array<unknown>)] } : {}),
  };
};

export const getLatestPrebidAnalytics = ({
  context: { prebidAnalyticsData: preExistingEvents },
}: ActionArgs): Array<PreBidEventData> => {
  const env = getEnv();
  const events = env.prebid?.getEvents() || [];
  const alreadyPocessedEvents = preExistingEvents.length;

  const processed: Array<PreBidEventData> = events
    .slice(alreadyPocessedEvents)
    .map(({ args: bidEvent, eventType: bidEventType }, i) => {
      const bidEventId = i + alreadyPocessedEvents;
      if (bidEventType === PreBidEventTypes.BID_RESPONSE) {
        return {
          bidEventId,
          bidEventType,
          bidEvent: cloneBidEvent(bidEvent),
          loadTimeDistribution: getLoadTimeDistribution((bidEvent as any).timeToRespond),
          cpmDistribution: getCpmDistribution((bidEvent as any).cpm),
        } as PreBidEventData;
      } else {
        return { bidEventId, bidEventType, bidEvent: cloneBidEvent(bidEvent) } as PreBidEventData;
      }
    });

  return [...preExistingEvents, ...processed];
};
