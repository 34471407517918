import {
  BrowserClient,
  inboundFiltersIntegration,
  functionToStringIntegration,
  breadcrumbsIntegration,
  httpContextIntegration,
  defaultStackParser,
  makeFetchTransport,
  getCurrentScope,
} from '@sentry/browser';

import urlDenyList from './url-denylist';

const dsn = 'https://899032cf20894ca3ac193916e76d1ff5@o362810.ingest.sentry.io/5227073';
const releaseVersion = `bordeaux@${process.env.npm_package_version}`;
const environment = (process.env.NODE_ENV || 'production') as 'production' | 'development' | 'test';
const isDevEnv = environment === 'development';
const enableDebug = isDevEnv;

const bordeauxURLs = [/^https?:\/\/bordeaux\.futurecdn\.net\/bordeaux\.js/];

const thirdPartyAPIs = [
  /^https?:\/\/c\.amazon-adsystem\.com\/aax2\/apstag\.js/,
  /^https?:\/\/securepubads\.g\.doubleclick.net\/tag\/js\/gpt\.js/,
  /^https?:\/\/cdn\.adsafeprotected\.com\/iasPET\.1\.js/,
  /^https?:\/\/js-sec\.indexww\.com\/ht\/p/,
];

export const init = (): BrowserClient => {
  const client = new BrowserClient({
    dsn,
    environment,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    debug: enableDebug,
    release: releaseVersion,
    denyUrls: [...urlDenyList.URLs],
    allowUrls: [...bordeauxURLs, ...thirdPartyAPIs],
    beforeSend: (event) => {
      if(event.level?.toLowerCase() !== 'error') {
        return null;
      }

      return event;
    },
    sampleRate: 0.01,
    integrations: [
      breadcrumbsIntegration(),
      functionToStringIntegration(),
      httpContextIntegration(),
      inboundFiltersIntegration(),
    ],
  })

  getCurrentScope().setClient(client);
  client.init();

  return client;
}
