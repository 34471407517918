import { SommelierResponse } from '@repo/shared-types';

import interstitialAdUnit from './ad-units/interstitial';
import { anchoredLeaderboardAdUnit, topLeaderboardAdUnit } from './ad-units/leaderboards';
import { mpu1AdUnit, mpu2AdUnit, incrementalMPUAdUnit } from './ad-units/mpu';
import overlayAdUnit from './ad-units/overlay';
import topLeaderboardSlot from './slots/leaderboard';
import {
  paragraphMPUSlots,
  headingMPUSlots,
  mpu1Slot,
  mpu2Slot,
  staticMPUSlots,
} from './slots/mpu';
import commonConfig from '../common';

const generateFallback = (): SommelierResponse => {
  const fallbackConfig: SommelierResponse = {
    ...commonConfig,
    ...(commonConfig.placement
      ? {
          placement: {
            ...commonConfig.placement,
            id: 99999,
            slots: [
              topLeaderboardSlot,
              mpu1Slot,
              mpu2Slot,
              staticMPUSlots,
              paragraphMPUSlots,
              headingMPUSlots,
            ],
            adunits: [
              topLeaderboardAdUnit,
              mpu1AdUnit,
              mpu2AdUnit,
              interstitialAdUnit,
              anchoredLeaderboardAdUnit,
              overlayAdUnit,
              incrementalMPUAdUnit,
            ],
          },
        }
      : {}),
  };

  return fallbackConfig;
};

export default generateFallback;
