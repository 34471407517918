
export enum DO_REPORT {
  MULTIPLE_SCRIPTS = 'MULTIPLE_SCRIPTS',
  FRAMEWORK_REQUEST = 'FRAMEWORK_REQUEST',
  AD_BLOCKED = 'AD_BLOCKED',
  CONTENT_LOAD = 'CONTENT_LOAD',

  CONFIG_REQUEST = 'REPORT_CONFIG_REQUEST',
  CONFIG_FAILURE = 'REPORT_CONFIG_FAILURE',
  CONFIG_LOAD = 'REPORT_CONFIG_LOAD',
  CONFIG_EMPTY = 'REPORT_CONFIG_EMPTY',
  CONFIG_PARSE_FAILURE = 'REPORT_CONFIG_PARSE_FAILURE',
  CONFIG_SUCCESS = 'REPORT_CONFIG_SUCCESS',
  // CONFIG_TIMEOUT = 'REPORT_CONFIG_TIMEOUT',

  THIRD_PARTY_REQUEST = 'REPORT_THIRD_PARTY_REQUEST',
  THIRD_PARTY_SUCCESS = 'REPORT_THIRD_PARTY_SUCCESS',

  CONSENT_REQUEST = 'REPORT_CONSENT_REQUEST',
  CONSENT_FAILURE = 'REPORT_CONSENT_FAILURE',
  CONSENT_SUCCESS = 'REPORT_CONSENT_SUCCESS',
  CONSENT_PENDING = 'REPORT_CONSENT_PENDING',
  CONSENT_LOADED = 'REPORT_CONSENT_LOADED',
  CONSENT_MOCKED = 'REPORT_CONSENT_MOCKED',
}
export enum REPORT_AUCTION {
  START = 'REPORT_AUCTION_START',
  END = 'REPORT_AUCTION_END',
  AD_LOAD = 'REPORT_AUCTION_AD_LOAD',
}
export enum REPORT_AUCTION_PARTNER {
  REQUEST = 'REPORT_AUCTION_PARTNER_REQUEST',
  SUCCESS = 'REPORT_AUCTION_PARTNER_SUCCESS',
  TIMEOUT = 'REPORT_AUCTION_PARTNER_TIMEOUT',
}
export enum REPORT_THIRD_PARTY_SCRIPT {
  REQUEST = 'REPORT_THIRD_PARTY_SCRIPT_REQUEST',
  TIMEOUT = 'REPORT_THIRD_PARTY_SCRIPT_TIMEOUT',
  SUCCESS = 'REPORT_THIRD_PARTY_SCRIPT_SUCCESS',
  FAILURE = 'REPORT_THIRD_PARTY_SCRIPT_FAILURE',
}

export enum DO_RECORD {
  EXPERIMENT_ID = 'RECORD_EXPERIMENT_ID',
  HYBRID_ABTEST_TARGETING = 'RECORD_HYBRID_ABTEST_TARGETING',
  HYBRID_ID = 'RECORD_HYBRID_ID',
  CFT_PARAMETERS = 'RECORD_CFT_PARAMETERS',

  INITIALISE_PAYLOAD = 'RECORD_INITIALISE_PAYLOAD',

  CONFIG_REQUEST = 'RECORD_CONFIG_REQUEST',
  CONFIG_TIMEOUT = 'RECORD_CONFIG_TIMEOUT',
  CONFIG_FAILURE = 'RECORD_CONFIG_FAILURE',
  CONFIG_SUCCESS = 'RECORD_CONFIG_SUCCESS',

  THIRD_PARTY_REQUEST = 'RECORD_THIRD_PARTY_REQUEST',
  THIRD_PARTY_SUCCESS = 'RECORD_THIRD_PARTY_SUCCESS',

  CONSENT_REQUEST = 'RECORD_CONSENT_REQUEST',
  CONSENT_PENDING = 'RECORD_CONSENT_PENDING',
  CONSENT_FAILURE = 'RECORD_CONSENT_FAILURE',
  CONSENT_SUCCESS = 'RECORD_CONSENT_SUCCESS',

  THIRD_PARTY_SCRIPT_REQUEST = 'RECORD_THIRD_PARTY_SCRIPT_REQUEST',
  THIRD_PARTY_SCRIPT_TIMEOUT = 'RECORD_THIRD_PARTY_SCRIPT_TIMEOUT',
  THIRD_PARTY_SCRIPT_FAILURE = 'RECORD_THIRD_PARTY_SCRIPT_FAILURE',
  THIRD_PARTY_SCRIPT_SUCCESS = 'RECORD_THIRD_PARTY_SCRIPT_SUCCESS',

  AUCTION_START = 'RECORD_AUCTION_START',
  AUCTION_PARTNER_REQUEST = 'RECORD_AUCTION_PARTNER_REQUEST',
  AUCTION_PARTNER_SUCCESS = 'RECORD_AUCTION_PARTNER_SUCCESS',
  AUCTION_PARTNER_TIMEOUT = 'RECORD_AUCTION_PARTNER_TIMEOUT',
  AUCTION_END = 'RECORD_AUCTION_END',
  AUCTION_AD_LOAD = 'RECORD_AUCTION_AD_LOAD',
}

export enum Partner {
  IAS = 'ias',
  AD_SERVER = 'hybrid',
  AMAZON = 'amazon',
  PREBID = 'prebid',
}