import { getEnv } from '@repo/utils';

export default (
  scriptLocation: string,
  scriptId: string,
  { async = false, defer = false } = {},
  onloadCallback?: () => void,
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const env = getEnv();
    const script: HTMLScriptElement = env.document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.src = scriptLocation;
    script.async = async;
    script.defer = defer;
    script.onload = (): void => {
      if (onloadCallback) onloadCallback();
      resolve();
    };
    script.onerror = (error: Event | string): void => reject(error);
    env.document.body.appendChild(script);
  });
