import { BordeauxMachineContext, AnyBordeauxEvent } from '@repo/shared-types';
import { enqueueActions, ParameterizedObject } from 'xstate';

type EnqueueActions = <R extends AnyBordeauxEvent>(
  ...args: Parameters<
    typeof enqueueActions<
      BordeauxMachineContext,
      R,
      ParameterizedObject['params'],
      AnyBordeauxEvent,
      any,
      any,
      any
    >
  >
) => ReturnType<
  typeof enqueueActions<
    BordeauxMachineContext,
    R,
    ParameterizedObject['params'],
    AnyBordeauxEvent,
    any,
    any,
    any
  >
>;

export default enqueueActions as EnqueueActions;
