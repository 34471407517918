import { SommelierResponseAdUnit } from '@repo/shared-types';

export const mpu1AdUnit: SommelierResponseAdUnit = {
  type: 'standard',
  name: 'mpu1',
  slotAffinity: 'mpu1',
  sizes: [{ w: 300, h: 250 }],
  targeting: {
    pos: ['1'],
    placement: ['dfp_rs_mobile_mpu_1'],
  },
  properties: {
    refresh: 'true',
  },
};

export const mpu2AdUnit: SommelierResponseAdUnit = {
  type: 'standard',
  name: 'mpu2',
  slotAffinity: 'mpu2',
  sizes: [{ w: 300, h: 250 }],
  targeting: {
    pos: ['2'],
    placement: ['dfp_rs_mobile_mpu_2'],
  },
  properties: {
    refresh: 'true',
  },
};

export const incrementalMPUAdUnit: SommelierResponseAdUnit = {
  type: 'incremental',
  name: 'incr-mpu',
  sizes: [{ w: 300, h: 250 }],
  targeting: {
    pos: ['3'],
    placement: ['dfp_rs_mobile_mpu_3'],
  },
  properties: {
    refresh: 'true',
  },
};
