import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export const dockAdTool = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EMIT.DOCK_AD_TOOL,
  });
};
export const unDockAdTool = (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EMIT.UN_DOCK_AD_TOOL,
  });
};
