import { log } from '@repo/utils';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

let initialised = false;
type Callback = () => void;
const callbacks: Array<Callback> = [];
export const onUpdateDynamicSlots = (newCallback: Callback): void => {
  callbacks.push(newCallback);
  initialised = true;
};
export default (_service: ApiMachineEventService): void => {
  if (!initialised) {
    log.info(`Manual dynamic root has been triggered before the system is ready.`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  callbacks.forEach(callback => {
    callback();
  });
};
