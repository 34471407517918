import {
  AdditionalAvoidance,
  Slot,
  AdDefinition,
  BordeauxMachineContext,
  DataObjectType,
} from '@repo/shared-types';
import { getEnv } from '@repo/utils';

export const passesAdditionalAvoidance = (
  deviceAvoidanceDistance: number,
  unmountedSlot: DataObjectType<Slot>,
): boolean => {
  const additionalAvoidance = unmountedSlot.getProperty('additionalAvoidance');
  if (additionalAvoidance.length === 0) {
    return true;
  }

  const unmountedSlotPosition = unmountedSlot.getProperty('element').getBoundingClientRect().top;
  const avoidanceOverlap = additionalAvoidance.some(
    (additionalAvoidanceConfig: AdditionalAvoidance) =>
      additionalAvoidanceConfig.elements.some(additionalElement => {
        const elementBounds = additionalElement.getBoundingClientRect();
        const slotIsAboveElement = unmountedSlotPosition < elementBounds.top;

        if (slotIsAboveElement) {
          return unmountedSlotPosition + deviceAvoidanceDistance >= elementBounds.top;
        }
        return (
          elementBounds.top + elementBounds.height + deviceAvoidanceDistance >=
          unmountedSlotPosition
        );
      }),
  );
  return !avoidanceOverlap;
};

export const passesAvoidanceRules = (
  context: Pick<BordeauxMachineContext, 'slots' | 'ads' | 'avoidanceDistance'>,
  slot: DataObjectType<Slot>,
  adDefinition: AdDefinition,
): boolean => {
  const avoidanceRules = adDefinition.categoryAvoidanceRules;
  const slotsToAvoid = context.slots.getValues().flatMap((candiateSlot: DataObjectType<Slot>) => {
    if (candiateSlot.getProperty('adID') === undefined) {
      return [];
    }
    const candidateAd = context.ads
      .getValues()
      .find(ad => ad.getProperty('id') === candiateSlot.getProperty('adID'));
    if (candidateAd === undefined) {
      return [];
    }

    const shouldAvoidOwnCategory =
      avoidanceRules && 'ownCategory' in avoidanceRules
        ? Boolean(avoidanceRules.ownCategory)
        : true;

    if (shouldAvoidOwnCategory && candidateAd.getProperty('category') === adDefinition.category) {
      return [{ slot: candiateSlot, distance: context.avoidanceDistance }];
    }

    if (avoidanceRules && (avoidanceRules.ads || avoidanceRules.tiles)) {
      const categoriesToAvoid = avoidanceRules.ads || avoidanceRules.tiles || [];
      return categoriesToAvoid.flatMap(rule => {
        if (candidateAd.getProperty('category') === rule.category) {
          return [{ slot: candiateSlot, distance: rule.distance }];
        }
        return [];
      });
    }

    return [];
  });
  const unmountedSlotBounds = slot.getProperty('element').getBoundingClientRect();
  const env = getEnv();
  return slotsToAvoid.every(avoidData => {
    const avoidSlotBounds = avoidData.slot.getProperty('element').getBoundingClientRect();
    const calculatedDistance = Math.max(
      0,
      unmountedSlotBounds.top - avoidSlotBounds.bottom,
      avoidSlotBounds.top - unmountedSlotBounds.bottom,
    );
    return (
      calculatedDistance >
      (avoidData.distance === 'screenheight' ? env.innerHeight : avoidData.distance)
    );
  });
};
