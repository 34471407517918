import { log, getEnv } from '@repo/utils';

const env = getEnv();

export default (message = 'Logged from the console'): void => {
  env.setTimeout(() => {
    const error: Error = new Error(message);
    log.error(error);
  });
};
