import { querySelectorAll } from '@repo/utils';
import { onUpdateDynamicSlots } from 'api/update-dynamic-slots';
import { DynamicSlotDefinition, SlotType } from '@repo/shared-types';
import handleGeneratedSlot from './generated-slots';

const setupMutationObservers = (elements: Array<HTMLElement>, callback: () => void): void => {
  elements.forEach(element => {
    const observer = new MutationObserver(callback);
    observer.observe(element, { childList: true });
    callback();
  });
};

export default (
  slot: DynamicSlotDefinition,
  automaticDynamic: boolean,
  addSlotCallback: (slotHookElements: Array<HTMLElement>) => void,
): void => {
  const rootMutationCallback = (): void => {
    const safeSlotName = slot.name.replace(/[\s-]/g, '');
    const slotHookElements = Array.from(querySelectorAll<HTMLElement>(slot.hook));
    const filteredSlotHookElements = slotHookElements.filter(
      element => element.dataset[safeSlotName] !== 'true',
    );

    if (filteredSlotHookElements.length === 0) {
      return;
    }

    filteredSlotHookElements.forEach(element => {
      element.dataset[safeSlotName] = 'true';
    });
    if (slot.type === SlotType.GENERATED) {
      handleGeneratedSlot(slot, filteredSlotHookElements).then(spacerElements => {
        addSlotCallback(spacerElements);
      });
    } else {
      addSlotCallback(filteredSlotHookElements);
    }
  };

  if (automaticDynamic) {
    const rootElements = Array.from(querySelectorAll<HTMLElement>(slot.dynamicConfig.root));
    setupMutationObservers(rootElements, rootMutationCallback);
  }

  onUpdateDynamicSlots((): void => {
    rootMutationCallback();
  });
};
