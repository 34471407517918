export const parseIabSegmentIdsTAM = (input: string | string[]): string[] => {
  const inputStrings = Array.isArray(input) ? input : [input];

  return inputStrings.map(inputString => {
    const lastIndex = inputString.lastIndexOf('.');
    return lastIndex !== -1 ? inputString.substring(lastIndex + 1).trim() : inputString.trim();
  });
};

export default (input: string | string[]): { id: string }[] => {
  const inputStrings = Array.isArray(input) ? input : [input];

  return inputStrings.map(inputString => ({
    id: (lastIndex =>
      lastIndex !== -1 ? inputString.substring(lastIndex + 1).trim() : inputString.trim())(
      inputString.lastIndexOf('.'),
    ),
  }));
};
