import { ThirdPartyAPIMachineConfigs, ThirdParty, PartialPick } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.LIVE_RAMP], 'thirdParty'> = {
  thirdParty: ThirdParty.LIVE_RAMP,
  methods: {
    getScriptLocation: ({
      data: {
        config: { configurationId },
      },
    }) => `https://ats-wrapper.privacymanager.io/ats-modules/${configurationId}/ats.js`,
    loadScript: scriptLocation =>
      loadScript(scriptLocation, 'liveramp-script', { async: true, defer: true }),
  },
};
export default config;
