import { getEnv, TimeoutError } from '@repo/utils';
import {
  DataObject,
  ThirdParty,
  Ad,
  IF,
  BordeauxMachineContext,
  AnyBordeauxEvent,
  BordeauxGuards,
  FEATURE,
  Slot,
} from '@repo/shared-types';
import { GuardPredicate } from 'xstate/guards';
import { getNextScreen } from './screens';

const guards: Record<
  IF,
  GuardPredicate<BordeauxMachineContext, AnyBordeauxEvent, any, BordeauxGuards>
> = {
  [IF.timingCollectionEnabled]: ({ context }) => context.timing.enabled,
  [IF.auctionIsUnsent]: ({ context }, auctionId: number) =>
    context.timing.unsentAuctions.includes(auctionId),
  [IF.errorIsTimeout]: (_, error: Error) => error instanceof TimeoutError,
  [IF.duplicateScripts]: () => {
    const env = getEnv();
    return (
      (
        env.document.querySelectorAll('script[src="https://bordeaux.futurecdn.net/bordeaux.js"]') ||
        []
      ).length > 1
    );
  },
  [IF.hasEnoughConsentForAuction]: ({
    context: {
      gdprConsent: { hasEnoughConsentForAuction },
    },
  }) => hasEnoughConsentForAuction,
  [IF.featuresInitialised]: ({ context }): boolean => context.featuresInitialised,
  [IF.hasExperimentId]: ({ context }): boolean => context.experimentId.length !== 0,
  [IF.adToolOpenedFromURL]: ({ context }) => context.queryParameters.debugTool !== null,
  [IF.adsBlocked]: ({ context }) => context.adBlocked,
  [IF.prebidEnabled]: ({ context }): boolean =>
    context.thirdPartyResults[ThirdParty.PREBID].config.enabled &&
    context.thirdPartyResults[ThirdParty.PREBID].success,
  [IF.liveIntentUserSyncEnabled]: ({ context }) => context.liveIntentUserSyncEnabled,
  [IF.consentDone]: ({ context }) => context.uspConsent.done && context.gdprConsent.done,
  [IF.hasAds]: (_, ads: Array<DataObject<Ad>>) => ads.length !== 0,

  [IF.featureEnabled]: ({ context }, featureName: FEATURE): boolean =>
    Boolean(context.features[featureName]),
  [IF.scrolledPastFirstTandem]: ({ context: { firstTandemAdPosition } }) => {
    const env = getEnv();
    const scroll = env.scrollY;
    const height = env.innerHeight;
    return scroll + height > firstTandemAdPosition;
  },
  [IF.slotIsBeforeFirstTandem]: (
    { context: { firstTandemAdPosition, config } },
    { slot, position }: { slot: DataObject<Slot>; position: { x: number; y: number } },
  ) => {
    const masterName = slot.getProperty('genericName');
    const companionDefinitions = config.placement.slots.static.filter(
      slotDefinition => slotDefinition.master === masterName,
    );
    if (!slot.getProperty('masterID') && companionDefinitions.length === 0) {
      return false;
    }
    return position.y < firstTandemAdPosition;
  },
  [IF.shouldUseScreenMode]: ({
    context: {
      config: {
        placement: { slots },
      },
    },
  }) =>
    Object.values(slots).some(slotList => slotList.some(slotDefinition => slotDefinition.master)),
  [IF.batchesInStack]: ({ context: { adBatches } }) => adBatches.length > 0,
  [IF.slotHasScreen]: (_, { slot }: { slot: DataObject<Slot> }) =>
    slot.getProperty('screen') !== 'none',
  [IF.screenlessSlotNeedsLoading]: (
    _,
    { slot, intersecting }: { slot: DataObject<Slot>; intersecting: boolean },
  ) => intersecting && slot.getProperty('screen') === 'none' && !slot.getProperty('adID'),
  [IF.hasNextScreen]: ({ context }) => Boolean(getNextScreen(context)),
  [IF.otherScreenInView]: ({ context: { screens } }) =>
    Object.values(screens).some(({ inView, satisfied }) => inView && satisfied),
};
export default guards;
