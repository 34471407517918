import { TargetingValue } from '@repo/shared-types';

const removeAfter =
  (needle: string) =>
  (haystack: string): string =>
    haystack.indexOf(needle) > -1 ? haystack.slice(0, haystack.indexOf(needle)) : haystack;

// APT-379 - no email addresses allowed
const removeAfterAt = removeAfter('@');
// URL encoded @
const removeAfterEncAt = removeAfter('%40');

// in the absense of a compose function...
const stripEmail = (str: string): string => removeAfterEncAt(removeAfterAt(str));

function escapeUnsafeString(str: string): string {
  return stripEmail(str).replace(/[#",*()=+<>\[\]]/g, c => `%${c.charCodeAt(0).toString(16)}`);
}

const escapeUnsafeArray = (arr: Array<string>): Array<string> => arr.map(escapeUnsafeString);

export default (value: TargetingValue): TargetingValue => {
  if (typeof value === 'string') {
    return escapeUnsafeString(value);
  }
  if (Array.isArray(value)) {
    return escapeUnsafeArray(value);
  }
  return value;
};
