import { AdDefinition, DataObjectType, Slot, Targeting } from '@repo/shared-types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';

const generateViewabilityTargeting = (
  adDefinition: AdDefinition,
  config: Pick<BordeauxConfig['placement'], 'id' | 'siteId'>,
  slot?: DataObjectType<Slot>,
): Targeting => {
  const slotName = slot?.getProperty('name').replace(/\s+/g, '') || 'NO_SLOT_FOUND';
  const placementId = config.id || 'NO_PLACEMENT_FOUND';
  const siteId = config.siteId || 'NO_SITE_ID_FOUND';
  const slotIndex = adDefinition.incremental
    ? slotName.includes('-')
      ? slotName.split('-').pop() || ''
      : ''
    : '';

  return {
    'plcmt-slot-refreshed': `${siteId}#${placementId}#${adDefinition.mode === 'anchored' ? 'anchored' : slotName}#false${slotIndex !== '' ? '#' : ''}${slotIndex}`,
  };
};

const updateViewabilityTargeting = (
  targetingString: string,
  refreshTargeting: string,
  apiRefreshTargeting: string,
): Targeting => {
  const refreshValue =
    refreshTargeting === 'yes' || apiRefreshTargeting === 'yes' ? 'true' : 'false';
  const updatedParts = targetingString
    .split('#')
    .map((part, index) => (index === 3 ? refreshValue : part));

  const resultParts = updatedParts.length > 3 ? updatedParts : updatedParts.concat(refreshValue);

  return {
    'plcmt-slot-refreshed': resultParts.join('#'),
  };
};

export { generateViewabilityTargeting, updateViewabilityTargeting };
