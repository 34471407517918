import { SommelierResponse } from '@repo/shared-types';

import { leaderboardAdUnit, incrementalLeaderboardAdUnit } from './ad-units/leaderboard';
import incrementalDMPUAdUnit from './ad-units/incremental-dbl-mpu';
import incrementalMPUAdUnit from './ad-units/incremental-mpu';
import overlayAdUnit from './ad-units/overlay';
import skinAdUnit from './ad-units/skin';
import { leaderboardSlot, staticLeaderboardSlots } from './slots/leaderboard';
import paragraphMPUSlots from './slots/paragraph-mpu';
import { topOfSidebarSlot, beforePopularBoxSlot, afterPopularBoxSlot } from './slots/sidebar';
import commonConfig from '../common';

const generateFallback = (): SommelierResponse => {
  const fallbackConfig: SommelierResponse = {
    ...commonConfig,
    ...(commonConfig.placement
      ? {
          placement: {
            ...commonConfig.placement,
            id: 99998,
            slots: [
              leaderboardSlot,
              topOfSidebarSlot,
              beforePopularBoxSlot,
              afterPopularBoxSlot,
              paragraphMPUSlots,
              staticLeaderboardSlots,
            ],
            adunits: [
              leaderboardAdUnit,
              overlayAdUnit,
              skinAdUnit,
              incrementalDMPUAdUnit,
              incrementalMPUAdUnit,
              incrementalLeaderboardAdUnit,
            ],
          },
        }
      : {}),
  };

  return fallbackConfig;
};

export default generateFallback;
