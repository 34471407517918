import { hasParam, isDevEnv } from '@repo/utils';

import { PROMETHEUS_SAMPLE_RATE } from '../constants';

export const isMetricsEnabled = (): boolean => {
  const randomlySampled: boolean = Math.random() < PROMETHEUS_SAMPLE_RATE;
  const forced: boolean = hasParam('force_metrics');

  return forced || (!isDevEnv && randomlySampled);
};
