import { mergeDeepWith, concat } from 'ramda';
import metrics from 'metrics';
import userSync from 'user-sync';
import {
  containsExperiment,
  containsVariant,
  getVariant,
  sendUserIdEventToFreyr,
  waitForJwPLayer,
  log,
  performAdBlockTest,
  getEnv,
  getParameters,
  getRefreshTargetingValues,
  hasUserOptOutCCPA,
  sentry,
  checkAdUnitCountries,
  loadAdTool,
  timeData,
  trimSlashes,
  querySelectorAll,
  partition,
  fastdom,
} from '@repo/utils';
import enqueueActions from '../proxy/enqueueActions';
import { fallbackAction, parsedFallback } from 'config';

import {
  ActionArgs,
  DO,
  SPAWN,
  BordeauxMachineContext,
  IF,
  DO_REPORT,
  REPORT_AUCTION,
  ThirdParty,
  AdDefinition,
  AdUnitMode,
  AdUnitStatus,
  ON,
  SetHybridABTestTargetingEvent,
  ReportEvent,
  API_RECIEVE,
  Refresh_RECIEVE,
  BordeauxActions,
  SlotPosition,
  AdManager_RECIEVE,
  Slotify_ON,
  SetTakeoverStatusEvent,
  SpecialGPTOutputs,
  DataObject,
  Slot,
  SlotObserverEventType,
  ScreenAdBatch,
  AdUnitCategory,
  ScreensDefinition,
  SlotDefinition,
  TO,
} from '@repo/shared-types';

import adServer from 'third-party-apis/ad-server';
import amClio from 'third-party-apis/am-clio';
import amazon from 'third-party-apis/amazon';
import euid from 'third-party-apis/euid';
import gpt from 'third-party-apis/gpt';
import ias from 'third-party-apis/ias';
import permutive from 'third-party-apis/permutive';
import indexExchange from 'third-party-apis/index-exchange';
import liveRamp from 'third-party-apis/liveramp';
import prebid from 'third-party-apis/prebid';
import pubmatic from 'third-party-apis/pubmatic';
import pubx from 'third-party-apis/pubx';
import tmt from 'third-party-apis/the-media-trust';

import expandHeight from 'ad-features/expand-height';
import fullWidth from 'ad-features/full-width';
import multiFrame from 'ad-features/multi-frame';
import popOut, { cancelPopOut, enablePopOut } from 'ad-features/pop-out';
import closeButton from 'ad-features/close-button';
import getTargeting, { getHybridAbTestTargeting } from 'ad-framework/targeting';
import { mergeConfigs } from 'third-party-apis/merge-configs';
import assign, { assignParams } from '../proxy/assign';
import raise from '../proxy/raise';
import sendTo from '../proxy/send-to';
import * as report from '../report';
import { SommelierResponse } from 'config/sommelier-request/sommelier-response.types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import { getAdsToRefresh } from 'ad-framework/refresh/automatic/action';
import { createAdLabel } from 'ad-framework/ad/create-ad-label';
import raiseParams from '../proxy/raise-params';
import handleHybridAbTestsIfAny from './handle-hybrid-ab-tests-if-any';
import getTakeoverStatus from 'ad-framework/takeover';
import { createAdData } from 'slotify/actors/create-ads';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import screenSlotGenerator from 'slotify/generator/screen-slot-generator';
import { updateViewabilityTargeting } from 'slotify/viewability-targeting';
import { mapScreenSlots } from 'state/screens';
import { findAdsForSlotDefinition } from 'slotify/find-best-ad-for-slot';
import doubleVerify from 'third-party-apis/double-verify';

export const setupCustomActivations = assign({
  unrefreshableLineItems: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.TAKEOVER,
    );
    if (adRefreshDisabled.LINE_ITEM) {
      return adRefreshDisabled.LINE_ITEM;
    }
    return context.unrefreshableLineItems;
  },
  unrefreshableOrders: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.TAKEOVER,
    );
    if (adRefreshDisabled.ORDER) {
      return adRefreshDisabled.ORDER;
    }
    return context.unrefreshableOrders;
  },
  unrefreshableAdvertisers: ({ context }) => {
    const adRefreshDisabled = mergeDeepWith(
      concat,
      context.config.features.customActivations.AD_REFRESH_DISABLED,
      context.config.features.customActivations.TAKEOVER,
    );
    if (adRefreshDisabled.ADVERTISER) {
      return adRefreshDisabled.ADVERTISER;
    }
    return context.unrefreshableAdvertisers;
  },
});

export const decideRefreshTime = enqueueActions(() => {
  /** Noop - no special refresh time currently in use */
});

export const setupUserSync = ({ context }: ActionArgs): void => {
  userSync(context);
};

export const handleError = (): void => {
  metrics.recordFrameworkRequest('fail');
};

export const sendUserIdsToFreyr = ({ context }: ActionArgs): void => {
  const hybridId = [{ name: 'hybridId', id: context.hybridId }];
  const emailHash = context.queryParameters.lrh
    ? [{ name: 'email_sha256', id: context.queryParameters.lrh }]
    : [];

  sendUserIdEventToFreyr([...hybridId, ...emailHash]);
};

const TEST_KEYS = ['hybridTestID', 'hybridQATestID'];
type VariantValueMap = {
  [variantId: string]: { mobile: number; tablet: number; desktop: number };
};
const variantValues: VariantValueMap = {
  '25|95': { mobile: 3000, tablet: 3000, desktop: 3000 },
  '25|96': { mobile: 3500, tablet: 3500, desktop: 3500 },
  '25|97': { mobile: 4000, tablet: 4000, desktop: 4000 },
  '25|98': { mobile: 4500, tablet: 4500, desktop: 4500 },
  '25|99': { mobile: 5000, tablet: 5000, desktop: 5000 },
  '25|100': { mobile: 5500, tablet: 5500, desktop: 5500 },
  '25|101': { mobile: 6000, tablet: 6000, desktop: 6000 },
  '25|102': { mobile: 6500, tablet: 6500, desktop: 6500 },
  '25|103': { mobile: 7000, tablet: 7000, desktop: 7000 },
};

export const storeHybridTestSessions = ({ context }: ActionArgs): void => {
  Object.entries(context.config.targeting)
    .filter(([key]) => TEST_KEYS.includes(key))
    .forEach(([, value]) => {
      sessionStorage.setItem('force_abtest', typeof value === 'string' ? value : value.join(','));
    });
};

export const decideDoubleVerifyActive = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    // const excludedURLs = [
    //   'gardeningknowhow-progressive',
    //   'homesandgardens-progressive',
    //   'idealhome',
    //   'livingetc-progressive',
    //   'marieclairecom-progressive',
    //   'realhomes-progressive',
    //   'whowhatwear-progressive',
    //   'womanandhome-progressive',
    //   'decanter',
    // ];
    if (context.pageParameters.site === 'myimperfectlife-progressive') {
      return {
        ...context.thirdPartyApiConfigOverrides,
        doubleVerify: {
          enabled: true,
        },
        ias: {
          ...context.thirdPartyApiConfig.ias,
          enabled: false,
        },
      };
    } else return context.thirdPartyApiConfigOverrides;
  },
});

export const decideTestAuctionTimeouts = assign({
  auctionTimeouts: ({ context }): BordeauxMachineContext['auctionTimeouts'] => {
    const variantEntry = Object.entries(context.sommelierResponse.targeting || {})
      .reverse()
      .find(
        ([key, value]) =>
          TEST_KEYS.includes(key) &&
          ((typeof value === 'string' && variantValues[value]) ||
            (Array.isArray(value) && value.some(v => v in variantValues))),
      );
    if (!variantEntry) return context.auctionTimeouts;

    const variantValue = variantEntry[1];
    return typeof variantValue === 'string'
      ? variantValues[variantValue]
      : variantValues[variantValue.find(v => v in variantValues) as string];
  },
});

export const decidePubxAbTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    if (containsExperiment(context.config, '50')) {
      return {
        ...context.thirdPartyApiConfigOverrides,
        pubx: {
          enabled: containsVariant(context.config, '50', '170'),
        },
      };
    } else if (containsExperiment(context.config, '41')) {
      return {
        ...context.thirdPartyApiConfigOverrides,
        pubx: {
          enabled: containsVariant(context.config, '41', '140'),
        },
      };
    } else {
      return context.thirdPartyApiConfigOverrides;
    }
  },
});

export const decideSiteActivationDistance = assign({
  activationDistance: ({ context }): BordeauxMachineContext['activationDistance'] => {
    return context.config.placement.settings.activationDistance
      ? context.config.placement.settings.activationDistance
      : context.activationDistance;
  },
});

export const decidePermutiveEnable = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    const currentGeoLocation = (context.config.targeting.geo as string) ?? 'GB';

    if (!['GB', 'CA', 'AU', 'US'].includes(currentGeoLocation)) {
      return {
        ...context.thirdPartyApiConfigOverrides,
        permutive: {
          enabled: false,
        },
      };
    }
    return context.thirdPartyApiConfigOverrides;
  },
});

export const decideActivationDistanceTest = assign({
  activationDistance: ({ context }): BordeauxMachineContext['activationDistance'] => {
    if (containsExperiment(context.config, '49')) {
      const variant = getVariant(context.config, '49');
      switch (variant) {
        case '160':
          return 600;
        case '161':
          return 2000;
        default:
          return context.activationDistance;
      }
    } else {
      return context.activationDistance;
    }
  },
});

export const decideV2ActivationDistanceTest = assign({
  activationDistance: ({ context }): BordeauxMachineContext['activationDistance'] => {
    if (containsExperiment(context.config, '53')) {
      const variant = getVariant(context.config, '53');
      switch (variant) {
        case '179':
          return 1200;
        case '180':
          return 800;
        case '181':
          return 700;
        case '182':
          return 600;
        case '183':
          return 500;
        case '184':
          return 400;
        case '185':
          return 300;
        default:
          return context.activationDistance;
      }
    } else {
      return context.activationDistance;
    }
  },
});

export const decideIdsActivationTest = assign({
  thirdPartyApiConfigOverrides: ({
    context,
  }): BordeauxMachineContext['thirdPartyApiConfigOverrides'] => {
    if (containsExperiment(context.config, '43')) {
      const variant = getVariant(context.config, '43');
      switch (variant) {
        case '142':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '143':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '144':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: true,
            },
          };
        case '145':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: false,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: false,
            },
          };
        case '146':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: false,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: false,
            },
            uid2: {
              enabled: false,
            },
          };
        case '147':
          return {
            ...context.thirdPartyApiConfigOverrides,
            euid: {
              enabled: true,
            },
            liveRamp: {
              ...context.thirdPartyApiConfig.liveRamp,
              enabled: true,
            },
            liveIntent: {
              enabled: true,
            },
            uid2: {
              enabled: true,
            },
          };
        default:
          return context.thirdPartyApiConfigOverrides;
      }
    } else {
      return context.thirdPartyApiConfigOverrides;
    }
  },
});

export const customVideoBehaviourAction = ({
  context: {
    ads,
    config,
    pageParameters: { site },
  },
}: ActionArgs): void => {
  const allowedSites = ['marieclairecom-progressive', 'whowhatwear-progressive'];
  if (!allowedSites.includes(site)) {
    return;
  }

  const {
    features: { customActivations },
  } = config;

  waitForJwPLayer()
    .then(jw => {
      const gptOutputs = ads
        .getValues()
        .map(ad => ad.getProperty('gptOutput'))
        .filter(gptOutput => !!gptOutput);

      const isLineItemVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.lineItem)
        .some(lineItem =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.LINE_ITEM?.includes(lineItem),
        );

      const isAdvertiserVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.advertiser)
        .some(advertiser =>
          customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ADVERTISER?.includes(advertiser),
        );

      const isOrderVideoStickyDisabled = gptOutputs
        .map(gptOutput => gptOutput.campaign)
        .some(order => customActivations.VIDEO_STICKY_AUTOPLAY_DISABLED.ORDER?.includes(order));

      const disableStatus =
        isLineItemVideoStickyDisabled ||
        isAdvertiserVideoStickyDisabled ||
        isOrderVideoStickyDisabled;

      if (disableStatus) {
        jw().remove();
      }
    })
    .catch(err => {
      log.warn(err);
    });
};

const actions: BordeauxActions = {
  [DO.spawn_APIMachine]: assign({
    [TO.API]: ({ spawn }) => spawn(SPAWN.API, { id: TO.API }),
  }),
  [DO.spawn_adFeatureMachine]: assign({
    [TO.adFeatures]: ({ spawn }) => spawn(SPAWN.listenForAdFeatures, { id: TO.adFeatures }),
  }),
  [DO.initialiseFeatures]: assign({
    featuresInitialised: true,
  }),

  [DO.decide_adToolSource]: assign({
    adToolSource: ({ context: { adToolVersion } }) =>
      adToolVersion
        ? `https://bdx.${adToolVersion}.public.rtb-qa-eks-euw1.futureplc.engineering/ad-tool.js`
        : 'https://bordeaux.futurecdn.net/ad-tool.js',
  }),
  [DO.checkAdBlock]: assign({
    adBlocked: await performAdBlockTest(),
  }),
  [DO.updateAdToolPage]: ({
    context: {
      queryParameters: { debugTool },
    },
  }) => {
    const env = getEnv();
    const searchParams = new URLSearchParams(env.location.search);
    searchParams.set('debug_ads', debugTool || '');
    const newurl = `${env.location.protocol}//${env.location.host}${env.location.pathname}?${searchParams}${env.location.hash}`;
    env.history.replaceState(null, '', newurl);
  },
  [DO.readQueryParameters]: assign({
    queryParameters: () => {
      const env = getEnv();
      const query = new URLSearchParams(env.location.search);
      return {
        country: query.get('force_locale') || query.get('force_country') || query.get('CC'),
        debugTool: query.get('debug_ads'),
        forceTakeover: query.get('force_takeover'),
        forceTargeting: query.get('force_targeting'),
        sommelierUrl: query.get('sommelier_url'),
        forcePLCDB: query.get('force_plc_db'),
        forceABTestControl: query.get('force_abtest_control'),
        forceABTestVariant: query.get('force_abtest_variant'),
        lrh: query.get('lrh'),
      };
    },
  }),
  [DO.readPageParameters]: assign({
    pageParameters: getParameters,
  }),
  [DO.spawn_arbitraryEventEmitter]: assign({
    [TO.arbitraryEventEmitter]: ({ context, spawn }) =>
      spawn(SPAWN.arbitraryEventEmitter, {
        id: TO.arbitraryEventEmitter,
        input: { pageParameters: context.pageParameters },
      }),
  }),
  [DO.assignIndexExchangeDeviceType]: ({ context }) => {
    const env = getEnv();
    // Allows index exchange to pick the right kind of ads depending on the current device
    env.indexExchangeDeviceType = context.pageParameters.device;
  },
  [DO.assignBordeauxAdsPromise]: () => {
    const env = getEnv();
    // Resolve if page is using Bordeaux ads
    env.bordeauxAds = Promise.resolve(true);
  },
  [DO.checkMultipleScripts]: enqueueActions(({ check, enqueue }) => {
    if (check(IF.duplicateScripts)) enqueue(DO_REPORT.MULTIPLE_SCRIPTS);
  }),
  [DO.spawn_adToolTapOpenMachine]: assign({
    [TO.elementTapHandler]: ({ spawn }) =>
      spawn(SPAWN.elementTapHandler, {
        id: TO.elementTapHandler,
        input: {
          element: document.body,
          fingers: 2,
          repetitions: 10,
        },
      }),
  }),
  [DO.checkAdToolParam]: enqueueActions(({ check, enqueue }) => {
    if (check(IF.adToolOpenedFromURL)) enqueue(DO.raiseOpenAdTool);
  }),
  [DO.spawn_automaticRefreshMachine]: assign({
    [TO.automaticRefresh]: ({ spawn }) =>
      spawn(SPAWN.automaticRefresh, { id: TO.automaticRefresh }),
  }),
  [DO.reportIfAdBlocked]: enqueueActions(({ check, enqueue }) => {
    if (check(IF.adsBlocked)) enqueue(DO_REPORT.AD_BLOCKED);
  }),

  [DO.useParsedFallbackConfig]: assign({
    config: parsedFallback,
  }),
  [DO.useFallbackConfig]: assign({
    sommelierResponse: fallbackAction,
  }),

  [DO.raiseOpenAdTool]: raise({
    type: ON.openAdTool,
  }),
  [DO.thirdPartiesReady]: sendTo(TO.API, {
    type: API_RECIEVE.THIRD_PARTIES_READY,
  }),
  [DO.decide_avoidanceDistance]: assign({
    avoidanceDistance: ({ context }) => {
      const configAvoidanceDistance =
        context.config.placement.settings.adDensity?.avoidanceDistance;
      const apiAvoidanceDistanceConfig = context.deviceAvoidanceDistance;

      if (apiAvoidanceDistanceConfig) {
        const { device } = context.pageParameters;
        return apiAvoidanceDistanceConfig[device];
      }
      if (configAvoidanceDistance === undefined) {
        return context.avoidanceDistance;
      }
      return configAvoidanceDistance;
    },
  }),
  [DO.decide_siteActivationDistance]: decideSiteActivationDistance,
  [DO.decide_testActivationDistance]: decideActivationDistanceTest,
  [DO.decide_v2TestActivationDistance]: decideV2ActivationDistanceTest,
  [DO.decide_testPubx]: decidePubxAbTest,
  [DO.decide_testAdServiceActivation]: decideIdsActivationTest,
  [DO.decide_enablePermutive]: decidePermutiveEnable,
  [DO.decide_doubleVerifyActive]: decideDoubleVerifyActive,
  [DO.decide_thirdPartyConfig]: assign({
    thirdPartyApiConfig: ({ context }) =>
      mergeConfigs(context.config.thirdPartyAPIConfig, context.thirdPartyApiConfigOverrides),
  }),
  [DO.decide_liveIntentUserSync]: assign({
    liveIntentUserSyncEnabled: ({ context }) =>
      context.thirdPartyApiConfig.liveIntent.enabled && Math.random() < 0.9,
  }),
  [DO.decide_pageAdunitPath]: assign({
    pageAdUnitPath: ({ context }) =>
      `/${[
        context.config.placement.siteAdUnitPath,
        ...(!context.pageCategory ? [] : [context.pageCategory]),
        context.config.placement.adUnitPath,
      ]
        .map(trimSlashes)
        .join('/')}`,
  }),
  [DO.initialiseSentry]: report.initialiseSentry,
  [DO.sendABTestToFreyr]: handleHybridAbTestsIfAny,
  [DO.setup_customActivations]: setupCustomActivations,
  [DO.decide_refreshTime]: decideRefreshTime,
  [DO.setup_userSync]: setupUserSync,
  [DO.spawn_adManager]: assign({
    [TO.adManager]: ({ spawn, context: { hybridId, uspConsent } }) =>
      spawn(SPAWN.adManager, {
        id: TO.adManager,
        input: {
          privacySettings: { restrictDataProcessing: hasUserOptOutCCPA(uspConsent) },
          ...(hybridId
            ? {
                publisherProvidedId: hybridId,
              }
            : {}),
        },
      }),
  }),
  [DO.handleError]: handleError,
  [DO.assignLiveIntentUserSyncTargeting]: enqueueActions(({ check, enqueue }) => {
    if (check(IF.prebidEnabled)) {
      if (check(IF.liveIntentUserSyncEnabled)) {
        enqueue({
          type: DO.updatePageTargeting,
          params: {
            'li-module-enabled': ['on'],
          },
        });
      } else {
        enqueue({
          type: DO.updatePageTargeting,
          params: {
            'li-module-enabled': ['off'],
          },
        });
      }
    }
  }),
  [DO.storeHybridTestSessions]: storeHybridTestSessions,
  [DO.decideTestAuctionTimeouts]: decideTestAuctionTimeouts,

  [DO.decide_takeoverIncrementals]: assign({
    takeoverIncrementals: ({
      context: {
        takeoverIncrementalChooser,
        adUnits: { incremental: adDefinitions },
      },
    }) => {
      const incrementalChooser = takeoverIncrementalChooser;
      return adDefinitions.filter((ad: AdDefinition): boolean => {
        if (incrementalChooser) {
          const apiChooserOverride = incrementalChooser(ad);
          if (apiChooserOverride === false) return false;
          if (apiChooserOverride === true) return true;
        }
        return Boolean(ad.takeoverIncremental);
      });
    },
  }),
  [DO.decide_validAdunits]: assign({
    adUnits: ({
      context: {
        pageParameters: { country },
        config: {
          placement: { adUnits },
        },
      },
    }) => ({
      standard: adUnits.standard.filter(checkAdUnitCountries(country.toLowerCase())),
      incremental: adUnits.incremental.filter(checkAdUnitCountries(country.toLowerCase())),
    }),
  }),
  [DO.hideAnchored]: ({ context }) => {
    context.ads
      .getValues()
      .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
      .forEach(ad => {
        const element = ad.getProperty('elements')?.outerContainer;
        if (element) {
          element.style.transform = 'translate(0, 100%)';
          element.style.transition = '.5s';
        }
      });
  },
  [DO.showAnchored]: ({ context }) => {
    context.ads
      .getValues()
      .filter(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED)
      .forEach(ad => {
        const element = ad.getProperty('elements')?.outerContainer;
        if (element) {
          element.style.transform = 'translate(0, 0)';
          element.style.transition = '.5s';
        }
      });
  },
  [DO.adManagerAuction]: sendTo(TO.adManager, ({}, ads) => ({
    type: AdManager_RECIEVE.auction,
    data: {
      ads: ads.sort((a, b) => a.getProperty('requestOrder') - b.getProperty('requestOrder')),
    },
  })),
  [DO.updateAdManagerTargeting]: sendTo(TO.adManager, ({ context }) => ({
    type: AdManager_RECIEVE.setTargeting,
    data: {
      targeting: getTargeting(context),
    },
  })),
  [DO.spawn_auctionPreProcessor]: assignParams({
    [TO.adPreProcessor]: (
      { spawn, context: { [TO.adPreProcessor]: adPreProcessors } },
      auctionId,
    ) => ({
      ...adPreProcessors,
      [auctionId]: spawn(SPAWN.adPreProcessor, {
        id: `${TO.adPreProcessor}${auctionId}`,
        input: ({
          context,
          event: {
            data: { auctionId, ads },
          },
        }) => ({
          context,
          auctionId,
          ads,
        }),
      }),
    }),
  }),

  [DO.raise_adManagerAuctionProcessed]: sendTo(TO.adManager, (_, { auctionId }) => ({
    type: AdManager_RECIEVE.auctionProcessed,
    data: {
      auctionId,
    },
  })),
  [DO.raise_auctionEnd]: raiseParams((_, { auctionId }) => ({
    type: REPORT_AUCTION.END,
    data: {
      time: timeData(),
      auction: auctionId,
    },
  })),
  [DO.finaliseAd]: ({ context: { ads } }, { adId, gptOutput }) => {
    const env = getEnv();
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    sentry.breadcrumb({
      category: 'advert',
      message: `Ad slotRenderEnded - ${ad.getProperty('name')}`,
    });
    metrics.mark(`Ad slotRenderEnded - ${ad.getProperty('name')}`);

    if (gptOutput !== SpecialGPTOutputs.EMPTY_OUTPUT) {
      const loadTime = env.performance.now();
      ad.update({
        status: AdUnitStatus.DELIVERED,
        loadTime,
        gptOutput,
      });
      const adLabel = ad.getProperty('label');
      if (adLabel) {
        const adLabelElement = createAdLabel(ad.getProperty('id'), adLabel);
        const adElement = ad.getProperty('elements')?.element;

        if (adElement) {
          adElement.insertBefore(adLabelElement, adElement.firstChild);
        }
      }
    } else {
      ad.update({ status: AdUnitStatus.UNDELIVERED });
    }
  },
  [DO.markAdViewed]: ({ context: { ads } }, { adId }) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    ad.update({ viewed: true, status: AdUnitStatus.VIEWED });
  },
  [DO.raise_reportAdLoad]: raiseParams(({ context: { ads } }, { adId }: { adId: string }) => ({
    type: REPORT_AUCTION.AD_LOAD,
    data: {
      time: timeData(),
      auction: ads
        .getValues()
        .find(ad => ad.getProperty('id') === adId)!
        .getProperty('auctionId')!,
    },
  })),
  [DO.reportAdLoad]: ({ context: { ads } }, { adId }) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }
    sentry.breadcrumb({
      category: 'advert',
      message: `Ad slotOnLoad - ${ad.getProperty('name')}`,
    });
    metrics.mark(`Ad slotOnLoad - ${ad.getProperty('name')}`);
    const auctionId = ad.getProperty('auctionId');
    if (!auctionId) {
      log.error('Error handling GPT slot loaded, auctionId is undefined.');
      metrics.recordLoadGptSlotError();
      return;
    }
  },
  [DO.updateAdViewability]: ({ context: { ads } }, { adId, inViewPercentage }) => {
    const ad = ads.getValues().find(ad => ad.getProperty('id') === adId);
    if (!ad) {
      return;
    }

    ad.update({ inView: inViewPercentage >= 50 });
    ad.update({ inViewport: inViewPercentage > 0 });
    ad.update({ inView75Percent: inViewPercentage > 75 });
  },
  [DO.disableAnchoredRefresh]: assign({
    anchoredRefreshDisabled: false,
  }),
  [DO.enableAnchoredRefresh]: assign({
    anchoredRefreshDisabled: true,
  }),
  [DO.updateAdsViewedTime]: ({ context: { ads } }) => {
    ads.getValues().forEach(ad => {
      if (ad.getProperty('inView')) {
        const viewedTime = ad.getProperty('viewedTime') || 0;
        ad.update({ viewedTime: viewedTime + 1 });
      }
    });
  },
  [DO.raise_automaticRefresh]: raise(({ context }) => ({
    type: ON.automaticRefresh,
    data: getAdsToRefresh(context),
  })),
  [DO.resetAds]: (_, { ads }) => {
    ads.forEach(ad => {
      const targeting = ad.getProperty('targeting');
      ad.update({
        targeting: {
          ...targeting,
          ...getRefreshTargetingValues(ad, false),
          ...updateViewabilityTargeting(
            String(targeting['plcmt-slot-refreshed']),
            getRefreshTargetingValues(ad, false)['refresh'],
            String(getRefreshTargetingValues(ad, false)['api-refresh']),
          ),
        },
        inView: false,
        viewedTime: 0,
      });
    });
  },
  [DO.refreshAds]: sendTo(TO.adManager, (_, { ads }) => ({
    type: AdManager_RECIEVE.refresh,
    data: { ads },
  })),
  [DO.updateRefreshTakeoverStatus]: sendTo(
    TO.automaticRefresh,
    ({ context: { takeoverActive } }) => ({
      type: Refresh_RECIEVE.setTakeoverStatus,
      data: takeoverActive,
    }),
  ),
  [DO.updateSlotifyTakeoverStatus]: sendTo(
    TO.slotify,
    ({ context: { takeoverActive } }) =>
      ({
        type: Slotify_ON.setTakeoverStatus,
        data: takeoverActive,
      }) as SetTakeoverStatusEvent,
  ),
  [DO.updateAPITakeoverStatus]: sendTo(TO.API, {
    type: API_RECIEVE.TAKEOVER_READY,
  }),
  [DO.set_takeover]: assign({
    takeoverActive: getTakeoverStatus,
  }),
  [DO.customVideoBehaviour]: customVideoBehaviourAction,
  [DO.loadAdTool]: loadAdTool,
  [DO.reportPageLoad]: report.pageLoad,
  [DO.set_CFTParameters]: assignParams({
    cftParameters: (_, cftParameters) => cftParameters,
  }),
  [DO.reportPageUnload]: report.pageUnload,
  [DO.set_hybridId]: assignParams({
    hybridId: (_, hybridId) => hybridId,
  }),
  [DO.updateAPIHybridID]: sendTo(TO.API, {
    type: API_RECIEVE.HYBRID_ID_READY,
  }),
  [DO.sendUserIdsToFreyr]: sendUserIdsToFreyr,
  [DO.set_hybridABTestTargeting]: assignParams({
    hybridABTestTargeting: (_, hybridABTestTargeting) => hybridABTestTargeting,
  }),
  [DO.adFeature_expandHeight]: expandHeight,
  [DO.adFeature_fullWidth]: fullWidth,
  [DO.adFeature_popOut]: popOut,
  [DO.adFeature_multiFrame]: multiFrame,
  [DO.adFeature_closeButton]: closeButton,
  [DO.decide_hybridABTestTargeting]: raise(
    ({ context }): SetHybridABTestTargetingEvent => ({
      type: ON.setHybridABTestTargeting,
      data: getHybridAbTestTargeting(context),
    }),
  ),
  [DO.spawn_anchoredLogic]: assign({
    [TO.anchoredLogic]: ({ spawn, context }) =>
      spawn(SPAWN.anchoredLogic, {
        id: TO.anchoredLogic,
        input: {
          slots: context.slots,
          ads: context.ads,
          config: context.config,
          pageParameters: context.pageParameters,
        },
      }),
  }),
  [DO.set_pageStyleConstants]: assignParams({
    pageStyleConstants: (_, pageStyleConstants) => pageStyleConstants,
  }),
  [DO.set_GDPRConsent]: assignParams({
    gdprConsent: (_, gdprConsent) => gdprConsent,
  }),
  [DO.set_GPPConsent]: assignParams({
    gppConsent: (_, gppConsent) => gppConsent,
  }),
  [DO.set_USPConsent]: assignParams({
    uspConsent: (_, uspConsent) => uspConsent,
  }),
  [DO.updatePageTargeting]: assignParams({
    pageTargeting: ({ context }, pageTargeting) => ({
      ...context.pageTargeting,
      ...pageTargeting,
    }),
  }),
  [DO.updateAPIConfig]: sendTo(TO.API, {
    type: API_RECIEVE.CONFIG_READY,
  }),
  [DO.set_config]: assignParams({
    config: (_, config): BordeauxConfig => config,
  }),
  [DO.raise_consentDone]: raise({
    type: ON.consentDone,
  }),
  [DO.reportConfigFailure]: raiseParams(
    (_, error): ReportEvent<DO_REPORT.CONFIG_FAILURE> => ({
      type: DO_REPORT.CONFIG_FAILURE,
      data: {
        time: timeData(),
        error,
      },
    }),
  ),
  [DO.set_sommelierResponse]: assignParams({
    sommelierResponse: (_, sommelierResponse): SommelierResponse => sommelierResponse,
  }),
  [DO.reportContentLoad]: raiseParams(
    (_, time): ReportEvent<DO_REPORT.CONTENT_LOAD> => ({
      type: DO_REPORT.CONTENT_LOAD,
      data: { time },
    }),
  ),
  [DO.spawn_pageEventEmitter]: assign({
    [TO.pageEventEmitter]: ({ spawn }) =>
      spawn(SPAWN.pageEventEmitter, { id: TO.pageEventEmitter }),
  }),
  [DO.decide_timingEnabled]: assign({
    timing: ({ context }) => ({
      ...context.timing,
      enabled: Math.random() < context.timing.sampleRate,
    }),
  }),
  [DO.updateAPIReady]: sendTo(TO.API, {
    type: API_RECIEVE.API_READY,
  }),
  [DO.spawn_thirdPartyMachines]: assign({
    [TO.thirdPartyAPI]: ({ spawn, context }) => {
      const bordeaux: Pick<
        BordeauxMachineContext,
        | 'config'
        | 'liveIntentUserSyncEnabled'
        | 'gdprConsent'
        | 'loadGptExternallyPromise'
        | 'loadGptExternally'
        | 'thirdPartyApiConfig'
        | 'sommelierResponse'
        | 'pageTargeting'
      > = {
        config: context.config,
        gdprConsent: context.gdprConsent,
        liveIntentUserSyncEnabled: context.liveIntentUserSyncEnabled,
        thirdPartyApiConfig: context.thirdPartyApiConfig,
        loadGptExternallyPromise: context.loadGptExternallyPromise,
        loadGptExternally: context.loadGptExternally,
        sommelierResponse: context.sommelierResponse,
        pageTargeting: context.pageTargeting,
      };
      return {
        [ThirdParty.AD_SERVER]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.AD_SERVER}`,
          input: { bordeaux, thirdPartyMethods: adServer },
        }),
        [ThirdParty.AM_CLIO]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.AM_CLIO}`,
          input: { bordeaux, thirdPartyMethods: amClio },
        }),
        [ThirdParty.AMAZON]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.AMAZON}`,
          input: { bordeaux, thirdPartyMethods: amazon },
        }),
        [ThirdParty.DOUBLE_VERIFY]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.DOUBLE_VERIFY}`,
          input: { bordeaux, thirdPartyMethods: doubleVerify },
        }),
        [ThirdParty.EUID]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.EUID}`,
          input: { bordeaux, thirdPartyMethods: euid },
        }),
        [ThirdParty.GPT]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.GPT}`,
          input: { bordeaux, thirdPartyMethods: gpt },
        }),
        [ThirdParty.IAS]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.IAS}`,
          input: { bordeaux, thirdPartyMethods: ias },
        }),
        [ThirdParty.PERMUTIVE]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.PERMUTIVE}`,
          input: { bordeaux, thirdPartyMethods: permutive },
        }),
        [ThirdParty.INDEX_EXCHANGE]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.INDEX_EXCHANGE}`,
          input: { bordeaux, thirdPartyMethods: indexExchange },
        }),
        [ThirdParty.LIVE_RAMP]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.LIVE_RAMP}`,
          input: { bordeaux, thirdPartyMethods: liveRamp },
        }),
        [ThirdParty.PREBID]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.PREBID}`,
          input: { bordeaux, thirdPartyMethods: prebid },
        }),
        [ThirdParty.PUBMATIC]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.PUBMATIC}`,
          input: { bordeaux, thirdPartyMethods: pubmatic },
        }),
        [ThirdParty.PUBX]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.PUBX}`,
          input: { bordeaux, thirdPartyMethods: pubx },
        }),
        [ThirdParty.TMT]: spawn(SPAWN.setupThirdPartyAPI, {
          id: `${TO.thirdPartyAPI}${ThirdParty.TMT}`,
          input: { bordeaux, thirdPartyMethods: tmt },
        }),
      } as BordeauxMachineContext[TO.thirdPartyAPI];
    },
  }),
  [DO.set_thirdPartyResults]: assignParams({
    thirdPartyResults: (_, thirdPartyResults) => thirdPartyResults,
  }),

  [DO.set_adToolVersion]: assignParams({
    adToolVersion: (_, adToolVersion) => adToolVersion,
  }),
  [DO.set_loadGPTExternally]: assignParams({
    loadGptExternally: (_, loadGptExternally) => loadGptExternally,
  }),
  [DO.set_prebidAnalyticsEnabled]: assignParams({
    prebidAnalyticsEnabled: (_, prebidAnalyticsEnabled) => prebidAnalyticsEnabled,
  }),
  [DO.set_auctionTimeouts]: assignParams({
    auctionTimeouts: (_, auctionTimeouts) => auctionTimeouts,
  }),
  [DO.set_automaticDynamic]: assignParams({
    automaticDynamic: (_, automaticDynamic) => automaticDynamic,
  }),
  [DO.set_overrideCompanionBounds]: assignParams({
    overrideCompanionBounds: (_, overrideCompanionBounds) => overrideCompanionBounds,
  }),

  [DO.set_experimentId]: assignParams({
    experimentId: (_, experimentId) => experimentId,
  }),
  [DO.set_pageCategory]: assignParams({
    pageCategory: (_, pageCategory) => pageCategory,
  }),
  [DO.set_pageTemplate]: assignParams({
    pageTemplate: (_, pageTemplate) => pageTemplate,
  }),
  [DO.set_refreshTime]: assignParams({
    refreshTime: (_, refreshTime) => refreshTime,
  }),
  [DO.set_takeoverIncrementalCaps]: assignParams({
    takeoverIncrementalCaps: (_, takeoverIncrementalCaps) => takeoverIncrementalCaps,
  }),
  [DO.set_takeoverIncrementalChooser]: assignParams({
    takeoverIncrementalChooser: (_, takeoverIncrementalChooser) => takeoverIncrementalChooser,
  }),
  [DO.set_activationDistance]: assignParams({
    activationDistance: (_, activationDistance) => activationDistance,
  }),
  [DO.set_avoidanceDistance]: assignParams({
    deviceAvoidanceDistance: (_, deviceAvoidanceDistance) => deviceAvoidanceDistance,
  }),
  [DO.set_thirdPartyAPIConfigOverrides]: assignParams({
    thirdPartyApiConfigOverrides: (_, thirdPartyApiConfigOverrides) => thirdPartyApiConfigOverrides,
  }),
  [DO.set_fallbackResponses]: assignParams({
    fallbackResponses: ({ context }, fallbackResponses) => ({
      ...context.fallbackResponses,
      ...fallbackResponses,
    }),
  }),
  [DO.addUnrefreshableNames]: assignParams({
    unrefreshableNames: ({ context }, unrefreshableNames) => [
      ...context.unrefreshableNames,
      ...unrefreshableNames,
    ],
  }),
  [DO.feature_enable]: assignParams({
    features: ({ context }, name) => ({
      ...context.features,
      [name]: true,
    }),
  }),
  [DO.feature_disable]: assignParams({
    features: ({ context }, name) => ({
      ...context.features,
      [name]: false,
    }),
  }),

  [DO.changeBigtopDisplay]: ({ context: { slots } }, { allowPopout }) => {
    // Celtra Big-Tops via ad-features
    if (allowPopout) {
      enablePopOut();
    } else {
      cancelPopOut();
    }

    // FAM Big-Tops
    const slotsToHide = slots.getValues().filter(slot => slot.getProperty('hideFromOtherScreens'));
    const slotElements = slotsToHide.map(slot => {
      const slotPlacementType = slot.getProperty('position');
      return slotPlacementType === SlotPosition.APPEND || slotPlacementType === SlotPosition.PREPEND
        ? slot.getProperty('hookElement')
        : slot.getProperty('element');
    });
    slotElements.forEach(element => {
      element.style.position = allowPopout ? '' : 'static';
    });

    // GAM Big-Tops
    const thirdWay = querySelectorAll<HTMLElement>('#iframe-future-big-top-collapsed');
    [...thirdWay].forEach(element => {
      element.style.top = allowPopout ? '0' : '-1000px';
    });
  },

  [DO.openDockedTool]: assign({
    dockedTool: loadAdTool,
  }),
  [DO.closeUndockedTool]: ({ context: { unDockedTool } }) => {
    if (!unDockedTool) {
      log.error('Un-docked Window does not exist');
      return;
    }
    unDockedTool.close();
  },
  [DO.openUndockedTool]: assign({
    unDockedTool: ({ context: { adToolSource, adToolCommunicationKey } }) => {
      const unDockedTool = getEnv().open('', '_blank', 'popup=true width=400,height=900')!;
      if (!unDockedTool) log.error('Un-docked window does not exist');
      unDockedTool.document.write(
        `<body></body><script>window.unDockedAdTool = true; window.bordeauxAPIKey = "${adToolCommunicationKey}";</script><script src="${adToolSource}" ></script>`,
      );
      return unDockedTool;
    },
  }),
  [DO.closeDockedTool]: ({ context: { dockedTool } }) => {
    if (!dockedTool) {
      log.error('Docked IFrame does not exist');
      return;
    }
    dockedTool.remove();
  },
  [DO.decide_screens]: assign({
    screensDefinition: ({
      context: {
        config: {
          placement: { slots, adUnits },
        },
      },
    }) => {
      const uniformUnits = ([] as Array<AdDefinition>).concat(...Object.values(adUnits));
      const uniformSlots = ([] as Array<SlotDefinition>).concat(...Object.values(slots));
      const [nonAffinityUnits, affinityUnits] = partition(uniformUnits, unit =>
        Boolean(unit.affinitySlotID),
      );

      const screens = uniformSlots
        .map(slotDefinition => {
          const affinity = affinityUnits.find(ad => ad.affinitySlotID === slotDefinition.name);
          return {
            slotDefinition,
            adDefinitions: affinity
              ? [affinity]
              : nonAffinityUnits.filter(findAdsForSlotDefinition(slotDefinition)),
          };
        })
        .reduce(
          (current: ScreensDefinition, match) => {
            const { slotDefinition, adDefinitions } = match;
            if (adDefinitions[0].affinitySlotID) {
              current[0].matches.push(match);
            } else if (
              slotDefinition.adCategoryAllowList.includes(AdUnitCategory.SPONSORED_POST) ||
              slotDefinition.adCategoryAllowList.includes(AdUnitCategory.SPONSORED_BRAND)
            ) {
              current.none.matches.push(match);
            } else {
              const existingScreen = Object.entries(current).find(([, { matches }]) =>
                matches.some(
                  ({ slotDefinition: otherSlot }) =>
                    // If master & companion
                    slotDefinition.master === otherSlot.name ||
                    otherSlot.master === slotDefinition.name,
                ),
              );
              if (existingScreen) current[existingScreen[0]].matches.push(match);
              else
                current[Object.keys(current).length - 1] = {
                  matches: [match],
                  recur: match.adDefinitions.every(ad => ad.incremental),
                };
            }
            return current;
          },
          {
            '0': { matches: [], recur: false },
            none: { matches: [], recur: false },
          },
        );
      return mapScreenSlots(screens, (slot, screenId) => ({
        ...slot,
        screen: screenId,
      }));
    },
  }),
  [DO.createScreenBatch]: assignParams({
    adBatches: (
      {
        context: {
          adBatches,
          screens,
          adCounter,
          config,
          pageAdUnitPath,
          adTypeCounters: globalTypeCounters,
        },
      },
      { screenId }: { screenId: string },
    ) => {
      const screen = screens[screenId];
      const batch = screen.matches.reduce(
        (
          { adTypeCounters, matches, screenId }: ScreenAdBatch,
          { slot, adDefinitions: ads },
          index,
        ) => {
          // TODO: decide based on something
          const adDefinition = ads[0];

          const adUnitPath =
            adDefinition.incremental || screen.recur
              ? `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, (globalTypeCounters[adDefinition.name] || 0) + (adTypeCounters[adDefinition.name] || 0))}`
              : `${pageAdUnitPath}/${adDefinition.name}`;

          const ad = createAdData(
            {
              slot,
              adDefinition,
              adID: `bordeaux-ad-${adCounter + index}`,
              recuring: adDefinition.incremental || screen.recur,
              adUnitPath,
            },
            config,
          );

          return {
            screenId,
            adTypeCounters: {
              ...adTypeCounters,
              [adDefinition.name]: (adTypeCounters[adDefinition.name] || 0) + 1,
            },
            matches: [...matches, { slot, ad }],
          };
        },
        { adTypeCounters: {}, matches: [], screenId },
      );
      return [...adBatches, batch];
    },
  }),
  [DO.countAdBatch]: assignParams({
    adTypeCounters: (
      { context: { adTypeCounters } },
      { adTypeCounters: batchCounts }: ScreenAdBatch,
    ) => ({
      ...adTypeCounters,
      ...Object.fromEntries(
        Object.entries(batchCounts).map(([type, count]) => [
          type,
          (adTypeCounters[count] || 0) + count,
        ]),
      ),
    }),
    adCounter: ({ context: { adCounter } }, { adTypeCounters }: ScreenAdBatch) =>
      Object.values(adTypeCounters).reduce((s, a) => s + a, adCounter),
  }),
  [DO.registerAdBatch]: ({ context: { ads } }, { matches }: ScreenAdBatch) => {
    matches.forEach(({ ad }) => {
      ads.push(ad);
    });
  },
  [DO.registerScreenSlots]: ({ context: { screens, slots } }) => {
    const allSlots = Object.values(screens).flatMap(screen =>
      screen.matches.map(match => match.slot),
    );
    allSlots.forEach(slot => {
      slots.push(slot);
    });
  },
  [DO.replaceScreenSlotConstants]: assign({
    screensDefinition: ({ context: { screensDefinition, pageStyleConstants } }) =>
      mapScreenSlots(screensDefinition, replaceConstantsInSlot(pageStyleConstants)),
  }),
  [DO.generateScreens]: assign({
    screens: ({ context: { screensDefinition } }) => screenSlotGenerator(screensDefinition),
  }),
  [DO.popCurrentBatch]: assign({
    adBatches: ({ context: { adBatches } }) => adBatches.slice(1),
  }),

  [DO.reportFirstAdLoad]: ({ context: { firstAdsLoaded } }) => {
    if (firstAdsLoaded) return;
    report.adsLoaded();
  },
  [DO.markScreenDestroyed]: assignParams({
    screens: ({ context: { screens } }, { screenId }: { screenId: string }) => ({
      ...screens,
      [screenId]: {
        ...screens[screenId],
        destroyed: true,
        satisfied: false,
      },
    }),
  }),
  [DO.spawn_screenSlotWatchers]: assign({
    [TO.screenSlotWatcher]: ({ context: { screens, activationDistance }, spawn }) =>
      Object.fromEntries(
        Object.values(screens).flatMap(screen =>
          screen.matches.map(({ slot }) => [
            slot.getProperty('id'),
            spawn(SPAWN.screenSlotWatcher, {
              id: `${TO.screenSlotWatcher}${slot.getProperty('id')}`,
              input: { slot, activationDistance },
            }),
          ]),
        ),
      ),
  }),

  [DO.updateSlotViewability]: assignParams({
    screens: (
      { context: { screens } },
      {
        slot,
        intersecting,
        type,
      }: { slot: DataObject<Slot>; intersecting: boolean; type: SlotObserverEventType },
    ) => ({
      ...screens,
      [slot.getProperty('screen')]: {
        ...screens[slot.getProperty('screen')],
        [type === SlotObserverEventType.BUFFER ? 'slotsInBuffer' : 'slotsInView']: {
          ...screens[slot.getProperty('screen')][
            type === SlotObserverEventType.BUFFER ? 'slotsInBuffer' : 'slotsInView'
          ],
          [slot.getProperty('id')]: intersecting,
        },
      },
    }),
  }),
  [DO.updateScreenViewability]: assignParams({
    screens: ({ context: { screens } }, { screenId }: { screenId: string }) => {
      const inView = Object.values(screens[screenId].slotsInView).some(Boolean);
      const inBuffer = inView || Object.values(screens[screenId].slotsInBuffer).some(Boolean);
      return {
        ...screens,
        [screenId]: {
          ...screens[screenId],
          alreadyInView: inView || (inBuffer && screens[screenId].alreadyInView),
          inView,
          inBuffer,
        },
      };
    },
  }),
  [DO.markUnsatisfiableScreens]: assign({
    screens: ({ context: { screens } }) => ({
      ...screens,
      ...Object.fromEntries(
        Object.entries(screens)
          .filter(
            ([, { alreadyInView, inView, everSatisfied }]) =>
              !inView && alreadyInView && !everSatisfied,
          )
          .map(([id, screen]) => [id, { ...screen, unsatisfiedInView: true }]),
      ),
    }),
  }),
  [DO.markCurrentScreenSatisfied]: assign({
    screens: ({ context: { screens, currentScreen } }) => ({
      ...screens,
      [currentScreen]: {
        ...screens[currentScreen],
        satisfied: true,
        everSatisfied: true,
      },
    }),
  }),
  [DO.createSoloSlotBatch]: assignParams({
    adBatches: (
      {
        context: {
          adBatches,
          adCounter,
          config,
          screens,
          pageAdUnitPath,
          adTypeCounters: globalTypeCounters,
        },
      },
      { slot }: { slot: DataObject<Slot> },
    ) => {
      const match = screens.none.matches.find(
        match => match.slot.getProperty('id') === slot.getProperty('id'),
      )!;

      // TODO: decide based on something
      const adDefinition = match.adDefinitions[0];

      const adUnitPath = adDefinition.incremental
        ? `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, globalTypeCounters[adDefinition.name] || 0)}`
        : `${pageAdUnitPath}/${adDefinition.name}`;

      const ad = createAdData(
        {
          slot: match.slot,
          adDefinition,
          adID: `bordeaux-ad-${adCounter}`,
          recuring: adDefinition.incremental,
          adUnitPath,
        },
        config,
      );

      return [
        ...adBatches,
        {
          matches: [{ slot: match.slot, ad }],
          adTypeCounters: { [adDefinition.name]: 1 },
          screenId: 'none',
        },
      ];
    },
  }),
  [DO.destroyScreen]: ({ context: { ads, screens } }, { screenId }: { screenId: string }) => {
    const screenAdIds = screens[screenId!]!.matches.map(({ slot }) => slot.getProperty('adID'));
    const screenAds = ads.getValues().filter(ad => screenAdIds.includes(ad.getProperty('id')));
    screenAds.forEach(ad => {
      ad.update({ inView: false });
    });
    fastdom.mutate(() => {
      screenAds.forEach(ad => {
        Object.values(ad.getProperty('elements') || {}).forEach(el => {
          el.remove();
        });
      });
    });
    screens[screenId!]!.matches.forEach(({ slot }) => {
      slot.update({ adID: undefined });
    });
  },
};
export default actions;
