import { addBreadcrumb, SeverityLevel } from '@sentry/browser';
import { log } from '../log';

export interface Breadcrumb {
  message: string;
  category: string;
  level?: SeverityLevel;
}

export const breadcrumb = ({ message, category = 'ui', level = 'info' }: Breadcrumb): void => {
  if (!message) {
    log.error('You must provide a message to a Sentry breadcrumb');
    return;
  }

  addBreadcrumb({
    category,
    message,
    level,
  });
};
