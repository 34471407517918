import { log } from '@repo/utils';
import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export default (service: ApiMachineEventService, version = ''): void => {
  if (typeof version !== 'string') {
    log.info(`Argument passed to setAdToolVersion must be a string, ${typeof version} given`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  service.sendEvent({
    type: API_EMIT.SET_ADTOOL_VERSION,
    data: version,
  });
};
