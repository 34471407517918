import { z } from 'zod';
import { placementSchema } from './zod-schema';
import { Placement } from '../../../types/ad-framework/placement/index.types';

const adaptPlacementConfig = ({
  adunits: adUnits,
  placementAdUnitPath: adUnitPath,
  ...same
}: z.output<typeof placementSchema>): Placement => ({
  ...same,
  adUnits,
  adUnitPath,
});
export default adaptPlacementConfig;
