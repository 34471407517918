import { log } from '@repo/utils';
import { DeviceOptions, DeviceAvoidanceDistance, API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export default (
  service: ApiMachineEventService,
  avoidanceDistance: DeviceAvoidanceDistance,
): void => {
  if (typeof avoidanceDistance !== 'object') {
    log.info(
      `Argument passed to setSlotAvoidanceDistance must be an object, ${typeof avoidanceDistance} given`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  if (
    !(
      DeviceOptions.MOBILE in avoidanceDistance &&
      typeof avoidanceDistance.mobile === 'number' &&
      DeviceOptions.TABLET in avoidanceDistance &&
      typeof avoidanceDistance.tablet === 'number' &&
      DeviceOptions.DESKTOP in avoidanceDistance &&
      typeof avoidanceDistance.desktop === 'number'
    )
  ) {
    log.info(
      `Argument passed to setSlotAvoidanceDistance must be an object, and must contain the keys "${DeviceOptions.MOBILE}", "${DeviceOptions.TABLET}" and "${DeviceOptions.DESKTOP}" with number values`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  service.sendEvent({
    type: API_EMIT.SET_AVOIDANCE_DISTANCE,
    data: avoidanceDistance,
  });
};
