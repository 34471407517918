import setupMarks from './setupMarks';
import setupPerformance from './setupPerformance';
import setupPrometheus from './setupPrometheus';
import setupResources from './setupResources';
import {
  MarksAPI,
  PerformanceAPI,
  PrometheusAPI,
  ResourcesAPI,
  MetricsAPI,
} from '@repo/shared-types';

const init = (): MetricsAPI => {
  const marks: MarksAPI = setupMarks();
  const performance: PerformanceAPI = setupPerformance();
  const prometheus: PrometheusAPI = setupPrometheus();
  const resources: ResourcesAPI = setupResources();

  return {
    ...marks,
    ...performance,
    ...prometheus,
    ...resources,
  };
};
const metrics = init();

export default metrics;
