import { Labels } from '@future/promjs/lib';
import { Counter } from '@future/promjs/lib/counter';

import { PrometheusAPI } from '@repo/shared-types';

import { labels } from '../labels';
import { PrometheusController } from '../PrometheusController';

export const recordConfigLoad =
  (counter: Counter): PrometheusAPI['recordConfigLoad'] =>
  result => {
    if (labels.site) {
      const configLabels: Labels = { target: 'config', result, ...labels };
      PrometheusController.update({ configLoaded: true, metricsUpdated: true });
      counter.inc(configLabels);
    }
  };

export const recordThirdPartiesLoad =
  (counter: Counter): PrometheusAPI['recordThirdPartiesLoad'] =>
  () => {
    if (labels.site) {
      const thirdPartyLabels: Labels = { target: 'thirdparties', ...labels };
      PrometheusController.update({ thirdPartiesLoaded: true, metricsUpdated: true });
      counter.inc(thirdPartyLabels);
    }
  };
