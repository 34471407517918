import getEnv from './env';

const env = getEnv();
const url: string = env.document.URL;

const DEV_ENVS: string[] = [
  'local.fte',
  'local.responsive',
  'local.progressive',
  'dev.fte',
  'stage.fte',
  'futureplc.engineering',
];

const isDevSite = Boolean(DEV_ENVS.find(domain => url.indexOf(domain) > -1));
const isDevVersion = (process.env.npm_package_version || '').includes('Dev');
const isDevEnv = isDevSite || isDevVersion;

export default isDevEnv;
