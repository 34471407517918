import { z } from 'zod';
import { sommelierResponseSchema } from './sommelier-request/zod-schema';

export const configSchema = sommelierResponseSchema.transform(
  ({ config: thirdPartyAPIConfig, ...rest }) => ({
    ...rest,
    thirdPartyAPIConfig,
  }),
);

export type BordeauxConfig = z.output<typeof configSchema>;
