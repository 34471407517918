export enum Googletag_ON {
  visibilityChanged = 'GOOGLETAG:EVENT:SLOT_VISIBILITY_CHANGED',
  renderEnded = 'GOOGLETAG:EVENT:SLOT_RENDER_ENDED',
  onLoad = 'GOOGLETAG:EVENT:SLOT_ON_LOAD',
  impressionViewable = 'GOOGLETAG:EVENT:SLOT_IMPRESSION_VIEWABLE',
}
export enum AdManager_RECIEVE {
  auction = 'AD_MANAGER:EVENT_IN:AUCTION',
  refresh = 'AD_MANAGER:EVENT_IN:REFRESH',
  setTargeting = 'AD_MANAGER:EVENT_IN:SET_TARGETING',
  auctionProcessed = 'AD_MANAGER:EVENT_IN:AUCTION_PROCESSED',
  destroyAds = 'AD_MANAGER:EVENT_IN:DESTROY_ADS'
}
export enum AdManager_EMIT {
  auctionCreated = 'AD_MANAGER:EVENT_OUT:AUCTION_CREATED',
  auctionLoaded = 'AD_MANAGER:EVENT_OUT:AUCTION_LOADED',
  adVisibilityChanged = 'AD_MANAGER:EVENT_OUT:SLOT_VISIBILITY_CHANGED',
  adRenderEnded = 'AD_MANAGER:EVENT_OUT:SLOT_RENDER_ENDED',
  adOnLoad = 'AD_MANAGER:EVENT_OUT:SLOT_ON_LOAD',
  adImpressionViewable = 'AD_MANAGER:EVENT_OUT:SLOT_IMPRESSION_VIEWABLE',
}