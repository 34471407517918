import { SlotifyMachineContext, AnySlotifyEvent } from '@repo/shared-types';
import { ParameterizedObject, raise as genericRaise } from 'xstate';

const raise = <
  E extends AnySlotifyEvent = AnySlotifyEvent,
  S extends AnySlotifyEvent = AnySlotifyEvent,
>(
  ...args: Parameters<
    typeof genericRaise<SlotifyMachineContext, E, S, ParameterizedObject['params']>
  >
) => genericRaise<SlotifyMachineContext, E, S, ParameterizedObject['params']>(...args);

export default raise;
