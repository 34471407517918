
export enum METRICS {
  CONFIG = 'CONFIG',
  AUCTIONS = 'AUCTIONS',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_SCRIPTS = 'THIRD_PARTY_SCRIPTS',
}

export enum IF {
  timingCollectionEnabled = 'BORDEAUX:GUARD:TIMING_COLLECTION_ENABLED',
  auctionIsUnsent = 'BORDEAUX:GUARD:AUCTION_IS_UNSENT',
  errorIsTimeout = 'BORDEAUX:GUARD:ERROR_IS_TIMEOUT',
  duplicateScripts = 'BORDEAUX:GUARD:DUPLICATE_SCRIPTS',
  hasEnoughConsentForAuction = 'BORDEAUX:GUARD:HAS_ENOUGH_CONSENT_FOR_AUCTION',
  featuresInitialised = 'BORDEAUX:GUARD:FEATURES_INITIALISED',
  featureEnabled = 'BORDEAUX:GUARD:FEATURE_ENABLED',
  hasExperimentId = 'BORDEAUX:GUARD:HAS_EXPERIMENT_ID',
  adToolOpenedFromURL = 'BORDEAUX:GUARD:AD_TOOL_OPENED_FROM_URL',
  adsBlocked = 'BORDEAUX:GUARD:ADS_BLOCKED',
  prebidEnabled = 'BORDEAUX:GUARD:PREBID_ENABLED',
  liveIntentUserSyncEnabled = 'BORDEAUX:GUARD:LIVE_INTENT_USER_SYNC_ENABLED',
  consentDone = 'BORDEAUX:GUARD:CONSENT_DONE',
  hasAds = 'BORDEAUX:GUARD:HAS_ADS',
  scrolledPastFirstTandem = 'BORDEAUX:GUARD:SCROLLED_PAST_FIRST_TANDEM',
  slotIsBeforeFirstTandem = 'BORDEAUX:GUARD:SLOT_IS_BEFORE_FIRST_TANDEM',
  shouldUseScreenMode = 'BORDEAUX:GUARD:SHOULD_USE_SCREEN_MODE',
  batchesInStack = 'BORDEAUX:GUARD:BATCHES_IN_STACK',
  hasNextScreen = 'BORDEAUX:GUARD:HAS_NEXT_SCREEN',
  slotHasScreen = 'BORDEAUX:GUARD:SLOT_HAS_SCREEN',
  screenlessSlotNeedsLoading = 'BORDEAUX:GUARD:SCREENLESS_SLOT_NEEDS_LOADING',
  otherScreenInView = 'BORDEAUX:GUARD:OTHER_SCREEN_IN_VIEW',
}
export enum GO {
  initialising = 'BORDEAUX:STATE:INITIALISING',
  scrapingParameters = 'BORDEAUX:STATE:SCRAPING_PARAMATERS',
  fetchingConfig = 'BORDEAUX:STATE:FETCHING_CONFIG',
  checkingConfig = 'BORDEAUX:STATE:CHECKING_CONFIG',
  parsingConfig = 'BORDEAUX:STATE:PARSING_CONFIG',
  retrievingConsent = 'BORDEAUX:STATE:RETRIEVING_CONSENT',
  loadingThirdParties = 'BORDEAUX:STATE:LOADING_THIRD_PARTIES',
  decidingPageStyleConstants = 'BORDEAUX:STATE:DECIDING_PAGE_STYLE_CONSTANTS',
  decidingMode = 'BORDEAUX:STATE:DECIDING_MODE',

  handlingSlots = 'BORDEAUX:STATE:HANDLING_SLOTS',
  handlingScreens = 'BORDEAUX:STATE:HANDLING_SCREENS',
  waitForNextBatch = 'BORDEAUX:STATE:WAIT_FOR_NEXT_BATCH',
  loadAdBatch = 'BORDEAUX:STATE:LOAD_AD_BATCH',

  error = 'BORDEAUX:STATE:ERROR',
  waitForInitialisation = 'BORDEAUX:STATE:WAIT_FOR_INITIALISATION',
}

export enum DO {
  useParsedFallbackConfig = 'BORDEAUX:ACTION:USE_PARSED_FALLBACK_CONFIG',
  useFallbackConfig = 'BORDEAUX:ACTION:USE_FALLBACK_CONFIG',

  raiseOpenAdTool = 'BORDEAUX:ACTION:RAISE_OPEN_AD_TOOL',
  spawn_APIMachine = 'BORDEAUX:ACTION:CREATE_API_MACHINE',
  initialiseFeatures = 'BORDEAUX:ACTION:INITIALISE_FEATURES',

  initialiseSentry = 'BORDEAUX:ACTION:INITIALISE_SENTRY',

  thirdPartiesReady = 'BORDEAUX:ACTION:THIRD_PARTIES_READY',
  sendABTestToFreyr = 'BORDEAUX:ACTION:SEND_AB_TEST_TO_FREYR',

  decide_avoidanceDistance = 'BORDEAUX:ACTION:DECIDE_AVOIDANCE_DISTANCE',
  decide_enablePermutive = 'BORDEAUX:ACTION:DECIDE_ENABLE_PERMUTIVE',
  decide_thirdPartyConfig = 'BORDEAUX:ACTION:DECIDE_THIRD_PARTY_CONFIG',
  decide_pageAdunitPath = 'BORDEAUX:ACTION:DECIDE_PAGE_ADUNIT_PATH',
  decide_validAdunits = 'BORDEAUX:ACTION:DECIDE_VALID_ADUNITS',
  decide_refreshTime = 'BORDEAUX:ACTION:DECIDE_REFRESH_TIME',
  decide_takeoverIncrementals = 'BORDEAUX:ACTION:DECIDE_TAKEOVER_INCREMENTALS',
  decide_liveIntentUserSync = 'BORDEAUX:ACTION:DECIDE_LIVE_INTENT_USER_SYNC',
  decide_siteActivationDistance = 'BORDEAUX:ACTION:DECIDE_SITE_ACTIVATION_DISTANCE',
  decide_testActivationDistance = 'BORDEAUX:ACTION:DECIDE_TEST_ACTIVATION_DISTANCE',
  decide_v2TestActivationDistance = 'BORDEAUX:ACTION:DECIDE_V2_TEST_ACTIVATION_DISTANCE',
  decide_testPubx = 'BORDEAUX:ACTION:DECIDE_TEST_PUBX',
  decide_testAdServiceActivation = 'BORDEAUX:ACTION:DECIDE_TEST_ID_SERVICE_ACTIVATION',
  decide_adToolSource = 'BORDEAUX:ACTION:DECIDE_AD_TOOL_SOURCE',
  decide_hybridABTestTargeting = 'BORDEAUX:ACTION:DECIDE_HYBRID_AB_TEST_TARGETING',
  decide_timingEnabled = 'BORDEAUX:ACTION:DECIDE_TIMING_ENABLED',
  decide_screens = 'BORDEAUX:ACTION:DECIDE_SCREENS',
  decide_doubleVerifyActive = 'BORDEAUX:ACTION:DECIDE_DOUBLE_VERIFY_ACTIVE',

  setup_userSync = 'BORDEAUX:ACTION:SETUP_USER_SYNC',
  spawn_adManager = 'BORDEAUX:ACTION:SETUP_AD_MANAGER',
  setup_customActivations = 'BORDEAUX:ACTION:SETUP_CUSTOM_ACTIVATIONS',


  handleError = 'BORDEAUX:ACTION:HANDLE_ERROR',

  checkAdBlock = 'BORDEAUX:ACTION:CHECK_AD_BLOCK',
  readQueryParameters = 'BORDEAUX:ACTION:READ_QUERY_PARAMETERS',
  readPageParameters = 'BORDEAUX:ACTION:READ_PAGE_PARAMETERS',
  spawn_arbitraryEventEmitter = 'BORDEAUX:ACTION:CREATE_SHAMEFUL_MACHINE',
  assignIndexExchangeDeviceType = 'BORDEAUX:ACTION:ASSIGN_INDEX_EXCHANGE_DEVICE_TYPE',
  assignBordeauxAdsPromise = 'BORDEAUX:ACTION:ASSIGN_BORDEAUX_ADS_PROMISE',
  checkMultipleScripts = 'BORDEAUX:ACTION:CHECK_MULTIPLE_SCRIPTS',
  spawn_adToolTapOpenMachine = 'BORDEAUX:ACTION:CREATE_ADTOOL_TAP_OPEN_MACHINE',
  checkAdToolParam = 'BORDEAUX:ACTION:CHECK_ADTOOL_PARAM',
  spawn_automaticRefreshMachine = 'BORDEAUX:ACTION:CREATE_AUTOMATIC_REFRESH_MACHINE',
  spawn_adFeatureMachine = 'BORDEAUX:ACTION:CREATE_AD_FEATURE_MACHINE',
  reportIfAdBlocked = 'BORDEAUX:ACTION:REPORT_IF_AD_BLOCKED',
  assignLiveIntentUserSyncTargeting = 'BORDEAUX:ACTION:ASSIGN_LIVE_INTENT_USER_SYNC_TARGETING',

  createScreenBatch = 'BORDEAUX:ACTION:CREATE_SCREEN_BATCH',
  countAdBatch = 'BORDEAUX:ACTION:COUNT_AD_BATCH',
  destroyScreen = 'BORDEAUX:ACTION:DESTROY_SCREEN',
  markScreenDestroyed = 'BORDEAUX:ACTION:MARK_SCREEN_DESTROYED',
  markUnsatisfiableScreens = 'BORDEAUX:ACTION:MARK_UNSATISFIABLE_SCREENS',
  markCurrentScreenSatisfied = 'BORDEAUX:ACTION:MARK_CURRENT_SCREEN_SATISFIED',
  spawn_screenSlotWatchers = 'BORDEAUX:ACTION:WATCH_SCREEN_SLOTS',
  registerScreenSlots = 'BORDEAUX:ACTION:REGISTER_SCREEN_SLOTS',
  replaceScreenSlotConstants = 'BORDEAUX:ACTION:REPLACE_SCREEN_SLOT_CONSTANTS',
  generateScreens = 'BORDEAUX:ACTION:GENERATE_SCREENS',
  popCurrentBatch = 'BORDEAUX:ACTION:POP_CURRENT_BATCH',
  reportFirstAdLoad = 'BORDEAUX:ACTION:REPORT_FIRST_AD_LOAD',
  updateSlotViewability = 'BORDEAUX:ACTION:UPDATE_SLOT_VIEWABILITY',
  updateScreenViewability = 'BORDEAUX:ACTION:UPDATE_SCREEN_VIEWABILITY',
  registerAdBatch = 'BORDEAUX:ACTION:REGISTER_AD_BATCH',
  createSoloSlotBatch = 'BORDEAUX:ACTION:CREATE_SOLO_SLOT_BATCH',

  updateAdToolPage = 'BORDEAUX:ACTION:UPDATE_AD_TOOL_PAGE',
  storeHybridTestSessions = 'BORDEAUX:ACTION:STORE_HYBRID_TEST_SESSIONS',
  decideTestAuctionTimeouts = 'BORDEAUX:ACTION:DECIDE_TEST_AUCTION_TIMEOUTS',

  adManagerAuction = 'BORDEAUX:ACTION:AD_MANAGER_AUCTION',
  updateAdManagerTargeting = 'BORDEAUX:ACTION:UPDATE_AD_MANAGER_TARGETING',
  spawn_auctionPreProcessor = 'BORDEAUX:ACTION:PRE_PROCESS_AUCTION',

  showAnchored = 'BORDEAUX:ACTION:SHOW_ANCHORED',
  hideAnchored = 'BORDEAUX:ACTION:HIDE_ANCHORED',
  
  changeBigtopDisplay = 'BORDEAUX:ACTION:CHANGE_BIGTOP_DISPLAY',
  
  openDockedTool = 'BORDEAUX:ACTION:OPEN_DOCKED_TOOL',
  openUndockedTool = 'BORDEAUX:ACTION:OPEN_UNDOCKED_TOOL',
  closeDockedTool = 'BORDEAUX:ACTION:CLOSE_DOCKED_TOOL',
  closeUndockedTool = 'BORDEAUX:ACTION:CLOSE_UNDOCKED_TOOL',

  raise_adManagerAuctionProcessed = 'BORDEAUX:ACTION:RAISE_AD_MANAGER_AUCTION_PROCESSED',
  raise_auctionEnd = 'BORDEAUX:ACTION:RAISE_AUCTION_END',
  raise_reportAdLoad = 'BORDEAUX:ACTION:RAISE_REPORT_AD_LOAD',
  raise_automaticRefresh = 'BORDEAUX:ACTION:RAISE_AUTOMATIC_REFRESH',
  raise_consentDone = 'BORDEAUX:ACTION:RAISE_CONSENT_DONE',

  adFeature_expandHeight = 'BORDEAUX:ACTION:AD_FEATURE_EXPAND_HEIGHT',
  adFeature_fullWidth = 'BORDEAUX:ACTION:AD_FEATURE_FULL_WIDTH',
  adFeature_popOut = 'BORDEAUX:ACTION:AD_FEATURE_POP_OUT',
  adFeature_multiFrame = 'BORDEAUX:ACTION:AD_FEATURE_MULTI_FRAME',
  adFeature_closeButton = 'BORDEAUX:ACTION:AD_FEATURE_CLOSE_BUTTON',

  finaliseAd = 'BORDEAUX:ACTION:FINALISE_AD',
  markAdViewed = 'BORDEAUX:ACTION:MARK_AD_VIEWED',
  reportAdLoad = 'BORDEAUX:ACTION:REPORT_AD_LOAD',
  updateAdViewability = 'BORDEAUX:ACTION:UPDATE_AD_VIEWABILITY',
  disableAnchoredRefresh = 'BORDEAUX:ACTION:DISABLE_ANCHORED_REFRESH',
  enableAnchoredRefresh = 'BORDEAUX:ACTION:ENABLE_ANCHORED_REFRESH',
  updateAdsViewedTime = 'BORDEAUX:ACTION:UPDATE_ADS_VIEWED_TIME',
  resetAds = 'BORDEAUX:ACTION:RESET_ADS',
  refreshAds = 'BORDEAUX:ACTION:REFRESH_ADS',
  updateRefreshTakeoverStatus = 'BORDEAUX:ACTION:UPDATE_REFRESH_TAKEOVER_STATUS',
  updateSlotifyTakeoverStatus = 'BORDEAUX:ACTION:UPDATE_SLOTIFY_TAKEOVER_STATUS',
  updateAPITakeoverStatus = 'BORDEAUX:ACTION:UPDATE_API_TAKEOVER_STATUS',
  customVideoBehaviour = 'BORDEAUX:ACTION:CUSTOM_VIDEO_BEHAVIOUR',
  loadAdTool = 'BORDEAUX:ACTION:LOAD_AD_TOOL',
  reportPageLoad = 'BORDEAUX:ACTION:REPORT_PAGE_LOAD',
  reportPageUnload = 'BORDEAUX:ACTION:REPORT_PAGE_UNLOAD',
  updateAPIHybridID = 'BORDEAUX:ACTION:UPDATE_API_HYBRID_ID',
  sendUserIdsToFreyr = 'BORDEAUX:ACTION:SEND_USER_IDS_TO_FREYR',
  spawn_anchoredLogic = 'BORDEAUX:ACTION:START_ANCHORED_LOGIC',
  updatePageTargeting = 'BORDEAUX:ACTION:UPDATE_PAGE_TARGETING',
  updateAPIConfig = 'BORDEAUX:ACTION:UPDATE_API_CONFIG',
  reportConfigFailure = 'BORDEAUX:ACTION:REPORT_CONFIG_FAILURE',
  reportContentLoad = 'BORDEAUX:ACTION:REPORT_CONTENT_LOAD',
  spawn_pageEventEmitter = 'BORDEAUX:ACTION:CREATE_PAGE_EVENT_EMITTER',
  updateAPIReady = 'BORDEAUX:ACTION:UPDATE_API_READY',
  spawn_thirdPartyMachines = 'BORDEAUX:ACTION:CREATE_THIRD_PARTY_MACHINES',
  feature_enable = 'BORDEAUX:ACTION:ENABLE_FEATURE',
  feature_disable = 'BORDEAUX:ACTION:DISABLE_FEATURE',
  addUnrefreshableNames = 'BORDEAUX:ACTION:ADD_UNREFRESHABLE_NAMES',

  set_thirdPartyResults = 'BORDEAUX:ACTION:SET_THIRD_PARTY_RESULTS',
  set_takeover = 'BORDEAUX:ACTION:SET_TAKEOVER',
  set_CFTParameters = 'BORDEAUX:ACTION:SET_CFT_PARAMATERS',
  set_hybridId = 'BORDEAUX:ACTION:SET_HYBRID_ID',
  set_hybridABTestTargeting = 'BORDEAUX:ACTION:SET_HYBRID_AB_TEST_TARGETING',
  set_pageStyleConstants = 'BORDEAUX:ACTION:SET_PAGE_STYLE_CONSTANTS',
  set_GDPRConsent = 'BORDEAUX:ACTION:SET_GDPR_CONSENT',
  set_GPPConsent = 'BORDEAUX:ACTION:SET_GPP_CONSENT',
  set_USPConsent = 'BORDEAUX:ACTION:SET_USP_CONSENT',
  set_config = 'BORDEAUX:ACTION:SET_CONFIG',
  set_sommelierResponse = 'BORDEAUX:ACTION:SET_SOMMELIER_RESPONSE',
  set_adToolVersion = 'BORDEAUX:ACTION:SET_ADTOOL_VERSION',
  set_loadGPTExternally = 'BORDEAUX:ACTION:SET_LOAD_GPT_EXTERNALLY',
  set_prebidAnalyticsEnabled = 'BORDEAUX:ACTION:SET_PREBID_ANALYTICS_ENABLED',
  set_auctionTimeouts = 'BORDEAUX:ACTION:SET_AUCTION_TIMEOUTS',
  set_automaticDynamic = 'BORDEAUX:ACTION:SET_AUTOMATIC_DYNAMIC',
  set_overrideCompanionBounds = 'BORDEAUX:ACTION:SET_OVERRIDE_COMPANION_BOUNDS',
  set_experimentId = 'BORDEAUX:ACTION:SET_EXPERIMENT_ID',
  set_pageCategory = 'BORDEAUX:ACTION:SET_PAGE_CATEGORY',
  set_pageTemplate = 'BORDEAUX:ACTION:SET_PAGE_TEMPLATE',
  set_refreshTime = 'BORDEAUX:ACTION:SET_REFRESH_TIME',
  set_takeoverIncrementalCaps = 'BORDEAUX:ACTION:SET_TAKEOVER_INCREMENTAL_CAPS',
  set_takeoverIncrementalChooser = 'BORDEAUX:ACTION:SET_TAKEOVER_INCREMENTAL_CHOOSER',
  set_activationDistance = 'BORDEAUX:ACTION:SET_ACTIVATION_DISTANCE',
  set_avoidanceDistance = 'BORDEAUX:ACTION:SET_AVOIDANCE_DISTANCE',
  set_thirdPartyAPIConfigOverrides = 'BORDEAUX:ACTION:SET_THIRD_PARTY_API_CONFIG_OVERRIDES',
  set_fallbackResponses = 'BORDEAUX:ACTION:SET_FALLBACK_RESPONSES',
  measure_ad_density = 'BORDEAUX:ACTION:MEASURE_AD_DENSITY',
  setup_article_observer = 'BORDEAUX:ACTION:SETUP_ARTICLE_OBSERVER',
  decide_ad_density = 'BORDEAUX:ACTION:DECIDE_AD_DENSITY',
}

export enum SPAWN {
  arbitraryEventEmitter = 'BORDEAUX:ACTOR:ARBITRARY_EVENT_EMITTER',
  elementTapHandler = 'BORDEAUX:ACTOR:ELEMENT_TAP_HANDLER',
  automaticRefresh = 'BORDEAUX:ACTOR:INIT_AUTOMATIC_REFRESH',
  pageEventEmitter = 'BORDEAUX:ACTOR:PAGE_EVENT_EMITTER',
  getEnvDOMContentLoaded = 'BORDEAUX:ACTOR:GET_ENV_DOM_CONTENT_LOADED',
  API = 'BORDEAUX:ACTOR:SETUP_API',
  fetchConfig = 'BORDEAUX:ACTOR:FETCH_CONFIG',
  checkConfig = 'BORDEAUX:ACTOR:CHECK_CONFIG',
  parseConfig = 'BORDEAUX:ACTOR:PARSE_CONFIG',
  getGDPRConsent = 'BORDEAUX:ACTOR:GET_GDPR_CONSENT',
  getUSPConsent = 'BORDEAUX:ACTOR:GET_USP_CONSENT',
  getGPPConsent = 'BORDEAUX:ACTOR:GET_GPP_CONSENT',

  setupThirdPartyAPI = 'BORDEAUX:ACTOR:SETUP_THIRD_PARTY_API',
  waitForAllThirdParties = 'BORDEAUX:ACTOR:WAIT_FOR_ALL_THIRD_PARTIES',

  listenForAdFeatures = 'BORDEAUX:ACTOR:LISTEN_FOR_AD_FEATURES',
  readPageStyles = 'BORDEAUX:ACTOR:READ_PAGE_STYLES',

  anchoredLogic = 'BORDEAUX:ACTOR:ANCHORED_LOGIC',
  slotify = 'BORDEAUX:ACTOR:SLOTIFY',
  insertAds = 'BORDEAUX:ACTOR:INSERT_ADS',
  adManager = 'BORDEAUX:ACTOR:AD_MANAGER',
  adPreProcessor = 'BORDEAUX:ACTOR:AD_PRE_PROCESSOR',
  screenSlotWatcher = 'BORDEAUX:ACTOR:SCREEN_SLOT_WATCHER',
  toolAPI = 'BORDEAUX:ACTOR:TOOL_API',
}
export enum TO {
  arbitraryEventEmitter = 'ARBITRARY_EVENT_EMITTER',
  elementTapHandler = 'ELEMENT_TAP_HANDLER',
  automaticRefresh = 'INIT_AUTOMATIC_REFRESH',
  pageEventEmitter = 'PAGE_EVENT_EMITTER',
  getEnvDOMContentLoaded = 'GET_ENV_DOM_CONTENT_LOADED',
  API = 'SETUP_API',
  fetchConfig = 'FETCH_CONFIG',
  checkConfig = 'CHECK_CONFIG',
  parseConfig = 'PARSE_CONFIG',
  getGDPRConsent = 'GET_GDPR_CONSENT',
  getUSPConsent = 'GET_USP_CONSENT',
  getGPPConsent = 'GET_GPP_CONSENT',

  thirdPartyAPI = 'SETUP_THIRD_PARTY_API',
  waitForAllThirdParties = 'WAIT_FOR_ALL_THIRD_PARTIES',

  adFeatures = 'LISTEN_FOR_AD_FEATURES',
  readPageStyles = 'READ_PAGE_STYLES',

  anchoredLogic = 'ANCHORED_LOGIC',
  slotify = 'SLOTIFY',
  insertAds = 'INSERT_ADS',
  adManager = 'AD_MANAGER',
  adPreProcessor = 'AD_PRE_PROCESSOR',
  screenSlotWatcher = 'SCREEN_SLOT_WATCHER',
  toolAPI = 'TOOL_API',
}

export enum PAGE_STYLE_CONSTANTS {
  PAGE_BACKGROUND = 'page_background',
  PAGE_TEXT = 'page_text',
}