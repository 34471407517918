import {
  BordeauxMachineContext,
  Screen,
  ScreenDefinition,
  SlotDefinition,
} from '@repo/shared-types';

export const mapScreenSlots = <
  Input = SlotDefinition,
  Output = Input,
  S extends object = ScreenDefinition,
>(
  screens: Record<string, S>,
  mapper: (slotDefinition: Input, screenId: string, screen: S) => Output,
) =>
  Object.fromEntries(
    Object.entries(screens).map(([screenId, screen]) => [
      screenId,
      {
        ...screen,
        matches: (screen as any).matches.map(match => ({
          ...match,
          slotDefinition: mapper(match.slotDefinition as Input, screenId, screen),
        })),
      },
    ]),
  );

export const getNextScreen = ({
  screens,
  firstAdsLoaded,
}: Pick<BordeauxMachineContext, 'screens' | 'firstAdsLoaded'>): Screen | undefined =>
  Object.values(screens).find(
    ({ inBuffer, satisfied, alreadyInView, unsatisfiedInView }) =>
      !unsatisfiedInView && inBuffer && !satisfied && (!firstAdsLoaded || !alreadyInView),
  );
