import { GDPRConsent } from '@repo/shared-types';

const USER_SYNC_URL = 'https://pixel.servebom.com/partner';

export const getCacheBuster = (): string => Math.floor(Math.random() * 11000).toString(10);

export const getUserSyncURL = ({ uid = '', ...params }: Record<string, string>): string => {
  const url = new URL(USER_SYNC_URL);
  const allParams = {
    cb: getCacheBuster(),
    svc: 'us',
    ...params,
  };
  Object.entries(allParams).forEach(([params, value]) => {
    url.searchParams.set(params, value);
  });
  return `${url.toString()}&uid=${uid}`; // UID must be last
};

export const getGDPRData = (gdprConsent: GDPRConsent): string | false => {
  return (gdprConsent.consent?.gdprApplies && gdprConsent.consent.tcString) || false;
};
