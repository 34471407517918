import { log } from '@repo/utils';
import { DeviceOptions, TakeoverIncrementalCaps, API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export default (
  service: ApiMachineEventService,
  takeoverIncrementalCaps: TakeoverIncrementalCaps,
): void => {
  if (typeof takeoverIncrementalCaps !== 'object') {
    log.error(
      `Argument passed to setTakeoverIncrementalCaps must be an object, ${typeof takeoverIncrementalCaps} given`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  if (
    (DeviceOptions.MOBILE in takeoverIncrementalCaps &&
      typeof takeoverIncrementalCaps[DeviceOptions.MOBILE] !== 'number') ||
    (DeviceOptions.TABLET in takeoverIncrementalCaps &&
      typeof takeoverIncrementalCaps[DeviceOptions.TABLET] !== 'number') ||
    (DeviceOptions.DESKTOP in takeoverIncrementalCaps &&
      typeof takeoverIncrementalCaps[DeviceOptions.DESKTOP] !== 'number')
  ) {
    log.error(
      `Argument passed to setTakeoverIncrementalCaps must be an object, and must contain the keys "${DeviceOptions.MOBILE}", "${DeviceOptions.TABLET}" and/or "${DeviceOptions.DESKTOP}" with number values`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  service.sendEvent({
    type: API_EMIT.SET_TAKEOVER_INCREMENTAL_CAPS,
    data: takeoverIncrementalCaps,
  });
};
