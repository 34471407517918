import { SlotDefinition } from '@repo/shared-types';
import { querySelector, querySelectorAll } from '@repo/utils';

const selectSlotHooks = (
  { hook, multiple }: SlotDefinition,
  recur: boolean = false,
): Array<HTMLElement> => {
  if (multiple || recur) {
    const hookElements = querySelectorAll<HTMLElement>(hook);
    return hookElements;
  }
  const hookElement = querySelector<HTMLElement>(hook);
  if (hookElement) {
    return [hookElement];
  }
  return [];
};
export default selectSlotHooks;
