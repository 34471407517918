import {
  AddAdHighightsEvent,
  AddSlotHighightsEvent,
  AdToolPostActionRequest,
  API_EMIT,
  CleanBordeauxMachineContext,
  DockAdToolEvent,
  ON,
  ProvideBordeauxContextEvent,
  RemoveAdHighightsEvent,
  RemoveSlotHighightsEvent,
  SetAdToolPageEvent,
  SetPrebidAnalyticsEnabledApiEvent,
  Targeting,
  TO,
  ToggleRefreshPausedApiEvent,
  ToolRequestBordeauxContextEvent,
  UnDockAdToolEvent,
  UpdatePrebidAnalyticsApiEvent,
  AnyToolEmitEvent,
  AnyToolRecieveEvent,
  ToolGetTargetingEvent,
  ToolGetTargetingResponseEvent,
  ToolScrollToElementEvent,
  ToolScrollToSlotEvent,
  ToolSetMinimisedEvent,
  ToolSetPositionEvent,
  Tool_EMIT,
} from '@repo/shared-types';
import { cookies, getEnv, log } from '@repo/utils';
import { fromCallback } from 'xstate';

const deepClone = (obj: any) => {
  switch (typeof obj) {
    case 'function':
      return `<Function:${obj.name}>`;
    case 'object': {
      if (obj === null) return obj;
      if (Array.isArray(obj)) return obj.map(deepClone);
      if ('toJSON' in obj) return obj.toJSON();
      if (obj instanceof Window) return `<Window>`;
      if (obj instanceof Element) return `<Element:${obj.tagName}>`;
      return Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, deepClone(val)]));
    }
    default:
      return obj;
  }
};

const toolAPIMachine = fromCallback<
  AnyToolRecieveEvent,
  { adToolCommunicationKey: string },
  AnyToolEmitEvent
>(({ input: { adToolCommunicationKey }, sendBack, receive }) => {
  const env = getEnv();
  const ignoredContextProperties: Array<string> = (Object.values(TO) as Array<string>).concat([
    'dockedTool',
    'unDockedTool',
    'slots',
    'ads',
    'fallbackResponses',
    'loadGptExternallyPromise',
    'userSyncPixels',
  ]);
  const requests: Record<number, (event: AnyToolRecieveEvent) => void> = {};
  receive(event => {
    requests[event.data.requestId](event);
    delete requests[event.data.requestId];
  });
  const respond = (
    source: MessageEventSource,
    requestId: number,
    returnData: unknown = undefined,
  ) => {
    source.postMessage({
      type: 'bordeaux-ad-tool-response',
      apiKey: adToolCommunicationKey,
      requestId,
      return: returnData,
    });
  };
  const waitForResponse = <E extends AnyToolRecieveEvent = AnyToolRecieveEvent, R = unknown>(
    source: MessageEventSource,
    requestId: number,
    parse: (event: E) => R,
  ) => {
    requests[requestId] = (event: AnyToolRecieveEvent) => {
      source.postMessage({
        type: 'bordeaux-ad-tool-response',
        apiKey: adToolCommunicationKey,
        requestId,
        return: parse(event as E),
      });
    };
  };
  env.addEventListener('message', async event => {
    if (!event.data) {
      return;
    }
    if (!event.source) {
      return;
    }
    if (typeof event.data !== 'object') {
      return;
    }
    if (event.data.type !== 'bordeaux-ad-tool') {
      return;
    }
    if (event.data.apiKey !== adToolCommunicationKey) {
      return;
    }
    if (!('requestId' in event.data)) {
      return;
    }
    const adToolEvent = event.data as AdToolPostActionRequest;
    const source = event.source;
    switch (adToolEvent.action) {
      case 'getContext':
        waitForResponse<ProvideBordeauxContextEvent, CleanBordeauxMachineContext>(
          source,
          adToolEvent.requestId,
          event => {
            const cleanContext = Object.fromEntries(
              Object.entries(event.data.value).filter(
                ([key]) => !ignoredContextProperties.includes(key),
              ),
            );
            const transform = {
              ...cleanContext,
              slots: event.data.value.slots.getValues().map(dataObject => {
                const {
                  element: _element,
                  hookElement: _hookElement,
                  ...slot
                } = dataObject.getProperties();
                return slot;
              }),
              ads: event.data.value.ads.getValues().map(dataObject => {
                const { elements: _elements, gptOutput, ...ad } = dataObject.getProperties();
                return {
                  ...ad,
                  ...(gptOutput
                    ? {
                        gptOutput: {
                          ...gptOutput,
                          ...(gptOutput.gptSlot
                            ? {
                                gptSlot: {
                                  ...gptOutput.gptSlot,
                                  adUnitPath: gptOutput.gptSlot.getAdUnitPath(),
                                  attributeKeys: gptOutput.gptSlot.getAttributeKeys(),
                                  categoryExclusions: gptOutput.gptSlot.getCategoryExclusions(),
                                  responseInformation: gptOutput.gptSlot.getResponseInformation(),
                                  slotElementId: gptOutput.gptSlot.getSlotElementId(),
                                  targetingKeys: gptOutput.gptSlot.getTargetingKeys(),

                                  // clickUrl: gptOutput.gptSlot.getClickUrl(),
                                  // collapseEmptyDiv: gptOutput.gptSlot.getCollapseEmptyDiv(),
                                  // contentUrl: gptOutput.gptSlot.getContentUrl(),
                                  // divStartsCollapsed: gptOutput.gptSlot.getDivStartsCollapsed(),
                                  // escapedQemQueryId: gptOutput.gptSlot.getEscapedQemQueryId(),
                                  // firstLook: gptOutput.gptSlot.getFirstLook(),
                                  // html: gptOutput.gptSlot.getHtml(),
                                  // name: gptOutput.gptSlot.getName(),
                                  // outOfPage: gptOutput.gptSlot.getOutOfPage(),
                                  // services: gptOutput.gptSlot.getServices(),
                                  // sizes: gptOutput.gptSlot.getSizes(),
                                  // slotId: gptOutput.gptSlot.getSlotId(),
                                  // targeting: gptOutput.gptSlot.getTargeting(),
                                  targetingMap: (gptOutput.gptSlot as any).getTargetingMap(),
                                },
                              }
                            : {}),
                        },
                      }
                    : {}),
                };
              }),
            };
            const clone = deepClone(transform);
            return clone;
          },
        );
        sendBack({
          type: ON.toolRequestBordeauxContext,
          data: { requestId: adToolEvent.requestId },
        } as ToolRequestBordeauxContextEvent);
        return;
      case 'setToolPage':
        sendBack({
          type: ON.set_adToolPage,
          data: { page: adToolEvent.args[0] },
        } as SetAdToolPageEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'setPrebidAnalyticsEnabled': {
        sendBack({
          type: API_EMIT.SET_PREBID_ANALYTICS_ENABLED,
          data: true,
        } as SetPrebidAnalyticsEnabledApiEvent);
        respond(source, adToolEvent.requestId);
        return;
      }
      case 'setPrebidAnalyticsDisabled':
        sendBack({
          type: API_EMIT.SET_PREBID_ANALYTICS_ENABLED,
          data: false,
        } as SetPrebidAnalyticsEnabledApiEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'toggleAdsRefresh':
        sendBack({
          type: API_EMIT.TOGGLE_REFRESH_PAUSED,
        } as ToggleRefreshPausedApiEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'unDockTool':
        sendBack({
          type: API_EMIT.UN_DOCK_AD_TOOL,
        } as UnDockAdToolEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'dockTool':
        sendBack({
          type: API_EMIT.DOCK_AD_TOOL,
        } as DockAdToolEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'setToolPosition':
        sendBack({
          type: Tool_EMIT.setPosition,
          data: adToolEvent.args[0],
        } as ToolSetPositionEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'setToolMinimized':
        sendBack({
          type: Tool_EMIT.setMinimised,
          data: adToolEvent.args[0],
        } as ToolSetMinimisedEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'scrollToElement':
        sendBack({
          type: Tool_EMIT.scrollToElement,
          data: { selector: adToolEvent.args[0] },
        } as ToolScrollToElementEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'scrollToSlot':
        sendBack({
          type: Tool_EMIT.scrollToSlot,
          data: { slot: adToolEvent.args[0] },
        } as ToolScrollToSlotEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'updatePrebidAnalytics':
        sendBack({
          type: API_EMIT.UPDATE_PREBID_ANALYTICS_DATA,
        } as UpdatePrebidAnalyticsApiEvent);
        respond(source, adToolEvent.requestId);
        return;

      case 'resetConsentCookies': {
        const consentCookies = ['euconsent-v2', 'cmp'];
        const cookieEntries = env.document.cookie.split('; ');
        cookieEntries.forEach((cookie: string): void => {
          const dominion = env.location.hostname.split('.');
          while (dominion.length > 0) {
            const domain = dominion.join('.');
            const [cookieName] = cookie.split(';')[0].split('=');
            if (consentCookies.includes(cookieName)) {
              const cookieBase = `${encodeURIComponent(
                cookieName,
              )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${domain} ;path=`;
              const path = env.location.pathname.split('/');
              env.document.cookie = `${cookieBase}/`;
              while (path.length > 0) {
                env.document.cookie = cookieBase + path.join('/');
                path.pop();
              }
            }
            dominion.shift();
          }
        });
        respond(source, adToolEvent.requestId);
        return;
      }
      case 'openConsentModal':
        (env as any).__tcfapi('displayConsentUi', 2, () => {
          // Do nothing once the modal is open
        });
        respond(source, adToolEvent.requestId);
        return;
      case 'copyAdvert': {
        const [elementId, adUnitPath] = adToolEvent.args;
        const iframe = env.document.querySelector(
          `#${elementId} iframe[id*='${adUnitPath}']`,
        ) as HTMLIFrameElement;
        if (!iframe) {
          respond(source, adToolEvent.requestId);
          return Promise.reject(
            Error(
              `IFrame with id '${elementId}' and adUnit path '${adUnitPath}' could not be found.`,
            ),
          );
        }
        const frameDocument = iframe.contentWindow?.document || iframe.contentDocument;
        if (!frameDocument) {
          respond(source, adToolEvent.requestId);
          return Promise.reject(
            Error(
              `IFrame document is not publically accessible. Element ID: '${elementId}'. AdUnit Path: '${adUnitPath}'.`,
            ),
          );
        }
        const iframesrc = new XMLSerializer().serializeToString(frameDocument);
        await navigator.clipboard.writeText(iframesrc);
        respond(source, adToolEvent.requestId);
        return;
      }
      case 'addAdHighlights':
        sendBack({
          type: API_EMIT.ADD_AD_HIGHLIGHTS,
          data: { names: adToolEvent.args[0] },
        } as AddAdHighightsEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'addSlotHighlights':
        sendBack({
          type: API_EMIT.ADD_SLOT_HIGHLIGHTS,
          data: { names: adToolEvent.args[0] },
        } as AddSlotHighightsEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'removeAdHighlights':
        sendBack({
          type: API_EMIT.REMOVE_AD_HIGHLIGHTS,
          data: { names: adToolEvent.args[0] },
        } as RemoveAdHighightsEvent);
        respond(source, adToolEvent.requestId);
        return;
      case 'removeSlotHighlights':
        sendBack({
          type: API_EMIT.REMOVE_SLOT_HIGHLIGHTS,
          data: { names: adToolEvent.args[0] },
        } as RemoveSlotHighightsEvent);
        respond(source, adToolEvent.requestId);
        return;

      case 'getWindowInfo':
        respond(source, adToolEvent.requestId, {
          hostname: env.location.hostname,
          href: env.location.href,
          userAgent: env.navigator.userAgent,
          width: env.innerWidth,
        });
        return;
      case 'getLogs':
        respond(source, adToolEvent.requestId, (env as unknown as any).bordeaux.log.logs);
        return;
      case 'getTargeting':
        waitForResponse<ToolGetTargetingResponseEvent, Targeting>(
          source,
          adToolEvent.requestId,
          ({ data: { return: returnValue } }) => returnValue,
        );
        sendBack({
          type: Tool_EMIT.getTargeting,
          data: { requestId: adToolEvent.requestId },
        } as ToolGetTargetingEvent);
        return;
      case 'getExtraTargeting':
        respond(
          source,
          adToolEvent.requestId,
          (env as any).googletag && (env as any).googletag.pubads
            ? (env as any).googletag
                .pubads()
                .getTargetingKeys()
                .reduce(
                  (acc, key) =>
                    (env as any).googletag
                      ? { ...acc, [key]: (env as any).googletag.pubads().getTargeting(key) }
                      : {},
                  {},
                )
            : {},
        );
        return;
      case 'getCookies':
        respond(source, adToolEvent.requestId, cookies.getAll());
        return;
      case 'getMarkMetrics':
        respond(
          source,
          adToolEvent.requestId,
          (env as unknown as any).bordeaux.metrics
            .getMarks()
            .map(({ object, ...rest }) => ({ ...rest, object: object.toJSON() })),
        );
        return;
      case 'getResourceMetrics':
        respond(
          source,
          adToolEvent.requestId,
          (env as unknown as any).bordeaux.metrics
            .getResources()
            .map(({ object, ...rest }) => ({ ...rest, object: object.toJSON() })),
        );
        return;
      default:
        log.error('Unhandled bordeaux ad-tool event', adToolEvent);
        return;
    }
  });
});

export default toolAPIMachine;
