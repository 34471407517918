import { SommelierResponseSlot, SlotPosition, SlotType } from '@repo/shared-types';
import { MPU_WIDTH, DOUBLE_MPU_HEIGHT } from '../../constants';

export default {
  name: `mpu-image`,
  hook: '#article-body > [data-bordeaux-image-check]',
  type: SlotType.BLOCK,
  position: SlotPosition.BEFORE,
  size: {
    w: MPU_WIDTH,
    h: DOUBLE_MPU_HEIGHT,
  },
  properties: {
    style: {
      margin: '5px -325px 10px 25px',
      position: 'absolute',
      right: '0',
    },
    config: {
      multiple: true,
    },
  },
} as SommelierResponseSlot;
