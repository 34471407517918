import { Labels } from '@future/promjs/lib';
import { Counter } from '@future/promjs/lib/counter';
import { Registry } from '@future/promjs/lib/registry';

import { PrometheusController } from '../PrometheusController';
import { labels } from '../labels';
import { pushMetrics } from '../pushMetrics';

export const handleUnload = (counter: Counter, registry: Registry) => (): void => {
  if (labels.site) {
    if (!PrometheusController.getProperty('configLoaded')) {
      const configLabels: Labels = { target: 'config', ...labels };
      PrometheusController.update({ metricsUpdated: true });
      counter.inc(configLabels);
    } else if (!PrometheusController.getProperty('thirdPartiesLoaded')) {
      const thirdPartyLabels: Labels = { target: 'thirdparties', ...labels };
      PrometheusController.update({ metricsUpdated: true });
      counter.inc(thirdPartyLabels);
    } else if (!PrometheusController.getProperty('adsLoaded')) {
      const adsLabels: Labels = { target: 'ads', ...labels };
      PrometheusController.update({ metricsUpdated: true });
      counter.inc(adsLabels);
    }

    pushMetrics(registry)();
  }
};
