import { ThirdPartyAPIMachineConfigs, ThirdParty } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.TMT], 'thirdParty'> = {
  thirdParty: ThirdParty.TMT,
  methods: {
    getScriptLocation: ({
      data: {
        config: { configurationId },
      },
    }) => `https://scripts.webcontentassessor.com/scripts/${configurationId}`,
    loadScript: (scriptLocation): Promise<void> =>
      loadScript(scriptLocation, 'bordeaux-tmt', { async: true }),
  },
};
export default config;
