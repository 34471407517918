import { CloseButtonAdFeatureEvent, AdUnitMode, ActionArgs } from '@repo/shared-types';
import { fastdom, createCeltraCloseButton, log } from '@repo/utils';

const closeButton = ({ context }: ActionArgs, data: CloseButtonAdFeatureEvent['data']): void => {
  setTimeout(() => {
    const anchoredAd = context.ads
      .getValues()
      .find(ad => ad.getProperty('mode') === AdUnitMode.ANCHORED);
    if (!anchoredAd) {
      log.info(`Could not add close button to anchored ad, anchored ad does not exist.`);
      return;
    }

    if (anchoredAd.getProperty('closeButton')) {
      log.info('Could not add close button to anchored ad, anchored ad already has close button');
      return;
    }

    if (Array.from(data.container.querySelectorAll('.bordeaux-anchored-close')).length) {
      log.info('Could not add close button as it was already added');
      return;
    }

    fastdom.mutate(() => {
      const closeButton = createCeltraCloseButton(anchoredAd.getProperty('width'));
      data.container.appendChild(closeButton);
    });
  }, data.delay);
};

export default closeButton;
