import { SpecialAdUnitSizes, Ad, AdElementGroup, DataObjectType } from '@repo/shared-types';
import { createElement } from '@repo/utils';

const createSlotifyMarkup = (ad: DataObjectType<Ad>): AdElementGroup => {
  const element = createElement('div', {
    id: ad.getProperty('id'),
    style: {
      position: 'sticky',
      top: '0',
      margin: '0 auto',
      ...(ad.getProperty('verticallyCentered')
        ? {
            margin: 'auto auto',
          }
        : {}),
      ...(ad.getProperty('topPosition') > 0
        ? {
            top: `${ad.getProperty('topPosition')}px`,
          }
        : {}),
      ...(ad.getProperty('sizes').includes(SpecialAdUnitSizes.FLUID_SIZE)
        ? {
            width: '100%',
            height: '100%',
          }
        : {}),
    },
  });

  return {
    element,
  };
};

export default createSlotifyMarkup;
