import {
  Ad,
  AdUnitMode,
  DataObject,
  REPORT_AUCTION,
  Targeting,
  AdManager_EMIT,
  AdManager_DO,
  AdManager_SPAWN,
  AdManagerMachineContext,
  AdManagerActions,
  AdManager_TO,
} from '@repo/shared-types';
import { ExtendedGoogletag, getEnv, log, replaceAmpersand, sentry, timeData } from '@repo/utils';
import { sendParent } from 'xstate';
import metrics from 'metrics/index';
import assign, { assignParams } from './proxy/assign';

const defineGPTSlot = (
  { googletag, auctionId }: AdManagerMachineContext,
  ad: DataObject<Ad>,
): googletag.Slot | null => {
  const id = ad.getProperty('id');
  const targeting = ad.getProperty('targeting');
  const mode = ad.getProperty('mode');
  const name = ad.getProperty('name');
  const adUnitPath = ad.getProperty('adUnitPath');
  const isOutOfPage = mode === AdUnitMode.OOP;
  const isInterstitial = mode === AdUnitMode.INTERSTITIAL;
  const sizes = isOutOfPage ? [[1, 1]] : ad.getProperty('sizes');

  let gptSlot: googletag.Slot | null = null;
  try {
    if (isOutOfPage) {
      gptSlot = googletag.defineOutOfPageSlot(adUnitPath, id);
    } else if (isInterstitial) {
      gptSlot = googletag.defineOutOfPageSlot(
        adUnitPath,
        (googletag as ExtendedGoogletag).enums.OutOfPageFormat.INTERSTITIAL,
      );
    } else {
      if (sizes === undefined) throw new Error('Error defining GPT slot, sizes are undefined.');
      gptSlot = googletag.defineSlot(adUnitPath, sizes, id);
    }
  } catch (error) {
    if (error instanceof Error) log.error(`Error defining GPT slot. ${error.toString()}`);
    return null;
  }

  if (gptSlot === null) {
    log.error(`Unable to define GPT slot. advert: ${name}, adUnitPath: ${adUnitPath}`);
    return null;
  }

  Object.keys(targeting).forEach(key => {
    if (gptSlot !== null) {
      gptSlot.setTargeting(key, targeting[key]);
    }
  });
  gptSlot.addService(googletag.pubads());

  if (!isInterstitial) {
    if (getEnv().onDvtagReady) {
      getEnv().onDvtagReady?.(() => {
        googletag.display(id);
      });
    } else googletag.display(id);
  }

  gptSlot.setTargeting('auctionId', auctionId.toString());

  return gptSlot;
};

const adManagerActions: AdManagerActions = {
  [AdManager_DO.getGoogletag]: assign({
    googletag: () => {
      const env = getEnv();
      const { googletag } = env;
      return googletag!;
    },
  }),
  [AdManager_DO.enable]: ({ context: { googletag } }) => {
    googletag.enableServices();
  },
  [AdManager_DO.configure]: ({ context: { googletag, privacySettings, publisherProvidedId } }) => {
    googletag.pubads().enableAsyncRendering();
    googletag.pubads().collapseEmptyDivs(true);
    googletag.pubads().enableSingleRequest();
    googletag.pubads().disableInitialLoad();
    googletag.pubads().setCentering(true);

    googletag.setAdIframeTitle('Advertisement');

    if (privacySettings) googletag.pubads().setPrivacySettings(privacySettings);

    if (publisherProvidedId) googletag.pubads().setPublisherProvidedId(publisherProvidedId);
  },
  [AdManager_DO.listen]: assign({
    [AdManager_TO.googleTagListener]: ({ context: { googletag }, spawn }) =>
      spawn(AdManager_SPAWN.googleTagListener, {
        input: { googletag },
      }),
  }),
  [AdManager_DO.destroyAds]: (
    { context: { googletag, gptSlots } },
    { advertIds }: { advertIds: Array<string> },
  ) => {
    advertIds.forEach(advertId => {
      const slot = gptSlots[advertId];
      if (slot === undefined) {
        return;
      }

      // This should return true/false but the Index wrapped version returns undefined
      // Once this is fixed we can check if the destroy succeeded
      googletag.destroySlots([slot]);
    });
  },

  [AdManager_DO.createAd]: assignParams<{ ad: DataObject<Ad> }>({
    gptSlots: ({ context }, { ad }: { ad: DataObject<Ad> }) => {
      const adID = ad.getProperty('id');
      const gptSlot = defineGPTSlot(context, ad);
      if (!gptSlot) return context.gptSlots;
      return {
        ...context.gptSlots,
        [adID]: gptSlot,
      };
    },
  }),
  [AdManager_DO.addAuctionToStack]: assignParams<{ ads: Array<DataObject<Ad>> }>({
    auctionStack: ({ context: { auctionStack } }, { ads }: { ads: Array<DataObject<Ad>> }) => [
      ...auctionStack,
      ads,
    ],
  }),
  [AdManager_DO.setTargeting]: ({ context: { googletag } }, targeting: Targeting) => {
    Object.entries(replaceAmpersand(targeting)).forEach(([key, value]) => {
      if (typeof value === 'string' || Array.isArray(value)) {
        googletag.pubads().setTargeting(key, value);
      }
    });
  },
  [AdManager_DO.refreshAuction]: (
    { context: { gptSlots, auctions, googletag } },
    auctionId: number,
  ) => {
    const { ads } = auctions[auctionId];
    const refreshSlots = ads
      .map(ad => {
        const adID = ad.getProperty('id');
        return gptSlots[adID];
      })
      .filter((value): value is googletag.Slot => value !== undefined);
    if (getEnv().onDvtagReady) {
      getEnv().onDvtagReady?.(() => {
        googletag.pubads().refresh(refreshSlots, { changeCorrelator: false });
      });
    } else googletag.pubads().refresh(refreshSlots, { changeCorrelator: false });
  },
  [AdManager_DO.markRefreshStart]: (_, { ads }: { ads: Array<DataObject<Ad>> }) => {
    const adNames = ads.map(ad => ad.getProperty('name'));
    sentry.breadcrumb({
      category: 'script',
      message: `GAM API refresh - ${adNames}`,
    });
    metrics.mark(`GAM API refresh - ${adNames}`);
  },
  [AdManager_DO.markAuctionDone]: assignParams<number>({
    auctions: ({ context: { auctions } }, auctionId: number) => ({
      ...auctions,
      [auctionId]: {
        ...auctions[auctionId],
        ended: true,
      },
    }),
  }),
  [AdManager_DO.logError]: ({ context: { error } }) => {
    log.error(error || 'Error occurred in ad manager.');
    metrics.recordAdManagerError();
  },
  [AdManager_DO.incrementAuctionId]: assign({
    auctionId: ({ context: { auctionId } }) => auctionId + 1,
  }),
  [AdManager_DO.createNextAuction]: assign({
    auctions: ({
      context: {
        auctions,
        auctionId,
        auctionStack: [ads],
      },
    }) => ({
      ...auctions,
      [auctionId]: {
        id: auctionId,
        ads,
        loadedAds: {},
        ended: false,
      },
    }),
  }),
  [AdManager_DO.reportAuctionStart]: sendParent(
    ({
      context: {
        auctionId,
        auctionStack: [ads],
      },
    }) => ({
      type: REPORT_AUCTION.START,
      data: {
        time: timeData(),
        auction: auctionId,
        adNames: ads.map(ad => ad.getProperty('name')),
      },
    }),
  ),
  [AdManager_DO.markAdsInAuction]: ({
    context: {
      auctionId,
      auctionStack: [ads],
    },
  }) => {
    ads.forEach(ad => {
      ad.update({
        auctionId,
        fetchTime: getEnv().performance.now(),
      });
    });
  },
  [AdManager_DO.reportAuctionCreated]: sendParent(
    ({
      context: {
        auctionId,
        auctionStack: [ads],
      },
    }) => ({
      type: AdManager_EMIT.auctionCreated,
      data: {
        auctionId,
        ads,
      },
    }),
  ),
  [AdManager_DO.popAuctionStack]: assign({
    auctionStack: ({ context: { auctionStack } }) => auctionStack.slice(1),
  }),
};
export default adManagerActions;
