import { Registry } from '@future/promjs/lib/registry';
import { Counter } from '@future/promjs/lib/counter';
import { Histogram } from '@future/promjs/lib/histogram';

interface Metrics {
  metricAdManagerErrorCount: Counter;
  metricAdsCount: Counter;
  metricGamApiFetchErrorCount: Counter;
  metricInputErrorBordeauxApiCount: Counter;
  metricLoadCount: Counter;
  metricLoadGptSlotErrorCount: Counter;
  metricLongTasksHistogram: Histogram;
  metricRequestCount: Counter;
  metricSlotInsertionErrorCount: Counter;
  metricUnloadCount: Counter;
}

export const setupMetrics = (registry: Registry): Metrics => ({
  metricAdManagerErrorCount: registry.create(
    'counter',
    'adManagerErrorCount',
    'Ad Manager Error counter',
  ),
  metricAdsCount: registry.create('counter', 'ads', 'Ad counter'),
  metricGamApiFetchErrorCount: registry.create(
    'counter',
    'gamApiFetchErrorCount',
    'GAM API Fetch Error counter',
  ),
  metricInputErrorBordeauxApiCount: registry.create(
    'counter',
    'inputErrorBordeauxApiCount',
    'Input error when using bordeaux API counter',
  ),
  metricLoadCount: registry.create('counter', 'load', 'Load counter'),
  metricLoadGptSlotErrorCount: registry.create(
    'counter',
    'loadGptSlotErrorCount',
    'Load GPT slot error count - auctionId undefined',
  ),
  metricLongTasksHistogram: registry.create(
    'histogram',
    'longTasks',
    'Long tasks histogram',
    [50, 100, 500, 2000, 5000],
  ),
  metricRequestCount: registry.create('counter', 'request', 'Request counter'),
  metricSlotInsertionErrorCount: registry.create(
    'counter',
    'slotInsertionErrorCount',
    'Slot Insertion Logic Error Counter',
  ),
  metricUnloadCount: registry.create('counter', 'unload', 'Unload counter'),
});
