import handleGeneratedSlot from 'ad-framework/slot/generated-slots';
import handleDynamicSlot from 'ad-framework/slot/dynamic-slots';
import createSlotDataObject from 'ad-framework/slot';
import {
  DynamicSlotDefinition,
  GeneratedSlotDefinition,
  SlotPosition,
  SlotType,
  Slotify_ON,
  GeneratedSlotsDoneEvent,
  SlotHookedEvent,
} from '@repo/shared-types';
import { EventObject, fromCallback } from 'xstate';
import selectSlotHooks from '../selection';

const dynamicSlotGenerator = fromCallback<
  EventObject,
  {
    generatedSlotDefinitions: Array<GeneratedSlotDefinition>;
    dynamicSlotDefinitions: Array<DynamicSlotDefinition>;
    automaticDynamic: boolean;
  }
>(
  ({
    input: { generatedSlotDefinitions, dynamicSlotDefinitions, automaticDynamic },
    sendBack,
    receive,
  }) => {
    Promise.all(
      generatedSlotDefinitions.map(async (slotDefinition, configIndex) => {
        const hooks = selectSlotHooks(slotDefinition);
        const spacerElements = await handleGeneratedSlot(slotDefinition, hooks);
        const id = `bordeaux-slot_generated_${slotDefinition.name}-slot_${configIndex}`;
        spacerElements.forEach((hookElement, hookIndex) => {
          sendBack({
            type: Slotify_ON.slotCreated,
            data: createSlotDataObject(slotDefinition, {
              id: `${id}-hook_${hookIndex}`,
              name: `${slotDefinition.name}-${hookIndex}`,
              hookElement,
              type: SlotType.BLOCK,
              position: SlotPosition.APPEND,
            }),
          } as SlotHookedEvent);
        });
      }),
    ).then(() => {
      sendBack({
        type: Slotify_ON.generatedSlotsDone,
      } as GeneratedSlotsDoneEvent);
    });

    const createDynamicSlots = (initialIndex = 0) => {
      dynamicSlotDefinitions.forEach(async (slotDefinition, configIndex) => {
        const id = `bordeaux-slot-${slotDefinition.name}_dynamic_${configIndex + initialIndex}`;
        let internalIndex = 0;
        handleDynamicSlot(slotDefinition, automaticDynamic, slotHookElements => {
          slotHookElements.forEach((hookElement, index) => {
            sendBack({
              type: Slotify_ON.slotCreated,
              data: createSlotDataObject(slotDefinition, {
                id: `${id}-round_${internalIndex}-hook_${index}`,
                name: `${slotDefinition.name}-${internalIndex + index}`,
                hookElement,
                ...(slotDefinition.type === SlotType.GENERATED
                  ? {
                      type: SlotType.BLOCK,
                      position: SlotPosition.APPEND,
                    }
                  : {}),
              }),
            });
          });
          internalIndex += slotHookElements.length;
        });
      });
    };
    createDynamicSlots(0);

    let rounds = 0;
    receive(event => {
      if (event.type !== 'refresh') return;
      rounds += 1;
      createDynamicSlots(dynamicSlotDefinitions.length * rounds);
    });
  },
);

export default dynamicSlotGenerator;
