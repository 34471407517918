import { SlotifyMachineContext, AnySlotifyEvent } from '@repo/shared-types';
import { assign as genericAssign, NonReducibleUnknown } from 'xstate';

const assign = <R extends AnySlotifyEvent>(
  ...args: Parameters<
    typeof genericAssign<SlotifyMachineContext, R, NonReducibleUnknown, AnySlotifyEvent, any>
  >
) => genericAssign<SlotifyMachineContext, R, NonReducibleUnknown, AnySlotifyEvent, any>(...args);

export default assign;

export const assignParams = <P = NonReducibleUnknown>(
  ...args: Parameters<
    typeof genericAssign<SlotifyMachineContext, AnySlotifyEvent, P, AnySlotifyEvent, any>
  >
) => genericAssign<SlotifyMachineContext, AnySlotifyEvent, P, AnySlotifyEvent, any>(...args);
