import { sendABTestToFreyr, pushToGoogleAnalytics } from '@repo/utils';

import { ActionArgs } from '@repo/shared-types';

const handleHybridAbTestsIfAny = ({ context }: ActionArgs): void => {
  pushToGoogleAnalytics(context.config.placement);

  const { placement } = context.config;
  if (placement?.abTest) {
    placement.abTest.forEach(gaBean => {
      sendABTestToFreyr(gaBean);
    });
  }
};

export default handleHybridAbTestsIfAny;
