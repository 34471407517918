import { BordeauxMachineContext, AnyBordeauxEvent } from '@repo/shared-types';
import { ParameterizedObject, raise as genericRaise } from 'xstate';

const raiseParams = <
  P extends ParameterizedObject['params'] | undefined = ParameterizedObject['params'] | undefined,
  E extends AnyBordeauxEvent = AnyBordeauxEvent,
  S extends AnyBordeauxEvent = AnyBordeauxEvent,
>(
  ...args: Parameters<typeof genericRaise<BordeauxMachineContext, E, S, P>>
) => genericRaise<BordeauxMachineContext, E, S, P>(...args);

export default raiseParams;
