import {
  AdManager_IF as IF,
  AdManager_GO as GO,
  AdManager_DO as DO,
  AdManager_TO as TO,
  AdManager_SPAWN as SPAWN,
  AdManager_RECIEVE as RECIEVE,
  AdManager_EMIT as EMIT,
  AdManagerMachineContext,
  AdManagerMachineDefinition,
  AdManagerMachineInput,
  GoogletagListenerActor,
  AnyAdManagerEvent,
  Googletag_ON,
  SlotImpressionViewableEvent,
  SlotOnLoadEvent,
  SlotRenderEndedEvent,
  SlotVisibilityChangedEvent,
  AuctionLoadedEvent,
} from '@repo/shared-types';
import { log } from '@repo/utils';
import { assign, enqueueActions, sendParent, setup, stateIn } from 'xstate';
import adManagerActions from './actions';
import adManagerGuards from './guards';
import adManagerActors from './actors';

const adManagerMachine: AdManagerMachineDefinition = setup({
  types: {
    context: {} as AdManagerMachineContext,
    events: {} as AnyAdManagerEvent,
    input: {} as AdManagerMachineInput,
  },
  actors: {
    ...adManagerActors,
  },
  actions: {
    ...adManagerActions,
  },
  guards: {
    ...adManagerGuards,
  },
}).createMachine({
  context: ({ input }) => ({
    googletag: {} as googletag.Googletag,
    [TO.googleTagListener]: {} as GoogletagListenerActor,
    gptSlots: {},
    auctionId: 0,
    auctionStack: [],
    auctions: {},
    error: null,
    ...input,
  }),
  initial: GO.checkRequirements,
  states: {
    [GO.checkRequirements]: {
      invoke: {
        id: TO.waitForGoogleTag,
        src: SPAWN.waitForGoogleTag,
        onDone: [
          {
            guard: IF.googletagExists,
            actions: DO.getGoogletag,
            target: GO.configuration,
          },
          {
            actions: assign({ error: 'googletag is unavailable, unable to initialise.' }),
            target: GO.error,
          },
        ],
      },
    },
    [GO.configuration]: {
      always: {
        actions: [DO.configure, DO.listen, DO.enable],
        target: GO.waitForAuction,
      },
    },
    [GO.waitForAuction]: {
      always: {
        guard: IF.auctionsInStack,
        target: GO.auction,
      },
    },
    [GO.auction]: {
      always: {
        actions: [
          DO.incrementAuctionId,
          DO.createNextAuction,
          DO.reportAuctionStart,
          DO.markAdsInAuction,
          enqueueActions(
            ({
              enqueue,
              context: {
                auctionStack: [ads],
              },
            }) => {
              ads.forEach(ad => {
                enqueue({
                  type: DO.createAd,
                  params: { ad },
                });
              });
            },
          ),
          DO.reportAuctionCreated,
          DO.popAuctionStack,
        ],
        target: GO.waitForAuction,
      },
    },
    [GO.error]: {
      entry: DO.logError,
    },
  },
  on: {
    [RECIEVE.auctionProcessed]: {
      actions: [
        {
          type: DO.refreshAuction,
          params: ({
            event: {
              data: { auctionId },
            },
          }) => auctionId,
        },
        {
          type: DO.markAuctionDone,
          params: ({
            event: {
              data: { auctionId },
            },
          }) => auctionId,
        },
      ],
    },
    [RECIEVE.setTargeting]: {
      actions: {
        type: DO.setTargeting,
        params: ({
          event: {
            data: { targeting },
          },
        }) => targeting,
      },
    },
    [RECIEVE.auction]: [
      {
        guard: stateIn(GO.waitForAuction),
        actions: {
          type: DO.addAuctionToStack,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
        target: `.${GO.auction}`,
      },
      {
        actions: {
          type: DO.addAuctionToStack,
          params: ({
            event: {
              data: { ads },
            },
          }) => ({ ads }),
        },
      },
    ],
    [RECIEVE.refresh]: [
      {
        guard: {
          type: IF.hasAds,
          params: ({
            event: {
              data: { ads },
            },
          }) => ads,
        },
        actions: [
          {
            type: DO.markRefreshStart,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ ads }),
          },
          {
            type: DO.destroyAds,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ advertIds: ads.map(ad => ad.getProperty('id')) }),
          },
          {
            type: DO.addAuctionToStack,
            params: ({
              event: {
                data: { ads },
              },
            }) => ({ ads }),
          },
        ],
      },
      {
        actions: () => {
          log.warn('Called GAM API refresh with no ads.');
        },
      },
    ],
    [RECIEVE.destroyAds]: {
      actions: [
        {
          type: DO.destroyAds,
          params: ({
            event: {
              data: { advertIds },
            },
          }) => ({ advertIds }),
        },
        ({ context: { googletag } }) => {
          googletag.pubads().updateCorrelator();
        },
      ],
    },
    [Googletag_ON.visibilityChanged]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: EMIT.adVisibilityChanged,
            data,
          }) as SlotVisibilityChangedEvent,
      ),
    },
    [Googletag_ON.renderEnded]: [
      {
        actions: [
          sendParent(
            ({ event: { data } }) =>
              ({
                type: EMIT.adRenderEnded,
                data,
              }) as SlotRenderEndedEvent,
          ),
          assign({
            auctions: ({ event: { data }, context: { auctions } }) => {
              const { adId, gptOutput } = data;
              const auction = Object.values(auctions).find(({ ads }) =>
                ads.some(ad => ad.getProperty('id') === adId),
              );
              if (!auction) return auctions;

              return {
                ...auctions,
                [auction.id]: {
                  ...auction,
                  loadedAds: {
                    ...auction.loadedAds,
                    [adId]: gptOutput,
                  },
                },
              };
            },
          }),
          enqueueActions(({ enqueue, event: { data }, context: { auctions } }) => {
            const { adId } = data;
            const auction = Object.values(auctions).find(({ ads }) =>
              ads.some(ad => ad.getProperty('id') === adId),
            );
            if (!auction) return;

            const { id, ads, loadedAds } = auction;
            if (ads.length === Object.keys(loadedAds).length) {
              enqueue.sendParent({
                type: EMIT.auctionLoaded,
                data: { auctionId: id },
              } as AuctionLoadedEvent);
            }
          }),
        ],
      },
    ],
    [Googletag_ON.onLoad]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: EMIT.adOnLoad,
            data,
          }) as SlotOnLoadEvent,
      ),
    },
    [Googletag_ON.impressionViewable]: {
      actions: sendParent(
        ({ event: { data } }) =>
          ({
            type: EMIT.adImpressionViewable,
            data,
          }) as SlotImpressionViewableEvent,
      ),
    },
  },
});

export default adManagerMachine;
