import {
  detect,
  BrowserInfo,
  BotInfo,
  NodeInfo,
  SearchBotDeviceInfo,
  ReactNativeInfo,
} from 'detect-browser/index.js';
import { BrowserOptions } from '@repo/shared-types';

const unknown: BrowserOptions = BrowserOptions.OTHER;
const browserMap: { [key: string]: true | BrowserOptions } = {
  ie: true,
  edge: true,
  safari: true,
  chrome: true,
  firefox: true,

  'edge-chromium': BrowserOptions.CHROME,

  crios: BrowserOptions.CHROME,
  'chromium-webview': BrowserOptions.CHROME,

  fxios: BrowserOptions.FIREFOX,

  ios: BrowserOptions.SAFARI,
  'ios-webview': BrowserOptions.SAFARI,
};

export default (): BrowserOptions => {
  const browser: BrowserInfo | BotInfo | NodeInfo | SearchBotDeviceInfo | ReactNativeInfo | null =
    detect();
  let browserName: BrowserOptions = unknown;
  if (browser !== null) {
    const detectedName: string = browser.name;
    if (detectedName in browserMap) {
      const mappedBrowserName: true | BrowserOptions = browserMap[detectedName];
      if (mappedBrowserName === true) {
        browserName = detectedName as BrowserOptions;
      } else {
        browserName = mappedBrowserName;
      }
    }
  }
  return browserName;
};

export const detectBrowserVersion = (): string => {
  const browser: BrowserInfo | BotInfo | NodeInfo | SearchBotDeviceInfo | ReactNativeInfo | null =
    detect();
  return browser !== null ? (browser.version as unknown as string) : 'unknown';
};

// All possible output values of detect-browser
/* [
  'ie',
  'edge',
  'edge-chromium',

  'chrome',
  'crios',
  'chromium-webview',

  'firefox',
  'fxios',

  'opera-mini',
  'opera',

  'ios',
  'ios-webview',

  'safari',

  'android',

  'searchbot',
  'yandexbrowser',
  'aol',
  'vivaldi',
  'kakaotalk',
  'samsung',
  'silk',
  'miui',
  'beaker',
  'facebook',
  'instagram',
  'bb10',
  'phantomjs',
]*/
