import { getEnv } from '@repo/utils';
import { CftParameters } from '@repo/shared-types';

const env = getEnv();

const getCtfParameters = async (): Promise<CftParameters> => {
  const labelName =
    'cookieDeprecationLabel' in env.navigator
      ? ((await env.navigator.cookieDeprecationLabel?.getValue()) as string)
      : 'NOT DETECTED';
  return { labelName };
};
export default getCtfParameters;
