import getEnv from '../env'
import { getUuid, getScreenResolutionFormatted, referrer } from './utils';
import TimeoutError from '../error/timeout';
import { log } from '../log';
import { HybridBaseRequest } from '../index.types';
import { SiteConfig, ThirdParty, RAMPResponse, State } from '@repo/shared-types';

const HOST = 'https://ads.servebom.com';
const timeout = 5000;
const timeoutErrorMessage = `Took more than ${timeout}ms to resolve and timed out`;

const getDatetime = (): string => {
  const zeroPadding = (val: number): string => (val <= 9 ? `0${val}` : `${val}`);

  const date = new Date();
  const y = date.getFullYear();
  const m = zeroPadding(date.getMonth() + 1);
  const d = zeroPadding(date.getDate());
  const hh = zeroPadding(date.getHours());
  const mm = zeroPadding(date.getMinutes());
  const ss = zeroPadding(date.getSeconds());

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
};

export class RAMPRequest implements HybridBaseRequest {
  placement: number | null = null;

  refreshMode: boolean | null = null;

  location: string | null = null;

  pageTitle: string | null = null;

  personalizedAdsMode: 'allowed' | 'managed' | 'blocked' = 'allowed';

  gdprConsent: string | null = null;

  uspConsent: string | null = null;

  gppConsent: string | null = null;

  gppSID: Array<number> | null = null;

  targeting: string[][] = [];

  discovery: string | null = null;

  adunits: object[] = [];

  pageTemplate: string | null = null;

  segments: string | null = null;

  floorPrices: Record<string, number> | null = null;

  adserverBidders: Array<number> | undefined = undefined;

  hybridTestID: string[] | undefined = undefined;

  private config: SiteConfig[ThirdParty.PUBMATIC];

  constructor(config: SiteConfig[ThirdParty.PUBMATIC]) {
    this.config = config;
  }

  setPlacement(placement: number | null): RAMPRequest {
    this.placement = placement;
    return this;
  }

  setRefreshMode(refreshMode: boolean | null = false): RAMPRequest {
    this.refreshMode = refreshMode;
    return this;
  }

  setLocation(location: string | null): RAMPRequest {
    this.location = location;
    return this;
  }

  setPersonalizedAdsMode(personalizedAdsMode: 'allowed' | 'managed' | 'blocked'): RAMPRequest {
    if (['allowed', 'managed', 'blocked'].includes(personalizedAdsMode)) {
      this.personalizedAdsMode = personalizedAdsMode;
    }
    return this;
  }

  setGdprConsent(gdprConsent: string | null): RAMPRequest {
    this.gdprConsent = gdprConsent;
    return this;
  }

  setUSPConsent(consent: string | null): RAMPRequest {
    this.uspConsent = consent;
    return this;
  }

  setGPPConsent(gppConsent: string | null): RAMPRequest {
    this.gppConsent = gppConsent;
    return this;
  }

  setGPPSID(gppSID: Array<number> | null): RAMPRequest {
    this.gppSID = gppSID;
    return this;
  }

  setTargeting(targeting: string[][]): RAMPRequest {
    this.targeting = targeting;
    return this;
  }

  setAdunits(adunits: object[]): RAMPRequest {
    this.adunits = adunits;
    return this;
  }

  setPageTemplate(pageTemplate: string | null): RAMPRequest {
    this.pageTemplate = pageTemplate;
    return this;
  }

  setSegments(segments: string | null): RAMPRequest {
    this.segments = segments;
    return this;
  }

  setFloorPrices(floorPrices: Record<string, number> | null): RAMPRequest {
    this.floorPrices = floorPrices;
    return this;
  }

  setAdServerBidders(adserverBidders: Array<number> | undefined): RAMPRequest {
    this.adserverBidders = adserverBidders;
    return this;
  }

  setHybridTestID(hybridTestID: string[] | string | undefined): RAMPRequest {
    this.hybridTestID = typeof hybridTestID === 'string' ? [hybridTestID] : hybridTestID;
    return this;
  }

  async execute(): Promise<RAMPResponse> {
    const queryParams: Array<Array<string | number>> = [
      ['r', Math.round(Math.random() * 1000)],
      ['o', JSON.stringify(this.getState())],
      ['uuid', getUuid()],
    ];

    if (this.discovery != null) {
      queryParams.push(['discovery', this.discovery]);
    }

    const queryString: string = queryParams.map(pair => pair.join('=')).join('&');

    const url = `${HOST}/ramp?${queryString}`;
    return Promise.race<RAMPResponse>([
      fetch(url, { credentials: 'include' })
        .then(response => response.json())
        .catch(error => {
          log.warn(`AdServer /ramp request error: ${error}`);
          return Promise.reject(error);
        }),
      new Promise((_resolve, reject) => {
        setTimeout(() => reject(new TimeoutError(timeoutErrorMessage)), timeout);
      }),
    ]);
  }

  private getState(): State {
    return {
      f: this.refreshMode ? 1 : '',
      p: this.placement,
      l: this.location,
      rf: encodeURIComponent(referrer()),
      fs: 0,
      t: getDatetime(),
      tz: new Date().getTimezoneOffset(),
      r: getScreenResolutionFormatted(),
      pam: this.personalizedAdsMode,
      gdprConsent: this.gdprConsent,
      ccpa: this.uspConsent,
      gppConsent: this.gppConsent,
      gppSID: this.gppSID,
      g: this.targeting,
      a: this.adunits,
      ex: [],
      identities: getIdentities(this.config),
      tpl: this.pageTemplate,
      seg: this.segments,
      fp: this.floorPrices,
      ab: this.adserverBidders,
      htestid: this.hybridTestID,
    };
  }
}
const getIdentities = (
  siteConfig: SiteConfig[ThirdParty.PUBMATIC],
): Record<string, string> | undefined => {
  const env = getEnv();
  if (siteConfig.enabled && env.PWT) {
    return env.PWT.getUserIds();
  }
  return undefined;
};

export default (config: SiteConfig[ThirdParty.PUBMATIC]): RAMPRequest => new RAMPRequest(config);
