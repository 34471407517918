import { AdDefinition, AdUnitMode, Slot, SlotDefinition, DataObjectType } from '@repo/shared-types';

const findBestAdForSlot =
  (slot: DataObjectType<Slot>) =>
  (bestAdDefinition: AdDefinition | null, adDefinition: AdDefinition): AdDefinition | null => {
    if (slot.getProperty('adID') !== undefined) {
      return bestAdDefinition;
    }

    if (adDefinition.affinitySlotID && adDefinition.affinitySlotID !== slot.getProperty('name')) {
      return bestAdDefinition;
    }
    if (adDefinition.affinitySlotID === slot.getProperty('name')) {
      return adDefinition;
    }
    if (bestAdDefinition && bestAdDefinition.affinitySlotID === slot.getProperty('name')) {
      return bestAdDefinition;
    }

    if (adDefinition.mode !== AdUnitMode.SLOTIFY) {
      return bestAdDefinition;
    }

    const slotAdCategoryAllowList = slot.getProperty('adCategoryAllowList');
    const adCategory = adDefinition.category;
    if (
      slotAdCategoryAllowList.length !== 0 &&
      (adCategory === undefined || !slotAdCategoryAllowList.includes(adCategory))
    ) {
      return bestAdDefinition;
    }

    if (slot.getProperty('fluid') && adDefinition.fluid) {
      return bestAdDefinition || adDefinition;
    }

    const adWidth = adDefinition.width;
    const adHeight = adDefinition.height;
    const slotWidth = slot.getProperty('width');
    const slotHeight = slot.getProperty('height');
    if (
      adWidth > slotWidth ||
      adHeight > slotHeight ||
      (bestAdDefinition !== null && (bestAdDefinition.width || 1) >= adWidth)
    ) {
      return bestAdDefinition;
    }

    return adDefinition;
  };

export const findAdsForSlotDefinition =
  (slot: SlotDefinition) =>
  (adDefinition: AdDefinition): boolean => {
    if (adDefinition.affinitySlotID && adDefinition.affinitySlotID !== slot.name) {
      return false;
    }
    if (adDefinition.affinitySlotID === slot.name) {
      return true;
    }

    if (adDefinition.mode !== AdUnitMode.SLOTIFY) {
      return false;
    }

    const slotAdCategoryAllowList = slot.adCategoryAllowList;
    const adCategory = adDefinition.category;
    if (
      slotAdCategoryAllowList.length !== 0 &&
      (adCategory === undefined || !slotAdCategoryAllowList.includes(adCategory))
    ) {
      return false;
    }

    if (slot.fluid && !adDefinition.fluid) {
      return false;
    }
    if (!slot.fluid && adDefinition.fluid) {
      return false;
    }
    if (slot.fluid && adDefinition.fluid) {
      return true;
    }

    if (adDefinition.width > slot.width || adDefinition.height > slot.height) {
      return false;
    }

    return true;
  };

export default findBestAdForSlot;
