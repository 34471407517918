import { Ad, BordeauxMachineContext, DataObjectType } from '@repo/shared-types';
import { adCanRefresh, tandemAdsPresent } from '../logic';

export const getAdsToRefresh = (context: BordeauxMachineContext): Array<DataObjectType<Ad>> => {
  const ads = context.ads.getValues();

  if (!context.config.placement) {
    return [];
  }

  const refreshTime =
    context.refreshTime ||
    context.config.placement.settings.refreshTime ||
    Number.POSITIVE_INFINITY;

  const adsCanRefresh = ads
    .filter(adCanRefresh(context))
    .filter(ad => (ad.getProperty('viewedTime') || 0) >= refreshTime);
  if (context.slotifyMode) {
    return tandemAdsPresent(adsCanRefresh, context.slots.getValues());
  } else {
    return adsCanRefresh.filter(ad => {
      const screen = Object.values(context.screens).find(screen =>
        screen.matches.some(({ slot }) => slot.getProperty('adID') === ad.getProperty('id')),
      );
      if (!screen) return true;
      const screenAds = screen.matches.map(
        ({ slot }) =>
          context.ads
            .getValues()
            .find(otherAd => slot.getProperty('adID') === otherAd.getProperty('id'))!,
      );
      if (!screenAds.every(ad => adsCanRefresh.includes(ad))) return false;
      return true;
    });
  }
};
