import getEnv from '../../env';
import cookies from '../../cookies';

import { USPAPI } from '@repo/shared-types';
import findAPI, { CMP_TIMEOUT } from './utils';
import { log } from '../../log';

// Set default usprivacy cookie if it does not exist
// https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md
if (!cookies.get('usprivacy')) {
  const env = getEnv();
  cookies.set('usprivacy', '1YNN', 60 * 60 * 24 * 365, env.window.top?.location.host);
}

const mockUSPAPI = (): USPAPI => {
  return (command, version, callback): void => {
    if (command !== 'getUSPData') {
      return;
    }
    callback({ version, uspString: '1---' }, true);
  };
};

export default async (): Promise<USPAPI> => {
  const env = getEnv();
  const uspapiPromise = new Promise<USPAPI>((resolve, reject) => {
    const uspapi = findAPI('__uspapi');

    if (uspapi === null) {
      reject(new Error('__uspapi API does not exist in window frames'));
      return;
    }

    resolve(uspapi);
  });

  const timeoutPromise = new Promise<USPAPI>((_resolve, reject) => {
    env.setTimeout(
      () => reject(new Error(`Timeout retrieving __uspapi API after ${CMP_TIMEOUT}ms`)),
      CMP_TIMEOUT,
    );
  });

  return Promise.race([uspapiPromise, timeoutPromise]).catch(reason => {
    log.error(reason);
    return mockUSPAPI();
  });
};
