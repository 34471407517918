import { assign, fromCallback, fromPromise, raise, setup, sendParent, not, and } from 'xstate';

import { supportsPassive, getEnv, debounce } from '@repo/utils';

import {
  Refresh_IF as IF,
  Refresh_GO as GO,
  Refresh_DO as DO,
  Refresh_ON as ON,
  Refresh_TO as TO,
  Refresh_SPAWN as SPAWN,
  Refresh_RECIEVE as RECIEVE,
  UserActivity_EMIT,
  AnyAutomaticRefreshEvent,
  AnyUserActivityEvent,
  AutomaticRefreshGuardArgs,
  RefreshMachineContext,
  TriggerAutomaticRefreshEvent,
  ON as Bordeaux_ON,
  RefreshMachineDefinition,
} from '@repo/shared-types';

const userActivityMachine = fromCallback<AnyUserActivityEvent>(({ sendBack }) => {
  const env = getEnv();

  const sendMouseMoveEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: UserActivity_EMIT.mouseMove,
        data: timestamp,
      }),
    100,
  );
  const sendScrollEvent = debounce(
    (timestamp: number) =>
      sendBack({
        type: UserActivity_EMIT.scroll,
        data: timestamp,
      }),
    100,
  );

  env.document.addEventListener(
    'visibilitychange',
    () => {
      sendBack({
        type: UserActivity_EMIT.documentVisibility,
        data: !document.hidden,
      });
    },
    false,
  );

  env.addEventListener('mousemove', (): void => {
    sendMouseMoveEvent(Date.now());
  });

  env.addEventListener(
    'scroll',
    (): void => {
      sendScrollEvent(Date.now());
    },
    supportsPassive ? { passive: true } : false,
  );
});

const featureEnabled = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (context.takeoverActive) return false;
  if (context.refreshPaused) return false;

  return context.featureEnabled;
};
const userActiveRecently = ({ context }: AutomaticRefreshGuardArgs): boolean => {
  if (!context.documentVisible) return false;
  const timestamp = Date.now();
  if (timestamp - context.mouseMoved < context.activityTimeout) return true;
  if (timestamp - context.scrolled < context.activityTimeout) return true;
  return false;
};

const waitInterval = fromPromise<
  void,
  {
    updateInterval: number;
  }
>(({ input }) => new Promise(resolve => setTimeout(resolve, input.updateInterval)));

const refreshMachine: RefreshMachineDefinition = setup({
  actors: {
    [SPAWN.userActivity]: userActivityMachine,
    [SPAWN.waitInterval]: waitInterval,
  },
  actions: {
    [DO.ceateUserActivityMachine]: assign({
      [TO.userActivity]: ({ spawn }) => spawn(SPAWN.userActivity, { id: TO.userActivity }),
    }),
    [DO.triggerFeatureCheck]: raise({
      type: ON.check,
    }),
    [DO.set_documentVisible]: assign({
      documentVisible: (_, value: boolean) => value,
    }),
    [DO.set_featureEnabled]: assign({
      featureEnabled: (_, value: boolean) => value,
    }),
    [DO.set_mouseMoved]: assign({
      mouseMoved: (_, value: number) => value,
    }),
    [DO.set_scrolled]: assign({
      scrolled: (_, value: number) => value,
    }),
    [DO.set_takeoverStatus]: assign({
      takeoverActive: (_, value: boolean) => value,
    }),
    [DO.set_refreshPaused]: assign({
      refreshPaused: (_, value: boolean) => value,
    }),
    [DO.triggerRefresh]: sendParent({
      type: Bordeaux_ON.triggerAutomaticRefresh,
    } as TriggerAutomaticRefreshEvent),
  },
  types: {} as {
    context: RefreshMachineContext;
    events: AnyAutomaticRefreshEvent;
  },
  guards: {
    [IF.userActiveRecently]: userActiveRecently,
    [IF.featureDisabled]: actionArgs => !featureEnabled(actionArgs),
    [IF.featureEnabled]: featureEnabled,
  },
}).createMachine({
  context: {
    activityTimeout: 60000,
    updateInterval: 1000,

    documentVisible: true,
    mouseMoved: 0,
    scrolled: 0,

    refreshPaused: false,
    takeoverActive: false,
    featureEnabled: true,

    [TO.userActivity]: {} as RefreshMachineContext[TO.userActivity],
  },
  initial: GO.setup,
  states: {
    [GO.setup]: {
      entry: DO.ceateUserActivityMachine,
      always: [
        {
          guard: IF.featureEnabled,
          target: GO.wait,
        },
        { target: GO.stop },
      ],
    },
    [GO.wait]: {
      invoke: {
        id: TO.waitInterval,
        src: SPAWN.waitInterval,
        input: ({ context }) => ({ updateInterval: context.updateInterval }),
        onDone: GO.update,
      },
      on: {
        [ON.check]: {
          guard: IF.featureDisabled,
          target: GO.stop,
        },
      },
    },
    [GO.update]: {
      always: [
        {
          guard: and([IF.featureEnabled, IF.userActiveRecently]),
          actions: DO.triggerRefresh,
          target: GO.wait,
        },
        {
          guard: not(IF.featureEnabled),
          target: GO.stop,
        },
      ],
    },
    [GO.stop]: {
      on: {
        [ON.check]: {
          guard: IF.featureEnabled,
          target: GO.wait,
        },
      },
    },
  },
  on: {
    [UserActivity_EMIT.documentVisibility]: {
      actions: {
        type: DO.set_documentVisible,
        params: ({ event: { data } }) => data,
      },
    },
    [UserActivity_EMIT.mouseMove]: {
      actions: { type: DO.set_mouseMoved, params: ({ event: { data } }) => data },
    },
    [UserActivity_EMIT.scroll]: {
      actions: { type: DO.set_scrolled, params: ({ event: { data } }) => data },
    },
    [RECIEVE.setTakeoverStatus]: {
      actions: [
        { type: DO.set_takeoverStatus, params: ({ event: { data } }) => data },
        DO.triggerFeatureCheck,
      ],
    },
    [RECIEVE.setRefreshPaused]: {
      actions: [
        { type: DO.set_refreshPaused, params: ({ event: { data } }) => data },
        DO.triggerFeatureCheck,
      ],
    },
    [RECIEVE.setFeatureEnabled]: {
      actions: [
        { type: DO.set_featureEnabled, params: ({ event: { data } }) => data },
        DO.triggerFeatureCheck,
      ],
    },
  },
});

export default refreshMachine;
