import getEnv from './env';

const get = (name: string): string | undefined => {
  const env = getEnv();
  const value = `; ${env.document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const end = parts.pop();
    if (end) {
      return end.split(';').shift();
    }
  }

  return undefined;
};

const getAll = (): { [key: string]: string } => {
  const env = getEnv();
  if (env.document.cookie === '') {
    return {};
  }

  return env.document.cookie.split('; ').reduce((map, keyValue) => {
    const equalPos = keyValue.indexOf('=');
    map[keyValue.slice(0, equalPos)] = keyValue.slice(equalPos + 1);
    return map;
  }, {});
};

const set = (name: string, value: string, lifespanInSec: number, domain?: string): void => {
  const env = getEnv();
  const d = new Date();
  d.setTime(d.getTime() + lifespanInSec * 1000);
  const cookieExpires = `expires=${d.toUTCString()}`;
  const cookieDomain = domain ? `domain=${domain};` : '';
  env.document.cookie = `${name}=${value};${cookieExpires};path=/;${cookieDomain}SameSite=Lax;secure`;
};

const gaSessionId = (): string => {
  return document.cookie.replace(/(?:(?:^|.*;\s*)_ga_.*?\=\s*GS\d+\.\d+\.([^;.]*).*$)|^.*$/, '$1');
};

export default {
  get,
  getAll,
  set,
  gaSessionId,
};
