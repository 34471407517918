import {
  Ad,
  AdUnitMode,
  AdUnitSizeTuple,
  DataObjectType,
  Partner,
  REPORT_AUCTION_PARTNER,
} from '@repo/shared-types';
import { getEnv, timeData, validSizes } from '@repo/utils';
import { includes } from 'ramda';
import { AnyEventObject } from 'xstate';

interface AmazonSlot {
  slotID: string;
  slotName: string;
  sizes: Array<AdUnitSizeTuple>;
}

const env = getEnv();

const getBidDataFromAds = (
  bidDataSlots: Array<AmazonSlot>,
  ad: DataObjectType<Ad>,
): Array<AmazonSlot> => {
  const adMode = ad.getProperty('mode');
  if (adMode === AdUnitMode.OOP) {
    return bidDataSlots;
  }
  if (adMode === AdUnitMode.INTERSTITIAL) {
    return bidDataSlots;
  }

  const adID = ad.getProperty('id');

  const sizes = ad.getProperty('sizes');

  const bidSizes = sizes.filter((size): size is AdUnitSizeTuple =>
    includes(size, validSizes as Array<AdUnitSizeTuple>),
  );

  if (bidSizes.length === 0) {
    return bidDataSlots;
  }

  return bidDataSlots.concat([
    {
      slotID: adID,
      slotName: ad.getProperty('adUnitPath'),
      sizes: bidSizes,
    },
  ]);
};

export default (
  sendBack: (event: AnyEventObject) => void,
  ads: Array<DataObjectType<Ad>>,
  timeout: number,
  id: number,
): Promise<string> => {
  const { apstag } = env;
  if (!apstag) {
    return Promise.resolve('Error');
  }

  sendBack({
    type: REPORT_AUCTION_PARTNER.REQUEST,
    data: {
      time: timeData(),
      auction: id,
      partner: Partner.AMAZON,
    },
  });

  const bidData: { slots: Array<AmazonSlot> } = {
    slots: ads.reduce(getBidDataFromAds, []),
  };

  if (bidData.slots.length === 0) {
    return Promise.resolve('No slots to bid on');
  }

  let resolveComplete: ((value: string) => void) | null;
  const complete = new Promise((resolve: (value: string) => void) => {
    resolveComplete = resolve;
  });

  setTimeout(() => {
    if (resolveComplete !== null) {
      resolveComplete('Timeout');
      resolveComplete = null;

      sendBack({
        type: REPORT_AUCTION_PARTNER.TIMEOUT,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.AMAZON,
        },
      });
    }
  }, timeout);

  apstag.fetchBids(bidData, () => {
    apstag.setDisplayBids();
    if (resolveComplete !== null) {
      resolveComplete('Complete');
      resolveComplete = null;

      sendBack({
        type: REPORT_AUCTION_PARTNER.SUCCESS,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.AMAZON,
        },
      });
    }
  });

  return complete;
};
