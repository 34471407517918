import { getCacheBuster, getUserSyncURL } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const id = '46';
const pubmaticEmea: UserSyncConfig = {
  id,
  bidderName: 'pubmatic-emea',
  iabIdList: [76],
  element: UserSyncElement.IFRAME,
  url: 'https://ads.pubmatic.com/AdServer/js/user_sync.html',
  params: {
    r: getCacheBuster(),
    p: '156007',
    predirect: getUserSyncURL({ id }),
  },
};
export default pubmaticEmea;
