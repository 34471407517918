import {
  Slot,
  DataObjectType,
  ScreenWatcher_EMIT,
  ScreenViewabilityChangedEvent,
  SlotObserverEventType,
} from '@repo/shared-types';
import { EventObject, fromCallback } from 'xstate';
import { getObserver } from '../observer/utils';

const screenSlotWatcher = fromCallback<
  EventObject,
  {
    slot: DataObjectType<Slot>;
    activationDistance: number;
  }
>(({ input: { slot, activationDistance }, sendBack }) => {
  const element = slot.getProperty('element');

  const slotBuffer =
    slot.getProperty('genericName') === 'sponsored'
      ? slot.getProperty('sponsoredSlotActivationDistanceOverride') || activationDistance
      : activationDistance;

  const sendEvent = (type: SlotObserverEventType, intersecting: boolean) => {
    sendBack({
      type: ScreenWatcher_EMIT.viewabilityChanged,
      data: {
        type,
        intersecting,
        slot,
      },
    } as ScreenViewabilityChangedEvent);
  };

  let lastUpdate = 0;
  const handle = (type: SlotObserverEventType) => (entry: IntersectionObserverEntry) => {
    if (entry.time < lastUpdate) return;
    lastUpdate = entry.time;
    if (entry.isIntersecting) sendEvent(type, true);
    else sendEvent(type, false);
  };

  const unobserve = [getObserver(element, handle(SlotObserverEventType.VIEWPORT), 0)];
  if (slotBuffer !== 0)
    unobserve.push(getObserver(element, handle(SlotObserverEventType.BUFFER), slotBuffer));

  return () => {
    unobserve.forEach(unobserve => {
      unobserve();
    });
  };
});

export default screenSlotWatcher;
