import { log, partition } from '@repo/utils';
import { ApiMachineEventService } from './events.service';
import { API_EMIT, AddUnrefreshableNamesApiEvent } from '@repo/shared-types';
import metrics from 'metrics';

export default (service: ApiMachineEventService, unrefreshableAds: Array<string>): void => {
  if (!Array.isArray(unrefreshableAds)) {
    log.info(`Attempted to set unrefreshable ads with ${JSON.stringify(unrefreshableAds)}`);
    return;
  }
  const [invalidAds, validAds] = partition(unrefreshableAds, adName => typeof adName === 'string');

  invalidAds.forEach(ad => {
    log.info(`Attempted to set unrefreshable ad name with ${JSON.stringify(ad)}`);
    metrics.recordInputErrorBordeauxApi();
  });

  service.sendEvent({
    type: API_EMIT.ADD_UNREFRESHABLE_NAMES,
    data: validAds,
  } as AddUnrefreshableNamesApiEvent);
};
