import { Ad, AdDefinition, DataObject} from '@repo/shared-types';
import { HumanBoolean, Targeting } from './get-gpt-targeting.types';

const toString = (truthy: unknown): HumanBoolean => (truthy ? 'yes' : 'no');

export const standardTargeting: Targeting = {
  incremental: 'no',
  refresh: 'no',
  'api-refresh': 'no',
  additional: 'no',
};

export const getRefreshTargetingValues = (ad: DataObject<Ad>, fromApi = false): Targeting => ({
  incremental: toString(ad.getProperty('name').includes('incr')),
  refresh: fromApi ? 'no' : 'yes',
  'api-refresh': fromApi ? 'yes' : 'no',
  additional: 'yes',
});

export const getFetchTargetingValues = (ad: AdDefinition): Targeting => ({
  incremental: toString(ad.incremental),
  refresh: 'no',
  'api-refresh': 'no',
  additional: toString(ad.name.includes('incr')),
});
