import getEnv from '../env';
import resizeGamCreativeContainerSize from './creative-resizer';
import { Bid, CREATIVE_EVENT } from '@repo/shared-types';

const env = getEnv();

export default (storedBids: Map<string, Bid>): void => {
  window.addEventListener(
    'message',
    event => {
      if (event.data.message !== CREATIVE_EVENT.RAMP_RENDER_REQUEST) {
        return;
      }

      const bidId = event.data.pattern;
      const bid = storedBids.get(bidId);

      if (!bid) {
        return;
      }

      if (!bid.adm) {
        return;
      }

      const markup = bid.adm.replace(/'\r\n|\r|\n/gm, '');
      const width = bid.size ? bid.size.w : 1;
      const height = bid.size ? bid.size.h : 1;

      const confiantMarkupId = `purch_${bid.bidder.name}`;
      const bidObject = {
        message: CREATIVE_EVENT.RAMP_RENDER_RESPONSE,
        ad: markup,
        bidder: confiantMarkupId,
        width,
        height,
        size: `${width}x${height}`,
        cpm: bid.price,
        trackingParams: bid.tracking_params,
      };

      event.source?.postMessage(bidObject, { targetOrigin: '*' });

      const slot =
        env.googletag &&
        env.googletag
          .pubads()
          .getSlots()
          .find(checkSlot => checkSlot && bid.divid === checkSlot.getSlotElementId());

      if (slot) {
        resizeGamCreativeContainerSize(slot, width, height);
        slot.setTargeting('_bd', 'none');
      }

      storedBids.delete(bidId);
    },
    false,
  );
};
