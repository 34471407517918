import { AdDefinition, AdUnitListGroup } from '../../../index';

const adaptAdUnitListGroupConfig = (adUnits: Array<AdDefinition>): AdUnitListGroup => ({
  standard: adUnits
    .filter(adUnit => !adUnit.incremental)
    .map(
      (adUnit, index): AdDefinition => ({
        ...adUnit,
        requestOrder: index,
      }),
    ),
  incremental: adUnits.filter(adUnit => adUnit.incremental),
});

export default adaptAdUnitListGroupConfig;
