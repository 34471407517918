const trimSlashes = (input: string = ''): string => {
  // Fast and does not require regex: https://stackoverflow.com/a/35961423
  let endIndex = input.length - 1;
  let startIndex = 0;

  while (input.charAt(startIndex) === '/' && ++startIndex);
  while (input.charAt(endIndex) === '/' && --endIndex);

  return input.slice(startIndex, endIndex + 1);
};
export default trimSlashes;
