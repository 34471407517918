import { unique, getEnv } from '@repo/utils';
import { startsWith, prop, compose, not } from 'ramda';
import { MARK, MARK_PREFIX } from './constants';
import { MarkOptions, MarksAPI, MarkEvent } from '@repo/shared-types';
import { getEntriesByType, now as performanceNow } from './setupPerformance';

const markEvents: Array<MarkEvent> = [];
const env = getEnv();

const mark = 'mark';
const createMark = (
  object: PerformanceMark | undefined,
  time: number,
  message: string,
  origin = 'Bordeaux',
  config: MarkOptions | string = '',
  startMark: false | number = false,
  eventInfo: Record<string, unknown> | null = null,
): MarkEvent => ({
  id: unique(),
  type: mark,
  time,
  object,
  origin,
  ...(config instanceof Object ? config : {}),
  ...(startMark ? { startMark } : {}),
  ...(eventInfo === null ? {} : { eventInfo }),
  message,
});

const isBordeauxMark = compose<[PerformanceMark], string, boolean>(
  startsWith(MARK_PREFIX),
  prop('name'),
);

const getMarkEntries = (): PerformanceMark[] => getEntriesByType(MARK) as PerformanceMark[];

const sendMark = (
  markObject: PerformanceMark | undefined,
  message: string,
  origin: string,
  config: MarkOptions | string = '',
  startMark: false | number = false,
  eventInfo: Record<string, unknown> | null = null,
): MarkEvent => {
  const eventObject: MarkEvent = createMark(
    markObject,
    markObject ? markObject.startTime : performanceNow(),
    message,
    origin,
    config,
    startMark,
    eventInfo,
  );

  markEvents.push(eventObject);

  return eventObject;
};

const watchInterval = 2000;
const watchInactivityLimit = 5;
const watchForMarks = (): number => {
  let previousPoint = 0;
  let missedUpdates = 0;
  // const interval: number | undefined;

  const stopWatching = (): void => {
    env.clearInterval(interval);
  };

  const watchStep = (): void => {
    if (missedUpdates > watchInactivityLimit) {
      stopWatching();
      return;
    }

    const allMarks: PerformanceMark[] = getMarkEntries();
    const unknownMarks: PerformanceMark[] = allMarks.slice(previousPoint);
    const nonBdxMarks: PerformanceMark[] = unknownMarks.filter(compose(not, isBordeauxMark));

    previousPoint = allMarks.length;

    if (nonBdxMarks.length === 0) {
      missedUpdates++;
      return;
    }

    nonBdxMarks.forEach(mark => sendMark(mark, mark.name, 'System'));
  };

  const interval = env.setInterval(watchStep, watchInterval);

  return interval;
};

const setupMarksAPI = (): MarksAPI => {
  watchForMarks();

  return {
    mark: (
      config: MarkOptions | string,
      startMark: false | number = false,
      eventInfo: Record<string, unknown> | null = null,
    ): number => {
      const message: string = config instanceof Object ? config.message : config;
      const markMessage = MARK_PREFIX + message;
      let markObject: PerformanceMark | undefined;
      if (typeof env.performance.mark === 'function') {
        markObject = env.performance.mark(markMessage);
      }

      const eventObject: MarkEvent = sendMark(
        markObject,
        markMessage,
        'Bordeaux',
        config,
        startMark,
        eventInfo,
      );

      return eventObject.id;
    },
    getMarks: () => markEvents,
  };
};

export default setupMarksAPI;
