import { getEnv, PartialPick } from '@repo/utils';
import { ThirdParty, ThirdPartyAPIMachineConfigs } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.IAS], 'thirdParty'> = {
  thirdParty: ThirdParty.IAS,
  scriptLocation: 'https://cdn.adsafeprotected.com/iasPET.1.js',
  methods: {
    loadScript: async (scriptLocation, { data: { config } }): Promise<void> => {
      const env = getEnv();
      await loadScript(scriptLocation, 'ias-script', { async: true });
      env.__iasPET = env.__iasPET || {
        queue: [],
        setTargetingForGPT: (): void => {
          // If the real env.__iasPET is not found we just provide an
          // empty function to avoid crashing if someone tries to call it
        },
        pubId: config.pubId,
      };
      env.__iasPET.queue = env.__iasPET.queue || [];
      env.__iasPET.pubId = config.pubId;
    },
  },
};
export default config;
