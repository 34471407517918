import { Ad, DataObjectStoreType } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import isAnchoredHideable from '../ad-framework/ad/is-anchored-hidable';

export default async (service: ApiMachineEventService): Promise<boolean> => {
  const [anchoredLineItems, ads] = await Promise.all([
    service.getData<Array<number> | undefined>(
      'config',
      'features',
      'customActivations',
      'HIDEABLE_ANCHORED_ENABLED',
      'LINE_ITEM',
    ),
    service.getData<DataObjectStoreType<Ad>>('ads'),
  ]);
  return isAnchoredHideable(ads.getValues(), anchoredLineItems);
};
