import {
  Ad,
  AdUnitSizeTuple,
  DataObject,
  Partner,
  REPORT_AUCTION_PARTNER,
} from '@repo/shared-types';
import { getEnv, timeData } from '@repo/utils';
import { AnyEventObject } from 'xstate';

const env = getEnv();

interface IASSlot {
  adSlotId: string;
  size: Array<AdUnitSizeTuple>;
  adUnitPath: string;
}

export default (
  sendBack: (event: AnyEventObject) => void,
  ads: Array<DataObject<Ad>>,
  timeout: number,
  id: number,
): Promise<string> => {
  const { __iasPET } = env;
  if (!__iasPET) {
    return Promise.resolve('Error');
  }

  sendBack({
    type: REPORT_AUCTION_PARTNER.REQUEST,
    data: {
      time: timeData(),
      auction: id,
      partner: Partner.IAS,
    },
  });

  const iasPETSlots: Array<IASSlot> = [];

  ads.forEach(ad => {
    const adID = ad.getProperty('id');

    const sizes = ad
      .getProperty('sizes')
      .filter(
        (sizePair): sizePair is AdUnitSizeTuple =>
          typeof sizePair !== 'string' && sizePair.length > 1,
      );

    iasPETSlots.push({
      adSlotId: adID,
      size: sizes,
      adUnitPath: ad.getProperty('adUnitPath'),
    });
  });

  let resolveComplete: ((value: string) => void) | null;
  const complete = new Promise((resolve: (value: string) => void) => {
    resolveComplete = resolve;
  });

  const __iasPETTimeoutRequestAds = setTimeout(() => {
    if (resolveComplete !== null) {
      resolveComplete('Timeout');
      resolveComplete = null;

      sendBack({
        type: REPORT_AUCTION_PARTNER.TIMEOUT,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.IAS,
        },
      });
    }
  }, timeout);

  const iasDataHandler = (): void => {
    clearTimeout(__iasPETTimeoutRequestAds);
    __iasPET.setTargetingForGPT();
    if (resolveComplete !== null) {
      resolveComplete('Complete');
      resolveComplete = null;

      sendBack({
        type: REPORT_AUCTION_PARTNER.SUCCESS,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.IAS,
        },
      });
    }
  };

  __iasPET.queue.push({
    adSlots: iasPETSlots,
    dataHandler: iasDataHandler,
  });

  return complete;
};
