import { BordeauxMachineContext } from '@repo/shared-types';

export const isExperimentMatch = (testId: string, experimentId: string): boolean => {
  const [experiment] = testId.split('|');
  return experiment === experimentId;
};

export const getVariant = (
  config: BordeauxMachineContext['config'],
  experimentId: string,
): string | undefined => {
  const { hybridTestID } = config.targeting;

  const extractVariant = (testId: string): string | undefined =>
    testId.includes('|') ? testId.split('|')[1].trim() : '';

  if (typeof hybridTestID === 'string' && isExperimentMatch(hybridTestID, experimentId)) {
    return extractVariant(hybridTestID);
  }

  if (Array.isArray(hybridTestID)) {
    const experimentMatch = hybridTestID.find(testId => isExperimentMatch(testId, experimentId));
    return experimentMatch ? extractVariant(experimentMatch) : undefined;
  }

  return undefined;
};

export const isVariantMatch = (
  testId: string,
  experimentId: string,
  variantId: string,
): boolean => {
  const [experiment, variant] = testId.split('|');
  return experiment === experimentId && variant === variantId;
};

export const containsExperiment = (
  config: BordeauxMachineContext['config'],
  experimentId: string,
): boolean => {
  const { hybridTestID, hybridQATestID } = config.targeting;
  const testID = hybridTestID ?? hybridQATestID;

  if (typeof testID === 'string') {
    return isExperimentMatch(testID, experimentId);
  }

  if (Array.isArray(testID)) {
    return testID.some(testId => isExperimentMatch(testId, experimentId));
  }

  return false;
};

export const containsVariant = (
  config: Pick<BordeauxMachineContext['config'], 'targeting'>,
  experimentId: string,
  variantId: string,
): boolean => {
  const { hybridTestID, hybridQATestID } = config.targeting;
  const testID = hybridTestID ?? hybridQATestID;

  if (typeof testID === 'string') {
    return isVariantMatch(testID, experimentId, variantId);
  }
  if (Array.isArray(testID)) {
    return testID.some(testId => isVariantMatch(testId, experimentId, variantId));
  }

  return false;
};
