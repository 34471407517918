import { PrometheusController } from '../PrometheusController';
import { Histogram } from '@future/promjs/lib/histogram';
import { labels } from 'metrics/setupPrometheus/labels';

export const recordLongTask =
  (histogram: Histogram) =>
  (list: PerformanceObserverEntryList): void => {
    if (labels.site) {
      list.getEntries().forEach(entry => {
        // @ts-expect-error new types, not present in lib.dom typescript
        entry.scripts.forEach(script => {
          if (script.sourceURL.includes('bordeaux.js') && script.duration > 50) {
            PrometheusController.update({ metricsUpdated: true });
            histogram.observe(script.duration, { invoker: script.invoker, ...labels });
          }
        });
      });
    }
  };
