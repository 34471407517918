import { getEnv, parseIabSegmentIdsTAM, getCanonicalUrl } from '@repo/utils';
import { ThirdParty, ThirdPartyAPIMachineConfigs, PartialPick } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.AMAZON], 'thirdParty'> = {
  thirdParty: ThirdParty.AMAZON,
  scriptLocation: 'https://c.aps.amazon-adsystem.com/apstag.js',
  methods: {
    getConfig: context => {
      const {
        apiConfig: { signals, schain, ...apiConfig },
        ...config
      } = context.bordeaux.thirdPartyApiConfig[ThirdParty.AMAZON];
      const { sellerID } = context.bordeaux.thirdPartyApiConfig[ThirdParty.GLOBAL];
      const { iabCategories } = context.bordeaux.pageTargeting;
      const { iabCategoryIds } = context.bordeaux.pageTargeting;
      return {
        ...config,
        apiConfig: {
          ...apiConfig,
          ...(schain && sellerID
            ? {
                schain: {
                  ...schain,
                  nodes: [
                    // Can be empty if the owner of the site is creating this request
                    {
                      asi: 'futureplc.com', // Populate with the canonical domain of the advertising system where the seller.JSON file is hosted
                      sid: sellerID, // The identifier associated with the seller or reseller account within your advertising system
                      hp: 1, // 1 or 0, whether this node is involved in the payment flow
                    },
                    ...schain.nodes,
                  ],
                },
              }
            : { schain }),
          ...(signals && iabCategories && iabCategoryIds
            ? {
                signals: {
                  ortb2: {
                    site: {
                      page: getCanonicalUrl(),
                      publisher: {
                        id: 'ead6c15f-37ee-4ca3-a645-fe0666bdcedd',
                      },
                      cattax: 7,
                      cat: parseIabSegmentIdsTAM(iabCategoryIds),
                      keywords: Array.isArray(iabCategories) ? iabCategories : [iabCategories],
                    },
                  },
                },
              }
            : {
                signals: {
                  ...signals,
                  ortb2: {
                    site: {
                      page: getCanonicalUrl(),
                      publisher: {
                        id: 'ead6c15f-37ee-4ca3-a645-fe0666bdcedd',
                      },
                      cattax: 7,
                      cat: [],
                      keywords: [],
                    },
                  },
                },
              }),
        },
      };
    },
    loadScript: async (scriptLocation, { data: { config } }): Promise<void> => {
      const env = getEnv();
      await loadScript(scriptLocation, 'amazon-script');
      if (typeof env.apstag === 'undefined' || env.apstag === null || env.apstag.init === null) {
        throw new Error(`Amazon API setup failed: apstag is not available.`);
      }
      if (env.apstag.inited !== true) {
        env.apstag.init({ ...config.apiConfig });
        env.apstag.inited = true;
      }
    },
  },
};
export default config;
