import { Ad, DataObjectStoreType } from '@repo/shared-types';
import setupElements from './setup-elements';

const adHighlights: Record<string, HTMLElement> = {};
let highlightAllAds = false;

const adHighlightStyles = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(186, 255, 186, 0.66)',
  zIndex: '10003',
};

const adInformationStyles = {
  fontFamily: `'Fira Mono', monospace`,
  fontWeight: '700',
  color: 'black',
  fontSize: '20px',
  width: 'fit-content',
  backgroundColor: 'rgba(186, 255, 186)',
  zIndex: '10004',
  position: 'absolute',
  top: '0px',
};

export const addAdHighlight = (ads: DataObjectStoreType<Ad>, name: string): void => {
  let adElement: Element | null = null;
  ads.getValues().forEach(ad => {
    if (ad.getProperty('name') === name) {
      adElement = ad.getProperty('elements')?.element || null;
    }
  });
  if (!adElement) {
    return;
  }

  const config = {
    name: ' - ad',
    id: '-bdx-ad-highlight',
    highlight: adHighlightStyles,
    information: adInformationStyles,
  };

  setupElements(adElement, name, adHighlights, config);
};

export const removeAdHighlight = (name: string): void => {
  const highlight = adHighlights[name];
  if (!highlight) {
    return;
  }

  if (highlight.parentElement) {
    highlight.parentElement.removeChild(highlight);
  }

  delete adHighlights[name];
};

export const addAllAdHighlights = (ads: DataObjectStoreType<Ad>): void => {
  ads.getValues().forEach(ad => {
    if (!Object.keys(adHighlights).includes(ad.getProperty('name'))) {
      addAdHighlight(ads, ad.getProperty('name'));
    }
  });
  highlightAllAds = true;
};

export const removeAllAdHighlights = (): void => {
  Object.keys(adHighlights).forEach(name => {
    removeAdHighlight(name);
  });
  highlightAllAds = false;
};

export const updateAdHighlights = (ads: DataObjectStoreType<Ad>): void => {
  if (highlightAllAds) {
    addAllAdHighlights(ads);
  }
};
