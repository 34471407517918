export default function throttle<CbFn extends (...args: any[]) => any>(
  cb: CbFn,
  delay = 1000,
): (...args: Parameters<CbFn>) => void {
  let shouldWait = false;

  return (...args: Parameters<CbFn>): void => {
    if (!shouldWait) {
      shouldWait = true;
      setTimeout(() => {
        shouldWait = false;
      }, delay);

      cb(...args);
    }
  };
}
