import { FallbackAdServerResponses } from 'config/fallback/index.types';
import { log } from '@repo/utils';
import { ApiMachineEventService } from './events.service';
import { API_EMIT } from '@repo/shared-types';
import metrics from 'metrics';

export default (
  service: ApiMachineEventService,
  fallbackRespones: Partial<FallbackAdServerResponses>,
): void => {
  if (typeof fallbackRespones !== 'object') {
    log.info(
      `Argument passed to setFallbackConfig must be an object, ${typeof fallbackRespones} given`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  service.sendEvent({
    type: API_EMIT.SET_FALLBACK_RESPONSES,
    data: fallbackRespones,
  });
};
