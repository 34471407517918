import SlotObserver from 'slotify/observer';
import { log, fastdom } from '@repo/utils';
import { ActionArgs, PopOutAdFeatureEvent, SlotObserverEventType } from '@repo/shared-types';
import { getFrames, onFrame } from './multi-frame';

const enabledCreatives: Array<string> = [];
let popOutCanceled = false;
const intendedPopouts = {};
export const cancelPopOut = () => {
  if (popOutCanceled) return;
  enabledCreatives.forEach(creativeID => {
    const iframes = Object.values(getFrames(creativeID));
    iframes.forEach(iframeElement => {
      if (!iframeElement.contentWindow) {
        return;
      }
      iframeElement.contentWindow.postMessage(
        {
          type: 'bordeaux-ad-feature-response',
          bordeauxFeature: 'pop-out',
          out: false,
          creativeID,
        },
        '*',
      );
    });
  });
  popOutCanceled = true;
};

export const enablePopOut = () => {
  if (!popOutCanceled) return;
  enabledCreatives.forEach(creativeID => {
    if (!intendedPopouts[creativeID]) return;
    const iframes = Object.values(getFrames(creativeID));
    iframes.forEach(iframeElement => {
      if (!iframeElement.contentWindow) {
        return;
      }
      iframeElement.contentWindow.postMessage(
        {
          type: 'bordeaux-ad-feature-response',
          bordeauxFeature: 'pop-out',
          out: true,
          creativeID,
        },
        '*',
      );
    });
  });
  popOutCanceled = false;
};

const popOut = ({ context }: ActionArgs, data: PopOutAdFeatureEvent['data']): void => {
  if (enabledCreatives.includes(data.creativeID)) return;

  const slot = context.slots
    .getValues()
    .find(searchSlot => searchSlot.getProperty('id') === data.slotID);
  if (!slot) {
    log.info(`Could not make ad pop out, slot with ID ${data.slotID} does not exist.`);
    return;
  }

  const ad = context.ads
    .getValues()
    .find(searchAd => searchAd.getProperty('id') === slot.getProperty('adID'));
  if (!ad) {
    log.info(`Could not make ad pop out, ad with ID ${slot.getProperty('adID')} does not exist.`);
    return;
  }

  if (data.expandedFrame && data.expandedHeight) {
    onFrame(data.creativeID, data.expandedFrame, frame => {
      const celtraContainer = frame.closest<HTMLElement>('.celtra-expanded-ad');
      fastdom.mutate(() => {
        frame.style.maxHeight = `${data.expandedHeight}px`;
        if (celtraContainer) {
          celtraContainer.style.maxHeight = `${data.expandedHeight}px`;
        }
      });
    });
  }
  enabledCreatives.push(data.creativeID);

  const slotName = slot.getProperty('name');
  const element = slot.getProperty('element');

  const bufferOffset = (data.edge === 'top' ? -slot.getProperty('height') : 0) - data.distance;

  const slotObserver = new SlotObserver(element, slotName, bufferOffset);

  slotObserver.observe(event => {
    if (event.type !== SlotObserverEventType.BUFFER) return;

    const iframes = Object.values(getFrames(data.creativeID));
    if (!iframes.length) {
      log.info(`Could not make ad pop out, ad iframe element not found.`);
      return;
    }
    const shouldPopOut = !event.isIntersecting;
    intendedPopouts[data.creativeID] = shouldPopOut;

    if (!popOutCanceled || !shouldPopOut) {
      iframes.forEach(iframeElement => {
        if (!iframeElement.contentWindow) {
          log.info(`Could not make ad pop out, ad iframe element has no content window.`);
          return;
        }
        iframeElement.contentWindow.postMessage(
          {
            type: 'bordeaux-ad-feature-response',
            bordeauxFeature: 'pop-out',
            out: shouldPopOut,
            creativeID: data.creativeID,
          },
          '*',
        );
      });
    }
  });
};
export default popOut;
