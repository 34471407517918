import getEnv from '../env';
import { CREATIVE_EVENT, TrackingParams } from '@repo/shared-types';

const env = getEnv();

export default (): void => {
  window.addEventListener(
    'message',
    event => {
      if (event.data.message !== CREATIVE_EVENT.RAMP_AD_VIEW_EVENT) {
        return;
      }
      const { trackingParams } = event.data;

      triggerEvent(trackingParams as TrackingParams);
    },
    false,
  );
};

const triggerEvent = (params: TrackingParams): void => {
  let qs = Object.entries(params)
    .filter(([, val]) => typeof val !== 'string' || val.indexOf('$') !== 0)
    .map(([k, val]) => `${k}=${val}`)
    .join('&');

  const r = Math.floor(Math.random() * 11000);
  qs = `t=CP&r=${r}&${qs}`;

  if (params && params.ts && params.ts.indexOf('$') === -1) {
    qs += `&tm_alt=${Date.now() - Number(params.ts)}`;
  }

  const e = env.document.createElement('script');
  e.src = `//ads2.servebom.com/event.js?${qs}`;
  const scripts = env.document.getElementsByTagName('script')[0];
  if (scripts && scripts.parentNode) {
    scripts.parentNode.insertBefore(e, scripts);
  }
};
