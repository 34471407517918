import getEnv from './env';

const env = getEnv();

export default (): string => {
  const g = env.localStorage.getItem('bdx_pv');
  const pv = (g ? Number(g) + 1 : 1) + '';

  env.localStorage.setItem('bdx_pv', pv);
  return pv;
};
