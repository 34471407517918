import {
  getGDPRConsentMachine,
  getGPPConsent,
  getUSPConsent,
  elementTapHandler,
  getEnvDOMContentLoaded,
} from '@repo/utils';
import { checkConfig, fetchConfig, parseConfig } from 'config';

import { setupThirdPartyAPI } from 'third-party-apis/index';

import listenForAdFeatures from 'ad-features/post-message-listener';
import anchoredLogic from 'ad-framework/ad/anchored-logic';
import { SPAWN } from '@repo/shared-types';
import initAutomaticRefresh from 'ad-framework/refresh/automatic';
import setupAPI from 'api';
import slotifyMachine from 'slotify';
import arbitraryEventEmitter from './arbitrary-event-emitter';
import pageEventEmitter from './page-event-emitter';
import readPageStyles from './read-page-styles';
import waitForAllThirdParties from './wait-for-all-third-parties';
import adManagerMachine from 'ad-framework/ad-manager';
import { adPreProcessor } from 'ad-framework/ad-manager/pre-process-ads';
import insertAds from 'slotify/actors/insert-ads';
import screenSlotWatcher from 'slotify/actors/screens-watcher';
import toolAPIMachine from './tool-api';

const actors = {
  [SPAWN.arbitraryEventEmitter]: arbitraryEventEmitter,
  [SPAWN.elementTapHandler]: elementTapHandler,
  [SPAWN.automaticRefresh]: initAutomaticRefresh,
  [SPAWN.pageEventEmitter]: pageEventEmitter,
  [SPAWN.getEnvDOMContentLoaded]: getEnvDOMContentLoaded,
  [SPAWN.API]: setupAPI,
  [SPAWN.fetchConfig]: fetchConfig,
  [SPAWN.checkConfig]: checkConfig,
  [SPAWN.parseConfig]: parseConfig,
  [SPAWN.getGDPRConsent]: getGDPRConsentMachine,
  [SPAWN.getUSPConsent]: getUSPConsent,
  [SPAWN.getGPPConsent]: getGPPConsent,
  [SPAWN.setupThirdPartyAPI]: setupThirdPartyAPI,
  [SPAWN.listenForAdFeatures]: listenForAdFeatures,
  [SPAWN.waitForAllThirdParties]: waitForAllThirdParties,
  [SPAWN.readPageStyles]: readPageStyles,
  [SPAWN.anchoredLogic]: anchoredLogic,
  [SPAWN.slotify]: slotifyMachine,
  [SPAWN.adManager]: adManagerMachine,
  [SPAWN.adPreProcessor]: adPreProcessor,
  [SPAWN.insertAds]: insertAds,
  [SPAWN.screenSlotWatcher]: screenSlotWatcher,
  [SPAWN.toolAPI]: toolAPIMachine,
};
export default actors;
