import { DataObject, Ad, AdUnitMode, SlotLabelPosition, ScreenAdMatch } from '@repo/shared-types';
import {
  applyStyles,
  getEnv,
  getOnMountSlotStyles,
  log,
  querySelector,
  stringToStyle,
  fastdom,
} from '@repo/utils';
import { fromPromise } from 'xstate';
import createSlotifyMarkup from 'ad-framework/ad/handle-create/slotify';
import createAnchoredMarkup from 'ad-framework/ad/handle-create/anchored';
import createOutOfPageMarkup from 'ad-framework/ad/handle-create/out-of-page';
import createSkyscraperMarkup from 'ad-framework/ad/handle-create/skyscraper';
import { createSlotLabel, LABEL_STYLE } from 'ad-framework/ad/create-ad-label';
import { isEmpty } from 'ramda';
import metrics from 'metrics';

const createAdElements = (ad: DataObject<Ad>) => {
  switch (ad.getProperty('mode')) {
    case AdUnitMode.SLOTIFY:
      return createSlotifyMarkup(ad);
    case AdUnitMode.ANCHORED:
      return createAnchoredMarkup(ad);
    case AdUnitMode.OOP:
      return createOutOfPageMarkup(ad);
    case AdUnitMode.SKYSCRAPER:
      return createSkyscraperMarkup(ad);
    default:
      return null;
  }
};

const inlineLeftRightSlotsStore: Map<string, number> = new Map();

const computeAndStoreNbOccurencesOfCurrentSlot = (genericSlotName: string | undefined): number => {
  let nbSlotOccurences = 0;
  if (genericSlotName) {
    if (inlineLeftRightSlotsStore.get(genericSlotName) !== undefined) {
      nbSlotOccurences = (inlineLeftRightSlotsStore.get(genericSlotName) || 0) + 1;
    }
    inlineLeftRightSlotsStore.set(genericSlotName, nbSlotOccurences);
  }
  return nbSlotOccurences;
};

const insertAds = fromPromise<void, { matches: Array<ScreenAdMatch> }>(
  async ({ input: { matches } }): Promise<void> => {
    const env = getEnv();
    const results = await Promise.allSettled(
      matches.map(async ({ ad, slot }) => {
        const mode = ad.getProperty('mode');
        const elements = createAdElements(ad);
        if (!elements) {
          throw new Error(
            'Ad cannot be inserted into the DOM because its elements could not be created',
          );
        }
        ad.update({ elements });
        switch (mode) {
          case AdUnitMode.OOP:
          case AdUnitMode.ANCHORED: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            await fastdom.mutate(() => {
              env.document.body.appendChild(container);
            });
            break;
          }
          case AdUnitMode.SKYSCRAPER: {
            const container = elements?.outerContainer;
            if (!container) {
              throw new Error(
                'Ad cannot be inserted into the DOM because it has no outer container',
              );
            }
            const env = getEnv();
            const main = env.document.getElementById('main');
            if (!main) {
              throw new Error('Error in skyscraper setup, #main element not found');
            }
            const { width: contentWidth } = await fastdom.measure(() =>
              main.getBoundingClientRect(),
            );

            const skyscraperContainer =
              querySelector<HTMLElement>('.skyscraper-container') ||
              env.document.createElement('div');

            skyscraperContainer.classList.add('skyscraper-container');
            const skyscraperContainerStyle = {
              width: `${contentWidth}px`,
              height: 0,
              margin: `auto`,
              position: 'sticky',
              top: 0,
              'pointer-events': 'none',
            };
            Object.assign(skyscraperContainer.style, skyscraperContainerStyle);

            await fastdom.mutate(() => {
              if (!main.parentNode) {
                throw new Error('Error in skyscraper setup, #main element has no parent');
              }
              skyscraperContainer.appendChild(container);
              main.parentNode.insertBefore(skyscraperContainer, main);
            });
            break;
          }
          case AdUnitMode.SLOTIFY: {
            const adElement = elements?.element;
            if (!adElement) {
              throw new Error('Ad cannot be inserted into the DOM because it has no element');
            }
            const slotID = ad.getProperty('slotID')!;
            const slotElement = slot.getProperty('element');
            const slotLabel = slot.getProperty('label');
            const slotHasLabel = !(!slotLabel || isEmpty(slotLabel) || slotLabel.applyLabelToAds);
            const labelStyle = stringToStyle(slotLabel?.style || LABEL_STYLE);
            const slotStyles = getOnMountSlotStyles(
              slot,
              (slotHasLabel && labelStyle.height) || '0',
              computeAndStoreNbOccurencesOfCurrentSlot(slot.getProperty('genericName')),
            );

            const applyLabelToAds =
              slotLabel.applyLabelToAds && slotLabel.applyLabelToAds === 'true';
            if (applyLabelToAds) {
              ad.update({ label: slotLabel });
            }

            await fastdom.mutate(() => {
              applyStyles(slotElement, slotStyles);

              slotElement.classList.add('bordeaux-filled-slot');
              slotElement.ariaHidden = 'true';

              slotElement.appendChild(adElement);

              if (slotHasLabel) {
                const labelElement = createSlotLabel(slotID, slotLabel);
                if (slotLabel.position === SlotLabelPosition.ABOVE) {
                  slotElement.insertBefore(labelElement, slotElement.firstChild);
                }
                if (slotLabel.position === SlotLabelPosition.BELOW) {
                  slotElement.style.justifyContent = 'space-between';
                  slotElement.lastChild?.after(labelElement);
                }
              }
            });
            break;
          }
          default:
            throw new Error(`Ad insertion issue, ad has unrecognised mode: ${mode}`);
        }
      }),
    );
    const failed = results.filter(r => r.status === 'rejected');
    if (failed.length) {
      log.error('Ad elements could not be inserted', failed);
      metrics.recordSlotInsertionError();
    }
  },
);

export default insertAds;
