import { Labels } from '@future/promjs/lib';
import { Counter } from '@future/promjs/lib/counter';
import { PrometheusAPI } from '@repo/shared-types';

import { labels } from '../labels';
import { PrometheusController } from '../PrometheusController';

export const recordFrameworkRequest =
  (counter: Counter): PrometheusAPI['recordFrameworkRequest'] =>
  result => {
    if (labels.site) {
      const frameworkLabels: Labels = {
        target: 'bordeaux',
        ...labels,
        result,
      };
      PrometheusController.update({ metricsUpdated: true });
      counter.inc(frameworkLabels);
    }
  };
