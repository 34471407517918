import { SlotifyMachineContext, AnySlotifyEvent } from '@repo/shared-types';
import {
  ActorRef,
  NonReducibleUnknown,
  sendTo as genericSendTo,
  Snapshot,
  ParameterizedObject,
} from 'xstate';

const sendTo = <
  M extends ActorRef<Snapshot<unknown>, any>,
  E extends AnySlotifyEvent = AnySlotifyEvent,
  P extends ParameterizedObject['params'] = NonReducibleUnknown,
>(
  ...args: Parameters<typeof genericSendTo<SlotifyMachineContext, E, P, M, AnySlotifyEvent, string>>
) => genericSendTo<SlotifyMachineContext, E, P, M, AnySlotifyEvent>(...args);

export default sendTo;
