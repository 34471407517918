import { Labels } from '@future/promjs/lib';
import { Counter } from '@future/promjs/lib/counter';

import { PrometheusAPI } from '@repo/shared-types';

import { labels } from '../labels';
import { PrometheusController } from '../PrometheusController';

export const recordSlotInsertionError =
  (counter: Counter): PrometheusAPI['recordSlotInsertionError'] =>
  () => {
    if (labels.site) {
      const adsLabels: Labels = { ...labels };
      PrometheusController.update({ metricsUpdated: true });
      counter.inc(adsLabels);
    }
  };
