import { getEnv } from '@repo/utils';

import { ThirdParty, ThirdPartyAPIMachineConfigs, PartialPick } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.GPT], 'thirdParty'> = {
  thirdParty: ThirdParty.GPT,
  scriptLocation: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
  methods: {
    loadScript: async (scriptLocation, context) => {
      const env = getEnv();

      const loadExternally = context.bordeaux.loadGptExternally;
      if (!loadExternally) {
        env.googletag = env.googletag || ({ cmd: [] } as unknown as googletag.Googletag);
        env.googletag.cmd = env.googletag?.cmd || [];
        await loadScript(scriptLocation, 'gpt-api-script', { async: true });
      } else {
        await context.bordeaux.loadGptExternallyPromise.promise;
      }

      await new Promise<void>((resolve, reject) => {
        if (env.googletag && env.googletag.cmd) {
          env.googletag.cmd.push(() => {
            resolve();
          });
        } else {
          reject(
            Error(
              `GPT does not exist on the page when loaded ${
                loadExternally ? 'externally' : 'internally'
              }.`,
            ),
          );
        }
      });
    },
  },
};
export default config;
