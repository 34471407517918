import { getFetchTargetingValues } from '@repo/utils';
import { mergeDeepRight } from 'ramda';
import { Ad, AdDefinition, AdUnitStatus, DataObject, PartialPick } from '@repo/shared-types';

const createAdDataObject = (
  adDefinition: AdDefinition,
  adOverrides: PartialPick<Ad, 'id' | 'adUnitPath'>,
) => new DataObject<Ad>(createAd(adDefinition, adOverrides));

export const createAd = (
  adDefinition: AdDefinition,
  adOverrides: PartialPick<Ad, 'id' | 'adUnitPath'>,
) =>
  mergeDeepRight(
    {
      ...adDefinition,
      inView: false,
      inView75Percent: false,
      viewed: false,
      inViewport: false,
      viewedTime: 0,
      fetchTime: 0,
      loadTime: 0,
      status: AdUnitStatus.PENDING,
      targeting: {
        ...adDefinition.targeting,
        ...getFetchTargetingValues(adDefinition),
      },
    },
    adOverrides,
  ) as Ad;
export default createAdDataObject;
