/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { hasUserConsentedVendorGDPR, TimeoutError, log, PartialPick } from '@repo/utils';
import { assign, fromPromise, sendParent, setup } from 'xstate';
import {
  ThirdPartyAPIMachineContext,
  ThirdParty,
  ThirdPartyAPI_DO as DO,
  ThirdPartyAPI_IF as IF,
  ThirdPartyAPI_GO as GO,
  ThirdPartyAPI_EMIT as EMIT,
  ThirdPartyAPI_SPAWN as SPAWN,
  ThirdPartyAPI_TO as TO,
  APISetupResult,
  ThirdPartyFailureEvent,
  ThirdPartyRequestEvent,
  ThirdPartySuccessEvent,
  ThirdPartyTimeoutEvent,
} from '@repo/shared-types';
import { timeout, timeoutMessage } from './utils/timeout';

const timeoutScript = (thirdparty: ThirdParty): Promise<void> =>
  new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new TimeoutError(`${thirdparty}: ${timeoutMessage} [${timeout}ms]`));
    }, timeout);
  });

const loadScript = fromPromise<void, { context: ThirdPartyAPIMachineContext }>(
  ({ input }): Promise<void> =>
    Promise.race([
      input.context.data.methods.loadScript &&
        (input.context.data.methods.loadScript as Function)(
          input.context.data.scriptLocation,
          input.context,
        ),
      timeoutScript(input.context.data.thirdParty),
    ]),
);

export const setupThirdPartyAPI = setup({
  types: {} as {
    context: ThirdPartyAPIMachineContext;
    input: {
      thirdPartyMethods: PartialPick<ThirdPartyAPIMachineContext['data'], 'thirdParty'>;
      bordeaux: ThirdPartyAPIMachineContext['bordeaux'];
    };
    output: APISetupResult;
  },
  guards: {
    [IF.notEnabled]: ({
      context: {
        data: { config },
      },
    }) => !config.enabled,
    [IF.noConsent]: ({ context: { consent } }) => !consent,
    [IF.noScript]: ({
      context: {
        data: { methods, scriptLocation },
      },
    }) => !scriptLocation || !methods.loadScript,
  },
  actions: {
    [DO.getConfig]: assign({
      data: ({ context }) => ({
        ...context.data,
        config: context.data.methods.getConfig
          ? (context.data.methods.getConfig as Function)(context)
          : context.bordeaux.thirdPartyApiConfig[context.data.thirdParty],
      }),
    }),
    [DO.getConsent]: assign({
      consent: ({
        context: {
          bordeaux,
          data: { config },
        },
      }) =>
        !('consentVendor' in config) ||
        config.consentVendor === undefined ||
        hasUserConsentedVendorGDPR(bordeaux.gdprConsent, config.consentVendor),
    }),
    [DO.getScriptLocation]: assign({
      data: ({ context }) => ({
        ...context.data,
        scriptLocation: context.data.methods.getScriptLocation
          ? (context.data.methods.getScriptLocation as Function)(context)
          : context.data.scriptLocation,
      }),
    }),
    [DO.markSuccess]: assign({
      success: true,
    }),
    [DO.sendRequestEvent]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartyRequestEvent => ({
        type: EMIT.request,
        data: thirdParty,
      }),
    ),
    [DO.sendSuccessEvent]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartySuccessEvent => ({
        type: EMIT.success,
        data: thirdParty,
      }),
    ),
    [DO.sendFailureEvent]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
        event,
      }): ThirdPartyFailureEvent | ThirdPartyTimeoutEvent => ({
        type: event.data instanceof TimeoutError ? EMIT.timeout : EMIT.failure,
        data: thirdParty,
      }),
    ),
  },
  actors: {
    [SPAWN.loadScript]: loadScript,
  },
}).createMachine({
  initial: GO.start,
  context: ({ input }) => ({
    data: {
      config: {},
      methods: {},
      ...input.thirdPartyMethods,
    } as ThirdPartyAPIMachineContext['data'],
    consent: false,
    scriptLocation: undefined,
    success: false,
    bordeaux: input.bordeaux,
  }),
  output: ({
    context: {
      data: { thirdParty, config, scriptLocation },
      consent,
      success,
    },
  }): APISetupResult => ({
    thirdParty,
    config,
    consent,
    scriptLocation,
    success,
  }),
  states: {
    [GO.start]: {
      entry: [DO.getConfig, DO.getConsent, DO.getScriptLocation],
      always: [
        {
          guard: IF.notEnabled,
          target: GO.done,
        },
        {
          guard: IF.noConsent,
          target: GO.done,
        },
        {
          guard: IF.noScript,
          actions: DO.markSuccess,
          target: GO.done,
        },
        {
          target: GO.load,
        },
      ],
    },
    [GO.load]: {
      entry: DO.sendRequestEvent,
      invoke: {
        id: TO.loadScript,
        src: SPAWN.loadScript,
        input: ({ context }) => ({ context }),
        onDone: {
          actions: [DO.sendSuccessEvent, DO.markSuccess],
          target: GO.done,
        },
        onError: {
          actions: [({ event }) => log.warn(event.error), DO.sendFailureEvent],
          target: GO.done,
        },
      },
    },
    [GO.done]: {
      type: 'final',
    },
  },
});
