import casalemedia from './bidders/casalemedia'; // 2
import triplelift from './bidders/triplelift'; // 14
import rubicon from './bidders/rubicon'; // 19
import openrtb from './bidders/openrtb'; // 22
import appnexus from './bidders/appnexus'; // 23, 26, 29, 33
import sovrn from './bidders/sovrn'; // 24
import pubmaticEmea from './bidders/pubmatic-emea'; // 46
import verizon from './bidders/verizon'; // 61
import mediagrid from './bidders/mediagrid'; // 62
import sharethrough from './bidders/sharethrough'; // 64
import openweb from './bidders/openweb'; // 65
import rise from './bidders/rise'; // 66
import { UserSyncConfig } from '@repo/shared-types';

const userSyncConfig: Array<UserSyncConfig> = [
  appnexus,
  openrtb,
  sovrn,
  triplelift,
  casalemedia,
  pubmaticEmea,
  rubicon,
  verizon,
  mediagrid,
  sharethrough,
  openweb,
  rise,
];
export default userSyncConfig;
