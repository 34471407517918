export enum Refresh_IF {
  userActiveRecently = 'REFRESH:GUARD:USER_ACTIVE_RECENTLY',
  featureEnabled = 'REFRESH:GUARD:FEATURE_ENABLED',
  featureDisabled = 'REFRESH:GUARD:FEATURE_DISABLED',
}

export enum Refresh_RECIEVE {
  setTakeoverStatus = 'REFRESH:EVENT_IN:SET_TAKEOVER_STATUS',
  setRefreshPaused = 'REFRESH:EVENT_IN:SET_REFRESH_PAUSED',
  setFeatureEnabled = 'REFRESH:EVENT_IN:SET_FEATURE_ENABLED',
}
export enum Refresh_ON {
  check = 'REFRESH:EVENT:CHECK',
}

export enum Refresh_SPAWN {
  userActivity = 'REFRESH:ACTOR:USER_ACTIVITY',
  waitInterval = 'REFRESH:ACTOR:WAIT_INTERVAL',
}
export enum Refresh_TO {
  userActivity = 'USER_ACTIVITY',
  waitInterval = 'WAIT_INTERVAL',
}

export enum Refresh_DO {
  ceateUserActivityMachine = 'REFRESH:ACTION:REATE_USER_ACTIVITY_MACHINE',
  set_documentVisible = 'REFRESH:ACTION:SET_DOCUMENT_VISIBLE',
  set_featureEnabled = 'REFRESH:ACTION:SET_FEATURE_ENABLED',
  set_mouseMoved = 'REFRESH:ACTION:SET_MOUSE_MOVED',
  set_scrolled = 'REFRESH:ACTION:SET_SCROLLED',
  set_takeoverStatus = 'REFRESH:ACTION:SET_TAKEOVER_STATUS',
  set_refreshPaused = 'REFRESH:ACTION:SET_REFRESH_PAUSED',
  triggerFeatureCheck = 'REFRESH:ACTION:TRIGGER_FEATURE_CHECK',
  triggerRefresh = 'REFRESH:ACTION:TRIGGER_REFRESH',
}

export enum Refresh_GO {
  setup = 'REFRESH:STATE:SETUP',
  wait = 'REFRESH:STATE:WAIT',
  update = 'REFRESH:STATE:UPDATE',
  stop = 'REFRESH:STATE:STOP',
}

export enum UserActivity_EMIT {
  documentVisibility = 'USER_ACTIVITY:EVENT_OUT:DOCUMENT_VISIBILITY',
  mouseMove = 'USER_ACTIVITY:EVENT_OUT:MOUSE_MOVE',
  scroll = 'USER_ACTIVITY:EVENT_OUT:SCROLL',
}
