import { SommelierResponse } from '@repo/shared-types';

import incrementalBillboardAdUnit from './ad-units/incremental-billboard';
import incrementalLeaderboardAdUnit from './ad-units/incremental-leaderboard';
import incrementalMPUAdUnit from './ad-units/incremental-mpu';
import leaderboardAdUnit from './ad-units/leaderboard';
import skinAdUnit from './ad-units/skin';
import overlayAdUnit from './ad-units/overlay';
import {
  leaderboardSlot,
  titleLeaderboardSlots,
  mediaLeaderboardSlots,
  staticLeaderboardSlots,
} from './slots/leaderboard';
import { sponsoredPostSlots, articleSponsoredPostSlots } from './slots/sponsored';
import { topOfSidebarSlot, beforePopularBoxSlot, afterPopularBoxSlot } from './slots/sidebar';
import imageMPUSlots from './slots/image-mpu';
import furnitureMPUSlots from './slots/furniture-mpu';
import beforeArticleCommentsMPUSlot from './slots/before-article-comment-mpu';
import articleCommentsMPUSlots from './slots/article-comments-mpu';
import paragraphMPUSlots from './slots/paragraph-mpu';
import commonConfig from '../common';

const generateFallback = (): SommelierResponse => {
  const fallbackConfig: SommelierResponse = {
    ...commonConfig,
    ...(commonConfig.placement
      ? {
          placement: {
            ...commonConfig.placement,
            id: 99997,
            slots: [
              leaderboardSlot,
              titleLeaderboardSlots,
              mediaLeaderboardSlots,
              staticLeaderboardSlots,
              beforeArticleCommentsMPUSlot,
              topOfSidebarSlot,
              beforePopularBoxSlot,
              afterPopularBoxSlot,
              imageMPUSlots,
              furnitureMPUSlots,
              articleCommentsMPUSlots,
              paragraphMPUSlots,
              sponsoredPostSlots,
              articleSponsoredPostSlots,
            ],
            adunits: [
              leaderboardAdUnit,
              overlayAdUnit,
              skinAdUnit,
              incrementalBillboardAdUnit,
              incrementalLeaderboardAdUnit,
              incrementalMPUAdUnit,
            ],
          },
        }
      : {}),
  };

  return fallbackConfig;
};

export default generateFallback;
