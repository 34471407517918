export enum AdManager_DO {
  configure = 'AD_MANAGER:ACTION:CONFIGURE',
  enable = 'AD_MANAGER:ACTION:ENABLE',
  listen = 'AD_MANAGER:ACTION:LISTEN',
  getGoogletag = 'AD_MANAGER:ACTION:GET_GOOGLETAG',
  destroyAds = 'AD_MANAGER:ACTION:DESTROY_ADS',
  createAd = 'AD_MANAGER:ACTION:CREATE_AD',
  addAuctionToStack = 'AD_MANAGER:ACTION:ADD_AUCTION_TO_STACK',
  setTargeting = 'AD_MANAGER:ACTION:SET_TARGETING',
  refreshAuction = 'AD_MANAGER:ACTION:REFRESH_AUCTION',
  markRefreshStart = 'AD_MANAGER:ACTION:MARK_REFRESH_START',
  markAuctionDone = 'AD_MANAGER:ACTION:MARK_AUCTION_DONE',
  logError = 'AD_MANAGER:ACTION:LOG_ERROR',
  incrementAuctionId = 'AD_MANAGER:ACTION:INCREMENT_AUCTION_ID',
  createNextAuction = 'AD_MANAGER:ACTION:CREATE_NEXT_AUCTION',
  reportAuctionStart = 'AD_MANAGER:ACTION:REPORT_AUCTION_START',
  markAdsInAuction = 'AD_MANAGER:ACTION:MARK_ADS_IN_AUCTION',
  reportAuctionCreated = 'AD_MANAGER:ACTION:REPORT_AUCTION_CREATED',
  popAuctionStack = 'AD_MANAGER:ACTION:POP_AUCTION_STACK',
}
export enum AdManager_GO {
  checkRequirements = 'AD_MANAGER:STATE:CHECK_REQUIREMENTS',
  configuration = 'AD_MANAGER:STATE:CONFIGURATION',
  error = 'AD_MANAGER:STATE:ERROR',
  waitForAuction = 'AD_MANAGER:STATE:WAIT_FOR_AUCTION',
  auction = 'AD_MANAGER:STATE:AUCTION',
}
export enum AdManager_IF {
  googletagExists = 'AD_MANAGER:GUARD:GOOGLETAG_EXISTS',
  auctionsInStack = 'AD_MANAGER:GUARD:AUCTIONS_IN_STACK',
  hasAds = 'AD_MANAGER:GUARD:HAS_ADS',
}
export enum AdManager_SPAWN {
  waitForGoogleTag = 'AD_MANAGER:ACTOR:WAIT_FOR_GOOGLE_TAG',
  googleTagListener = 'AD_MANAGER:ACTOR:GOOGLE_TAG_LISTENER',
}

export enum AdManager_TO {
  waitForGoogleTag = 'WAIT_FOR_GOOGLE_TAG',
  googleTagListener = 'GOOGLE_TAG_LISTENER',
}

export enum SpecialGPTOutputs {
  EMPTY = 'EMPTY_GPT_OUTPUT'
}