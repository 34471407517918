import { and, not, setup } from 'xstate';

import { cookies, accessObjectPath, getEnv, timeData } from '@repo/utils';

import {
  Ad,
  AdUnitCategory,
  AdUnitMode,
  AdUnitStatus,
  Slot,
  ON,
  FEATURE,
  AuctionPayload,
  Payload,
  DataObjectStore,
  BordeauxMachineContext,
  ProvideBordeauxContextEvent,
  ProvideBordeauxDataEvent,
  AnyBordeauxEvent,
  RequestBordeauxContextEvent,
  RequestBordeauxDataEvent,
  DO,
  IF,
  GO,
  SPAWN,
  METRICS,
  DO_REPORT,
  REPORT_THIRD_PARTY_SCRIPT,
  ThirdPartyReportEvent,
  FullAPISetupResults,
  AdFeatures_EMIT,
  ThirdPartyAPI_EMIT,
  API_EMIT,
  DO_RECORD,
  CMP_EMIT,
  GDPRConsentStatus,
  GPPConsentStatus,
  USPConsentStatus,
  Slotify_ON,
  AdManager_EMIT,
  FirstAuctionDoneEvent,
  BordeauxMachineDefinition,
  Slotify_EMIT,
  ScreenWatcher_EMIT,
  AdManager_RECIEVE,
  DestroySlotsEvent,
  TO,
  ToolRequestBordeauxContextEvent,
  ToolGetTargetingResponseEvent,
  Tool_EMIT,
  Tool_RECIEVE,
} from '@repo/shared-types';

import defaultFallbacks from 'config/fallback';

import userSyncPixels from '../user-sync/user-sync-config';
import actions from './actions';
import apiEvents from './api';
import raise from './proxy/raise';
import sendTo from './proxy/send-to';
import * as report from './report';
import { SommelierResponse } from 'config/sommelier-request/sommelier-response.types';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import forwardEventData from './proxy/forward-event-data';
import guards from './guards';
import actors from './actors';
import assign from './proxy/assign';
import screensPositioner from './actors/screens-positioner';
import { getNextScreen } from './screens';
import enqueueActions from './proxy/enqueueActions';
import getTargeting from 'ad-framework/targeting';

const bordeauxMachine: BordeauxMachineDefinition = setup({
  types: {} as {
    context: BordeauxMachineContext;
    events: AnyBordeauxEvent;
  },
  actors: {
    ...actors,
  },
  guards: {
    ...guards,
  },
  actions: {
    ...report.recordActions,
    ...report.reportActions,
    ...actions,
  },
}).createMachine({
  id: 'bordeaux',
  initial: GO.initialising,
  context: {
    branchName: process.env.BRANCH_NAME || 'undefined',
    config: {} as BordeauxConfig,
    fallbackResponses: defaultFallbacks,
    sommelierResponse: {} as SommelierResponse,
    slots: new DataObjectStore<Slot>(),
    ads: new DataObjectStore<Ad>(),
    adUnits: { incremental: [], standard: [] },
    anchoredRefreshDisabled: false,
    timing: {
      payload: {} as Payload,
      auctionPayloads: {} as Record<number, AuctionPayload>,
      endpoint: `https://eventsproxy.gargantuan.futureplc.com/future.adtech.bordeaux.v1.AdRequestCompletedEvent`,
      unsentAuctions: [],
      sampleRate: 1,
      enabled: false,
    },
    thirdPartyResults: {} as FullAPISetupResults,
    metrics: {
      [METRICS.THIRD_PARTY_SCRIPTS]: {},
      [METRICS.AUCTIONS]: {},
    },
    auctions: {},
    gdprConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      status: GDPRConsentStatus.NOT_APPLICABLE,
      hasEnoughConsentForAuction: false,
    },
    uspConsent: {
      done: false,
      askedForConsent: false,
      consent: null,
      ccpaApplies: false,
      status: USPConsentStatus.NOT_APPLICABLE,
    },
    gppConsent: {
      done: false,
      consent: null,
      status: GPPConsentStatus.NOT_APPLICABLE,
    },
    experimentId: '',
    hybridABTestTargeting: [],
    hybridId: '',
    gaSessionId: cookies.gaSessionId(),

    [TO.adManager]: {} as BordeauxMachineContext[TO.adManager],
    [TO.arbitraryEventEmitter]: {} as BordeauxMachineContext[TO.arbitraryEventEmitter],
    [TO.adFeatures]: {} as BordeauxMachineContext[TO.adFeatures],
    [TO.pageEventEmitter]: {} as BordeauxMachineContext[TO.pageEventEmitter],
    [TO.elementTapHandler]: {} as BordeauxMachineContext[TO.elementTapHandler],
    [TO.automaticRefresh]: {} as BordeauxMachineContext[TO.automaticRefresh],
    [TO.API]: {} as BordeauxMachineContext[TO.API],
    [TO.toolAPI]: {} as BordeauxMachineContext[TO.toolAPI],
    [TO.anchoredLogic]: {} as BordeauxMachineContext[TO.anchoredLogic],
    [TO.adPreProcessor]: {},
    [TO.screenSlotWatcher]: {},
    [TO.thirdPartyAPI]: {} as BordeauxMachineContext[TO.thirdPartyAPI],

    cftParameters: {
      labelName: 'UNDECIDED',
    } as BordeauxMachineContext['cftParameters'],
    pageParameters: {} as BordeauxMachineContext['pageParameters'],
    queryParameters: {} as BordeauxMachineContext['queryParameters'],
    pageStyleConstants: {} as BordeauxMachineContext['pageStyleConstants'],
    adBlocked: false,
    takeoverActive: false,
    takeoverIncrementals: [],
    loadGptExternallyPromise: ((): BordeauxMachineContext['loadGptExternallyPromise'] => {
      let resolve;
      const promise = new Promise<boolean>(res => {
        resolve = res;
      });
      return {
        promise,
        resolve,
      };
    })(),
    userSyncPixels,
    slotViewability: {},
    firstTandemAdPosition: Infinity,
    adToolSource: '',
    unDockedTool: null,
    dockedTool: null,
    screens: {},
    screensDefinition: {},
    currentScreen: 'none',
    adCounter: 0,
    adTypeCounters: {},
    adBatches: [],
    slotifyMode: false,
    firstAdsLoaded: false,
    adToolCommunicationKey: Math.random().toString(32),
    prebidAnalyticsData: [],

    // API
    overrideCompanionBounds: {},
    unrefreshableNames: [],
    unrefreshableLineItems: [],
    unrefreshableOrders: [],
    unrefreshableAdvertisers: [],
    unrefreshableStatuses: [AdUnitStatus.PENDING],
    unrefreshableModes: [AdUnitMode.OOP],
    unrefreshableCategories: [AdUnitCategory.SPONSORED_POST],
    adToolVersion: '',
    loadGptExternally: false,
    prebidAnalyticsEnabled: false,
    auctionTimeouts: undefined,
    automaticDynamic: true,
    pageCategory: undefined,
    pageTemplate: undefined,
    refreshTime: 0,
    takeoverIncrementalCaps: null,
    takeoverIncrementalChooser: null,
    activationDistance: 1200,
    deviceAvoidanceDistance: undefined,
    avoidanceDistance: 400,
    thirdPartyApiConfig: {} as BordeauxMachineContext['thirdPartyApiConfig'],
    thirdPartyApiConfigOverrides: {},
    pageTargeting: {},
    pageAdUnitPath: 'undecided',
    features: {
      [FEATURE.ADS_INCREMENTAL]: true,
      [FEATURE.ADS_STANDARD]: true,
      [FEATURE.ADS_REFRESH]: true,
    },
    featuresInitialised: false,
    liveIntentUserSyncEnabled: false,
    refreshPaused: false,
  },
  states: {
    [GO.initialising]: {
      entry: [DO.spawn_adFeatureMachine, DO.decide_timingEnabled, DO.spawn_pageEventEmitter],
      invoke: {
        id: TO.getEnvDOMContentLoaded,
        src: SPAWN.getEnvDOMContentLoaded,
        onDone: {
          actions: {
            type: DO.reportContentLoad,
            params: ({ event }) => event.output,
          },
          target: GO.scrapingParameters,
        },
        onError: GO.error,
      },
    },
    [GO.scrapingParameters]: {
      entry: [
        DO.checkAdBlock,
        DO.readQueryParameters,
        DO.readPageParameters,
        DO_RECORD.INITIALISE_PAYLOAD,
        DO.spawn_arbitraryEventEmitter,
        DO.assignIndexExchangeDeviceType,
        DO.assignBordeauxAdsPromise,
        DO.checkMultipleScripts,
        DO.decide_adToolSource,
        DO.spawn_adToolTapOpenMachine,
        DO.checkAdToolParam,
        DO.spawn_automaticRefreshMachine,
        DO.reportIfAdBlocked,
        DO.spawn_APIMachine,
      ],
      on: {
        [ON.setExternalAPI]: {
          actions: [
            ({ event }): void => {
              const env = getEnv();
              // Casting to any to allow us to attach to the env without types which would
              // cause circular reference warnings.
              (env as any).bordeaux = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
              (env as any).bdx = event.data; // eslint-disable-line @typescript-eslint/no-explicit-any
            },
            DO.updateAPIReady,
          ],
        },
        [API_EMIT.COMMANDS_RUN]: [
          {
            guard: ({
              event: {
                data: { initialised },
              },
            }) => initialised,
            target: GO.fetchingConfig,
          },
          {
            target: GO.waitForInitialisation,
          },
        ],
      },
    },
    [GO.waitForInitialisation]: {
      on: {
        [API_EMIT.INITIALISE]: {
          target: GO.fetchingConfig,
        },
      },
    },
    [GO.fetchingConfig]: {
      entry: [
        DO_REPORT.FRAMEWORK_REQUEST,
        DO.initialiseSentry,
        DO.initialiseFeatures,
        DO_REPORT.CONFIG_REQUEST,
      ],
      invoke: {
        id: TO.fetchConfig,
        src: SPAWN.fetchConfig,
        input: ({ context }) => ({
          pageTemplate: context.pageTemplate,
        }),
        onDone: {
          actions: [
            DO_REPORT.CONFIG_LOAD,
            {
              type: DO.set_sommelierResponse,
              params: ({ event }): SommelierResponse => event.output,
            },
          ],
          target: GO.checkingConfig,
        },
        onError: {
          actions: [
            { type: DO.reportConfigFailure, params: ({ event }) => event.error as Error },
            DO.useFallbackConfig,
          ],
          target: GO.parsingConfig,
        },
      },
    },
    [GO.checkingConfig]: {
      invoke: {
        id: TO.checkConfig,
        src: SPAWN.checkConfig,
        input: ({ context }) => ({ sommelierResponse: context.sommelierResponse }),
        onDone: GO.parsingConfig,
        onError: {
          actions: [DO_REPORT.CONFIG_EMPTY, DO.useFallbackConfig],
          target: GO.parsingConfig,
        },
      },
    },
    [GO.parsingConfig]: {
      invoke: {
        id: TO.parseConfig,
        src: SPAWN.parseConfig,
        input: ({ context }) => ({
          sommelierResponse: context.sommelierResponse,
        }),
        onDone: {
          actions: [
            { type: DO.set_config, params: ({ event }): BordeauxConfig => event.output },
            DO_REPORT.CONFIG_SUCCESS,
            DO.sendABTestToFreyr,
            DO.decide_siteActivationDistance,
            DO.decide_testActivationDistance,
            DO.decide_v2TestActivationDistance,
            DO.decide_avoidanceDistance,
            DO.decide_testPubx,
            DO.decide_doubleVerifyActive,
            DO.decide_testAdServiceActivation,
            DO.decide_enablePermutive,
            DO.decide_thirdPartyConfig,
            DO.decide_pageAdunitPath,
            DO.decide_validAdunits,
            DO.decide_takeoverIncrementals,
            DO.updateAPIConfig,
            DO.storeHybridTestSessions,
            DO.decideTestAuctionTimeouts,
          ],
          target: GO.retrievingConsent,
        },
        onError: {
          actions: [
            DO.useParsedFallbackConfig,
            { type: DO_REPORT.CONFIG_PARSE_FAILURE, params: ({ event }) => event.error as Error },
            DO.sendABTestToFreyr,
            DO.decide_siteActivationDistance,
            DO.decide_testActivationDistance,
            DO.decide_v2TestActivationDistance,
            DO.decide_avoidanceDistance,
            DO.decide_thirdPartyConfig,
            DO.decide_pageAdunitPath,
            DO.decide_validAdunits,
            DO.decide_takeoverIncrementals,
            DO.updateAPIConfig,
          ],
          target: GO.retrievingConsent,
        },
      },
    },
    [GO.retrievingConsent]: {
      entry: [DO.setup_customActivations, DO.decide_refreshTime, DO_REPORT.CONSENT_REQUEST],
      invoke: [
        {
          id: TO.getUSPConsent,
          src: SPAWN.getUSPConsent,
          onDone: {
            actions: [
              { type: DO.set_USPConsent, params: ({ event }) => event.output },
              {
                type: DO.updatePageTargeting,
                params: ({ event }) => ({
                  _usp_status: event.output.status,
                }),
              },
              DO.raise_consentDone,
            ],
          },
          onError: {
            target: GO.error,
          },
        },
        {
          id: TO.getGDPRConsent,
          src: SPAWN.getGDPRConsent,
          onDone: {
            actions: [
              DO_REPORT.CONSENT_SUCCESS,
              { type: DO.set_GDPRConsent, params: ({ event }) => event.output },
              {
                type: DO.updatePageTargeting,
                params: ({ event }) => ({
                  _gdpr_status: event.output.status,
                }),
              },
              DO.raise_consentDone,
            ],
          },
          onError: {
            actions: DO_REPORT.CONSENT_FAILURE,
            target: GO.error,
          },
        },
        {
          id: TO.getGPPConsent,
          src: SPAWN.getGPPConsent,
          onDone: {
            actions: [
              { type: DO.set_GPPConsent, params: ({ event }) => event.output },
              {
                type: DO.updatePageTargeting,
                params: ({ event }) => ({
                  _gpp_status: event.output.status,
                }),
              },
              DO.raise_consentDone,
            ],
          },
          onError: {
            target: GO.error,
          },
        },
      ],
      on: {
        [CMP_EMIT.pending]: {
          actions: DO_REPORT.CONSENT_PENDING,
        },
        [CMP_EMIT.loaded]: {
          actions: DO_REPORT.CONSENT_LOADED,
        },
        [CMP_EMIT.mocked]: {
          actions: DO_REPORT.CONSENT_MOCKED,
        },
        [ON.consentDone]: {
          guard: IF.consentDone,
          target: GO.loadingThirdParties,
        },
      },
    },
    [GO.loadingThirdParties]: {
      entry: [
        DO_REPORT.THIRD_PARTY_REQUEST,
        DO.decide_liveIntentUserSync,
        DO.spawn_thirdPartyMachines,
      ],
      invoke: {
        id: TO.waitForAllThirdParties,
        src: SPAWN.waitForAllThirdParties,
        input: ({ context }) => ({ thirdPartyMachines: context[TO.thirdPartyAPI] }),
        onDone: {
          actions: [
            DO_REPORT.THIRD_PARTY_SUCCESS,
            { type: DO.set_thirdPartyResults, params: ({ event }) => event.output },
            DO.assignLiveIntentUserSyncTargeting,
            DO.thirdPartiesReady,
          ],
          target: GO.decidingPageStyleConstants,
        },
        onError: GO.error,
      },
      on: {
        [ThirdPartyAPI_EMIT.request]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.REQUEST,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyAPI_EMIT.success]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.SUCCESS,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyAPI_EMIT.timeout]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.TIMEOUT,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
        [ThirdPartyAPI_EMIT.failure]: {
          actions: raise(
            ({ event }): ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE> => ({
              type: REPORT_THIRD_PARTY_SCRIPT.FAILURE,
              data: {
                thirdParty: event.data,
                time: timeData(),
              },
            }),
          ),
        },
      },
    },
    [GO.decidingPageStyleConstants]: {
      invoke: {
        id: TO.readPageStyles,
        src: SPAWN.readPageStyles,
        onDone: {
          actions: {
            type: DO.set_pageStyleConstants,
            params: ({ event }) => event.output,
          },
          target: GO.decidingMode,
        },
      },
    },
    [GO.decidingMode]: {
      always: [
        {
          guard: IF.shouldUseScreenMode,
          target: GO.handlingScreens,
        },
        {
          actions: assign({
            slotifyMode: true,
          }),
          target: GO.handlingSlots,
        },
      ],
    },

    [GO.handlingSlots]: {
      entry: [
        DO.setup_userSync,
        DO.decide_hybridABTestTargeting,
        DO.spawn_anchoredLogic,
        DO.spawn_adManager,
      ],
      invoke: {
        id: TO.slotify,
        src: SPAWN.slotify,
        input: ({ context }) => context,
        onError: GO.error,
      },
    },

    [GO.handlingScreens]: {
      entry: [
        DO.setup_userSync,
        DO.decide_hybridABTestTargeting,
        DO.spawn_anchoredLogic,
        DO.spawn_adManager,
        DO.decide_screens,
        DO.replaceScreenSlotConstants,
        DO.generateScreens,
        DO.registerScreenSlots,
      ],
      invoke: {
        src: screensPositioner,
        input: ({ context: { screens } }) => ({ screens }),
      },
      on: {
        [ON.screensPositioned]: {
          actions: DO.spawn_screenSlotWatchers,
          target: GO.waitForNextBatch,
        },
      },
    },
    [GO.waitForNextBatch]: {
      always: {
        guard: IF.batchesInStack,
        target: GO.loadAdBatch,
      },
    },
    [GO.loadAdBatch]: {
      invoke: {
        id: TO.insertAds,
        src: SPAWN.insertAds,
        input: ({
          context: {
            adBatches: [{ matches }],
          },
        }) => ({
          matches,
        }),
        onDone: {
          actions: [
            {
              type: DO.adManagerAuction,
              params: ({
                context: {
                  adBatches: [{ matches }],
                },
              }) => matches.map(({ ad }) => ad),
            },
            DO.reportFirstAdLoad,
            assign({
              firstAdsLoaded: true,
            }),
            DO.popCurrentBatch,
          ],
          target: GO.waitForNextBatch,
        },
      },
    },

    [GO.error]: {
      entry: [(data): void => console.error(`Main machine error`, data), DO.handleError],
    },
  },
  on: {
    ...report.reportEvents,
    ...apiEvents,
    [AdFeatures_EMIT.fullWidth]: {
      actions: forwardEventData(DO.adFeature_fullWidth),
    },
    [AdFeatures_EMIT.popOut]: {
      actions: forwardEventData(DO.adFeature_popOut),
    },
    [AdFeatures_EMIT.multiFrame]: {
      actions: forwardEventData(DO.adFeature_multiFrame),
    },
    [AdFeatures_EMIT.expandHeight]: {
      actions: forwardEventData(DO.adFeature_expandHeight),
    },
    [AdFeatures_EMIT.closeButton]: {
      actions: forwardEventData(DO.adFeature_closeButton),
    },
    [ON.setHybridABTestTargeting]: {
      actions: [
        forwardEventData(DO.set_hybridABTestTargeting),
        forwardEventData(DO_RECORD.HYBRID_ABTEST_TARGETING),
      ],
    },
    [ON.setHybridId]: {
      actions: [
        forwardEventData(DO.set_hybridId),
        forwardEventData(DO_RECORD.HYBRID_ID),
        DO.updateAPIHybridID,
        DO.sendUserIdsToFreyr,
      ],
    },
    [ON.setCFTParameters]: {
      actions: [forwardEventData(DO.set_CFTParameters), forwardEventData(DO_RECORD.CFT_PARAMETERS)],
    },
    [ON.pageUnload]: {
      guard: IF.timingCollectionEnabled,
      actions: [forwardEventData(DO.reportPageUnload), DO.closeUndockedTool],
    },
    [ON.pageLoad]: {
      actions: forwardEventData(DO.reportPageLoad),
    },
    [ON.openAdTool]: {
      actions: [
        assign({
          [TO.toolAPI]: ({ context: { adToolCommunicationKey }, spawn }) =>
            spawn(SPAWN.toolAPI, {
              id: TO.toolAPI,
              input: {
                adToolCommunicationKey,
              },
            }),
        }),
        DO.openDockedTool,
      ],
    },
    [ON.automaticRefresh]: [
      {
        guard: {
          type: IF.hasAds,
          params: ({ event: { data: ads } }) => ads,
        },
        actions: [
          {
            type: DO.resetAds,
            params: ({ event: { data: ads } }) => ({ ads }),
          },
          {
            type: DO.refreshAds,
            params: ({ event: { data: ads } }) => ({ ads }),
          },
        ],
      },
    ],
    [ON.triggerAutomaticRefresh]: {
      actions: [DO.updateAdsViewedTime, DO.raise_automaticRefresh],
    },

    [ON.requestBordeauxData]: {
      actions: sendTo<BordeauxMachineContext[TO.API], RequestBordeauxDataEvent>(
        TO.API,
        ({ context, event }): ProvideBordeauxDataEvent => ({
          type: ON.provideBordeauxData,
          data: {
            value: accessObjectPath(context, event.data.property),
            requestId: event.data.requestId,
          },
        }),
      ),
    },

    [ON.requestBordeauxContext]: {
      actions: sendTo<BordeauxMachineContext[TO.API], RequestBordeauxContextEvent>(
        TO.API,
        ({ context, event }): ProvideBordeauxContextEvent => ({
          type: ON.provideBordeauxContext,
          data: {
            value: context,
            requestId: event.data.requestId,
          },
        }),
      ),
    },

    [ON.toolRequestBordeauxContext]: {
      actions: sendTo<BordeauxMachineContext[TO.toolAPI], ToolRequestBordeauxContextEvent>(
        TO.toolAPI,
        ({ context, event }): ProvideBordeauxContextEvent => ({
          type: ON.provideBordeauxContext,
          data: {
            value: context,
            requestId: event.data.requestId,
          },
        }),
      ),
    },
    [Tool_EMIT.getTargeting]: {
      actions: [
        sendTo<BordeauxMachineContext[TO.toolAPI]>(
          TO.toolAPI,
          ({ context, event }): ToolGetTargetingResponseEvent => ({
            type: Tool_RECIEVE.getTargeting,
            data: {
              return: getTargeting(context),
              requestId: event.data.requestId,
            },
          }),
        ),
      ],
    },
    [Tool_EMIT.setPosition]: [
      {
        guard: ({ context: { dockedTool }, event: { data } }) =>
          Boolean(dockedTool && data === 'RIGHT'),
        actions: ({ context: { dockedTool } }) => {
          if (!dockedTool) return;
          dockedTool.style.right = '0px';
          dockedTool.style.left = 'auto';
        },
      },
      {
        guard: ({ context: { dockedTool }, event: { data } }) =>
          Boolean(dockedTool && data === 'LEFT'),
        actions: ({ context: { dockedTool } }) => {
          if (!dockedTool) return;
          dockedTool.style.left = '0px';
          dockedTool.style.right = 'auto';
        },
      },
    ],
    [Tool_EMIT.setMinimised]: [
      {
        guard: ({ context: { dockedTool }, event: { data } }) => Boolean(dockedTool && data),
        actions: ({ context: { dockedTool } }) => {
          if (!dockedTool) return;
          dockedTool.style.width = '100px';
          dockedTool.style.height = '100px';
          dockedTool.style.boxShadow = '';
        },
      },
      {
        guard: ({ context: { dockedTool }, event: { data } }) => Boolean(dockedTool && !data),
        actions: ({ context: { dockedTool } }) => {
          if (!dockedTool) return;
          dockedTool.style.width = '100vw';
          dockedTool.style.height = '100%';
          dockedTool.style.boxShadow = `
            0 1px 1px rgba(0,0,0,0.11),
            0 2px 2px rgba(0,0,0,0.11),
            0 4px 4px rgba(0,0,0,0.11),
            0 6px 8px rgba(0,0,0,0.11),
            0 8px 16px rgba(0,0,0,0.11)`;
        },
      },
    ],
    [Tool_EMIT.scrollToSlot]: {
      actions: ({
        context: { slots },
        event: {
          data: { slot },
        },
      }) => {
        const slotObj = slots.getValues().find(slotObj => slotObj.getProperty('id') === slot);
        if (!slotObj) return;
        slotObj.getProperty('hookElement').scrollIntoView();
      },
    },
    [Tool_EMIT.scrollToElement]: {
      actions: ({
        event: {
          data: { selector },
        },
      }) => {
        const env = getEnv();
        env.document.querySelector(selector)?.scrollIntoView();
      },
    },
    [ON.hideAnchoredAds]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [DO.hideAnchored, DO.enableAnchoredRefresh],
      },
      {
        actions: [DO.hideAnchored],
      },
    ],
    [ON.showAnchoredAds]: [
      {
        guard: ({ event }) => Boolean(event.data?.infiniteScrollIntersection),
        actions: [DO.showAnchored, DO.disableAnchoredRefresh],
      },
      {
        actions: [DO.showAnchored],
      },
    ],
    [AdManager_EMIT.adVisibilityChanged]: {
      actions: forwardEventData(DO.updateAdViewability),
    },
    [AdManager_EMIT.adOnLoad]: {
      actions: [forwardEventData(DO.reportAdLoad), forwardEventData(DO.raise_reportAdLoad)],
    },
    [AdManager_EMIT.adImpressionViewable]: {
      actions: forwardEventData(DO.markAdViewed),
    },
    [AdManager_EMIT.adRenderEnded]: {
      actions: [forwardEventData(DO.finaliseAd)],
    },
    [ON.requestAuction]: {
      actions: {
        type: DO.adManagerAuction,
        params: ({
          event: {
            data: { ads },
          },
        }) => ads,
      },
    },
    [AdManager_EMIT.auctionCreated]: [
      {
        guard: IF.hasEnoughConsentForAuction,
        actions: [
          DO.updateAdManagerTargeting,
          {
            type: DO.spawn_auctionPreProcessor,
            params: ({
              event: {
                data: { auctionId },
              },
            }) => auctionId,
          },
        ],
      },
      {
        actions: [DO.updateAdManagerTargeting, forwardEventData(DO.raise_auctionEnd)],
      },
    ],
    [AdManager_EMIT.auctionLoaded]: [
      {
        guard: and([
          ({
            event: {
              data: { auctionId },
            },
          }) => auctionId === 1,
          ({ context: { slotifyMode } }) => slotifyMode,
        ]),
        actions: [
          DO.set_takeover,
          DO.customVideoBehaviour,
          DO.updateRefreshTakeoverStatus,
          DO.updateSlotifyTakeoverStatus,
          forwardEventData(DO.updateAPITakeoverStatus),

          sendTo(TO.slotify, {
            type: Slotify_ON.firstAuctionDone,
          } as FirstAuctionDoneEvent),
        ],
      },
      {
        guard: ({
          event: {
            data: { auctionId },
          },
        }) => auctionId === 1,
        actions: [
          DO.set_takeover,
          DO.customVideoBehaviour,
          DO.updateRefreshTakeoverStatus,
          forwardEventData(DO.updateAPITakeoverStatus),
        ],
      },
    ],
    [ON.auctionProcessed]: {
      actions: [
        forwardEventData(DO.raise_auctionEnd),
        forwardEventData(DO.raise_adManagerAuctionProcessed),
      ],
    },

    [ON.hideBigtopAds]: {
      actions: { type: DO.changeBigtopDisplay, params: { allowPopout: false } },
    },
    [ON.showBigtopAds]: {
      actions: { type: DO.changeBigtopDisplay, params: { allowPopout: true } },
    },
    [ON.checkBigtopAllowed]: [
      {
        guard: IF.scrolledPastFirstTandem,
        actions: raise({ type: ON.hideBigtopAds }),
      },
      {
        actions: raise({ type: ON.showBigtopAds }),
      },
    ],

    [Slotify_EMIT.slotPositioned]: {
      guard: forwardEventData(IF.slotIsBeforeFirstTandem),
      actions: [
        assign({
          firstTandemAdPosition: ({
            event: {
              data: { position },
            },
          }) => position.y,
        }),
        raise({
          type: ON.checkBigtopAllowed,
        }),
      ],
    },
    [Slotify_EMIT.slotViewabilityChanged]: {
      actions: raise({
        type: ON.checkBigtopAllowed,
      }),
    },
    [ON.destroyScreen]: {
      actions: [
        forwardEventData(DO.markScreenDestroyed),
        sendTo(
          TO.adManager,
          ({
            context: { screens },
            event: {
              data: { screenId },
            },
          }) =>
            ({
              type: AdManager_RECIEVE.destroyAds,
              data: {
                advertIds: screens[screenId].matches.map(({ slot }) => slot.getProperty('adID')),
              },
            }) as DestroySlotsEvent,
        ),
        forwardEventData(DO.destroyScreen),
      ],
    },
    [ON.updateScreens]: [
      {
        guard: and([IF.hasNextScreen, not(IF.otherScreenInView)]),
        actions: [
          assign({ currentScreen: ({ context }) => getNextScreen(context)!.id }),
          enqueueActions(({ context: { screens, currentScreen }, enqueue }) => {
            // Destroy any other satisfied screens
            const toDestroy = Object.values(screens).find(
              ({ satisfied, id }) => satisfied && id !== currentScreen,
            );
            if (toDestroy) {
              enqueue.raise({
                type: ON.destroyScreen,
                data: { screenId: toDestroy.id },
              });
            }
          }),
          {
            type: DO.createScreenBatch,
            params: ({ context: { currentScreen } }) => ({ screenId: currentScreen }),
          },
          DO.markCurrentScreenSatisfied,
          assign({ firstAdsLoaded: true }),
          {
            type: DO.countAdBatch,
            params: ({ context: { adBatches } }) => adBatches[adBatches.length - 1],
          },
          {
            type: DO.registerAdBatch,
            params: ({ context: { adBatches } }) => adBatches[adBatches.length - 1],
          },
        ],
      },
    ],
    [ScreenWatcher_EMIT.viewabilityChanged]: [
      {
        guard: forwardEventData(IF.screenlessSlotNeedsLoading),
        actions: [
          forwardEventData(DO.createSoloSlotBatch),
          {
            type: DO.countAdBatch,
            params: ({ context: { adBatches } }) => adBatches[adBatches.length - 1],
          },
          {
            type: DO.registerAdBatch,
            params: ({ context: { adBatches } }) => adBatches[adBatches.length - 1],
          },
        ],
      },
      {
        guard: forwardEventData(IF.slotHasScreen),
        actions: [
          forwardEventData(DO.updateSlotViewability),
          {
            type: DO.updateScreenViewability,
            params: ({
              event: {
                data: { slot },
              },
            }) => ({ screenId: slot.getProperty('screen') }),
          },
          DO.markUnsatisfiableScreens,
          raise({
            type: ON.updateScreens,
          }),
        ],
      },
    ],
    [ON.set_adToolPage]: {
      guard: ({
        context: {
          queryParameters: { debugTool },
        },
        event: {
          data: { page },
        },
      }) => page !== debugTool,
      actions: [
        assign({
          queryParameters: ({
            context: { queryParameters },
            event: {
              data: { page },
            },
          }) => ({
            ...queryParameters,
            debugTool: page,
          }),
        }),
        DO.updateAdToolPage,
      ],
    },
  },
});

export default bordeauxMachine;
