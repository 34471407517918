import { SlotPosition } from '@repo/shared-types';

export default (element: HTMLElement, position: SlotPosition, hookElement: HTMLElement): void => {
  if (hookElement === undefined || hookElement === null) {
    throw new Error('Error in add slot handler, slot hook is undefined');
  }
  if (element === null || element === undefined) {
    throw new Error('Error in add slot handler, element is undefined');
  }

  switch (position) {
    case SlotPosition.BEFORE:
      if (hookElement.parentNode === null) throw new Error(`No parent node of hook.`);
      hookElement.parentNode.insertBefore(element, hookElement);
      return;
    case SlotPosition.AFTER:
      if (hookElement.parentNode === null) throw new Error(`No parent node of hook.`);
      hookElement.parentNode.insertBefore(element, hookElement.nextSibling);
      return;
    case SlotPosition.PREPEND:
      hookElement.insertBefore(element, hookElement.firstElementChild);
      return;
    case SlotPosition.APPEND:
    default:
      hookElement.appendChild(element);
  }
};
