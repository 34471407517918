import { SlotLabel, SlotLabelPosition } from '@repo/shared-types';
import { createElement, stringToStyle } from '@repo/utils';

export const LABEL_TEXT = 'Advertisement';
export const LABEL_STYLE = `font-size: 12px; width: 100%; height: 30px; text-align: center; line-height: 30px; z-index: 1; background-color: #EDEDED; position: relative;`;
export const LABEL_IDENTIFIER = '-label';

export const createSlotLabel = (id: string, label: SlotLabel): HTMLElement => {
  return createElement('div', {
    style: {
      ...stringToStyle(label?.style || LABEL_STYLE),
      ...(label.position === SlotLabelPosition.BELOW
        ? {
            zIndex: '0',
          }
        : {}),
    },
    id: `${id}${LABEL_IDENTIFIER}`,
    textContent: label.text || LABEL_TEXT,
  });
};
export const createAdLabel = (id: string, label: SlotLabel): HTMLElement => {
  return createElement(
    'div',
    {
      id: `${id}${LABEL_IDENTIFIER}`,
      style: stringToStyle(label.style || LABEL_STYLE),
    },
    label.text || LABEL_TEXT,
  );
};
