import { SommelierResponseAdUnit } from '@repo/shared-types';

export default {
  type: 'incremental',
  name: `incr-mpu`,
  sizes: [
    { w: 300, h: 250 },
    { w: 300, h: 600 },
  ],
  targeting: {
    pos: ['2'],
    placement: [`dfp_rs_tablet_mpu`],
    format: ['takeover'],
  },
  properties: {
    ignoreAvoidance: 'true',
    refresh: 'true',
  },
} as SommelierResponseAdUnit;
