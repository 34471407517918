import { getEnv } from '@repo/utils';
import { DeviceOptions, MessageEvent } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default async (service: ApiMachineEventService): Promise<boolean> => {
  const device = await service.getData<DeviceOptions>('pageParameters', 'device');
  if (device !== DeviceOptions.MOBILE) {
    return false;
  }
  return new Promise<boolean>(resolve => {
    const env = getEnv();
    env.addEventListener('message', ({ data }: MessageEvent) => {
      const bigTopVisible =
        typeof data === 'object' && Object.values(data).includes('big_top_visible');
      const bigTopLoaded = data === 'mobile-bigtop-loaded';

      if (bigTopVisible || bigTopLoaded) {
        resolve(true);
      }
    });
  });
};
