import { fromCallback } from 'xstate';
import { getEnvLoad, getEnvUnload } from '@repo/utils';
import { ON } from '@repo/shared-types';

const pageEventEmitter = fromCallback(({ sendBack }) => {
  getEnvUnload().then(timeData => {
    sendBack({
      type: ON.pageUnload,
      data: timeData,
    });
  });

  getEnvLoad().then(timeData => {
    sendBack({
      type: ON.pageLoad,
      data: timeData,
    });
  });
});
export default pageEventEmitter;
