import { BordeauxMachineContext } from '@repo/shared-types';
import fastdom from './fastdom';

export const measureAdDensity = async (context: BordeauxMachineContext): Promise<number | void> => {
  const article = document.getElementById('article-body');
  if(article) {
    return fastdom.measure(() => {
      const articleHeight = article.getBoundingClientRect().height;
      const adsHeight = context.ads.getValues().reduce((acc, ad) => {
        if(ad.getProperty('countTowardsAdsDensity')) {
         return acc + ad.getProperty('height');
        }

        return acc;
      }, 0)

      return adsHeight / articleHeight;
    })
  }
}