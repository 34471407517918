import { FallbackAdServerResponses } from './index.types';
import desktop from './desktop';
import tablet from './tablet';
import mobile from './mobile';

const fallbackRespones: FallbackAdServerResponses = {
  desktop,
  tablet,
  mobile,
};

export default fallbackRespones;
