import getEnv from '../env';
import findParam from '../find-param';
import { BordeauxMachineContext } from '@repo/shared-types';
import { log } from '../log';

const env = getEnv();
const fallback = 'GB';

const getCookie = (): null | string => {
  try {
    return findParam('FTR_Country_Code', env.document.cookie, ';');
  } catch (error) {
    log.warn('Bordeaux country lookup failed, could not find FTR_Country_Code cookie', error);
    return null;
  }
};

export default (context: Pick<BordeauxMachineContext, 'queryParameters'>): string =>
  (context.queryParameters.country ?? getCookie() ?? fallback).toUpperCase();
