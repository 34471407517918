import { z } from 'zod';

const sizeList = z.array(z.tuple([z.number(), z.number()]));

export const prebidConfigListSchema = <T extends z.ZodTypeAny>(
  paramsSchema: T,
): z.ZodArray<
  z.ZodObject<{
    sizes: z.ZodUnion<[z.ZodArray<z.ZodTuple<[z.ZodNumber, z.ZodNumber]>>, z.ZodLiteral<'all'>]>;
    params: T;
  }>
> =>
  z.array(
    z.object({
      sizes: sizeList.or(z.literal('all')),
      params: paramsSchema,
    }),
  );

export const prebidBiddersSchema = z.record(prebidConfigListSchema(z.record(z.unknown())));
