import { ThirdPartyAPIMachineConfigs, ThirdParty } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { PartialPick } from '@repo/utils';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.PUBX], 'thirdParty'> = {
  thirdParty: ThirdParty.PUBX,
  methods: {
    getScriptLocation: () => `https://cdn.pbxai.com/776eda21-6ffa-450f-bac1-7ccbb2bff5c8.js`,
    loadScript: scriptLocation => loadScript(scriptLocation, 'pubx-script', { async: true }),
  },
};
export default config;
