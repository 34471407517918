import { log } from '@repo/utils/src/log';
import { z } from 'zod';
import { AdDefinition, AdUnitCategory, AdUnitMode, AdUnitSizeTuple, Rules, FLUID_SIZE, nativeCategories } from '../../../index';
import { adUnitSchema } from './zod-schema';

const defaultSize: AdUnitSizeTuple = [0, 0];
const largestWidth = (previous: AdUnitSizeTuple, current: AdUnitSizeTuple): AdUnitSizeTuple =>
  current[0] > previous[0] ? current : previous;
const largestHeight = (previous: AdUnitSizeTuple, current: AdUnitSizeTuple): AdUnitSizeTuple =>
  current[1] > previous[1] ? current : previous;

const adaptAdUnitConfig = (adUnit: z.output<typeof adUnitSchema>): AdDefinition => {
  let parsedAvoidanceRules: Rules = {};
  if (adUnit.properties.rules) {
    try {
      parsedAvoidanceRules = JSON.parse(adUnit.properties.rules);
    } catch (_error) {
      log.error(`Invalid JSON structure for rules`);
    }
  }
  return {
    name: adUnit.name,
    mode:
      adUnit.type === 'standard' ||
      adUnit.type === 'incremental' ||
      adUnit.type === 'native' ||
      adUnit.type === 'sponsored'
        ? AdUnitMode.SLOTIFY
        : adUnit.type,
    incremental: adUnit.type === 'incremental',
    width: adUnit.sizes.reduce(largestWidth, defaultSize)[0],
    height: adUnit.sizes.reduce(largestHeight, defaultSize)[1],
    lazyload: adUnit.lazyload,
    affinitySlotID: adUnit.slotAffinity,
    sizes: [
      ...adUnit.sizes,
      ...(adUnit.properties.addFluidSize ? [FLUID_SIZE as typeof FLUID_SIZE] : []),
    ],
    targeting:
      adUnit.type !== 'incremental'
        ? {
            ...adUnit.targeting,
            adUnitName: [adUnit.name],
          }
        : adUnit.targeting,

    category: adUnit.properties.category,
    position: adUnit.properties.position,
    refreshDisabled: adUnit.properties.refreshDisable,
    verticallyCentered: adUnit.properties.centerVertically,
    topPosition: adUnit.properties.topPosition,
    refresh: adUnit.properties.refresh,
    fluid: adUnit.properties.fluid,
    ignoreCategoryAvoidance: adUnit.properties.ignoreAvoidance,
    closeButton: adUnit.properties.closeButton,
    inTakeover: adUnit.properties.inTakeover || adUnit.properties.inRoadblock,
    takeoverIncremental: adUnit.properties.takeoverIncremental || adUnit.properties.roadblockIncremental,
    refreshDisabledSizes: adUnit.properties.refreshDisabledSizes,
    enabledCountries: adUnit.properties.enabledCountries,
    disabledCountries: adUnit.properties.disabledCountries,
    categoryAvoidanceRules: parsedAvoidanceRules,
    requestOrder: adUnit.requestOrder,
    nativeContent: Boolean(
      adUnit.properties.category && nativeCategories.includes(adUnit.properties.category),
    ),

    ...(adUnit.type === 'sponsored'
      ? {
          category: AdUnitCategory.SPONSORED_POST,
          fluid: true,
        }
      : {}),
  };
};
export default adaptAdUnitConfig;
