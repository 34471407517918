import prom from '@future/promjs/lib';

import { PrometheusAPI } from '@repo/shared-types';
import { getEnv } from '@repo/utils';

import { PROMETHEUS_PUSH_INTERVAL } from '../constants';
import {
  recordFrameworkRequest,
  handleUnload,
  recordAds,
  recordConfigLoad,
  recordThirdPartiesLoad,
  recordAdManagerError,
  recordLoadGptSlotError,
  recordGamApiFetchError,
  recordSlotInsertionError,
  recordInputErrorBordeauxApi,
  recordLongTask,
} from './promApi';
import { pushMetrics } from './pushMetrics';
import { isMetricsEnabled } from './isMetricsEnabled';
import { setupMetrics } from './setupMetrics';
import { PrometheusController } from './PrometheusController';

export default (): PrometheusAPI => {
  if (isMetricsEnabled()) {
    PrometheusController.update({ metricsEnabled: true });
    const env = getEnv();

    const registry = prom();
    const {
      metricLongTasksHistogram,
      metricRequestCount,
      metricAdsCount,
      metricLoadCount,
      metricAdManagerErrorCount,
      metricSlotInsertionErrorCount,
      metricGamApiFetchErrorCount,
      metricInputErrorBordeauxApiCount,
      metricLoadGptSlotErrorCount,
      metricUnloadCount,
    } = setupMetrics(registry);

    env.setInterval(pushMetrics(registry), PROMETHEUS_PUSH_INTERVAL);
    env.addEventListener('pagehide', handleUnload(metricUnloadCount, registry));
    // NOTE: visibilityState changing to 'hidden' is not handled correctly by Safari
    env.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        pushMetrics(registry)();
      }
    });

    // Observe long tasks created by bordeaux
    if (env.PerformanceObserver) {
      const observer = new PerformanceObserver(recordLongTask(metricLongTasksHistogram));
      observer.observe({ entryTypes: ['long-animation-frame'] });
    }

    return {
      recordFrameworkRequest: recordFrameworkRequest(metricRequestCount),
      recordAds: recordAds(metricAdsCount),
      recordConfigLoad: recordConfigLoad(metricLoadCount),
      recordThirdPartiesLoad: recordThirdPartiesLoad(metricLoadCount),
      recordAdManagerError: recordAdManagerError(metricAdManagerErrorCount),
      recordGamApiFetchError: recordGamApiFetchError(metricGamApiFetchErrorCount),
      recordSlotInsertionError: recordSlotInsertionError(metricSlotInsertionErrorCount),
      recordLoadGptSlotError: recordLoadGptSlotError(metricLoadGptSlotErrorCount),
      recordInputErrorBordeauxApi: recordInputErrorBordeauxApi(metricInputErrorBordeauxApiCount),
    };
  }

  // Return empty functions for sessions without metrics
  return {
    recordFrameworkRequest: (): void => {},
    recordAds: (): void => {},
    recordConfigLoad: (): void => {},
    recordThirdPartiesLoad: (): void => {},
    recordAdManagerError: (): void => {},
    recordGamApiFetchError: (): void => {},
    recordSlotInsertionError: (): void => {},
    recordLoadGptSlotError: (): void => {},
    recordInputErrorBordeauxApi: (): void => {},
  };
};
