import {
  Slot,
  FEATURE,
  DataObjectType,
  ON as BDX_EVENTS,
  Slotify_DO,
  AD_AFFINITY_FAILED_REASONS,
  Slotify_ON,
  Slotify_RECIEVE,
  SlotifyActions,
  Slotify_SPAWN,
  AdditionalAvoidance,
  Slotify_TO,
} from '@repo/shared-types';
import { log, querySelectorAll } from '@repo/utils';
import { sendParent } from 'xstate';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import { watchExclusionZones } from '../exclusion';
import { adsLoaded } from 'state/report';
import { reorderAds } from './reorderAds';
import assign, { assignParams } from '../proxy/assign';
import raise from '../proxy/raise';
import sendTo from '../proxy/send-to';

const addAdditionalWatchers = (slot: DataObjectType<Slot>): void => {
  const additionalAvoidance = slot.getProperty('additionalAvoidance');

  additionalAvoidance.forEach((additionalAvoidanceConfig: AdditionalAvoidance) => {
    const elementsToAvoid = querySelectorAll<HTMLElement>(additionalAvoidanceConfig.hook);
    elementsToAvoid.forEach(element => {
      additionalAvoidanceConfig.elements.push(element);
    });
  });
};

const slotifyActions: SlotifyActions = {
  [Slotify_DO.reportAdAffinityFailed]: (
    {
      context: {
        config: {
          placement: {
            slots: { static: slotDefinitions },
          },
        },
      },
    },
    { adDefinition, reason },
  ) => {
    switch (reason) {
      case AD_AFFINITY_FAILED_REASONS.ABSENT:
        log.warn(`Slotify standard handling error - Affinity undefined for ${adDefinition.name}`);
        break;
      case AD_AFFINITY_FAILED_REASONS.NO_SLOT: {
        const slotDefinition = slotDefinitions.find(
          searchSlot => searchSlot.name === adDefinition.affinitySlotID,
        );
        if (slotDefinition) {
          if (!slotDefinition.ignoreErrors) {
            log.warn(
              `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but the slot could not be created.`,
            );
          }
        } else {
          log.warn(
            `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but no matching slot was configured.`,
          );
        }
        break;
      }
      case AD_AFFINITY_FAILED_REASONS.SLOT_FILLED:
        log.warn(`Slotify standard handling error - Slot already filled for ${adDefinition.name}`);
        break;
      default:
        log.warn(
          `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but something went wrong.`,
        );
    }
  },
  [Slotify_DO.raise_enableIncrementalAds]: raise({
    type: Slotify_ON.incrementalAdsEnabled,
  }),

  [Slotify_DO.raise_enableStandardAds]: raise({ type: Slotify_RECIEVE.standardAdsEnabled }),
  [Slotify_DO.enableIncrementalAds]: assign({
    features: ({ context: { features } }) => ({
      ...features,
      [FEATURE.ADS_INCREMENTAL]: true,
    }),
  }),
  [Slotify_DO.enableStandardAds]: assign({
    features: ({ context: { features } }) => ({
      ...features,
      [FEATURE.ADS_STANDARD]: true,
    }),
  }),

  [Slotify_DO.positionSlotElement]: assignParams({
    slotPositioners: ({ context, spawn }, slot) => [
      ...context.slotPositioners,
      spawn(Slotify_SPAWN.slotPositioner, {
        id: `${Slotify_TO.slotPositioner}${slot.getProperty('id')}`,
        input: {
          slot,
        },
      }),
    ],
  }),
  [Slotify_DO.addSlot]: assignParams({
    slots: ({ context: { slots } }, slot) => {
      slots.push(slot);
      return slots;
    },
  }),
  [Slotify_DO.createAdditionalSlotWatchers]: (_, slot) => {
    addAdditionalWatchers(slot);
  },
  [Slotify_DO.createSlotWatcher]: assignParams({
    slotWatchers: ({ context, spawn }, slot) => [
      ...context.slotWatchers,
      spawn(Slotify_SPAWN.slotWatcher, {
        id: `${Slotify_TO.slotWatcher}${slot.getProperty('id')}`,
        input: {
          slot,
          activationDistance: context.activationDistance,
        },
      }),
    ],
  }),
  [Slotify_DO.reportSlotHookFailed]: (_, slotDefinition) => {
    if (slotDefinition.ignoreErrors) return;
    if (slotDefinition.multiple) {
      log.info(
        `Static slot ${slotDefinition.name} could not find any elements with hook '${slotDefinition.hook}'.`,
      );
    } else {
      log.info(
        `Static slot ${slotDefinition.name} could not find an element with hook '${slotDefinition.hook}'.`,
      );
    }
  },
  [Slotify_DO.createSynamicSlotGenerator]: assign({
    [Slotify_TO.dynamicSlotGenerator]: ({ spawn, context }) =>
      spawn(Slotify_SPAWN.dynamicSlotGenerator, {
        id: Slotify_TO.dynamicSlotGenerator,
        input: {
          generatedSlotDefinitions: context.config.placement.slots.generated.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
          dynamicSlotDefinitions: context.config.placement.slots.dynamic.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
          automaticDynamic: context.automaticDynamic,
        },
      }),
  }),
  [Slotify_DO.watchExclusionZones]: watchExclusionZones,

  [Slotify_DO.incrementAdTypeCounters]: assignParams({
    adTypeCounters: ({ context: { adTypeCounters } }, newAds) =>
      newAds.reduce(
        (adTypeCounters, ad) => ({
          ...adTypeCounters,
          [ad.getProperty('name')]: 1 + (adTypeCounters[ad.getProperty('name')] || 0),
        }),
        adTypeCounters,
      ),
  }),
  [Slotify_DO.incrementAdCounter]: assignParams({
    adCounter: ({ context: { adCounter } }, newAds) => adCounter + newAds.length,
  }),
  [Slotify_DO.incrementTakeoverIncrementalCounter]: assignParams({
    takeoverIncrementalCount: ({ context: { takeoverActive, takeoverIncrementalCount } }, newAds) =>
      takeoverIncrementalCount +
      (takeoverActive ? newAds.filter(ad => !(ad && ad.getProperty('nativeContent'))).length : 0),
  }),
  [Slotify_DO.addSlotToStack]: assignParams({
    slotStack: ({ context: { slotStack } }, { slot }) => [...slotStack, slot],
  }),
  [Slotify_DO.reorderAds]: assign({ newAds: reorderAds }),
  [Slotify_DO.triggerDynamicSlotRefresh]: sendTo(Slotify_TO.dynamicSlotGenerator, {
    type: 'refresh',
  }),
  [Slotify_DO.incrementBatchCounter]: assign({
    adMatches: [],
    newAds: [],
    batchCounter: ({ context: { batchCounter } }) => batchCounter + 1,
  }),
  [Slotify_DO.triggerAuction]: sendParent(({ context: { newAds } }) => ({
    type: BDX_EVENTS.requestAuction,
    data: { ads: newAds },
  })),
  [Slotify_DO.reportFirstAdLoad]: ({ context: { batchCounter } }) => {
    if (batchCounter !== 0) return;
    adsLoaded();
  },
  [Slotify_DO.putNewAdsInStore]: ({ context: { ads, newAds } }) => {
    newAds.forEach(ad => {
      ads.push(ad);
    });
  },
  [Slotify_DO.setNewAds]: assignParams({
    newAds: (_, ads) => ads,
  }),
  [Slotify_DO.popSlotStack]: assign({
    slotStack: ({ context: { slotStack } }) => slotStack.slice(1),
  }),
  [Slotify_DO.setAdMatches]: assignParams({
    adMatches: (_, adMatches) => adMatches,
  }),
  [Slotify_DO.firstAuctionDone]: assign({ firstAuctionDone: true }),
};
export default slotifyActions;
