import getEnv from '../env';
import getBestKey from '../get-best-key';

const siteMap = {
  '5gradar.com': '5gradar',
  'advnture.com': 'advnture',
  'anandtech.com': 'anandtech',
  'androidcentral.com': 'androidcentral',
  'avnetwork.com': 'avnetwork',
  'bikeperfect.com': 'bikeperfect',
  'cinemablend.com': 'cinemablend',
  'cloudsavvyit.com': 'cloudsavvyit',
  'coachmag.co.uk.master.': 'coachmag',
  'coachmag.co.uk': 'coachmag',
  'coachweb.com': 'coachmag',
  'countrylife.co.uk': 'countrylife',
  'crackberry.com': 'crackberry',
  'creativebloq.com': 'creativebloq',
  'cyclingnews.com': 'cyclingnews',
  'cyclingweekly.com': 'cyclingweekly-progressive',
  'decanter.com': 'decanter',
  'digitalcameraworld.com': 'digitalcameraworld',
  'fitandwell.com': 'fitandwell-progressive',
  'fourfourtwo.com': 'fourfourtwo',
  'gamesradar.com': 'gamesradar',
  'gardeningetc.com': 'gardeningetc-progressive',
  'gardeningknowhow.com': 'gardeningknowhow-progressive',
  'getprice.au': 'getprice',
  'getprice.com.au': 'getprice',
  'gnomesingardens.com': 'gnomesingardens',
  'golf-monthly.co.uk': 'golfmonthly-progressive',
  'golfmonthly.com': 'golfmonthly-progressive',
  'goodto.com': 'goodtoknow-progressive',
  'guitarplayer.com': 'guitarplayer',
  'guitarworld.com': 'guitarworld',
  'homebuilding.co.uk': 'homebuilding',
  'homesandgardens.com': 'homesandgardens-progressive',
  'horseandhound.co.uk': 'horseandhound',
  'howtogeek.com': 'howtogeek',
  'idealhome.co.uk': 'idealhome',
  'idealhome.co.uk.master.': 'idealhome-progressive-qa',
  'imore.com.master.': 'imore',
  'imore.com': 'imore',
  'itpro.preprod.coreapp.didev.co.uk': 'itprocouk',
  'itpro.staging.coreapp.didev.co.uk': 'itprocouk',
  'itpro.develop.coreapp.didev.co.uk': 'itprocouk',
  'itpro.co.uk': 'itprocouk',
  'itprocom.preprod.coreapp.didev.co.uk': 'itprocom',
  'itprocom.staging.coreapp.didev.co.uk': 'itprocom',
  'itprocom.develop.coreapp.didev.co.uk': 'itprocom',
  'itpro.com': 'itprocom',
  'itproportal.com': 'itproportal',
  'kiplinger.preprod.coreapp.didev.co.uk': 'kiplinger',
  'kiplinger.staging.coreapp.didev.co.uk': 'kiplinger',
  'kiplinger.develop.coreapp.didev.co.uk': 'kiplinger',
  'kiplinger.com': 'kiplinger',
  'laptopmag.com': 'laptopmag',
  'lifesavvy.com': 'lifesavvy',
  'livescience.com': 'livescience',
  'livingetc.com': 'livingetc-progressive',
  'look.co.uk': 'look',
  'loudersound.com': 'loudersound',
  'marieclaire.co.uk': 'marieclaire',
  'marieclaire.com': 'marieclairecom-progressive',
  'mbr.co.uk': 'mbr',
  'mby.com': 'mby',
  'mindbounce.com': 'mindbounce',
  'moneyweek.preprod.coreapp.didev.co.uk': 'moneyweek',
  'moneyweek.staging.coreapp.didev.co.uk': 'moneyweek',
  'moneyweek.develop.coreapp.didev.co.uk': 'moneyweek',
  'moneyweek.com': 'moneyweek',
  'musicradar.com': 'musicradar',
  'myimperfectlife.com': 'myimperfectlife-progressive',
  'nexttv.com': 'nexttv',
  'pbo.co.uk': 'pbo',
  'pcgamer.com': 'pcgamer',
  'petsradar.com': 'petsradar',
  'realhomes.com': 'realhomes-progressive',
  'reviewgeek.com': 'reviewgeek',
  'rugbyworld.com': 'rugbyworld',
  'sandbox.futurehybrid.tech': 'sandbox',
  'sciencealert.wpengine.com': 'sciencealert',
  'sciencealert2022.com': 'sciencealert',
  'sciencealert.com': 'sciencealert',
  'shootinguk.co.uk': 'shootinguk',
  'space.com': 'space',
  't3.com': 't3-progressive',
  'techlearning.com': 'techlearning',
  'techlearninguniversity.com': 'techlearninguniversity',
  'techradar.com': 'techradar',
  'techspot.com': 'techspot',
  'thecaribou.com': 'thecaribou',
  'thefield.co.uk': 'thefield',
  'themoneyedit.com': 'themoneyedit-progressive',
  'theweekuk.preprod.coreapp.didev.co.uk': 'theweekcouk',
  'theweekuk.staging.coreapp.didev.co.uk': 'theweekcouk',
  'theweekuk.develop.coreapp.didev.co.uk': 'theweekcouk',
  'theweek.co.uk': 'theweekcouk',
  'theweekus.preprod.coreapp.didev.co.uk': 'theweekcom',
  'theweekus.staging.coreapp.didev.co.uk': 'theweekcom',
  'theweekus.develop.coreapp.didev.co.uk': 'theweekcom',
  'theweek.com': 'theweekcom',
  'tomsguide.com': 'tomsguide',
  'tomshardware.com': 'tomshardware',
  'toptenreviews.com': 'toptenreviews',
  'tvtechnology.com': 'tvtechnology',
  'wallpaper.com': 'wallpaper',
  'whatculture.com': 'whatculture',
  'whathifi.com': 'whathifi',
  'whatsontv.co.uk': 'whatsontv',
  'whattowatch.com': 'whattowatch',
  'whowhatwear.com': 'whowhatwear-progressive',
  'windowscentral.com': 'windowscentral',
  'windowscentral.com.master.': 'windowscentral-qa',
  'womanandhome.com': 'womanandhome-progressive',
  'yachtingmonthly.com': 'yachtingmonthly',
  'yachtingworld.com': 'yachtingworld',
  'ybw.com': 'ybw',
};

const getSite = (): string => {
  const env = getEnv();
  const siteKey = getBestKey(siteMap, env.location.hostname);
  return siteKey ? siteMap[siteKey] : '';
};

export default getSite;
