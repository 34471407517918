function partition<T, B extends T = T, A extends T = Exclude<T, B>>(
  array: Array<T>,
  predicate: (item: T, index: number, list: Array<T>) => item is B,
): [Array<A>, Array<B>];
function partition<T, B extends T = T, A extends T = T>(
  array: Array<T>,
  predicate: (item: T, index: number, list: Array<T>) => boolean,
): [Array<A>, Array<B>];
function partition<T, B extends T, A extends T>(
  array: Array<T>,
  predicate:
    | ((item: T, index: number, list: Array<T>) => item is B)
    | ((item: T, index: number, list: Array<T>) => boolean),
): [Array<A>, Array<B>] {
  const valid: Array<B> = [];
  const invalid: Array<A> = [];
  for (let index = 0; index < array.length; index++) {
    if (predicate(array[index], index, array)) valid.push(array[index] as B);
    else invalid.push(array[index] as A);
  }
  return [[...invalid], [...valid]];
}
export default partition;
