import { log } from '@repo/utils';
import { FEATURE, API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

const features = Object.values(FEATURE);

export const enableFeature = (service: ApiMachineEventService, feature: FEATURE): void => {
  if (typeof feature !== 'string') {
    log.info(`Argument passed to enableFeature must be a string, ${typeof feature} given`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  if (!features.includes(feature)) {
    log.warn(`Unable to enable feature ${feature}, no handler specified.`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  service.sendEvent({
    type: API_EMIT.ENABLE_FEATURE,
    data: feature,
  });
};

export const disableFeature = (service: ApiMachineEventService, feature: FEATURE): void => {
  if (typeof feature !== 'string') {
    log.info(`Argument passed to disableFeature must be a string, ${typeof feature} given`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  if (!features.includes(feature)) {
    log.info(`Unable to disable feature ${feature}, no handler specified.`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  service.sendEvent({
    type: API_EMIT.DISABLE_FEATURE,
    data: feature,
  });
};
