export enum AdUnitMode {
  ANCHORED = 'anchored',
  INTERSTITIAL = 'interstitial',
  OOP = 'oop',
  SKYSCRAPER = 'skyscraper',
  SLOTIFY = 'slotify',
}
export enum AdUnitCategory {
  MPU = 'mpu',
  DMPU = 'dmpu',
  LEADERBOARD = 'leaderboard',
  SPONSORED_POST = 'sponsored-post',
  SPONSORED_BRAND = 'sponsored-brand',
  INFINITE_SCROLL = 'infinite-scroll',
}
export enum AdUnitStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  VIEWED = 'viewed',
  INVALID = 'invalid',
}
export enum AdUnitIssues {
  MISSING_AFFINITY = 'missing-affinity',
  AFFINITY_OCCUPIED = 'affinity-occupied',
  NO_AFFINITY = 'no-affinity',
}
export enum AdUnitPosition {
  LEFT = 'left',
  RIGHT = 'right',
}