import { DataObject, Ad, AdUnitMode } from '@repo/shared-types';

export const reorderAds = ({ context: { newAds } }): DataObject<Ad>[] => {
  if (newAds) {
    const nonOopAds = newAds
      .filter((newAd: DataObject<Ad>) => newAd.getProperty('mode') !== AdUnitMode.OOP)
      .map((nonOopAd: DataObject<Ad>, i: number) => {
        nonOopAd.update({ requestOrder: i + 1 });

        return nonOopAd;
      });

    const oopAds = newAds
      .filter((newAd: DataObject<Ad>) => newAd.getProperty('mode') === AdUnitMode.OOP)
      .map((oopAd: DataObject<Ad>, i: number) => {
        oopAd.update({ requestOrder: nonOopAds.length + i + 1 });

        return oopAd;
      });

    return [...nonOopAds, ...oopAds];
  }
  return newAds;
};
