import { getGDPRData, getUserSyncURL } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const id = '65';
const openweb: UserSyncConfig = {
  element: UserSyncElement.IFRAME,
  id,
  bidderName: 'openweb',
  iabIdList: [259],
  url: 'https://visitor.omnitagjs.com/visitor/isync',
  params: {
    uid: 'ebd167208cfac599416ddea5dbceb5dd',
    name: 'FUTURE_PLC',
    url: getUserSyncURL({ id, uid: 'PARTNER_USER_ID' }),
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return {
      ...(gdprData
        ? {
            gdpr: 'true',
            gdpr_consent: gdprData,
          }
        : {}),
    };
  },
};
export default openweb;
