import { ActorRef } from 'xstate';

import { BordeauxMachineContext, ON } from '@repo/shared-types';

import fastdom from './fastdom';
import createElement from './create-element';

export const setupArticleObserver = ({ context, self }: { context: BordeauxMachineContext, self: ActorRef<any, any> }) => {
  if(context.adDensityEnabled) {
    const article = document.getElementById('article-body');
    if (article) {
      const sentinel = createElement('div', {
        style: {
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          height: '5px',
          visibility: 'hidden',
          pointerEvents: 'none'
        },
      })

      fastdom.mutate(() => {
        article.appendChild(sentinel);

        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                self.send({
                  type: ON.articleEndVisible
                });
                observer.disconnect();
              }
            });
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
          }
        );

        observer.observe(sentinel);
      })
    }
  }
};