import {
  AdUnitList,
  AdDefinition,
  AdUnitMode,
  Slot,
  DataObjectType,
  AD_AFFINITY_FAILED_REASONS,
  AdAffinityFailedEvent,
  AdMatchEvent,
  Slotify_ON,
} from '@repo/shared-types';
import { partition } from '@repo/utils';
import { EventObject, fromCallback } from 'xstate';

const affinityAdGenerator = fromCallback<
  EventObject,
  {
    adDefinitions: AdUnitList;
    slots: Array<DataObjectType<Slot>>;
  }
>(({ input: { adDefinitions, slots }, sendBack }) => {
  const [nonSlotifyAdDefinitions, slotifyAdDefinitions] = partition(
    adDefinitions,
    ad => ad.mode === AdUnitMode.SLOTIFY,
  );

  const firstBatch: Array<{
    adDefinition: AdDefinition;
    slot?: DataObjectType<Slot>;
  }> = [
    ...nonSlotifyAdDefinitions
      .filter(
        adDefinition =>
          // ADP-12918 anchored ads need to be dealt with after takeover is decided
          !(adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inTakeover),
      )
      .map(adDefinition => ({ adDefinition })),
  ];

  slotifyAdDefinitions.forEach(adDefinition => {
    const adAffinity = adDefinition.affinitySlotID;
    if (!adAffinity) {
      sendBack({
        type: Slotify_ON.adAffinityFailed,
        data: {
          adDefinition,
          reason: AD_AFFINITY_FAILED_REASONS.ABSENT,
        },
      } as AdAffinityFailedEvent);
      return;
    }

    const slot = slots.find(searchSlot => searchSlot.getProperty('name') === adAffinity);
    if (!slot) {
      sendBack({
        type: Slotify_ON.adAffinityFailed,
        data: {
          adDefinition,
          reason: AD_AFFINITY_FAILED_REASONS.NO_SLOT,
        },
      } as AdAffinityFailedEvent);
      return;
    }
    if (slot.getProperty('adID') !== undefined) {
      sendBack({
        type: Slotify_ON.adAffinityFailed,
        data: {
          adDefinition,
          reason: AD_AFFINITY_FAILED_REASONS.SLOT_FILLED,
        },
      } as AdAffinityFailedEvent);
      return;
    }

    firstBatch.push({ adDefinition, slot });
  });

  sendBack({
    type: Slotify_ON.adsMatch,
    data: firstBatch,
  } as AdMatchEvent);
});

export default affinityAdGenerator;
