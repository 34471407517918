import createElement from './create-element';

export const createCeltraCloseButton = (adWidth: number): HTMLElement => createElement(
      'div',
      {
        class: 'bordeaux-anchored-close',
        style: {
          position: 'absolute',
          // Adjust speed of close button position movement according to ad width
          right: `max(4px, calc((${adWidth - 58}px - 100vw) / 2 + 32px))`,
          top: '4px',
        },
        onClick: event => {
          if (event.currentTarget instanceof HTMLDivElement) {
            const container = event.currentTarget?.parentNode;
            container?.parentNode?.removeChild(container);
          }
        },
      },
      `
      <img 
        src="https://cache-ssl.celtra.com/api/blobs/c04d8751fa4e56e9ad6d9af3606753a2c24a2eedcc4b33fb04f71789e48b96d3/Close-black.png?transform=crush&amp;quality=256" 
        style="width: 20px; height: 20px;"
      >
    `,
    );