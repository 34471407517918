import { ThirdPartyAPIMachineConfigs, ThirdParty, PartialPick } from '@repo/shared-types';
import loadScript from 'third-party-apis/utils/load-script';
import { getEnv } from '@repo/utils';
import { Dvtag, Opt } from '@repo/utils/src/env.types';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.DOUBLE_VERIFY], 'thirdParty'> = {
  thirdParty: ThirdParty.DOUBLE_VERIFY,
  scriptLocation: 'https://pub.doubleverify.com/dvtag/37903656/DV1705976/pub.js',
  methods: {
    loadScript: async (scriptLocation): Promise<void> => {
      const env = getEnv();
      env.onDvtagReady = function (callback, timeout = 750) {
        env.dvtag = env.dvtag || ({} as Dvtag);
        env.dvtag.cmd = env.dvtag.cmd || [];
        const opt: Opt = {
          callback,
          timeout,
          timestamp: new Date().getTime(),
        };
        env.dvtag.cmd.push(function () {
          env.dvtag?.queueAdRequest(opt);
        });
        setTimeout(function () {
          const cb = opt.callback;
          opt.callback = null;
          if (cb) cb();
        }, timeout);
      };

      await loadScript(scriptLocation, 'bordeaux-double-verify', { async: true });
    },
  },
};
export default config;
