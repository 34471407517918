import {
  Ad,
  AdManager_IF,
  AdManagerGuards,
  AdManagerMachineContext,
  AnyAdManagerEvent,
  DataObject,
} from '@repo/shared-types';
import { getEnv } from '@repo/utils';
import { GuardPredicate } from 'xstate/guards';

const adManagerGuards: Record<
  AdManager_IF,
  GuardPredicate<AdManagerMachineContext, AnyAdManagerEvent, any, AdManagerGuards>
> = {
  [AdManager_IF.googletagExists]: () => {
    const env = getEnv();
    const { googletag } = env;
    return Boolean(googletag);
  },
  [AdManager_IF.auctionsInStack]: ({ context: { auctionStack } }) => auctionStack.length !== 0,
  [AdManager_IF.hasAds]: (_, ads: Array<DataObject<Ad>>) => ads.length !== 0,
};

export default adManagerGuards;
