import { APISetupResultLegacy, FullAPISetupResults } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';

export default async (service: ApiMachineEventService): Promise<Array<APISetupResultLegacy>> => {
  await service.waitFor.thirdParties;
  const thirdParties = await service.getData<FullAPISetupResults>('thirdPartyResults');
  return Object.values(thirdParties).map(
    ({
      thirdParty,
      config,
      success,
      scriptLocation,
    }: FullAPISetupResults[keyof FullAPISetupResults]): APISetupResultLegacy => ({
      name: thirdParty,
      config,
      enabled: config.enabled,
      success,
      scriptLocation,
    }),
  );
};
