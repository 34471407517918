import { getGDPRData, getUserSyncURL } from './utils';
import { UserSyncElement, UserSyncConfig } from '@repo/shared-types';

const id = '66';
const rise: UserSyncConfig = {
  id,
  bidderName: 'rise',
  iabIdList: [1043],
  element: UserSyncElement.IFRAME,
  url: 'https://cs-server-s2s.yellowblue.io/sync-iframe',
  params: {
    redirect: getUserSyncURL({ id, uid: '{partnerId}' }),
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return gdprData
      ? {
          gdpr: 'true',
          gdpr_consent: gdprData,
        }
      : ({} as Record<string, string>);
  },
};
export default rise;
