export enum API_EMIT {
  SET_ADTOOL_VERSION = 'API_SET_ADTOOL_VERSION',
  SET_LOAD_GPT_EXTERNALLY = 'API_SET_LOAD_GPT_EXTERNALLY',
  SET_PREBID_ANALYTICS_ENABLED = 'API_SET_PREBID_ANALYTICS_ENABLED',
  UPDATE_PREBID_ANALYTICS_DATA = 'API_UPDATE_PREBID_ANALYTICS_DATA',
  SET_AUCTION_TIMEOUTS = 'API_SET_AUCTION_TIMEOUTS',
  SET_AUTOMATIC_DYNAMIC = 'API_SET_AUTOMATIC_DYNAMIC',
  SET_EXPERIMENT_ID = 'API_SET_EXPERIMENT_ID',
  SET_COMPANION_BOUNDS = 'SET_COMPANION_BOUNDS',
  SET_FALLBACK_RESPONSES = 'API_SET_FALLBACK_RESPONSES',
  SET_PAGE_CATEGORY = 'API_SET_PAGE_CATEGORY',
  SET_PAGE_TEMPLATE = 'API_SET_PAGE_TEMPLATE',
  SET_REFRESH_TIME = 'API_SET_REFRESH_TIME',
  SET_TAKEOVER_INCREMENTAL_CAPS = 'API_SET_TAKEOVER_INCREMENTAL_CAPS',
  SET_TAKEOVER_INCREMENTAL_CHOOSER = 'API_SET_TAKEOVER_INCREMENTAL_CHOOSER',
  SET_ACTIVATION_DISTANCE = 'API_SET_ACTIVATION_DISTANCE',
  SET_AVOIDANCE_DISTANCE = 'API_SET_AVOIDANCE_DISTANCE',
  SET_THIRD_PARTY_API_CONFIG = 'API_SET_THIRD_PARTY_API_CONFIG',
  SET_PAGE_TARGETING = 'API_SET_PAGE_TARGETING',
  ENABLE_FEATURE = 'API_ENABLE_FEATURE',
  DISABLE_FEATURE = 'API_DISABLE_FEATURE',
  GPT_LOADED_EXTERNALLY = 'GPT_LOADED_EXTERNALLY',
  SET_REFRESH_PAUSED = 'API_SET_REFRESH_PAUSED',
  INITIALISE = 'API_INITIALISE',
  REQUEST_HANDLE_DYNAMIC_SLOTS = 'API_REQUEST_HANDLE_DYNAMIC_SLOTS',
  ADD_UNREFRESHABLE_NAMES = 'ADD_UNREFRESHABLE_NAMES',
  DOCK_AD_TOOL = 'DOCK_AD_TOOL',
  UN_DOCK_AD_TOOL = 'UN_DOCK_AD_TOOL',
  TOGGLE_REFRESH_PAUSED = 'TOGGLE_REFRESH_PAUSED',
  ADD_AD_HIGHLIGHTS = 'ADD_AD_HIGHLIGHTS',
  REMOVE_AD_HIGHLIGHTS = 'REMOVE_AD_HIGHLIGHTS',
  ADD_SLOT_HIGHLIGHTS = 'ADD_SLOT_HIGHLIGHTS',
  REMOVE_SLOT_HIGHLIGHTS = 'REMOVE_SLOT_HIGHLIGHTS',

  COMMANDS_RUN = 'API_COMMANDS_RUN',
}
export enum API_RECIEVE {
  CONFIG_READY = 'CONFIG_READY',
  THIRD_PARTIES_READY = 'THIRD_PARTIES_READY',
  HYBRID_ID_READY = 'HYBRID_ID_READY',
  TAKEOVER_READY = 'TAKEOVER_READY',
  API_READY = 'API_READY',
}