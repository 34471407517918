import { fastdom, getEnv, querySelectorAll, getDeviceSize } from '@repo/utils';
import { Slot, DataObject, DeviceOptions } from '@repo/shared-types';

const env = getEnv();

interface ZoneConfig {
  selector: string;
  minHeight: number;
  devices: Array<DeviceOptions>;
}

interface Zone {
  top: number;
  bottom: number;
}

interface ZoneMap {
  [index: number]: Zone;
}

const exclusionSelectors: Array<ZoneConfig> = !env.ffte
  ? []
  : [
      { selector: '#sidebar', minHeight: 3000, devices: [DeviceOptions.DESKTOP] },
      {
        selector: '.article__sidebar .van_vid_carousel.vid-present',
        minHeight: 0,
        devices: [DeviceOptions.DESKTOP],
      },
      { selector: '#archives-filter-sidebar', minHeight: 3000, devices: [DeviceOptions.DESKTOP] },
      {
        selector: '.listing--related .listing__title',
        minHeight: 30,
        devices: [DeviceOptions.DESKTOP],
      },
      {
        selector: '.listing--related .listing__item',
        minHeight: 330,
        devices: [DeviceOptions.DESKTOP],
      },
      { selector: '.article-bottom', minHeight: 3500, devices: [DeviceOptions.DESKTOP] },
      { selector: '.author--article-page', minHeight: 60, devices: [DeviceOptions.DESKTOP] },
      { selector: '.slice-author-bio', minHeight: 60, devices: [DeviceOptions.DESKTOP] },
    ];

const exclusionZones: ZoneMap = {};

const generateMutationHandler =
  (id: number, exclusionElement: Element, minHeight: number) => (): void => {
    fastdom.measure(() => {
      const scroll = env.scrollY;
      const { top, height } = exclusionElement.getBoundingClientRect();
      const coordinates: Zone = {
        top: Math.floor(top) + scroll,
        bottom: Math.floor(Math.max(top + minHeight, top + height)) + scroll,
      };

      exclusionZones[id] = coordinates;
    });
  };

export const watchExclusionZones = (): void => {
  let observerIndex = 0;
  const device = getDeviceSize();
  exclusionSelectors
    .filter(({ devices = [] }) => devices.includes(device))
    .forEach(({ selector, minHeight }) => {
      const elements = querySelectorAll(selector);
      if (elements.length === 0) {
        return;
      }
      elements.forEach(element => {
        const observerConfig: MutationObserverInit = {
          attributes: true,
          childList: true,
          subtree: true,
        };
        const handler = generateMutationHandler(observerIndex, element, minHeight);
        const observer = new MutationObserver(handler);
        observer.observe(element, observerConfig);
        observerIndex += 1;
        handler();
      });
    });
};

export const overlapsExclusionZones = (slot: DataObject<Slot>): boolean => {
  const scroll = env.scrollY;
  const coordinates = slot.getProperty('element').getBoundingClientRect();
  const height = slot.getProperty('height');
  const style = slot.getProperty('style');
  const top =
    coordinates.top + scroll + parseInt(style['margin-top'] || style['marginTop'] || '0px', 10);
  const bottom = top + height;
  const expectedCoords = {
    top,
    bottom,
  };
  return Object.values(exclusionZones).some((zone: Zone) => {
    return !(zone.top > expectedCoords.bottom || zone.bottom < expectedCoords.top);
  });
};
