import metrics from 'metrics';
import {
  log,
  api as logAPI,
  sentry,
  getEnv,
  generateSessionId,
  mergeListItem,
  timeData,
  sendBeacon,
  sendPartnerBidsToFreyr,
} from '@repo/utils';
import {
  PartnerRequest,
  RequestStatus,
  Script,
  ReportEvent,
  AuctionPartnerReportEvent,
  AuctionReportEvent,
  METRICS,
  DO_REPORT,
  REPORT_AUCTION,
  REPORT_AUCTION_PARTNER,
  REPORT_THIRD_PARTY_SCRIPT,
  ThirdPartyReportEvent,
  AuctionReportStartEvent,
  IF,
  ActionArgs,
  TimeData,
  BordeauxRecordActions,
  BordeauxReportActions,
  DO_RECORD,
  BordeauxMachineContext,
  AnyBordeauxEvent,
  BordeauxActionsUnion,
} from '@repo/shared-types';
import assign, { assignParams } from './proxy/assign';
import raise from './proxy/raise';
import { and, EventObject, MetaObject, ProvidedActor, TransitionsConfig } from 'xstate';
import forwardEventData from './proxy/forward-event-data';
import raiseParams from './proxy/raise-params';

export const multipleScripts = (): void => {
  sentry.reportError(`More than one Bordeaux script has been loaded on this page.`);
};

export const frameworkRequest = (): void => {
  metrics.recordFrameworkRequest('success');
  metrics.mark('Bordeaux framework loaded');
  sentry.breadcrumb({
    category: 'script',
    message: 'Bordeaux framework loaded',
  });
};

export const contentLoad = ({ event }: ActionArgs<ReportEvent<DO_REPORT.CONTENT_LOAD>>) => {
  metrics.mark({ message: 'DOMContentLoaded event', time: event.data.time.timeStamp });
};

export const initialiseSentry = ({ context }: ActionArgs): void => {
  const env = getEnv();
  sentry.init();
  sentry.extraData({
    'device.size': context.pageParameters.device,
    'url.domain': env.location.hostname,
  });
  logAPI.onLogError(logEvent => {
    sentry.reportError(logEvent.logItem.args.toString());
  });
};

export const adBlocked = (): void => {
  metrics.recordAds('block');
};

export const pageLoad = (_, data: TimeData) => {
  metrics.mark({ message: 'PageLoad event', time: data.timeStamp });
};

export const pageUnload = ({ context }: ActionArgs, data: TimeData) => {
  context.timing.unsentAuctions.forEach(id => {
    sendBeacon(context.timing.endpoint, {
      ...context.timing.payload,
      user: {
        ...context.timing.payload.user,
        unload_time: data.dateString,
      },
      auction: context.timing.auctionPayloads[id],
    });
  });
};

export const configCreate = assign({
  metrics: ({ context }) => ({
    ...context.metrics,
    [METRICS.CONFIG]: metrics.mark('Sommelier config requested'),
  }),
});

export const configRequest = (): void => {
  sentry.breadcrumb({
    category: 'script',
    message: 'Sommelier config requested',
  });
};

export const configError = ({
  context,
  event,
}: ActionArgs<ReportEvent<DO_REPORT.CONFIG_FAILURE>>): void => {
  const requestConfig = context.metrics[METRICS.CONFIG];
  if (requestConfig === undefined)
    log.warn(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config request error',
  });
  metrics.mark('Sommelier config request error', requestConfig);
  log.error(`Sommelier config request failed: ${event.data.error?.message}`);
  sentry.disableReporting();
};

export const configFailure = (): void => {
  metrics.recordConfigLoad('fail');
};

export const adsLoaded = (): void => {
  metrics.recordAds('load');
};

export const configTimeout = (): void => {
  metrics.recordConfigLoad('timeout');
};

export const configLoad = (): void => {
  metrics.recordConfigLoad('success');
};

export const configEmpty = ({ context }: ActionArgs): void => {
  const requestConfig = context.metrics[METRICS.CONFIG];
  if (requestConfig === undefined)
    log.warn(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config empty',
  });
  metrics.mark('Sommelier config empty', requestConfig);
  log.error('Sommelier config response empty.');
  metrics.recordConfigLoad('empty');
  sentry.disableReporting();
};

export const configParseFailure = ({
  context,
  event: {
    data: { error },
  },
}: ActionArgs<ReportEvent<DO_REPORT.CONFIG_PARSE_FAILURE>>): void => {
  const requestConfig = context.metrics[METRICS.CONFIG];
  if (requestConfig === undefined)
    log.warn(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config parse error',
  });
  metrics.mark('Sommelier config parse error', requestConfig);
  log.error(
    'Unable to parse the config response, encountered the following issues: ',
    error?.message,
  );
  sentry.disableReporting();
};

export const configSuccess = ({ context }: ActionArgs): void => {
  const requestConfig = context.metrics[METRICS.CONFIG];
  if (requestConfig === undefined)
    log.warn(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config loaded',
  });
  metrics.mark('Sommelier config loaded', requestConfig);
  sentry.breadcrumb({
    category: 'config',
    message: 'Config retrieved',
  });
};

export const consentRequest = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent requested',
  });
};

export const consentFailure = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent error',
  });
};

export const consentSuccess = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent retrieved',
  });
};

export const thirdPartyCreate = assign({
  metrics: ({ context }) => ({
    ...context.metrics,
    [METRICS.THIRD_PARTY]: metrics.mark('Third parties requested'),
  }),
});

export const thirdPartyRequest = (): void => {
  sentry.breadcrumb({
    category: 'apis',
    message: 'Third parties requested',
  });
};

export const thirdPartySuccess = ({ context }: ActionArgs): void => {
  const requestThirdParty = context.metrics[METRICS.THIRD_PARTY];
  if (requestThirdParty === undefined)
    log.error(`Third Party request not measured, reporting may be incomplete.`);

  metrics.recordThirdPartiesLoad();
  sentry.breadcrumb({
    category: 'apis',
    message: 'Third parties loaded',
  });
  metrics.mark('Third parties loaded', requestThirdParty);
};

export const thirdPartyScriptCreate = assign<
  ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST>
>({
  metrics: ({ context, event }) => ({
    ...context.metrics,
    [METRICS.THIRD_PARTY_SCRIPTS]: {
      ...context.metrics[METRICS.THIRD_PARTY_SCRIPTS],
      [event.data.thirdParty]: metrics.mark(`${event.data.thirdParty} requested`),
    },
  }),
});

export const thirdPartyScriptRequest = ({
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST>>): void => {
  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} requested`,
  });
};

export const thirdPartyScriptSuccess = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS>>): void => {
  const requestThirdParty = context.metrics[METRICS.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.error(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} loaded`,
  });
  metrics.mark(`${event.data.thirdParty} loaded`, requestThirdParty);
};

export const thirdPartyScriptTimeout = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT>>): void => {
  const requestThirdParty = context.metrics[METRICS.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.error(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} timeout`,
  });
  metrics.mark(`${event.data.thirdParty} timeout`, requestThirdParty);
};

export const thirdPartyScriptFailure = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE>>): void => {
  const requestThirdParty = context.metrics[METRICS.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.warn(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} failed`,
  });
  metrics.mark(`${event.data.thirdParty} failed`, requestThirdParty);
};

export const auctionCreate = assign<AuctionReportStartEvent>({
  auctions: ({ context, event }) => ({
    ...context.auctions,
    [event.data.auction]: {
      adNames: event.data.adNames,
    },
  }),
  metrics: ({ context, event }) => ({
    ...context.metrics,
    [METRICS.AUCTIONS]: {
      ...context.metrics[METRICS.AUCTIONS],
      [event.data.auction]: {
        mark: metrics.mark(`Ad third party requests - ${event.data.adNames}`),
        partners: {},
      },
    },
  }),
});

export const auctionStart = ({ event }: ActionArgs<AuctionReportStartEvent>) => {
  sentry.breadcrumb({
    category: 'script',
    message: `Ad fetch - ${event.data.adNames}`,
  });
  metrics.mark(`Ad fetch - ${event.data.adNames}`);
  sentry.breadcrumb({
    category: 'script',
    message: `Ad third party requests - ${event.data.adNames}`,
  });
};

export const auctionEnd = ({
  context,
  event,
}: ActionArgs<AuctionReportEvent<REPORT_AUCTION.END>>) => {
  const requestAuctionMetric = context.metrics[METRICS.AUCTIONS]?.[event.data.auction];
  const requestAuction = context.auctions[event.data.auction];
  if (!requestAuctionMetric || !requestAuction)
    log.warn(`Auction request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `Ad third party responses - ${requestAuction.adNames}`,
  });
  metrics.mark(`Ad third party responses - ${requestAuction.adNames}`, requestAuctionMetric?.mark);
  sentry.breadcrumb({
    category: 'script',
    message: `Ad GAM request - ${requestAuction.adNames}`,
  });
  metrics.mark(`Ad GAM request - ${requestAuction.adNames}`);
};

export const auctionPartnerCreate = assign<AuctionPartnerReportEvent>({
  metrics: ({ context, event }) => ({
    ...context.metrics,
    [METRICS.AUCTIONS]: {
      ...context.metrics[METRICS.AUCTIONS],
      [event.data.auction]: {
        mark: 0,
        ...context.metrics[METRICS.AUCTIONS][event.data.auction],
        partners: {
          ...context.metrics[METRICS.AUCTIONS][event.data.auction]?.partners,
          [event.data.partner]: metrics.mark(`${event.data.partner} requested`),
        },
      },
    },
  }),
});

export const auctionPartnerRequest = ({
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.REQUEST>>): void => {
  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} requested`,
  });
};

export const auctionPartnerSuccess = ({
  context,
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.SUCCESS>>): void => {
  const requestPartner =
    context.metrics[METRICS.AUCTIONS]?.[event.data.auction]?.partners[event.data.partner];
  if (requestPartner === undefined)
    log.warn(`Auction partner {${event.data.partner}} not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} response`,
  });
  metrics.mark(`${event.data.partner} response`, requestPartner);
};

export const auctionPartnerTimeout = ({
  context,
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.TIMEOUT>>): void => {
  const requestPartner =
    context.metrics[METRICS.AUCTIONS]?.[event.data.auction]?.partners[event.data.partner];
  if (requestPartner === undefined)
    log.warn(`Auction partner {${event.data.partner}} not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} timeout`,
  });
  metrics.mark(`${event.data.partner} timeout`, requestPartner);
};

const findMatchesName =
  (matchName: string) =>
  ({ name }: { name: string }): boolean =>
    matchName === name;

export const recordActions: BordeauxRecordActions = {
  [DO_RECORD.CONFIG_REQUEST]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        config: {
          ...context.timing.payload.config,
          start_time: data.time.dateString,
          status: RequestStatus.PENDING,
        },
      },
    }),
  }),
  [DO_RECORD.CONFIG_SUCCESS]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        config: {
          ...context.timing.payload.config,
          end_time: data.time.dateString,
          status: RequestStatus.SUCCESSFUL,
        },
      },
    }),
  }),
  [DO_RECORD.CONFIG_TIMEOUT]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        config: {
          ...context.timing.payload.config,
          end_time: data.time.dateString,
          status: RequestStatus.TIMEOUT,
        },
      },
    }),
  }),
  [DO_RECORD.CONFIG_FAILURE]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        config: {
          ...context.timing.payload.config,
          end_time: data.time.dateString,
          status: RequestStatus.FAILED,
        },
      },
    }),
  }),

  [DO_RECORD.THIRD_PARTY_REQUEST]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        third_parties: {
          ...context.timing.payload.third_parties,
          start_time: data.time.dateString,
        },
      },
    }),
  }),
  [DO_RECORD.THIRD_PARTY_SUCCESS]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        third_parties: {
          ...context.timing.payload.third_parties,
          end_time: data.time.dateString,
        },
      },
    }),
  }),

  [DO_RECORD.THIRD_PARTY_SCRIPT_REQUEST]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['payload', 'third_parties', 'scripts'],
        findMatchesName(data.thirdParty),
        (existing: Script | undefined) => ({
          name: data.thirdParty,
          end_time: '',
          ...(existing ?? {}),
          start_time: data.time.dateString,
          status: RequestStatus.PENDING,
        }),
      ),
  }),
  [DO_RECORD.THIRD_PARTY_SCRIPT_SUCCESS]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['payload', 'third_parties', 'scripts'],
        findMatchesName(data.thirdParty),
        (existing: Script | undefined) => ({
          name: data.thirdParty,
          start_time: '',
          ...(existing ?? {}),
          end_time: data.time.dateString,
          status: RequestStatus.SUCCESSFUL,
        }),
      ),
  }),
  [DO_RECORD.THIRD_PARTY_SCRIPT_TIMEOUT]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['payload', 'third_parties', 'scripts'],
        findMatchesName(data.thirdParty),
        (existing: Script | undefined) => ({
          name: data.thirdParty,
          start_time: '',
          ...(existing ?? {}),
          end_time: data.time.dateString,
          status: RequestStatus.TIMEOUT,
        }),
      ),
  }),
  [DO_RECORD.THIRD_PARTY_SCRIPT_FAILURE]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['payload', 'third_parties', 'scripts'],
        findMatchesName(data.thirdParty),
        (existing: Script | undefined) => ({
          name: data.thirdParty,
          start_time: '',
          ...(existing ?? {}),
          end_time: data.time.dateString,
          status: RequestStatus.FAILED,
        }),
      ),
  }),

  [DO_RECORD.CONSENT_REQUEST]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        cmp: {
          ...context.timing.payload.cmp,
          start_time: context.timing.payload.cmp.start_time || data.time.dateString,
          status: RequestStatus.PENDING,
        },
      },
    }),
  }),
  [DO_RECORD.CONSENT_PENDING]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        cmp: {
          ...context.timing.payload.cmp,
          pending_time: context.timing.payload.cmp.pending_time || data.time.dateString,
          status: RequestStatus.PENDING,
        },
      },
    }),
  }),
  [DO_RECORD.CONSENT_SUCCESS]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        cmp: {
          ...context.timing.payload.cmp,
          load_time: context.timing.payload.cmp.load_time || data.time.dateString,
          status: RequestStatus.SUCCESSFUL,
        },
      },
    }),
  }),
  [DO_RECORD.CONSENT_FAILURE]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        cmp: {
          ...context.timing.payload.cmp,
          fail_time: data.time.dateString,
          status: RequestStatus.FAILED,
        },
      },
    }),
  }),

  [DO_RECORD.AUCTION_START]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      auctionPayloads: {
        ...context.timing.auctionPayloads,
        [data.auction]: {
          id: data.auction,
          start_time: data.time.dateString,
          end_time: null,
          adload_time: null,
          partner_requests: [],
        },
      },
      unsentAuctions: [...context.timing.unsentAuctions, data.auction],
    }),
  }),
  [DO_RECORD.AUCTION_END]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      auctionPayloads: {
        ...context.timing.auctionPayloads,
        [data.auction]: {
          ...context.timing.auctionPayloads[data.auction],
          end_time: data.time.dateString,
        },
      },
    }),
  }),
  [DO_RECORD.AUCTION_AD_LOAD]: assignParams({
    timing: ({ context }, data) => ({
      ...context.timing,
      auctionPayloads: {
        ...context.timing.auctionPayloads,
        [data.auction]: {
          ...context.timing.auctionPayloads[data.auction],
          adload_time: data.time.dateString,
        },
      },
    }),
  }),
  [DO_RECORD.AUCTION_PARTNER_REQUEST]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['auctionPayloads', data.auction, 'partner_requests'],
        findMatchesName(data.partner),
        (existing: PartnerRequest | undefined) => ({
          name: data.partner,
          end_time: null,
          ...(existing ?? {}),
          start_time: data.time.dateString,
          status: RequestStatus.PENDING,
        }),
      ),
  }),
  [DO_RECORD.AUCTION_PARTNER_TIMEOUT]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['auctionPayloads', data.auction, 'partner_requests'],
        findMatchesName(data.partner),
        (existing: PartnerRequest | undefined) => ({
          name: data.partner,
          start_time: '',
          ...(existing ?? {}),
          end_time: data.time.dateString,
          status: RequestStatus.TIMEOUT,
        }),
      ),
  }),
  [DO_RECORD.AUCTION_PARTNER_SUCCESS]: assignParams({
    timing: ({ context }, data) =>
      mergeListItem(
        context.timing,
        ['auctionPayloads', data.auction, 'partner_requests'],
        findMatchesName(data.partner),
        (existing: PartnerRequest | undefined) => ({
          name: data.partner,
          start_time: '',
          ...(existing ?? {}),
          end_time: data.time.dateString,
          status: RequestStatus.SUCCESSFUL,
        }),
      ),
  }),
  [DO_RECORD.INITIALISE_PAYLOAD]: assign({
    timing: ({ context }) => {
      const env = getEnv();
      return {
        ...context.timing,
        payload: {
          bordeaux: {
            version: process.env.npm_package_version || '',
            experiment_id: '',
          },
          hybrid_ab_test: [],
          browser: {
            type: context.pageParameters.browser,
            device_type: context.pageParameters.device,
            connection_type: env.navigator.connection?.effectiveType ?? '',
            cft_label_name: 'UNDECIDED',
          },
          user: {
            country: context.pageParameters.country,
            hybrid_id: null,
            unload_time: null,
          },
          cmp: {
            start_time: null,
            load_time: null,
            pending_time: null,
            fail_time: null,
            status: RequestStatus.UNSPECIFIED,
          },
          session: {
            url: env.location.href,
            id: generateSessionId(),
            ga_id: +context.gaSessionId,
          },
          config: {
            start_time: null,
            end_time: null,
            status: RequestStatus.UNSPECIFIED,
          },
          third_parties: {
            start_time: null,
            end_time: null,
            scripts: [],
          },
        },
      };
    },
  }),
  [DO_RECORD.EXPERIMENT_ID]: assignParams({
    timing: ({ context }, newExperimentId) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        bordeaux: {
          ...context.timing.payload.bordeaux,
          experiment_id: newExperimentId,
        },
      },
    }),
  }),
  [DO_RECORD.HYBRID_ABTEST_TARGETING]: assignParams({
    timing: ({ context }, newHybridAbTest) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        hybrid_ab_test: newHybridAbTest,
      },
    }),
  }),
  [DO_RECORD.HYBRID_ID]: assignParams({
    timing: ({ context }, newHybridId) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        user: {
          ...context.timing.payload.user,
          hybrid_id: newHybridId,
        },
      },
    }),
  }),
  [DO_RECORD.CFT_PARAMETERS]: assignParams({
    timing: ({ context }, cftParameters) => ({
      ...context.timing,
      payload: {
        ...context.timing.payload,
        browser: {
          ...context.timing.payload.browser,
          cft_label_name: cftParameters.labelName,
        },
      },
    }),
  }),
};

export const reportActions: BordeauxReportActions = {
  [DO_REPORT.MULTIPLE_SCRIPTS]: raise(
    (): ReportEvent<DO_REPORT.MULTIPLE_SCRIPTS> => ({
      type: DO_REPORT.MULTIPLE_SCRIPTS,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.FRAMEWORK_REQUEST]: raise(
    (): ReportEvent<DO_REPORT.FRAMEWORK_REQUEST> => ({
      type: DO_REPORT.FRAMEWORK_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.AD_BLOCKED]: raise(
    (): ReportEvent<DO_REPORT.AD_BLOCKED> => ({
      type: DO_REPORT.AD_BLOCKED,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONFIG_REQUEST]: raise(
    (): ReportEvent<DO_REPORT.CONFIG_REQUEST> => ({
      type: DO_REPORT.CONFIG_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONFIG_LOAD]: raise(
    (): ReportEvent<DO_REPORT.CONFIG_LOAD> => ({
      type: DO_REPORT.CONFIG_LOAD,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONFIG_EMPTY]: raise(
    (): ReportEvent<DO_REPORT.CONFIG_EMPTY> => ({
      type: DO_REPORT.CONFIG_EMPTY,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONFIG_PARSE_FAILURE]: raiseParams(
    (_, error): ReportEvent<DO_REPORT.CONFIG_PARSE_FAILURE> => ({
      type: DO_REPORT.CONFIG_PARSE_FAILURE,
      data: {
        time: timeData(),
        error,
      },
    }),
  ),
  [DO_REPORT.CONFIG_SUCCESS]: raise(
    (): ReportEvent<DO_REPORT.CONFIG_SUCCESS> => ({
      type: DO_REPORT.CONFIG_SUCCESS,
      data: { time: timeData() },
    }),
  ),

  [DO_REPORT.THIRD_PARTY_REQUEST]: raise(
    (): ReportEvent<DO_REPORT.THIRD_PARTY_REQUEST> => ({
      type: DO_REPORT.THIRD_PARTY_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.THIRD_PARTY_SUCCESS]: raise(
    (): ReportEvent<DO_REPORT.THIRD_PARTY_SUCCESS> => ({
      type: DO_REPORT.THIRD_PARTY_SUCCESS,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_REQUEST]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_REQUEST> => ({
      type: DO_REPORT.CONSENT_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_PENDING]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_PENDING> => ({
      type: DO_REPORT.CONSENT_PENDING,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_FAILURE]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_FAILURE> => ({
      type: DO_REPORT.CONSENT_FAILURE,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_SUCCESS]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_SUCCESS> => ({
      type: DO_REPORT.CONSENT_SUCCESS,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_LOADED]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_LOADED> => ({
      type: DO_REPORT.CONSENT_LOADED,
      data: { time: timeData() },
    }),
  ),
  [DO_REPORT.CONSENT_MOCKED]: raise(
    (): ReportEvent<DO_REPORT.CONSENT_MOCKED> => ({
      type: DO_REPORT.CONSENT_MOCKED,
      data: { time: timeData() },
    }),
  ),
};

export const reportEvents: TransitionsConfig<
  BordeauxMachineContext,
  AnyBordeauxEvent,
  ProvidedActor,
  BordeauxActionsUnion,
  any,
  string,
  EventObject,
  MetaObject
> = {
  [DO_REPORT.MULTIPLE_SCRIPTS]: {
    actions: multipleScripts,
  },
  [DO_REPORT.FRAMEWORK_REQUEST]: {
    actions: frameworkRequest,
  },
  [DO_REPORT.AD_BLOCKED]: {
    actions: adBlocked,
  },
  [DO_REPORT.CONTENT_LOAD]: {
    actions: contentLoad,
  },
  [DO_REPORT.CONFIG_REQUEST]: {
    actions: [forwardEventData(DO_RECORD.CONFIG_REQUEST), configCreate, configRequest],
  },
  [DO_REPORT.CONFIG_FAILURE]: [
    {
      guard: {
        type: IF.errorIsTimeout,
        params: ({
          event: {
            data: { error },
          },
        }) => error,
      },
      actions: [forwardEventData(DO_RECORD.CONFIG_TIMEOUT), configError, configTimeout],
    },
    {
      actions: [forwardEventData(DO_RECORD.CONFIG_FAILURE), configError, configFailure],
    },
  ],
  [DO_REPORT.CONFIG_LOAD]: {
    actions: configLoad,
  },
  [DO_REPORT.CONFIG_EMPTY]: {
    actions: [forwardEventData(DO_RECORD.CONFIG_FAILURE), configEmpty],
  },
  [DO_REPORT.CONFIG_PARSE_FAILURE]: {
    actions: [forwardEventData(DO_RECORD.CONFIG_FAILURE), configParseFailure],
  },
  [DO_REPORT.CONFIG_SUCCESS]: {
    actions: [forwardEventData(DO_RECORD.CONFIG_SUCCESS), configSuccess],
  },

  [DO_REPORT.THIRD_PARTY_REQUEST]: {
    actions: [forwardEventData(DO_RECORD.THIRD_PARTY_REQUEST), thirdPartyCreate, thirdPartyRequest],
  },
  [DO_REPORT.THIRD_PARTY_SUCCESS]: {
    actions: [forwardEventData(DO_RECORD.THIRD_PARTY_SUCCESS), thirdPartySuccess],
  },

  [REPORT_THIRD_PARTY_SCRIPT.REQUEST]: {
    actions: [
      forwardEventData(DO_RECORD.THIRD_PARTY_SCRIPT_REQUEST),
      thirdPartyScriptCreate,
      thirdPartyScriptRequest,
    ],
  },
  [REPORT_THIRD_PARTY_SCRIPT.TIMEOUT]: {
    actions: [forwardEventData(DO_RECORD.THIRD_PARTY_SCRIPT_TIMEOUT), thirdPartyScriptTimeout],
  },
  [REPORT_THIRD_PARTY_SCRIPT.SUCCESS]: {
    actions: [forwardEventData(DO_RECORD.THIRD_PARTY_SCRIPT_SUCCESS), thirdPartyScriptSuccess],
  },
  [REPORT_THIRD_PARTY_SCRIPT.FAILURE]: {
    actions: [forwardEventData(DO_RECORD.THIRD_PARTY_SCRIPT_FAILURE), thirdPartyScriptFailure],
  },

  [DO_REPORT.CONSENT_REQUEST]: {
    actions: [consentRequest, forwardEventData(DO_RECORD.CONSENT_REQUEST)],
  },
  [DO_REPORT.CONSENT_FAILURE]: {
    actions: consentFailure,
  },
  [DO_REPORT.CONSENT_SUCCESS]: {
    actions: consentSuccess,
  },
  [DO_REPORT.CONSENT_PENDING]: {
    actions: forwardEventData(DO_RECORD.CONSENT_PENDING),
  },
  [DO_REPORT.CONSENT_LOADED]: {
    actions: forwardEventData(DO_RECORD.CONSENT_SUCCESS),
  },
  [DO_REPORT.CONSENT_MOCKED]: {
    actions: forwardEventData(DO_RECORD.CONSENT_FAILURE),
  },

  [REPORT_AUCTION.START]: {
    actions: [forwardEventData(DO_RECORD.AUCTION_START), auctionCreate, auctionStart],
  },
  [REPORT_AUCTION.END]: {
    actions: [forwardEventData(DO_RECORD.AUCTION_END), auctionEnd],
  },
  [REPORT_AUCTION.AD_LOAD]: [
    {
      guard: and([
        IF.timingCollectionEnabled,
        ({ context, event }) =>
          context.timing.unsentAuctions.includes(
            (event as AuctionReportEvent<REPORT_AUCTION.AD_LOAD>).data.auction,
          ),
        // { type: GUARDS.AUCTION_IS_UNSENT, params: ({ event: { data: { auction } } }) => auction }
      ]),
      actions: [
        forwardEventData(DO_RECORD.AUCTION_AD_LOAD),
        ({ context, event }) => {
          sendBeacon(context.timing.endpoint, {
            ...context.timing.payload,
            auction: context.timing.auctionPayloads[event.data.auction],
          });
        },
        assign<AuctionReportEvent<REPORT_AUCTION.AD_LOAD>>({
          timing: ({ context, event }) => ({
            ...context.timing,
            unsentAuctions: context.timing.unsentAuctions.filter(
              auction => auction !== event.data.auction,
            ),
          }),
        }),
      ],
    },
    {
      actions: [forwardEventData(DO_RECORD.AUCTION_AD_LOAD)],
    },
  ],
  [REPORT_AUCTION_PARTNER.REQUEST]: {
    actions: [
      forwardEventData(DO_RECORD.AUCTION_PARTNER_REQUEST),
      auctionPartnerCreate,
      auctionPartnerRequest,
    ],
  },
  [REPORT_AUCTION_PARTNER.SUCCESS]: {
    actions: [
      forwardEventData(DO_RECORD.AUCTION_PARTNER_SUCCESS),
      auctionPartnerSuccess,
      sendPartnerBidsToFreyr,
    ],
  },
  [REPORT_AUCTION_PARTNER.TIMEOUT]: {
    actions: [forwardEventData(DO_RECORD.AUCTION_PARTNER_TIMEOUT), auctionPartnerTimeout],
  },
};
