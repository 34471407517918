import {
  Slot,
  DataObjectType,
  Slotify_ON,
  SlotifyMachineContext,
  CompanionBounds,
  AdDefinition,
  AdMatch,
  Slotify_SPAWN,
  SlotPositionedEvent,
} from '@repo/shared-types';
import { getEnv, fastdom } from '@repo/utils';
import { EventObject, fromCallback, fromPromise } from 'xstate';
import { overlapsExclusionZones } from '../exclusion';
import staticSlotGenerator from '../generator/static-slots';
import dynamicSlotGenerator from '../generator/dynamic-slots';
import affinityAdGenerator from '../generator/affinity-ads';
import findBestAdForSlot from '../find-best-ad-for-slot';
import { passesAdditionalAvoidance, passesAvoidanceRules } from '../avoidance';
import positionElement from 'ad-framework/slot/position-element';
import insertAds from './insert-ads';
import createAds from './create-ads';
import slotWatcher from './slot-watcher';

const slotPositioner = fromCallback<
  EventObject,
  {
    slot: DataObjectType<Slot>;
  }
>(({ input: { slot }, sendBack }) => {
  fastdom
    .mutate(() => {
      positionElement(
        slot.getProperty('element'),
        slot.getProperty('position'),
        slot.getProperty('hookElement'),
      );
    })
    .then(() =>
      fastdom.measure(() => {
        const env = getEnv();
        const scrollX = env.scrollX;
        const scrollY = env.scrollY;
        const bounds = slot.getProperty('element').getBoundingClientRect();
        return {
          x: bounds.x + scrollX,
          y: bounds.y + scrollY,
        };
      }),
    )
    .then(position => {
      sendBack({
        type: Slotify_ON.slotPositioned,
        data: {
          slot,
          position,
        },
      } as SlotPositionedEvent);
    });
});

const matchSlot = fromPromise<
  null | Array<AdMatch>,
  { slot: DataObjectType<Slot> } & Pick<
    SlotifyMachineContext,
    | 'slots'
    | 'overrideCompanionBounds'
    | 'config'
    | 'takeoverActive'
    | 'avoidanceDistance'
    | 'takeoverIncrementals'
    | 'adUnits'
    | 'ads'
  >
>(async ({ input }) => {
  const { slot, slots, overrideCompanionBounds, config } = input;

  const masterName = slot.getProperty('genericName');
  const companionDefinitions = config.placement.slots.static.filter(
    slotDefinition => slotDefinition.master === masterName,
  );
  return fastdom.measure(() => {
    if (companionDefinitions.length) {
      const potentialCompanions = slots
        .getValues()
        .filter(slot => slot.getProperty('master') === masterName && !slot.getProperty('masterID'));
      const companionSets = companionDefinitions.map(slotDefinition =>
        potentialCompanions.filter(
          companion => companion.getProperty('genericName') === slotDefinition.name,
        ),
      );

      const comanionsAvailable = companionSets.every(companionSet => companionSet.length > 0);
      if (comanionsAvailable) {
        const env = getEnv();
        const defaultPageBounds: CompanionBounds = { above: 1, below: 800 };
        const pageBounds: CompanionBounds = {
          ...defaultPageBounds,
          ...overrideCompanionBounds,
          ...slot.getProperty('companionBounds'),
        };

        const bounds = slot.getProperty('element').getBoundingClientRect();
        const lowerBound = pageBounds.below === 'screenheight' ? env.innerHeight : pageBounds.below;
        const upperBound = pageBounds.above === 'screenheight' ? env.innerHeight : pageBounds.above;

        const closeCompanions = companionSets.map(companionSet => {
          return companionSet.find(otherSlot => {
            const otherBounds = otherSlot.getProperty('element').getBoundingClientRect();
            if (otherBounds.top - bounds.top > lowerBound) return false;
            if (bounds.top - otherBounds.bottom > upperBound) return false;
            return true;
          });
        });
        const allCompanionsValid = closeCompanions.every(
          (otherSlot): otherSlot is DataObjectType<Slot> => Boolean(otherSlot),
        );
        if (allCompanionsValid) {
          // Tandem Slots
          closeCompanions.forEach(companionSlot => {
            companionSlot.update({ masterID: slot.getProperty('id') });
          });
          const allSlots = [slot, ...closeCompanions];
          return allSlots.reduce((matches: null | Array<AdMatch>, tandemSlot) => {
            if (!matches) return matches; // If any failed, short-circuit: return null

            const adDefinition = findAdDefinition(tandemSlot, input);
            if (!adDefinition) return null;

            matches.push({
              slot: tandemSlot,
              adDefinition,
            });
            return matches;
          }, []);
        }
      }
    } else if (!slot.getProperty('master')) {
      // Normal slot
      const adDefinition = findAdDefinition(slot, input);
      if (adDefinition) {
        return [
          {
            slot,
            adDefinition,
          },
        ];
      }
    }
    return null;
  });
});

const slotifyActors = {
  [Slotify_SPAWN.staticSlotGenerator]: staticSlotGenerator,
  [Slotify_SPAWN.dynamicSlotGenerator]: dynamicSlotGenerator,
  [Slotify_SPAWN.affinityAdGenerator]: affinityAdGenerator,
  [Slotify_SPAWN.slotWatcher]: slotWatcher,
  [Slotify_SPAWN.matchSlot]: matchSlot,
  [Slotify_SPAWN.createAds]: createAds,
  [Slotify_SPAWN.insertAds]: insertAds,
  [Slotify_SPAWN.slotPositioner]: slotPositioner,
};
export default slotifyActors;

const findAdDefinition = (
  slot: DataObjectType<Slot>,
  context: Pick<
    SlotifyMachineContext,
    'takeoverActive' | 'avoidanceDistance' | 'takeoverIncrementals' | 'adUnits' | 'slots' | 'ads'
  >,
): null | AdDefinition => {
  const {
    takeoverActive,
    avoidanceDistance,
    takeoverIncrementals,
    adUnits: { incremental: adDefinitions },
  } = context;
  const selectedAdDefinitions =
    takeoverActive && !slot.getProperty('nativeContent') ? takeoverIncrementals : adDefinitions;

  if (selectedAdDefinitions.length === 0) {
    return null;
  }

  if (slot.getProperty('adID') !== undefined) {
    return null;
  }
  if (!passesAdditionalAvoidance(avoidanceDistance, slot)) {
    return null;
  }
  if (!slot.getProperty('ignoreExclusion') && overlapsExclusionZones(slot)) {
    return null;
  }
  const adDefinition = selectedAdDefinitions.reduce(findBestAdForSlot(slot), null);

  if (
    adDefinition &&
    (adDefinition.ignoreCategoryAvoidance || passesAvoidanceRules(context, slot, adDefinition))
  ) {
    return adDefinition;
  }
  return null;
};
