import { SlotPosition, SlotType, SommelierResponseSlot } from '@repo/shared-types';

const topLeaderboardSlot: SommelierResponseSlot = {
  name: 'top-leaderboard',
  hook: '#top-leaderboard',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: 320,
    h: 100,
  },
  properties: {},
};

export default topLeaderboardSlot;
