const debounce = <F extends (...args: Array<any>) => void = (...args: Array<unknown>) => void>(
  method: F,
  delay = 100,
): F => {
  let lastArgs: Array<unknown> | null = null;
  let timeout: number | null = null;
  const exec = (args: Array<unknown>): void => {
    method(...args);
    timeout = window.setTimeout(() => {
      timeout = null;
      if (lastArgs !== null) {
        const a = lastArgs;
        lastArgs = null;
        exec(a);
      }
    }, delay);
  };
  return ((...args: Array<unknown>) => {
    if (timeout === null) {
      exec(args);
    } else {
      lastArgs = args;
    }
  }) as F;
};

export default debounce;
