import {
  Slot,
  DataObject,
  SlotViewabilityChangedEvent,
  SlotWatcher_EMIT,
} from '@repo/shared-types';
import { EventObject, fromCallback } from 'xstate';
import SlotObserver from '../observer';

const slotWatcher = fromCallback<
  EventObject,
  {
    slot: DataObject<Slot>;
    activationDistance: number;
  }
>(({ input: { slot, activationDistance }, sendBack }) => {
  const element = slot.getProperty('element');

  const slotBuffer =
    slot.getProperty('genericName') === 'sponsored'
      ? slot.getProperty('sponsoredSlotActivationDistanceOverride') || activationDistance
      : activationDistance;

  const slotName = slot.getProperty('name');

  const slotObserver = new SlotObserver(element, slotName, slotBuffer);

  slotObserver.observe(event => {
    sendBack({
      type: SlotWatcher_EMIT.viewabilityChanged,
      data: {
        slot,
        intersection: event,
      },
    } as SlotViewabilityChangedEvent);
  });
});

export default slotWatcher;
