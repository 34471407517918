import { Slot, DataObjectStore } from '@repo/shared-types';
import setupElements from './setup-elements';

const slotHighlights: Record<string, HTMLElement> = {};
let highlightAllSlots = false;

const slotHighlightStyles = {
  position: 'absolute',
  top: '-20px',
  left: '-20px',
  border: 'solid 20px rgba(255,192,203, 0.66)',
  zIndex: '10001',
  boxSizing: 'content-box',
  width: '100%',
  height: '100%',
};

const slotInformationStyles = {
  fontFamily: `'Fira Mono', monospace`,
  fontWeight: '700',
  color: 'black',
  marginTop: '-20px',
  marginLeft: '-20px',
  fontSize: '20px',
  width: 'fit-content',
  backgroundColor: 'rgba(230, 127, 144)',
  zIndex: '10002',
  position: 'absolute',
  top: '0px',
};

export const addSlotHighlight = (slots: DataObjectStore<Slot>, name: string): void => {
  let slotElement: Element | null = null;
  slots.getValues().forEach(slot => {
    if (slot.getProperty('name') === name) {
      slotElement = slot.getProperty('element') || null;
    }
  });
  if (!slotElement) {
    return;
  }

  const config = {
    name: ' - slot',
    id: '-bdx-slot-highlight',
    highlight: slotHighlightStyles,
    information: slotInformationStyles,
  };

  setupElements(slotElement, name, slotHighlights, config);
};

export const removeSlotHighlight = (name: string): void => {
  const highlight = slotHighlights[name];
  if (!highlight) {
    return;
  }

  if (highlight.parentElement) {
    highlight.parentElement.removeChild(highlight);
  }

  delete slotHighlights[name];
};

export const addAllSlotHighlights = (slots: DataObjectStore<Slot>): void => {
  slots.getValues().forEach(slot => {
    if (!Object.keys(slotHighlights).includes(slot.getProperty('name'))) {
      addSlotHighlight(slots, slot.getProperty('name'));
    }
  });
  highlightAllSlots = true;
};

export const removeAllSlotHighlights = (): void => {
  Object.keys(slotHighlights).forEach(name => {
    removeSlotHighlight(name);
  });
  highlightAllSlots = false;
};

export const updateSlotHighlights = (slots: DataObjectStore<Slot>): void => {
  if (highlightAllSlots) {
    addAllSlotHighlights(slots);
  }
};
