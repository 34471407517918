import { log } from '@repo/utils';
import { API_EMIT, Targeting } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export default (service: ApiMachineEventService, targetingData: Targeting): void => {
  if (typeof targetingData !== 'object' || Array.isArray(targetingData) || targetingData == null) {
    log.info(`Attempted to set targeting with ${JSON.stringify(targetingData)}`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  const cleanTargetingData = Object.fromEntries(
    Object.entries(targetingData).map(([key, value]) => {
      if (value === null || value === undefined || typeof value === 'boolean') {
        return [key, String(value)];
      }
      return [key, value];
    }),
  );

  service.sendEvent({
    type: API_EMIT.SET_PAGE_TARGETING,
    data: cleanTargetingData,
  });
};
