import { Screens, ScreensDefinition } from '@repo/shared-types';
import createSlotDataObject from 'ad-framework/slot';
import selectSlotHooks from 'slotify/selection';

const screenSlotGenerator = (screens: ScreensDefinition): Screens => {
  return Object.fromEntries(
    Object.entries(screens).flatMap(([screenId, screenDefinition]) => {
      // Make a new screen for each definition
      const matchHooks = screenDefinition.matches.map(match => ({
        match,
        hooks: selectSlotHooks(match.slotDefinition, screenDefinition.recur),
      }));
      if (screenId === 'none') {
        return [
          [
            screenId,
            {
              ...screenDefinition,
              id: screenId,
              unsatisfiedInView: false,
              everSatisfied: false,
              satisfied: false,
              destroyed: false,
              alreadyInView: false,
              inView: false,
              inBuffer: false,
              slotsInView: {},
              slotsInBuffer: {},
              matches: matchHooks.flatMap(({ match, hooks }) => {
                return hooks.map((hookElement, i) => {
                  const id = `bordeaux-slot_${match.slotDefinition.name}-hook_${i}`;
                  const slot = createSlotDataObject(match.slotDefinition, {
                    id,
                    hookElement,
                    screen: screenId,
                    ...(match.slotDefinition.multiple
                      ? {
                          name: `${match.slotDefinition.name}-${i}`,
                        }
                      : {}),
                  });
                  return {
                    ...match,
                    slot,
                  };
                });
              }),
            },
          ],
        ];
      }
      return matchHooks[0].hooks.map((_, i) => [
        `${screenId}.${i}`,
        {
          ...screenDefinition,
          id: `${screenId}.${i}`,
          unsatisfiedInView: false,
          everSatisfied: false,
          satisfied: false,
          destroyed: false,
          alreadyInView: false,
          inView: false,
          inBuffer: false,
          slotsInView: {},
          slotsInBuffer: {},
          matches: matchHooks.map(({ match, hooks }) => {
            const hookElement = hooks[i];
            const id = `bordeaux-slot_${match.slotDefinition.name}-hook_${i}`;
            const slot = createSlotDataObject(match.slotDefinition, {
              id,
              hookElement,
              screen: `${screenId}.${i}`,
              ...(match.slotDefinition.multiple
                ? {
                    name: `${match.slotDefinition.name}-${i}`,
                  }
                : {}),
            });
            return {
              ...match,
              slot,
            };
          }),
        },
      ]);
    }),
  );
};

export default screenSlotGenerator;
