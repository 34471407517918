import getEnv from './env';
import { TimeData } from '@repo/shared-types';

const timestamp = (): number => {
  const env = getEnv();
  return env.performance ? env.performance.now() : Date.now();
};
export default timestamp;

export const timeData = (): TimeData => ({
  timeStamp: timestamp(),
  dateString: new Date().toISOString(),
});
