export enum SlotType {
  BLOCK = 'block',
  INLINE = 'inline',
  INLINE_LEFT = 'inline_left',
  INLINE_RIGHT = 'inline_right',
  INLINE_LEFT_RIGHT = 'inline_left_right',
  GENERATED = 'generated',
}
export enum SlotPosition {
  BEFORE = 'before',
  AFTER = 'after',
  PREPEND = 'prepend',
  APPEND = 'append',
}
export enum SlotLabelPosition {
  ABOVE = 'above',
  BELOW = 'below',
}
export enum SlotAlign {
  CENTER = 'center',
}
export enum SlotStatus {
  OFF_SCREEN = 'OFF_SCREEN',
  NEARLY_VISIBLE = 'NEARLY_VISIBLE',
  VISIBLE = 'VISIBLE',
}