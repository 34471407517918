import { Ad, DataObject, GPTOutput } from '@repo/shared-types';

export default function isAnchoredHideable(
  ads: Array<DataObject<Ad>>,
  anchoredLineItems: Array<number> | undefined,
): boolean {
  if (anchoredLineItems) {
    return ads
      .map(ad => ad.getProperty('gptOutput'))
      .filter((gptOutput): gptOutput is GPTOutput => !!gptOutput)
      .map(gptOutput => gptOutput.lineItem)
      .some(lineItem => anchoredLineItems.includes(lineItem));
  }
  return false;
}
