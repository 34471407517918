import { AdUnitMode, SommelierResponseAdUnit } from '@repo/shared-types';

export const anchoredLeaderboardAdUnit: SommelierResponseAdUnit = {
  type: AdUnitMode.ANCHORED,
  name: 'anchored-leaderboard',
  sizes: [
    { w: 320, h: 50 },
    { w: 300, h: 50 },
    { w: 300, h: 31 },
  ],
  targeting: {
    pos: ['mobile_anchored_leaderboard', 'mobile_sticky_leaderboard', '1'],
    placement: ['dfp_rs_mobile_anchored_leaderboard', 'dfp_rs_mobile_sticky_leaderboard'],
  },
  properties: {
    refresh: 'true',
  },
};

export const topLeaderboardAdUnit: SommelierResponseAdUnit = {
  type: 'standard',
  name: 'top-leaderboard',
  slotAffinity: 'top-leaderboard',
  sizes: [
    { w: 320, h: 50 },
    { w: 300, h: 50 },
  ],
  targeting: {
    pos: ['mobile_top_leaderboard', '2'],
    placement: ['dfp_rs_top_leaderboard'],
  },
  properties: {
    centerVertically: 'true',
  },
};
