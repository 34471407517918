import { BordeauxMachineContext, AnyBordeauxEvent } from '@repo/shared-types';
import {
  ActorRef,
  NonReducibleUnknown,
  sendTo as genericSendTo,
  Snapshot,
  ParameterizedObject,
} from 'xstate';

const sendTo = <
  M extends ActorRef<Snapshot<unknown>, any>,
  E extends AnyBordeauxEvent = AnyBordeauxEvent,
  P extends ParameterizedObject['params'] = NonReducibleUnknown,
>(
  ...args: Parameters<
    typeof genericSendTo<BordeauxMachineContext, E, P, M, AnyBordeauxEvent, string>
  >
) => genericSendTo<BordeauxMachineContext, E, P, M, AnyBordeauxEvent>(...args);

export default sendTo;
