import { z } from 'zod';
import { settingsSchema } from './zod-schema';
import { Settings } from '../../../types/ad-framework/settings/index.types';

const adaptSettingsConfig = ({
  roadblock,
  takeover,
  ...same
}: z.output<typeof settingsSchema>): Settings => ({
  ...same,
  ...((takeover || roadblock) ? {takeover: {
    ...roadblock,
    ...takeover,
  }} : {}) as Settings['takeover']
});
export default adaptSettingsConfig;
