import { log } from '@repo/utils';
import { API_EMIT } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export const getPageTemplate = (service: ApiMachineEventService): Promise<undefined | string> =>
  service.getData<string | undefined>('pageTemplate');

export default (service: ApiMachineEventService, pageTemplate: string): void => {
  if (typeof pageTemplate !== 'string') {
    log.info(`Argument to setPageTemplate must be a string, ${typeof pageTemplate} given`);
    metrics.recordInputErrorBordeauxApi();
    return;
  }

  service.sendEvent({
    type: API_EMIT.SET_PAGE_TEMPLATE,
    data: pageTemplate.replace(/[^a-zA-Z0-9-_]/g, ''),
  });
};
