import {
  Slot,
  FEATURE,
  DataObjectType,
  Slotify_IF,
  AdUnitMode,
  SlotifyMachineContext,
  AnySlotifyEvent,
  SlotifyGuards,
  SlotObserverData,
} from '@repo/shared-types';
import { GuardPredicate } from 'xstate/guards';

const slotifyGuards: Record<
  Slotify_IF,
  GuardPredicate<SlotifyMachineContext, AnySlotifyEvent, any, SlotifyGuards>
> = {
  [Slotify_IF.firstAuctionDone]: ({ context: { firstAuctionDone } }) => firstAuctionDone,
  [Slotify_IF.standardAdsEnabled]: ({ context: { features } }) => features[FEATURE.ADS_STANDARD],
  [Slotify_IF.incrementalAdsEnabled]: ({ context: { features } }) =>
    features[FEATURE.ADS_INCREMENTAL],
  [Slotify_IF.slotsToProcess]: ({ context: { slotStack } }) => slotStack.length !== 0,
  [Slotify_IF.slotIsNative]: ({}, { slot }: { slot: DataObjectType<Slot> }) =>
    slot.getProperty('nativeContent'),
  [Slotify_IF.nativeContentFilled]: ({ context: { slots } }) =>
    slots.getValues().every(slot => !slot.getProperty('nativeContent') || slot.getProperty('adID')),
  [Slotify_IF.takeoverIncrementalsFilled]: ({
    context: {
      takeoverActive,
      takeoverIncrementalCaps,
      takeoverIncrementalCount,
      pageParameters: { device },
      config: {
        placement: {
          settings: { takeover },
        },
      },
    },
  }) => {
    // Makes sure we never exceed the takeover incremental cap

    if (!takeoverActive) return false;

    const incrementalCap = takeoverIncrementalCaps?.[device] ?? takeover?.incrementalCap;

    if (!incrementalCap) {
      return true;
    }

    if (takeoverIncrementalCount >= incrementalCap) {
      return true;
    }

    return false;
  },
  [Slotify_IF.takeoverActive]: ({ context: { takeoverActive } }) => takeoverActive,
  [Slotify_IF.anchoredNotInTakeover]: ({
    context: {
      adUnits: { standard: adDefinitions },
    },
  }) =>
    adDefinitions.some(
      adDefinition => adDefinition.mode === AdUnitMode.ANCHORED && !adDefinition.inTakeover,
    ),
  [Slotify_IF.incrementalsStarted]: ({ context: { incrementalsStarted } }) => incrementalsStarted,

  [Slotify_IF.intersectionInView]: (_, intersection: SlotObserverData) =>
    intersection.isIntersecting && intersection.scrollPosition !== -1,
  [Slotify_IF.allSlotsPositioned]: ({ context: { slots, slotPositions } }) =>
    slots.getValues().every(slot => slot.getProperty('id') in slotPositions),
};
export default slotifyGuards;
