import {
  DataObjectType,
  SlotifyMachineContext,
  Ad,
  Targeting,
  AdDefinition,
  Slot,
} from '@repo/shared-types';
import { selectFloorPrice, floorPriceTargeting, containsVariant } from '@repo/utils';
import { fromPromise } from 'xstate';
import autoIncrementTargeting from 'ad-framework/ad/auto-increment-targeting';
import createAdDataObject from 'ad-framework/ad';
import { BordeauxConfig } from '@repo/shared-types/src/zod-schemas';
import { generateViewabilityTargeting } from 'slotify/viewability-targeting';

export const createAdData = (
  {
    slot,
    adDefinition,
    adID,
    adUnitPath,
    recuring,
  }: {
    slot?: DataObjectType<Slot>;
    recuring?: boolean;
    adDefinition: AdDefinition;
    adID: string;
    adUnitPath: string;
  },
  config: BordeauxConfig,
): DataObjectType<Ad> => {
  if (slot) {
    slot.update({ adID });
  }

  let fpTargeting: Targeting = {};
  if (
    (containsVariant(config, '50', '163') || containsVariant(config, '50', '164')) &&
    config.bidFloors?.floorPrices
  ) {
    const floorPrice = selectFloorPrice(config, adDefinition.sizes);
    if (floorPrice) {
      fpTargeting = {
        flrp: floorPriceTargeting(floorPrice),
      };
    }
  }
  let targeting: Targeting = {};
  if (slot) {
    targeting = {
      ...targeting,
      ...fpTargeting,
      ...generateViewabilityTargeting(adDefinition, config.placement, slot),
      _slot: slot.getProperty('name'),
      _slot_type: slot.getProperty('genericName'),
    };
  }
  if (adDefinition.incremental || recuring) {
    targeting = {
      ...targeting,
      ...autoIncrementTargeting(adDefinition),
      adUnitName: [adDefinition.name],
    };
  }
  if (adDefinition.mode === 'anchored') {
    targeting = {
      ...targeting,
      ...generateViewabilityTargeting(adDefinition, config.placement, slot),
    };
  }

  return createAdDataObject(adDefinition, {
    id: adID,
    targeting,
    ...(slot
      ? {
          slotID: slot.getProperty('id'),
        }
      : {}),
    adUnitPath,
  });
};

const createAds = fromPromise<
  Array<DataObjectType<Ad>>,
  Pick<
    SlotifyMachineContext,
    'adMatches' | 'adCounter' | 'pageAdUnitPath' | 'adTypeCounters' | 'config'
  >
>(async ({ input: { adMatches, adCounter, pageAdUnitPath, adTypeCounters, config } }) =>
  adMatches.map(({ slot, adDefinition }, index, list) => {
    const adID = `bordeaux-ad-${adCounter + index}`;
    const adUnitPath = adDefinition.incremental
      ? `${pageAdUnitPath}/${adDefinition.name}-${Math.min(10, list.slice(0, index).reduce((counter, item) => counter + (item.adDefinition.name === adDefinition.name ? 1 : 0), 0) + (adTypeCounters[adDefinition.name] || 0))}`
      : `${pageAdUnitPath}/${adDefinition.name}`;

    return createAdData({ slot, adDefinition, adID, adUnitPath }, config);
  }),
);

export default createAds;
