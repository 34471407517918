export enum ON {
  setHybridABTestTargeting = 'BORDEAUX:EVENT:SET_HYBRID_ABTEST_TARGETING',
  setHybridId = 'BORDEAUX:EVENT:SET_HYBRID_ID',
  setCFTParameters = 'BORDEAUX:EVENT:SET_CFT_PARAMETERS',
  setTakeoverStatus = 'BORDEAUX:EVENT:SET_TAKEOVER_STATUS',
  pageUnload = 'BORDEAUX:EVENT:PAGE_UNLOAD',
  pageLoad = 'BORDEAUX:EVENT:PAGE_LOAD',
  openAdTool = 'BORDEAUX:EVENT:OPEN_AD_TOOL',
  automaticRefresh = 'BORDEAUX:EVENT:AUTOMATIC_REFRESH',
  triggerAutomaticRefresh = 'BORDEAUX:EVENT:TRIGGER_AUTOMATIC_REFRESH',
  setExternalAPI = 'BORDEAUX:EVENT:SET_EXTERNAL_API',

  requestBordeauxData = 'BORDEAUX:EVENT:REQUEST_BORDEAUX_DATA',
  provideBordeauxData = 'BORDEAUX:EVENT:PROVIDE_BORDEAUX_DATA',

  requestBordeauxContext = 'BORDEAUX:EVENT:REQUEST_BORDEAUX_CONTEXT',
  provideBordeauxContext = 'BORDEAUX:EVENT:PROVIDE_BORDEAUX_CONTEXT',
  toolRequestBordeauxContext = 'BORDEAUX:EVENT:TOOL_REQUEST_BORDEAUX_CONTEXT',

  consentDone = 'BORDEAUX:EVENT:CONSENT_DONE',

  hideAnchoredAds = 'BORDEAUX:EVENT:HIDE_ANCHORED_ADS',
  showAnchoredAds = 'BORDEAUX:EVENT:SHOW_ANCHORED_ADS',

  hideBigtopAds = 'BORDEAUX:EVENT:HIDE_BIGTOP_ADS',
  showBigtopAds = 'BORDEAUX:EVENT:SHOW_BIGTOP_ADS',
  checkBigtopAllowed = 'BORDEAUX:EVENT:CHECK_BIGTOP_ALLOWED',

  requestAuction = 'BORDEAUX:EVENT:REQUEST_AUCTION',
  auctionProcessed = 'BORDEAUX:EVENT:AUCTION_PROCESSED',
  screenPositioned = 'BORDEAUX:EVENT:SCREEN_POSITIONED',
  screensPositioned = 'BORDEAUX:EVENT:SCREENS_POSITIONED',
  destroyScreen = 'BORDEAUX:EVENT:DESTROY_SCREEN',
  updateScreens = 'BORDEAUX:EVENT:UPDATE_SCREENS',
  set_adToolPage = 'BORDEAUX:EVENT:SET_AD_TOOL_PAGE',
  toolRequest = 'BORDEAUX:EVENT:TOOL_REQUEST'
}
