import { AdUnitMode, SommelierResponseAdUnit } from '@repo/shared-types';

export default {
  type: AdUnitMode.OOP,
  name: 'overlay',
  sizes: [{ w: 1, h: 1 }],
  targeting: {
    placement: [`dfp_rs_desktop_overlay_oop_1`],
    oop: ['overlay'],
  },
  properties: {},
} as SommelierResponseAdUnit;
