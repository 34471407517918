
export enum GDPRConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'ACCEPT' = 'ACCEPT ALL',
  'LEGITIMATE_INTEREST' = 'LEGITIMATE INTEREST',
  'REJECT_ALL' = 'REJECT ALL',
}
export enum USPConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'TRUE' = 'TRUE',
  'FALSE' = 'FALSE',
}
export enum GPPConsentStatus {
  'NOT_APPLICABLE' = 'NOT APPLICABLE',
  'NULL_PAYLOAD' = 'NULL_PAYLOAD',
  'TRUE' = 'TRUE',
  // 'FALSE' = 'FALSE',
}
export enum GDPR_CMP_EVENTS {
  PENDING = 'GDPR_CMP_PENDING',
  MOCKED = 'GDPR_CMP_MOCKED',
  LOADED = 'GDPR_CMP_LOADED',
  FAILURE = 'GDPR_CMP_FAILURE',
}
export enum CMP_EMIT {
  pending = 'CMP:EVENT_OUT:PENDING',
  loaded = 'CMP:EVENT_OUT:LOADED',
  mocked = 'CMP:EVENT_OUT:MOCKED',
}
export enum CMP_GO {
  getTCFAPI = 'CMP:STATE:GET_TCF_API',
  loadAPI = 'CMP:STATE:LOAD_API',
  retrieveConsent = 'CMP:STATE:RETRIEVE_CONSENT',
  finished = 'CMP:STATE:FINISHED',
}
export enum CMP_SPAWN {
  getTCFAPI = 'CMP:ACTOR:GET_TCF_API',
  getGDPRLoaded = 'CMP:ACTOR:GET_GDPR_LOADED_MACHINE',
  retrieveGDPRConsent = 'CMP:ACTOR:RETRIEVE_GDPR_CONSENT_MACHINE',
}