import { log, fastdom } from '@repo/utils';
import { ActionArgs, SlotPosition, FullWidthAdFeatureEvent } from '@repo/shared-types';

const fullWidth = ({ context }: ActionArgs, data: FullWidthAdFeatureEvent['data']): void => {
  const slot = context.slots.getValues().find(slot => slot.getProperty('id') === data.slotID);
  if (!slot) {
    log.info(`Could not make ad full width, slot with ID ${data.slotID} does not exist.`);
    return;
  }

  const adID = slot.getProperty('adID');
  if (!adID) {
    log.info(`Could not make ad full width, slot does not have an ad associated with it.`);
    return;
  }

  const ad = context.ads.getValues().find(ad => ad.getProperty('id') === adID);
  if (!ad) {
    log.info(`Could not make ad full width, ad with ID ${adID} does not exist.`);
    return;
  }

  const element = slot.getProperty('element');
  const hookElement = slot.getProperty('hookElement');

  fastdom.mutate(() => {
    Object.assign(element.style, {
      width: '100%',
      maxWidth: '100vw',
    });
    const position = slot.getProperty('position');
    if (position === SlotPosition.APPEND || position === SlotPosition.PREPEND) {
      Object.assign(hookElement.style, {
        width: '100%',
        maxWidth: '100vw',
      });
    }
    [...element.querySelectorAll<HTMLElement>('.celtra-ad-inline-host')].forEach(celtraElement => {
      Object.assign(celtraElement.style, {
        minWidth: '100vw',
      });
    });
  });
};
export default fullWidth;
