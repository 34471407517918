import isAbsolute from 'is-absolute-url';
import { getBordeauxLocation } from '@repo/utils';

declare let __webpack_public_path__: string;

if (!isAbsolute(__webpack_public_path__)) {
  const bordeauxLocation = getBordeauxLocation();
  __webpack_public_path__ = bordeauxLocation + '/';
}

if (!__webpack_public_path__) {
  throw new Error('No __webpack_public_path__ - chunk loading will not work');
}
