import { log } from '@repo/utils';
import { API_EMIT, TakeoverIncrementalChooser } from '@repo/shared-types';
import { ApiMachineEventService } from './events.service';
import metrics from 'metrics';

export default (
  service: ApiMachineEventService,
  takeoverIncrementalChooser: TakeoverIncrementalChooser,
): void => {
  if (typeof takeoverIncrementalChooser !== 'function') {
    log.error(
      `Argument passed to setTakeoverIncrementalChooser must be a function, ${typeof takeoverIncrementalChooser} given`,
    );
    metrics.recordInputErrorBordeauxApi();
    return;
  }
  service.sendEvent({
    type: API_EMIT.SET_TAKEOVER_INCREMENTAL_CHOOSER,
    data: takeoverIncrementalChooser,
  });
};
