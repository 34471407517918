import getParam from './get-param';

const ABTEST_VARIANT_SEPARATOR = '|';
export const VARIANT_IS_CONTROL = 0;
export const NO_CODE = 0;

export default class AbTest {
  private code: number;

  private variant: number;

  constructor(abTest: string | null) {
    if (!abTest) {
      this.code = NO_CODE;
      this.variant = VARIANT_IS_CONTROL;
      return;
    }
    const codes = abTest.split(ABTEST_VARIANT_SEPARATOR);
    if (codes.length === 2) {
      this.code = parseInt(codes[0], 10) || NO_CODE;
      this.variant =
        this.code === NO_CODE ? VARIANT_IS_CONTROL : parseInt(codes[1], 10) || VARIANT_IS_CONTROL;
    } else if (codes.length === 1) {
      this.code = parseInt(abTest, 10) || NO_CODE;
      this.variant = VARIANT_IS_CONTROL;
    } else {
      this.code = NO_CODE;
      this.variant = VARIANT_IS_CONTROL;
    }
  }

  static splitAbTests(abTestsString: string | null): Record<string, AbTest> {
    const abTests: Record<string, AbTest> = {};
    if (abTestsString) {
      abTestsString.split(',').forEach((value: string) => {
        const abTest: AbTest = new AbTest(value);
        abTests[abTest.code] = abTest;
      });
    }
    return abTests;
  }

  static flattenAbTests(abTests: Record<string, AbTest>): string {
    return Object.values(abTests)
      .map(value => {
        return value.variant === VARIANT_IS_CONTROL
          ? value.code
          : `${value.code}${ABTEST_VARIANT_SEPARATOR}${value.variant}`;
      })
      .join(',');
  }

  static abTestsFromLocation(param: string): Record<string, AbTest> {
    const abTests: Record<string, AbTest> = {};
    const forceAbTest = getParam(param);
    if (forceAbTest) {
      forceAbTest.split(',').forEach((value: string) => {
        abTests[value] = new AbTest(value);
      });
    }
    return abTests;
  }

  getCode(): number {
    return this.code;
  }

  getVariant(): number {
    return this.variant;
  }
}
