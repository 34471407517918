import { Slot, DataObject } from '@repo/shared-types';
import { Env } from './env.types';

export type StyleData = string | { [key: string]: StyleData };
const LEFT = 'left';
const RIGHT = 'right';
const LEFT_RIGHT = `${LEFT}_${RIGHT}`;

const getInlineString = (floatDirection: string): string => {
  return `inline_${floatDirection}`;
};

export const flatten = (style: StyleData, window: Env): Record<string, string> =>
  Object.keys(style).reduce((obj, key) => ({ ...obj, ...match(style, key, window) }), {});

export const applyStyles = (
  element: HTMLElement,
  styles: CSSStyleDeclaration | Record<string, string>,
): void => {
  Object.assign(element.style, styles);
};

export const getOnMountSlotStyles = (
  slot: DataObject<Slot>,
  boilerplateHeight: string,
  nbOccurencesOfCurrentSlot: number,
): Record<string, string> => {
  const width = slot.getProperty('width');
  const height = slot.getProperty('height');
  const type = slot.getProperty('type');
  const style = slot.getProperty('style');
  return {
    ...(width ? { width: `${width}px` } : {}),
    ...(height
      ? {
          height: `${height + parseInt(boilerplateHeight, 10)}px`,
        }
      : {}),
    ...(type === getInlineString(LEFT) || type === getInlineString(RIGHT)
      ? { float: type === getInlineString(LEFT) ? LEFT : RIGHT }
      : {}),
    ...(type === getInlineString(LEFT_RIGHT)
      ? { float: nbOccurencesOfCurrentSlot % 2 !== 0 ? LEFT : RIGHT }
      : {}),
    ...style,
  };
};

export const stringToStyle = (style: string): CSSStyleDeclaration => {
  const styles = {} as CSSStyleDeclaration;
  style.split(';').forEach(s => {
    const parts = s.split(':', 2);
    if (parts.length > 1) {
      styles[parts[0].trim().replace(/-([a-z])/gi, (_, l) => l.toUpperCase())] = parts[1].trim();
    }
  });
  return styles;
};

const mediaQueryRegex = /@media/g;
const mediaQueryPosition = 7; // Ex. "@media (min-width: 340px)"
const match = (style: StyleData, key: string, window: Env): Record<string, string> => {
  if (key.match(mediaQueryRegex)) {
    if (window.matchMedia(key.slice(mediaQueryPosition)).matches) {
      return flatten(style[key] as StyleData, window);
    }
    return {};
  }
  return { [key]: style[key] as string };
};
