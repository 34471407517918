import getEnv from './env';
import { fromPromise } from 'xstate';
import { timeData } from './timestamp';
import { TimeData } from '@repo/shared-types';

export const getEnvDOMContentLoaded = fromPromise<TimeData>(
  (): Promise<TimeData> =>
    new Promise(resolve => {
      const env = getEnv();
      if (env.document.readyState === 'interactive' || env.document.readyState === 'complete') {
        resolve(timeData());
        return;
      }

      env.document.addEventListener('DOMContentLoaded', () => {
        resolve(timeData());
      });
    }),
);

export const getEnvLoad = (): Promise<TimeData> =>
  new Promise(resolve => {
    const env = getEnv();
    if (env.document.readyState === 'complete') {
      resolve(timeData());
      return;
    }

    env.addEventListener('load', () => {
      resolve(timeData());
    });
  });

export const getEnvUnload = (): Promise<TimeData> =>
  new Promise(resolve => {
    const env = getEnv();
    env.addEventListener('unload', () => {
      resolve(timeData());
    });
  });
