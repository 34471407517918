import {
  AnySlotDefinition,
  DynamicSlotDefinition,
  GeneratedSlotDefinition,
} from '../../../types/ad-framework/slot/index.types';
import { z } from 'zod';
import { slotListGroupSchema } from './zod-schema';
import { SlotListGroup } from '../../../types/ad-framework/slot-list-group/index.types';
import partition from '@repo/utils/src/array/partition';
  
const slotIsDynamic = (slot: AnySlotDefinition): slot is DynamicSlotDefinition =>
  Boolean(slot.dynamicConfig);
const slotIsGenerated = (slot: AnySlotDefinition): slot is GeneratedSlotDefinition =>
  Boolean('generatedConfig' in slot && slot.generatedConfig);

const adaptSlotListGroupConfig = (
  slotList: z.output<typeof slotListGroupSchema>,
): SlotListGroup => {
  const [nonDynamic, dynamic] = partition(slotList, slotIsDynamic);
  const [staticSlots, generated] = partition(nonDynamic, slotIsGenerated);
  return {
    static: staticSlots,
    dynamic,
    generated,
  };
};
export default adaptSlotListGroupConfig;
